import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { ApiService } from "../api/api-connectors";
import { BaseBusiness } from "../api/api-definitions";
import BusinessService from "../services/BusinessService";
import useOffline from "./useOffline";
import BusinessCacheService from "../services/BusinessCacheService";

export const LoadedLocation = atom<BaseBusiness>({
    key: 'LoadedLocation',
    default: null
})

export const useBusiness = function(): [boolean, BaseBusiness, (setData: BaseBusiness) => void, () => void] {
    const offline = useOffline();
    const [businessData, setBusinessData] = useRecoilState(LoadedLocation);
    const [loading, setLoading] = useState<boolean>(true);
    const params: any = useParams();
    const id = useRef()

    useEffect(() => {
        try {
            if (params.id !== id.current) loadBusiness();
            if (!params.id) setLoading(false)
        }
        catch (e) {
            console.error(e)
        }
    }, [params.id]);

    const loadBusiness = () => {
        if (params.id) {
            id.current = params.id;
            setLoading(true);
            (offline ? BusinessCacheService.GetBusiness(params.id) : BusinessService.GetBusiness(params.id)).then(data => {
                setBusinessData(data);
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }

    return [!loading, cloneDeep(businessData), setBusinessData, loadBusiness];
};