import React, { useState } from 'react';
import { ApiBusinessBookingDetails, WidgetImageLayout, ThemeOptions } from '../../../../api/api-definitions';
import styled from 'styled-components';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { ConvertCurrency } from '../../../../utils/currency-helper';
import Icon from '../../../../components/Media/Icon';
import ReadMore from '../../../../components/Typography/ReadMore';
import { Constants } from '../../../../constants';
import { Column, Row } from '../../../../components/Layout/Grid';

interface ComponentProps {
    title: string;
    description: string;
    price: number;
    location?: string;
    image?: string;
    business: ApiBusinessBookingDetails;
    layout: WidgetImageLayout;
    isSelected?: boolean;
    onSelect?: (id: number) => void;
    onSearch?: () => void;
    disabled?: boolean;
    id: number;
    unavailable?: boolean;
}

const BookingExperienceOption = ({
    id,
    title,
    description,
    image,
    price,
    layout,
    location,
    unavailable,
    business,
    isSelected,
    disabled,
    onSelect,
    onSearch
}: ComponentProps) => {
    return (
        <Wrapper hidePointer={!onSelect} widgetTheme={business?.theme} onClick={() => onSelect ? onSelect(isSelected ? undefined : id) : {}} selected={isSelected}>
            <ContentContainer leftImage={layout == WidgetImageLayout.Left}>
                {image && <ImageBanner src={`${Constants.baseImageUrl}${image}`} />}
                <ContentFlexContainer>
                    <div>
                        <TitleContainer>
                            <Title textLeft={layout == WidgetImageLayout.Left || isNullOrWhitespace(image)}>{title}</Title>
                            {unavailable && <UnavailableBox>Unavailable</UnavailableBox>}
                        </TitleContainer>
                        {!isNullOrWhitespace(description) && <Description><ReadMore text={description} /></Description>}
                    </div>
                    {layout == WidgetImageLayout.Left && ((!isNullOrWhitespace(price) && price > 0) || !isNullOrWhitespace(location)) &&
                        <BottomContainer>
                            {!isNullOrWhitespace(location) && <Location><IconContainer><LocationIcon name='location-dot' /></IconContainer>{location}</Location>}
                            {!isNullOrWhitespace(price) && price > 0 && <Price><span>{ConvertCurrency({ code: 'GBP', amount: price })} per person</span></Price>}
                        </BottomContainer>
                    }
                </ContentFlexContainer>
            </ContentContainer>
            {layout != WidgetImageLayout.Left && ((!isNullOrWhitespace(price) && price > 0) || !isNullOrWhitespace(location)) &&
                <BottomContainer>
                    {!isNullOrWhitespace(location) && <Location><IconContainer><LocationIcon name='location-dot' /></IconContainer>{location}</Location>}
                    {!isNullOrWhitespace(price) && price > 0 && <Price><span>{ConvertCurrency({ code: 'GBP', amount: price })} per person</span></Price>}
                </BottomContainer>
            }
            {onSelect && <SelectOptionBox selected={isSelected} widgetTheme={business?.theme}>{isSelected ? <><Icon name='check' /> Selected</> : 'Select option'}</SelectOptionBox>}
            {onSearch && <Button disabled={disabled} widgetTheme={business?.theme} onClick={() => onSearch()}>Search availability</Button>}
        </Wrapper>
    );
};

const ContentFlexContainer = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const ImageBanner = styled.img`
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    max-height: 10rem;
`;

const Button = styled.button<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 0.5rem;
    background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.buttonTextColour || props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    cursor: pointer;
    border: none;

    &:disabled {
        opacity: 0.7;
    }
`

const Wrapper = styled.div<{ widgetTheme?: ThemeOptions, selected?: boolean; hidePointer?: boolean; }>`
    border: ${props => props.selected ? '2px' : '1px'} solid ${props => props.widgetTheme?.primaryColour ? props.widgetTheme?.primaryColour : props.theme.primary};
    border-radius: 0.2rem;
    padding: 1rem;
    margin-bottom: 1rem;
    ${props => !props.hidePointer && `cursor: pointer`};
`

const Title = styled.div<{ colour?: string; textLeft?: boolean }>`
    font-weight: bold;
    flex: 1 1 auto;
    padding-right: 1rem;
    text-align: ${props => props.textLeft ? 'left' : 'center'};
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
`

const Location = styled.div`
    flex: 1 1 auto;
`

const LocationIcon = styled(Icon)`
    font-size: 0.8rem;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
`

const IconContainer = styled.div`
    width: 1.5rem;
    display: inline-block;
`

const TitleContainer = styled.div`
    display: flex;
`

const SelectOptionBox = styled.div<{ widgetTheme?: ThemeOptions, selected?: boolean }>`
    flex: none;
    border: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    height: fit-content;
    padding: 0.5rem;
    text-align: center;
    ${props => props.selected && `
        background-color: ${props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    `}
`

const UnavailableBox = styled.div<{ widgetTheme?: ThemeOptions, selected?: boolean }>`
    flex: none;
    width: 9rem;
    height: fit-content;
    padding: 0.5rem;
    text-align: center;
    font-style: italic;
`

const Price = styled.div`
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1rem;
    margin-top: 1rem;
    text-align: right;
    flex: none;
`

const Description = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`

const BottomContainer = styled.div`
    // display: flex;
    margin: 1rem 0;
`

const ContentContainer = styled.div<{ leftImage?: boolean }>`
    display: flex;
    ${props => props.leftImage ? `
        ${ImageBanner} {
            padding-right: 1rem;
            max-width: 50%;
        }
    ` : `
        flex-direction: column;
    `}
`

export default BookingExperienceOption;