import React, { useEffect, useState } from 'react';
import { BaseBusiness, BaseBusinessTag, OperationalStatus } from '../../../../../api/api-definitions';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { ApiService } from '../../../../../api/api-connectors';
import InfoButton from '../../../../../components/Cta/InfoButton';
import FileUpload from '../../../../../components/Forms/FileUpload';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../components/Forms/Messaging';
import TextInput from '../../../../../components/Forms/TextInput';
import { FloatingActionBar } from '../../../../../components/Layout/FloatingActionBar';
import Loader from '../../../../../components/Layout/Loader';
import { AnyUrlFieldSize, Constants } from '../../../../../constants';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../services/NotificationService';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';
import { Row, Column } from '../../../../../components/Layout/Grid';
import Textarea from '../../../../../components/Forms/Textarea';
import CoreButton from '../../../../../components/Forms/Button';
import Icon from '../../../../../components/Media/Icon';
import InlineLink from '../../../../../components/Navigation/inline-link';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../components/Dashboard/Panel';
import CoreModal from '../../../../../components/Layout/CoreModal';
import Checkbox from '../../../../../components/Forms/Checkbox';
import { StyledNumberInput, StyledTextarea, StyledTextInput } from '../../../../../theme/input.styles';
import ImageSelector from '../../../../../components/Forms/ImageSelector';

const ImageBanner = styled.div`
    width: 100%;
    height: 10rem;
    background-size: cover;
    background-position: center;
`;

interface ExtendedBaseBusinessTag extends BaseBusinessTag {
    guid: string;
    isDuplicate: boolean;
}

const BusinessDetails = () => {
    const [businessLoaded, data, setData, reloadBusiness] = useBusiness();
    const [businessData, setBusinessData] = useState<BaseBusiness>();
    const [hasDuplicateTags, setHasDuplicateTags] = useState<boolean>();
    const [modalOpen, setModalOpen] = useState(false);
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if (businessLoaded) {
            // if (!!data.tags && data.tags.length > 0) {
            //     data.tags.forEach((item: ExtendedBaseBusinessTag) => {
            //         item.guid = createUUID();
            //     })
            // } else {
            //     if (!data.tags) data.tags = []
            //     const newTag: ExtendedBaseBusinessTag = getNewTag(data)
            //     data.tags.push(newTag);
            // }

            setBusinessData(data)
        }
    }, [businessLoaded])

    function updateFormData(newData: BaseBusiness) {
        const oldData = cloneDeep(businessData);
        setBusinessData(Object.assign(oldData, newData));
    }

    function saveData() {
        setSaving(true)
        ApiService.business.Save__POST(businessData).then(() => {
            reloadBusiness()
            NotificationService.Confirm('Saved business info')
        }).catch(() => {
            NotificationService.Error('Failed to save business info')
        }).finally(() => setSaving(false))
    }

    // const addBusinessTag = () => {
    //     const newData = cloneDeep(businessData);
    //     const newTag: ExtendedBaseBusinessTag = getNewTag(businessData)
    //     newData.tags.push(newTag);

    //     checkForDuplicates(newData);

    //     setBusinessData(newData);
    // }

    // const removeBusinessTag = (guid: string) => {
    //     const newData = cloneDeep(businessData);
    //     const selectedTag = newData.tags.find((x: ExtendedBaseBusinessTag) => x.guid == guid);

    //     if (!!selectedTag.id && selectedTag.id != 0) {
    //         selectedTag.deleted = true;
    //     } else {
    //         newData.tags = newData.tags.filter((x: ExtendedBaseBusinessTag) => x.guid != guid)
    //     }

    //     checkForDuplicates(newData);

    //     setBusinessData(newData);
    // }

    // const updateTags = (value: any, guid: string) => {
    //     const newData = cloneDeep(businessData);
    //     const tag = newData.tags.find((x: ExtendedBaseBusinessTag) => x.guid == guid);
    //     tag.tagDescription = value;

    //     checkForTagDuplicates(newData);

    //     setBusinessData(newData);
    // }

    // const checkForTagDuplicates = (newData: BaseBusiness) => {
    //     if (newData.tags.some((x: ExtendedBaseBusinessTag) => x.isDuplicate)) {
    //         setHasDuplicateTags(true);
    //     } else {
    //         setHasDuplicateTags(false);
    //     }
    // }

    // const checkForDuplicates = (newDataToUse: BaseBusiness = null) => {

    //     const updateBusiness = !newDataToUse;
    //     const newData = newDataToUse ? newDataToUse : cloneDeep(businessData);

    //     newData.tags.forEach((thisTag: ExtendedBaseBusinessTag) => {
    //         thisTag.isDuplicate = !isNullOrWhitespace(thisTag.tagDescription) && !thisTag.deleted && !!newData.tags.find((x: ExtendedBaseBusinessTag) => !x.deleted &&
    //             x.guid != thisTag.guid &&
    //             x.tagDescription.toLowerCase() == thisTag.tagDescription.toLowerCase()
    //         );
    //     });

    //     checkForTagDuplicates(newData);

    //     if (updateBusiness) {
    //         setBusinessData(newData);
    //     }
    // }

    // const getNewTag = (businessData: BaseBusiness): ExtendedBaseBusinessTag => {
    //     return { tagDescription: "", parentBusinessId: businessData.parentBusinessId, id: 0, guid: createUUID(), isDuplicate: false };
    // }

    function updateImage(imageUrl) {
        const business = cloneDeep(data);
        business.imageUrl = imageUrl;

        ApiService.business.Save__POST(business).then(() => {
            setData(business)
            setBusinessData(business);
            NotificationService.Confirm('Saved business info')
        }).catch(() => {
            NotificationService.Error('Failed to save business info')
        })
    }

    const updateRating = (e) => {
        e.preventDefault();
        ApiService.business.UpdateFoodRating__GET(businessData.locationId).then(response => {
            if (response.success) {
                const business = cloneDeep(businessData);
                business.hygieneRating = parseInt(response.info);
                setBusinessData(business)
            } else {
                NotificationService.Error('Rating could not be obtained.')
            }
        })
    }

    if (!businessLoaded || !businessData) return <Loader />

    return (
        <>
            <DashboardHeader icon='edit' title='Business details' />
            <FormWrapper onUpdate={(updateData: BaseBusiness) => updateFormData(updateData)}>
                {({ id, valid }) => (
                    <>
                        {businessData.operationalStatus == OperationalStatus.TemporarilyClosed &&
                            <>
                                <br />
                                <ErrorMessage>
                                    This business is temporarily closed and will not appear in the search results until is starts operating again.<br />
                                    If this is incorrect, please <InlineLink to={`/contact`}>contact us</InlineLink> for support.
                                </ErrorMessage>
                            </>
                        }
                        {businessData.operationalStatus == OperationalStatus.PermanentlyClosed &&
                            <>
                                <br />
                                <ErrorMessage>
                                    This business is permanently closed and will not appear in the search results anymore.<br />
                                    If this is incorrect, please <InlineLink to={`/contact`}>contact us</InlineLink> for support.
                                </ErrorMessage>
                            </>
                        }
                        <DashboardPanel title='Business image'>
                            {/* {businessData.imageUrl &&
                                <ImageBanner style={{ backgroundImage: `url('${Constants.baseImageUrl}${businessData.imageUrl}')` }} />
                            }
                            {isNullOrWhitespace(businessData.imageUrl) &&
                                <strong>No image uploaded, please upload an image using the button below.</strong>
                            } */}
                            <Row>
                                <Column size={4}>
                                    {/* <FileUpload onUpload={(imageUrl) => updateImage(imageUrl)} /> */}
                                    <ImageSelector value={businessData.imageUrl} onChange={(imageUrl) => updateImage(imageUrl)} />
                                </Column>
                            </Row>
                        </DashboardPanel>
                        <DashboardPanel title='Business information'>
                            <Row>
                                <Column size={4} tablet={6} mobile={12}>
                                    <StyledTextInput value={businessData.name} model='name' label='Business name:' required />
                                </Column>
                                <Column size={4} tablet={6} mobile={12}>
                                    <StyledTextInput unlink value={businessData.locationAddress.formattedName} label='Location based name:' required onChange={e => setBusinessData({...businessData, locationAddress: {...businessData.locationAddress, formattedName: e.target.value}})} />
                                </Column>
                                <Column size={4} tablet={6} mobile={12}>
                                    <StyledTextInput value={businessData.phoneNumber} model='phoneNumber' label='Phone number:' />
                                </Column>
                                <Column size={12}>
                                    <StyledTextarea rows={6} value={businessData.description} model='description' label='Description:' />
                                </Column>
                            </Row>
                        </DashboardPanel>
                        <DashboardPanel title='Business address'>
                            <Row>
                                <Column size={6} mobile={12}>
                                    <>
                                        {businessData?.name && <>{businessData?.name}<br /></>}
                                        {businessData?.locationAddress.road && <>{businessData?.locationAddress.road}<br /></>}
                                        {businessData?.locationAddress.city && <>{businessData?.locationAddress.city}<br /></>}
                                        {businessData?.locationAddress.country && <>{businessData?.locationAddress.country}<br /></>}
                                        {businessData?.locationAddress.postCode && <>{businessData?.locationAddress.postCode}<br /></>}
                                        <InfoMessage>
                                            <>
                                                Not the correct address? Please&nbsp;<InlineLink to={`/contact`}>contact us</InlineLink>&nbsp;to amend it.
                                            </>
                                        </InfoMessage>
                                    </>
                                </Column>
                            </Row>
                        </DashboardPanel>
                        {/* <DashboardPanel title={<>
                            Business tags <InfoButton>These are searcheable tags. Your business will come back in the search results when
                                users search for these tags. Use tags that best describe your business to maximise chances of appearing in
                                the search results.</InfoButton>
                        </>}>
                            <>
                                {/* {businessData.tags.filter(x => !x.deleted).map((x: ExtendedBaseBusinessTag, index) => (
                                    <Row>
                                        <Column size={4}>
                                            <StyledTextInput unlink value={x.tagDescription} onBlur={(e) => checkForDuplicates()} onChange={(e) => updateTags(e.target.value, x.guid)} placeholder='e.g. Italian / Buffet / Street food etc' label={`Tag: ${index + 1}`} />
                                            <>{x.isDuplicate &&
                                                <BaseErrorMessage>Duplicate tag</BaseErrorMessage>}
                                            </>
                                        </Column>
                                        {index !== 4 &&
                                            <Column size={1}>
                                                <AddRemoveButton onClick={() => addBusinessTag()}><Icon name='plus' /></AddRemoveButton>
                                            </Column>
                                        }
                                        {index !== 0 &&
                                            <Column size={1}>
                                                <AddRemoveButton type='danger' onClick={() => removeBusinessTag(x.guid)}><Icon name='minus' /></AddRemoveButton>
                                            </Column>
                                        }
                                        {index >= 4 &&
                                            <p color='red'>
                                                &nbsp; &nbsp; &nbsp; <Icon name='info-circle' /> Need more tags? Subscribe for our membership to add more tags and gain access to additional features! *Subscribe now*
                                            </p>
                                        }
                                    </Row>
                                ))}
                            </>
                        </DashboardPanel> */}
                        {/* <DashboardPanel title='Food Hygiene Rating'>
                            <Row>
                                <Column size={4}>
                                    Current rating: {isNullOrWhitespace(businessData?.hygieneRating) ? 'Unrated' : businessData?.hygieneRating}
                                    <InfoMessage>Value not correct? <InlineLink onClick={(e) => updateRating(e)}>Update now</InlineLink></InfoMessage>
                                </Column>
                                <Column size={4}>
                                    Hygiene rating ID: {businessData?.hygieneRatingId}
                                </Column>
                            </Row>
                        </DashboardPanel> */}
                        <DashboardPanel title='External details'>
                            <Row>
                                <Column size={4} mobile={12}>
                                    <StyledTextInput max={AnyUrlFieldSize} model='websiteUrl' value={businessData.websiteUrl} label='Website URL:' />
                                </Column>
                                <Column size={4} mobile={12}>
                                    <StyledTextInput max={AnyUrlFieldSize} model='instagramUrl' value={businessData.instagramUrl} label='Instagram URL:' />
                                </Column>
                                <Column size={4} mobile={12}>
                                    <StyledTextInput max={AnyUrlFieldSize} model='facebookUrl' value={businessData.facebookUrl} label='Facebook URL:' />
                                </Column>
                                <Column size={4} mobile={12}>
                                    <StyledTextInput max={AnyUrlFieldSize} model='bookingUrl' value={businessData.bookingUrl} label='Booking URL:' />
                                </Column>
                                <Column size={4} mobile={12}>
                                    <StyledTextInput max={AnyUrlFieldSize} model='tripAdvisorId' value={businessData.tripAdvisorId} label='Trip advisor ID:' />
                                </Column>
                            </Row>
                        </DashboardPanel>

                        <DashboardPanel title='Location system settings'>
                            <Row>
                                <Column size={4} mobile={12}>
                                    <br />
                                    <Checkbox unlink asToggle model='disableScreensaver' inputName='disableScreensaver' checked={!!!businessData.disableScreensaver} label='Screen saver' onChange={() => updateFormData({ ...businessData, disableScreensaver: !businessData.disableScreensaver})} />
                                </Column>
                                <Column size={4} mobile={12}>
                                    <StyledNumberInput hasPlusMinus incrementOf={10} disabled={businessData.disableScreensaver} model='screenSaverTimeoutSeconds' value={businessData.screenSaverTimeoutSeconds || 30} label='Screensaver timeout (seconds):' />
                                </Column>
                            </Row>
                        </DashboardPanel>
                        {/* <CoreButton type='danger' onClick={() => setModalOpen(true)}><Icon name='trash' /> Delete location</CoreButton> */}
                        <FloatingActionBar>
                            <CoreButton requesting={saving} small disabled={!valid || hasDuplicateTags} onClick={() => saveData()} floatRight>
                                Save details <Icon name='save' />
                            </CoreButton>
                        </FloatingActionBar>
                    </>
                )}
            </FormWrapper>
            <CoreModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                title={`Are you sure you want to delete ${data?.name}?`}
                actionBar={<CoreButton type='danger'>Confirm deletion</CoreButton>}>
                <WarningMessage>
                    This is a dangerous action and will make your location not return in search results.
                    <br />
                    This will also delete all information attached to the location including all bookings.
                </WarningMessage>
            </CoreModal>
        </>
    );
};

export default BusinessDetails;

