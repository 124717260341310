import React, { useEffect } from 'react';
import useParentBusiness from '../../../../../hooks/useParentBusiness';
import { ApiService } from '../../../../../api/api-connectors';
import { ApiPaymentMethod, ApiSystemProduct } from '../../../../../api/api-definitions';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import DataTable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import { InfoMessage, WarningMessage } from '../../../../../components/Forms/Messaging';
import DashboardPanel from '../../../../../components/Dashboard/Panel';
import DashboardAddButton from '../../../../../components/Dashboard/AddButton';
import CoreModal from '../../../../../components/Layout/CoreModal';
import Checkout from '../../../../../components/Payments/Checkout';
import CoreButton from '../../../../../components/Forms/Button';
import { ModalService } from '../../../../../services/ModalService';
import Loader from '../../../../../components/Layout/Loader';
import { capitalizeFirst } from '../../../../../utils/string-helpers';
import { useBusinessPermissions } from '../../../../../hooks/useBusinessPermissions';


const PaymentMethods = () => {
    const [loaded, parentBusiness] = useParentBusiness();
    const [paymentMethods, setPaymentMethods] = React.useState<ApiPaymentMethod[]>();
    const [setupIntent, setSetupIntent] = React.useState<string>();
    const [permmissionsLoaded, permissions] = useBusinessPermissions();
    const hasSubscribed = !!(permissions && Object.keys(permissions).find(x => permissions[x]));

    useEffect(() => {
        if (parentBusiness) {
            load();
        }
    }, [parentBusiness])

    const load = () => {
        ApiService.checkout.GetPaymentMethods__GET(parentBusiness.id).then(response => {
            setPaymentMethods(response);
        })
    }

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        paymentMethods.forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'Type': {
                        value: capitalizeFirst(item.brand),
                    },
                    'Card number': {
                        value: '**** **** **** ' + item.lastFourDidgets,
                    },
                    'Expires': {
                        value: `${item.month}/${item.year}`,
                    },
                    'Remove': {
                        value: <CoreButton disabled={paymentMethods.length == 1 && hasSubscribed} type='danger' onClick={() => ModalService.Open({
                            title: `Are you sure you want to remove card ending ${item.lastFourDidgets}?`,
                            actionBar: <CoreButton onClick={() => removePaymentMethod(item.id)}>Confirm</CoreButton>
                        })}>Remove card</CoreButton>,
                        hideName: true,
                    }
                }
            }
            items.push(dataItem);
        });
        return items;
    }

    const getSetupIntent = () => {
        ApiService.checkout.SetupIntent__GET(parentBusiness.id).then(response => {
            setSetupIntent(response.info);
        });
    }

    const removePaymentMethod = (paymentMethodId: string) => {
        ApiService.checkout.RemovePaymentMethod__GET(paymentMethodId).then(() => {
            ModalService.Close();
            load();
        })
    }

    if (!paymentMethods) return <Loader />;

    return (
        <>
            {setupIntent &&
                <CoreModal title='Add payment method' onClose={() => setSetupIntent(undefined)}>
                    <Checkout intentSecret={setupIntent} payNow={false} />
                </CoreModal>
            }
            <DashboardPanel>
                {paymentMethods.length == 0 &&
                    <>
                        <WarningMessage>No payment methods are currently set up</WarningMessage>
                    </>
                }
                {paymentMethods.length > 0 &&
                    <DataTable data={gentable()} />
                }
                <br />
                <DashboardAddButton onClick={getSetupIntent}>Add payment method</DashboardAddButton>
            </DashboardPanel>
        </>
    );
};

export default PaymentMethods;