import React from "react"
import { AddPlaceholder, DocumentContentNode, HoverNode, Placeholder } from "."
import Icon from "../../components/Media/Icon"
import { DragAndDropLocation } from "../../pages/BusinessDashboard/modules/ParentBusinessModules/DocumentBuilder"
import { isNullOrWhitespace } from "../../utils/text-helpers"
import { CoreImage, CoreText, CoreView } from "../shared/CoreModules/coreComponents"
import Column from "./column"
import Row from "./row"

interface Props {
    node: DocumentContentNode;
    index: number;
    onHover: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: DragAndDropLocation) => void;
    onDragOver: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: DragAndDropLocation) => void;
    setDrag: (id: string) => void;
    onSelect: (node: DocumentContentNode) => void;
    hoverActive: boolean;
    selectedId: string;
    hoverItem?: DragAndDropLocation;
}

export const Node = ({ node, index, onHover, onDragOver, setDrag, onSelect, hoverActive, selectedId, hoverItem }: Props) => <>
    {node.Element === 'image' && 
        <CoreImage style={{ width: `${node.Values.width.size}${node.Values.width.unit}` }} src={node.Values.url || 'https://placehold.co/600x400'} />
    }
    {node.Element === 'text' &&
        <CoreText style={
            {
                ...node.Values.style,
                fontSize: `${node.Values.style?.fontSize?.value}${node.Values.style?.fontSize?.unit}`,
            }}>
            {isNullOrWhitespace(node.Values.value) ? 'Component text' : node.Values.value}
        </CoreText>
    }
    {node.Element === 'row' &&
        <Row>
            {node.Values.columns.map((col, colIndex) => (
                <Column key={`${node.Id}-column-${index}-${colIndex}`} size={col.value} paddedRight>
                    {node.Values.columnContent[colIndex]?.length > 0 ?
                        <>
                            <HoverNode onDragOver={(event, enter) => onDragOver(event, enter ? {index: -1, id: node.Id, columnIndex: colIndex} : undefined)} setDrag={() => { }} onSelect={() => { }}>
                                <AddPlaceholder collapsed={!hoverActive}>
                                <Icon name='plus' />
                                </AddPlaceholder>
                            </HoverNode>
                            {node.Values.columnContent[colIndex].map((colContent: DocumentContentNode, contentIndex: number) => (
                                <React.Fragment key={`${colContent.Id}-column-${index}-${colIndex}-${contentIndex}`}>
                                    <HoverNode
                                        setDrag={setDrag}
                                        onSelect={onSelect}
                                        node={colContent}
                                        selected={colContent.Id === selectedId || hoverItem?.id === colContent.Id}
                                        onHover={(event, enter) => onHover(event, enter ? { id: colContent.Id } : undefined)}
                                    >
                                        <Node hoverItem={hoverItem} selectedId={selectedId} index={contentIndex} node={colContent} onDragOver={onDragOver} onHover={onHover} setDrag={setDrag} onSelect={onSelect} hoverActive={hoverActive} />
                                    </HoverNode>
                                    <HoverNode onDragOver={(event, enter) => onDragOver(event, enter ? {index: index, id: node.Id, columnIndex: colIndex} : undefined)} setDrag={() => { }} onSelect={() => { }}>
                                        <AddPlaceholder collapsed={!hoverActive}>
                                        <Icon name='plus' />
                                        </AddPlaceholder>
                                    </HoverNode>
                                </React.Fragment>
                            ))}
                        </>
                        :
                        <HoverNode onDragOver={(event, enter) => onDragOver(event, enter ? {index: -1, id: node.Id, columnIndex: colIndex} : undefined)} setDrag={() => { }} onSelect={() => { }}>
                            <Placeholder small>
                                Drag module into column
                            </Placeholder>
                        </HoverNode>
                    }
                </Column>
            ))}
        </Row>
    }
</>