import React, { useEffect } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import { BaseExperience, ExtendedBaseExperience } from '../../../../../../api/api-definitions';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import Loader from '../../../../../../components/Layout/Loader';
import { Badge } from '@chakra-ui/react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import Icon from '../../../../../../components/Media/Icon';

interface ComponentProps {
    experienceId: number;
    children?: React.ReactNode;
}

const ExperienceInfoBadge = ({ experienceId, children }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const [data, setData] = React.useState<ExtendedBaseExperience>();
    const [modalOpen, setModalOpen] = React.useState(false);

    const loadData = () => ApiService.experience.Get__GET(experienceId).then(setData);

    const openModal = () => {
        if (!data) {
            loadData();
        }
        setModalOpen(true);
    }

    return (
        <>
            <Label colorScheme='green' onClick={openModal}>{children || 'Experience/Event'}</Label>
            {modalOpen &&
                <CoreModal
                cancelText='Close'
                small
                slimPanel
                onClose={() => setModalOpen(false)}
                title={data?.name}
                actionBar={<>{data && <StyledLink to={`/dashboard/${business.parentBusinessId}/locations/${business.locationId}/experiences/${experienceId}`}>Go to setup <Icon name='arrow-right' duo /></StyledLink>}</>}
                >
                    {!data && <Loader />}
                    {data && ((data?.description && <div dangerouslySetInnerHTML={{__html: data.description}}></div>) || 'Experience has not got a description.')}
                </CoreModal>
            }
        </>
    );
};

const Label = styled(Badge)`
    cursor: pointer;
    transition: all ease 0.4s;

    &:hover {
        opacity: 0.8;
    }
`

const StyledLink = styled(Link)`
    font-weight: bold;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
`

export default ExperienceInfoBadge;