import React, { useEffect, useMemo, useState } from 'react';
import { StripeUpdate } from '../../../../../api/api-definitions';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import CoreModal from '../../../../../components/Layout/CoreModal';
import { IntegrationBox, IntegrationTitle } from '.';
import CoreButton from '../../../../../components/Forms/Button';
import { Row, Column } from '../../../../../components/Layout/Grid';
import { StyledTextInput } from '../../../../../theme/input.styles';
import { ApiService } from '../../../../../api/api-connectors';
import { NotificationService } from '../../../../../services/NotificationService';
import Icon from '../../../../../components/Media/Icon';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { getQueryParams } from '../../../../../utils/data-helpers';
import { SuccessMessage } from '../../../../../components/Forms/Messaging';

interface ComponentProps {
    enabled: boolean;
    locationId: string;
    updateBusiness: (property: string, value: boolean) => void;
    error: () => void;
}

const StripeIntegration = ({ enabled, locationId, updateBusiness, error }: ComponentProps) => {
    const [businessLoaded, businessData] = useBusiness();
    const [data, setData] = useState<StripeUpdate>({});
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [stripeLink, setStripeLink] = useState<string>();
    const query = useMemo(() => getQueryParams(), []);

    useEffect(() => {
        ApiService.business.GetStripeRegisterUrl__GET(businessData.id).then((response) => setStripeLink(response.info));
        if (query['stripeReturn'] == 'return') {
            update();
        }
    }, [])

    const update = (clear: boolean = false) => {
        setUpdating(true)
        ApiService.business.UpdateStripe__POST({ locationId, businessId: businessData.id }).then((response) => {
            if (response.success) {
                setOpen(false);
                setDeleteOpen(false);
                setData({})
                NotificationService.Confirm(`Stripe integration ${clear ? 'deleted' : 'added'} successfully`);
            } else {
                NotificationService.Info('Stripe account needs activing for payments')
            }
        }).catch(error).finally(() => setUpdating(false))
    }

    return (
        <>
            <IntegrationBox hasDetails={enabled}
                onClick={enabled ? undefined : () => setOpen(true)}>
                <IntegrationTitle>Stripe payments</IntegrationTitle>
                <Icon name='stripe' />
                {enabled && <SuccessMessage>Linked!</SuccessMessage>}
            </IntegrationBox>
            {deleteOpen &&
                <CoreModal
                    onClose={() => setDeleteOpen(false)}
                    small
                    title={`Are you sure you want to delete your Stripe integration?`}
                    actionBar={<CoreButton type='danger' onClick={() => update(true)}>Confirm deletion</CoreButton>}
                />
            }
            {open &&
                <CoreModal
                    title='Stripe details'
                    small
                    onClose={() => setOpen(false)}
                    actionBar={<CoreButton
                        onClick={() => window.location.href = stripeLink}
                        >Proceed</CoreButton>
                    }>
                    By proceeding you will be taken through the Stripe setup process.
                    <br />
                    <br />
                    If you have already set up and activated your Stripe Connect account, click the button below to refresh integration status.
                    <br />
                    <br />
                    <CoreButton onClick={() => update()}>Refresh integration status</CoreButton>
                </CoreModal>
            }
        </>
    );
};

export default StripeIntegration;