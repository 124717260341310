import React from 'react';
import { BookingQuickInfoItem, BookingQuickInfoTwo } from '../../../booking.styles';
import Icon from '../../../../components/Media/Icon';
import moment from 'moment';
import { DATE_WITH_DAY_OF_WEEK_FORMAT, TIMEFORMAT } from '../../../../utils/date-helpers';
import { ApiBusinessBookingDetails } from '../../../../api/api-definitions';
import { BookingFormData } from '..';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    formData: BookingFormData;
    selectedTime: string;
    selectedExperience: number;
    experienceTypeShouldShow: (business: ApiBusinessBookingDetails) => boolean;
    getSelectedExperienceName: (selectedExperience: number, business: ApiBusinessBookingDetails) => string;
}

const BookingSummary = ({
    business,
    formData,
    selectedTime,
    selectedExperience,
    experienceTypeShouldShow,
    getSelectedExperienceName,
}: ComponentProps) => {
    return (
        <BookingQuickInfoTwo>
            <BookingQuickInfoItem expand>
                <Icon name='calendar' duo doNotStyleDuo /> {moment(formData.date).format(DATE_WITH_DAY_OF_WEEK_FORMAT)}
            </BookingQuickInfoItem>
            <BookingQuickInfoItem>
                <Icon name='clock' duo doNotStyleDuo /> {moment(selectedTime).format(TIMEFORMAT)}
            </BookingQuickInfoItem>
            <BookingQuickInfoItem>
                <Icon name='user-friends' duo doNotStyleDuo /> {formData.guests}
            </BookingQuickInfoItem>
            {experienceTypeShouldShow(business) && <>
                <br />
                <BookingQuickInfoItem expand>
                    <Icon name='utensils' duo doNotStyleDuo /> {
                        getSelectedExperienceName(selectedExperience, business)
                    }
                </BookingQuickInfoItem>
            </>}
        </BookingQuickInfoTwo>
    );
};

export default BookingSummary;