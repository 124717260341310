import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import { BookingDayOverviewResponse } from '../../../../../../api/api-definitions';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import moment, { Moment } from 'moment';
import { DATEONLYFORMAT, createMomentFromValue } from '../../../../../../utils/date-helpers';
import { H3 } from '../../../../../../components/Typography/Headings';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import styled from 'styled-components';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BoxShadowStyle } from '../../../../../../theme';
import SevenDayOverview from '../../../../../../components/Reporting/SevenDayOverview';
import CoreButton from '../../../../../../components/Forms/Button';
import { StyledCalendar } from '../../../../../../theme/input.styles';

interface ChartItem {
    x: string;
    y: number;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  export const options = {
    plugins: {
      title: {
        display: true,
        text: 'Bookings day report',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  export const byCoverOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Covers day report',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

const ChartContainer = styled.div`
    ${BoxShadowStyle}
    background-color: #FFF;
`
const getChartDataByCovers = (response: BookingDayOverviewResponse) => {
  return {
    labels: Object.keys(response.days).map((day) => createMomentFromValue(day).format("dd DD MMM")),
    datasets: [
      {
        label: 'Attending',
        data: Object.keys(response.days).map((day) => response.days[day].guests),
        backgroundColor: 'rgba(100, 100, 235, 0.5)',
      },
      {
        label: 'Cancelled',
        data: Object.keys(response.days).map((day) => response.days[day].cancelledGuests),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'No showed',
        data: Object.keys(response.days).map((day) => response.days[day].noShowedGuests),
        backgroundColor: 'rgba(255, 99, 132, 0.9)',
      }
    ],
  }
}

const getChartData = (response: BookingDayOverviewResponse) => {
    return {
        labels: Object.keys(response.days).map((day) => createMomentFromValue(day).format("dd DD MMM")),
        datasets: [
          {
            label: 'In-house',
            data: Object.keys(response.days).map((day) => response.days[day].inHouse - response.days[day].walkIns),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
            label: 'Walk ins',
            data: Object.keys(response.days).map((day) => response.days[day].walkIns),
            backgroundColor: 'rgba(100, 100, 235, 0.5)',
          },
          {
            label: 'Online',
            data: Object.keys(response.days).map((day) => response.days[day].web),
            backgroundColor: 'rgba(23, 30, 132, 0.5)',
          },
          {
            label: 'Cancellations',
            data: Object.keys(response.days).map((day) => response.days[day].cancelled),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'No shows',
            data: Object.keys(response.days).map((day) => response.days[day].noShowed),
            backgroundColor: 'rgba(255, 99, 132, 0.9)',
          },
        ],
      }
}

const BookingDayReport = () => {
    const [businessLoaded, business] = useBusiness();
    const [response, setResponse] = useState<BookingDayOverviewResponse>();
    const [startDate, setStartDate] = useState<Moment>(moment().subtract(14, 'days'));
    const [endDate, setEndDate] = useState<Moment>(moment());
    const requested = useRef(false);

    useEffect(() => {
        if (business && !requested.current) {
            requested.current = true;
            getData(startDate, endDate)
        }
    }, [business]);

    const getData = (startDate: Moment, endDate: Moment) => {
      ApiService.bookingStatistics.OverviewBetweenDates__GET(
        business.id,
        startDate.format(DATEONLYFORMAT),
        endDate.format(DATEONLYFORMAT)
      ).then(setResponse);
    }

    const onNextClick = () => {
      const diff = endDate.diff(startDate, 'days')
      const newEndDate = endDate.clone().add(diff, 'days');
      setEndDate(newEndDate)
      const newStartDate = startDate.clone().add(diff, 'days');
      setStartDate(newStartDate)
      getData(newStartDate, newEndDate)
    }

    const onPreviousClick = () => {
      const diff = endDate.diff(startDate, 'days')
      const newEndDate = endDate.clone().subtract(diff, 'days');
      setEndDate(newEndDate)
      const newStartDate = startDate.clone().subtract(diff, 'days');
      setStartDate(newStartDate)
      getData(newStartDate, newEndDate)
    }

    const startDateChange = (date: Moment) => {
      if (date.isSame(startDate)) return;
      setStartDate(date)
      getData(date, endDate)
    }

    const endDateChange = (date: Moment) => {
      if (date.isSame(endDate)) return;
      setEndDate(date)
      getData(startDate, date)
    }

    return (
        <div>
            <br />
            <H3>Next 7 days</H3>
            <SevenDayOverview />
            {response &&
                <Row>
                    <Column size={2}>
                      <CoreButton full onClick={onPreviousClick}>Previous</CoreButton>
                    </Column>
                    <Column size={4}>
                      <StyledCalendar value={startDate} onChange={e => startDateChange(createMomentFromValue(e))} />
                    </Column>
                    <Column size={4}>
                      <StyledCalendar value={endDate} onChange={e => endDateChange(createMomentFromValue(e))} />
                    </Column>
                    <Column size={2}>
                      <CoreButton full onClick={onNextClick}>Next</CoreButton>
                    </Column>
                    <Column size={12} mobile={12}>
                        <ChartContainer>
                            <Bar height={100} options={options} data={getChartData(response)} />
                        </ChartContainer>
                    </Column>
                    <Column size={12} mobile={12}>
                        <ChartContainer>
                            <Bar height={100} options={byCoverOptions} data={getChartDataByCovers(response)} />
                        </ChartContainer>
                    </Column>
                </Row>
            }
        </div>
    );
};

export default BookingDayReport;