import React from 'react';
import { SupportTicketPriority, SupportTicketStatus } from '../../../../../api/api-definitions';
import styled from 'styled-components';
import { Badge } from '@chakra-ui/react';

interface ComponentProps {
    status: SupportTicketPriority;
}

const BaseLabel = styled(Badge)`
  display: inline-block;
`

const Critical = styled(BaseLabel)`
  background-color: #c80000;
  color: white;
`

const Urgent = styled(BaseLabel)`
  background-color: #ff3d00;
  color: white;
`

const NonUrgent = styled(BaseLabel)`
  background-color: #0ead49;
  color: white;
`

const Question = styled(BaseLabel)`
  background-color: #4747bc;
  color: white;
`

const Suggestion = styled(BaseLabel)`
  background-color: #379abb;
  color: white;
`

const SupportTicketPriorityLabel = ({ status }: ComponentProps) => {
  if (status === SupportTicketPriority.Urgent) return <Critical>Major</Critical>;
  if (status === SupportTicketPriority.Medium) return <Urgent>Medium</Urgent>;
  if (status === SupportTicketPriority.Minor) return <NonUrgent>Minor</NonUrgent>;
  return <></>;
};

export default SupportTicketPriorityLabel;