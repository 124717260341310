export const MENUBUILDER: any = [
    {
        "menuItems": null,
        "rowKey": "ed70e4bd-6023-61d8-bc82-43dce58d30d4",
        "name": "Footer",
        "items": "[{\"name\":\"Privacy\",\"url\":\"/privacy\",\"items\":[]}]"
    },
    {
        "menuItems": null,
        "rowKey": "fc8c3a85-50cd-54e5-82f9-0d07bc461256",
        "name": "Main Menu",
        "items": "[{\"name\":\"Home\",\"url\":\"/\",\"items\":[]},{\"name\":\"Products\",\"url\":\"/products\",\"items\":[{\"name\":\"Awesome Products\",\"url\":\"/products/awesome-product\",\"items\":[]}]},{\"name\":\"Contact Us\",\"url\":\"/contact-us\",\"items\":[]}]"
    }
]

export const SAMPLE: any = {
    "pageInfo": {
        "components": [
            {
                "values": [
                    {
                        "rowKey": "5c794c4c-87b6-4647-b932-74a49dac25f2",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "propertyId": "e416fb6a-c429-38cb-92cb-e23b56861f63",
                        "value": "Ross Website"
                    },
                    {
                        "rowKey": "b6f2ccb3-a2e5-4da2-98a3-a1d4ef786017",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "propertyId": "b683cbd4-47c4-29ce-9e54-c868cc2f4b9e",
                        "value": "Main Menu"
                    },
                    {
                        "rowKey": "eb0334b5-72e4-4629-b4c3-1faba43c4835",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "propertyId": "b36859a6-1923-baad-9e65-6ef17e0500f6",
                        "value": "images-8da1e5d5-26aa-4948-bb0f-a1e7a08d01b5.jpg"
                    }
                ],
                "properties": [
                    {
                        "rowKey": "b36859a6-1923-baad-9e65-6ef17e0500f6",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "name": "Logo",
                        "type": "image",
                        "variableName": ""
                    },
                    {
                        "rowKey": "b683cbd4-47c4-29ce-9e54-c868cc2f4b9e",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "name": "Menu",
                        "type": "menu",
                        "variableName": ""
                    },
                    {
                        "rowKey": "e416fb6a-c429-38cb-92cb-e23b56861f63",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "name": "Site Name",
                        "type": "text",
                        "variableName": ""
                    }
                ],
                "filePath": "Navigation/MainNav",
                "rowKey": "5d20e230-2008-fa0f-92a2-4a0f201b0354",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                "order": 0,
                "parent": ""
            },
            {
                "values": [
                    {
                        "rowKey": "94d06148-9caa-45f3-9f13-34549edf7837",
                        "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                        "propertyId": "4b49ddf1-e364-c118-9879-9c59294aff2e",
                        "value": ""
                    },
                    {
                        "rowKey": "bca5bbb4-c3e1-4d74-ba9b-0f5924179d6d",
                        "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                        "propertyId": "0b451ee6-46f1-07df-8513-58eaff882490",
                        "value": ""
                    }
                ],
                "properties": [
                    {
                        "rowKey": "0b451ee6-46f1-07df-8513-58eaff882490",
                        "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                        "name": "Column One",
                        "type": "placeholder",
                        "variableName": ""
                    },
                    {
                        "rowKey": "4b49ddf1-e364-c118-9879-9c59294aff2e",
                        "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                        "name": "Column Two",
                        "type": "placeholder",
                        "variableName": ""
                    }
                ],
                "filePath": "Layouts/TwoColumn",
                "rowKey": "b1a72a0a-6619-de1a-9d7e-083f3072d007",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                "order": 1,
                "parent": ""
            },
            {
                "values": [
                    {
                        "rowKey": "fd7fad4f-51a6-4e06-95b2-5a4e9a91359d",
                        "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
                        "propertyId": "00fc9a2c-8abb-6525-a762-7dee2dfedd11",
                        "value": "Test content. "
                    }
                ],
                "properties": [
                    {
                        "rowKey": "00fc9a2c-8abb-6525-a762-7dee2dfedd11",
                        "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
                        "name": "Paragraph",
                        "type": "textarea",
                        "variableName": ""
                    }
                ],
                "filePath": "Generic/Paragraph",
                "rowKey": "c548952d-1012-8598-ba39-6d88273635b9",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
                "order": 2,
                "parent": ""
            }
        ],
        "rowKey": "2826572a-c602-4b34-926c-dd0886175feb",
        "name": "Home",
        "url": "/",
        "blog": false,
        "parent": "",
        "metaTitle": "Homepage",
        "metaDescription": "Welcome to awesome site",
        "categories": "6dae5993-9143-d202-80b8-fcccae2ad490",
        "tags": null,
        "published": true,
        "updated": "2021-04-08T12:41:44.402Z",
        "created": "2021-04-08T12:41:44.402Z"
    },
    "activeComponents": [
        {
            "values": [
                {
                    "rowKey": "5c794c4c-87b6-4647-b932-74a49dac25f2",
                    "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                    "propertyId": "e416fb6a-c429-38cb-92cb-e23b56861f63",
                    "value": "Ross Website"
                },
                {
                    "rowKey": "b6f2ccb3-a2e5-4da2-98a3-a1d4ef786017",
                    "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                    "propertyId": "b683cbd4-47c4-29ce-9e54-c868cc2f4b9e",
                    "value": "Main Menu"
                },
                {
                    "rowKey": "eb0334b5-72e4-4629-b4c3-1faba43c4835",
                    "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                    "propertyId": "b36859a6-1923-baad-9e65-6ef17e0500f6",
                    "value": "images-8da1e5d5-26aa-4948-bb0f-a1e7a08d01b5.jpg"
                }
            ],
            "properties": [
                {
                    "rowKey": "b36859a6-1923-baad-9e65-6ef17e0500f6",
                    "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                    "name": "Logo",
                    "type": "image",
                    "variableName": ""
                },
                {
                    "rowKey": "b683cbd4-47c4-29ce-9e54-c868cc2f4b9e",
                    "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                    "name": "Menu",
                    "type": "menu",
                    "variableName": ""
                },
                {
                    "rowKey": "e416fb6a-c429-38cb-92cb-e23b56861f63",
                    "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                    "name": "Site Name",
                    "type": "text",
                    "variableName": ""
                }
            ],
            "filePath": "Navigation/MainNav",
            "rowKey": "5d20e230-2008-fa0f-92a2-4a0f201b0354",
            "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
            "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
            "order": 0,
            "parent": ""
        },
        {
            "values": [
                {
                    "rowKey": "94d06148-9caa-45f3-9f13-34549edf7837",
                    "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                    "propertyId": "4b49ddf1-e364-c118-9879-9c59294aff2e",
                    "value": ""
                },
                {
                    "rowKey": "bca5bbb4-c3e1-4d74-ba9b-0f5924179d6d",
                    "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                    "propertyId": "0b451ee6-46f1-07df-8513-58eaff882490",
                    "value": ""
                }
            ],
            "properties": [
                {
                    "rowKey": "0b451ee6-46f1-07df-8513-58eaff882490",
                    "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                    "name": "Column One",
                    "type": "placeholder",
                    "variableName": ""
                },
                {
                    "rowKey": "4b49ddf1-e364-c118-9879-9c59294aff2e",
                    "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                    "name": "Column Two",
                    "type": "placeholder",
                    "variableName": ""
                }
            ],
            "filePath": "Layouts/TwoColumn",
            "rowKey": "b1a72a0a-6619-de1a-9d7e-083f3072d007",
            "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
            "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
            "order": 1,
            "parent": ""
        },
        {
            "values": [
                {
                    "rowKey": "fd7fad4f-51a6-4e06-95b2-5a4e9a91359d",
                    "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
                    "propertyId": "00fc9a2c-8abb-6525-a762-7dee2dfedd11",
                    "value": "Test content. "
                }
            ],
            "properties": [
                {
                    "rowKey": "00fc9a2c-8abb-6525-a762-7dee2dfedd11",
                    "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
                    "name": "Paragraph",
                    "type": "textarea",
                    "variableName": ""
                }
            ],
            "filePath": "Generic/Paragraph",
            "rowKey": "c548952d-1012-8598-ba39-6d88273635b9",
            "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
            "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
            "order": 2,
            "parent": ""
        }
    ],
    "availableComponents": [
        {
            "template": {
                "values": [
                    {
                        "rowKey": "ae81785f-cf14-46fd-a803-08e535614e67",
                        "componentId": "0301f781-c698-072f-8d42-4b65b4b1db5e",
                        "propertyId": "676408cd-ac6f-e8b8-b649-452fb7feb4b1",
                        "value": ""
                    },
                    {
                        "rowKey": "6b6b21f7-3b05-46c6-96f8-199a2c9d0f50",
                        "componentId": "0301f781-c698-072f-8d42-4b65b4b1db5e",
                        "propertyId": "85783442-9b6b-4bc2-81ff-d994f24a2eac",
                        "value": ""
                    },
                    {
                        "rowKey": "f3ebf3b3-30df-423f-a9af-e650b18ce85e",
                        "componentId": "0301f781-c698-072f-8d42-4b65b4b1db5e",
                        "propertyId": "fea8caa2-167a-be7b-9232-01213fb3dcba",
                        "value": ""
                    }
                ],
                "properties": [
                    {
                        "rowKey": "676408cd-ac6f-e8b8-b649-452fb7feb4b1",
                        "componentId": "0301f781-c698-072f-8d42-4b65b4b1db5e",
                        "name": "Column Two",
                        "type": "placeholder",
                        "variableName": ""
                    },
                    {
                        "rowKey": "85783442-9b6b-4bc2-81ff-d994f24a2eac",
                        "componentId": "0301f781-c698-072f-8d42-4b65b4b1db5e",
                        "name": "Column One",
                        "type": "placeholder",
                        "variableName": ""
                    },
                    {
                        "rowKey": "fea8caa2-167a-be7b-9232-01213fb3dcba",
                        "componentId": "0301f781-c698-072f-8d42-4b65b4b1db5e",
                        "name": "Column Three",
                        "type": "placeholder",
                        "variableName": ""
                    }
                ],
                "filePath": null,
                "rowKey": "",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "0301f781-c698-072f-8d42-4b65b4b1db5e",
                "order": 0,
                "parent": ""
            },
            "properties": null,
            "rowKey": "0301f781-c698-072f-8d42-4b65b4b1db5e",
            "name": "Three Column Layout",
            "order": 0,
            "markup": "",
            "category": "Layout",
            "filePath": "Layouts/ThreeColumn"
        },
        {
            "template": {
                "values": [
                    {
                        "rowKey": "b3fd287f-52d5-4143-a33c-ace3b68bd0b2",
                        "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
                        "propertyId": "00fc9a2c-8abb-6525-a762-7dee2dfedd11",
                        "value": ""
                    }
                ],
                "properties": [
                    {
                        "rowKey": "00fc9a2c-8abb-6525-a762-7dee2dfedd11",
                        "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
                        "name": "Paragraph",
                        "type": "textarea",
                        "variableName": ""
                    }
                ],
                "filePath": null,
                "rowKey": "",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
                "order": 0,
                "parent": ""
            },
            "properties": null,
            "rowKey": "0a42c61f-4dfb-b426-bb3c-0d03110558a2",
            "name": "Paragraph",
            "order": 0,
            "markup": "",
            "category": "Generic",
            "filePath": "Generic/Paragraph"
        },
        {
            "template": {
                "values": [
                    {
                        "rowKey": "b2965d25-f6de-4405-b525-a451fb003f50",
                        "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                        "propertyId": "0b451ee6-46f1-07df-8513-58eaff882490",
                        "value": ""
                    },
                    {
                        "rowKey": "e454afdc-eac0-47ea-b080-f392a43d6dd6",
                        "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                        "propertyId": "4b49ddf1-e364-c118-9879-9c59294aff2e",
                        "value": ""
                    }
                ],
                "properties": [
                    {
                        "rowKey": "0b451ee6-46f1-07df-8513-58eaff882490",
                        "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                        "name": "Column One",
                        "type": "placeholder",
                        "variableName": ""
                    },
                    {
                        "rowKey": "4b49ddf1-e364-c118-9879-9c59294aff2e",
                        "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                        "name": "Column Two",
                        "type": "placeholder",
                        "variableName": ""
                    }
                ],
                "filePath": null,
                "rowKey": "",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
                "order": 0,
                "parent": ""
            },
            "properties": null,
            "rowKey": "2ee9d590-4749-2a53-84b2-69ff6148aa09",
            "name": "Two Column Layout",
            "order": 0,
            "markup": "",
            "category": "Layout",
            "filePath": "Layouts/TwoColumn"
        },
        {
            "template": {
                "values": [
                    {
                        "rowKey": "f3cbadd4-6e1e-4f37-b651-894eb087163f",
                        "componentId": "6ed2e5a7-c4ec-3526-9313-9795cca1c06b",
                        "propertyId": "5f0553bc-94d8-1173-abd6-780f042c869d",
                        "value": ""
                    }
                ],
                "properties": [
                    {
                        "rowKey": "5f0553bc-94d8-1173-abd6-780f042c869d",
                        "componentId": "6ed2e5a7-c4ec-3526-9313-9795cca1c06b",
                        "name": "Image",
                        "type": "image",
                        "variableName": ""
                    }
                ],
                "filePath": null,
                "rowKey": "",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "6ed2e5a7-c4ec-3526-9313-9795cca1c06b",
                "order": 0,
                "parent": ""
            },
            "properties": null,
            "rowKey": "6ed2e5a7-c4ec-3526-9313-9795cca1c06b",
            "name": "Image",
            "order": 0,
            "markup": "",
            "category": "Generic",
            "filePath": "Generic/Image"
        },
        {
            "template": {
                "values": [
                    {
                        "rowKey": "3b312c68-6072-47de-845d-ab988b255aa5",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "propertyId": "b36859a6-1923-baad-9e65-6ef17e0500f6",
                        "value": ""
                    },
                    {
                        "rowKey": "8799bbdb-5ced-4384-a087-03beb0d2acee",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "propertyId": "b683cbd4-47c4-29ce-9e54-c868cc2f4b9e",
                        "value": ""
                    },
                    {
                        "rowKey": "ac8b8442-6a68-4a66-acc5-40adabbaa04c",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "propertyId": "e416fb6a-c429-38cb-92cb-e23b56861f63",
                        "value": ""
                    }
                ],
                "properties": [
                    {
                        "rowKey": "b36859a6-1923-baad-9e65-6ef17e0500f6",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "name": "Logo",
                        "type": "image",
                        "variableName": ""
                    },
                    {
                        "rowKey": "b683cbd4-47c4-29ce-9e54-c868cc2f4b9e",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "name": "Menu",
                        "type": "menu",
                        "variableName": ""
                    },
                    {
                        "rowKey": "e416fb6a-c429-38cb-92cb-e23b56861f63",
                        "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                        "name": "Site Name",
                        "type": "text",
                        "variableName": ""
                    }
                ],
                "filePath": null,
                "rowKey": "",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
                "order": 0,
                "parent": ""
            },
            "properties": null,
            "rowKey": "775f4b0e-f97a-93c5-994e-2a0935cdc0b1",
            "name": "Main Menu",
            "order": 0,
            "markup": "",
            "category": "Navigation",
            "filePath": "Navigation/MainNav"
        },
        {
            "template": {
                "values": [
                    {
                        "rowKey": "c057759d-2e96-41b4-a78e-c7589cd26552",
                        "componentId": "f0934533-7502-aa04-9d00-181387c6d248",
                        "propertyId": "a85f8550-f718-cc3e-8171-22f3c42452e1",
                        "value": ""
                    }
                ],
                "properties": [
                    {
                        "rowKey": "a85f8550-f718-cc3e-8171-22f3c42452e1",
                        "componentId": "f0934533-7502-aa04-9d00-181387c6d248",
                        "name": "Column One",
                        "type": "placeholder",
                        "variableName": ""
                    }
                ],
                "filePath": null,
                "rowKey": "",
                "pageId": "2826572a-c602-4b34-926c-dd0886175feb",
                "componentId": "f0934533-7502-aa04-9d00-181387c6d248",
                "order": 0,
                "parent": ""
            },
            "properties": null,
            "rowKey": "f0934533-7502-aa04-9d00-181387c6d248",
            "name": "One Column Layout",
            "order": 0,
            "markup": "",
            "category": "Layout",
            "filePath": "Layouts/OneColumn"
        }
    ],
    "menus": [],
    "categories": [
        {
            "rowKey": "6dae5993-9143-d202-80b8-fcccae2ad490",
            "name": "Fidan is amazing",
            "type": "0"
        },
        {
            "rowKey": "d439d67c-f49d-9ebc-8429-3f9403a8a561",
            "name": "Test Category",
            "type": "0"
        }
    ],
    "tags": [
        {
            "rowKey": "96529a76-0204-66c8-9820-a8181a903513",
            "name": "Test Tag",
            "type": "1"
        }
    ],
    "layouts": []
}