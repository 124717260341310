import React, { Component, useRef, useState } from 'react';
import { BaseInputStyle, BaseLabelStyle } from '../../../theme/input.core.styles';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import CoreButton from '../Button';
import { StyledTextInput, StyledTypeahead } from '../../../theme/input.styles';
import { Column, Row } from '../../Layout/Grid';
import { H4style } from '../../Typography/Headings';
import { ApiImage } from '../../../api/api-definitions';
import { ApiService } from '../../../api/api-connectors';
import Loader from '../../Layout/Loader';
import Typeahead, { TypeaheadDataItem } from '../EasyTypeahead';
import { createUUID } from '../../../utils/data-helpers';
import styled from 'styled-components';
import Dropzone, { FileWithPath } from 'react-dropzone';
import Icon from '../../Media/Icon';
import CoreModal from '../../Layout/CoreModal';
import { NotificationService } from '../../../services/NotificationService';

interface ComponentProps {
  onUpload: (value: string, imageDetails: ApiImage) => void;
  site?: string;
  folders?: string[];
  allowedFileTypes?: string[];
  multiple?: boolean;
}

const FildUpload = ({ onUpload, site, folders, multiple, allowedFileTypes = [] }: ComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [hasSelectedFile, setHasSelectedFile] = useState(false);
  const [imageDetails, setImageDetails] = useState<ApiImage>({});
  const fileRef = useRef<HTMLInputElement>();
  const [fileList, setFileList] = useState<FileWithPath[]>([]);

  const onFileChange = (acceptedFiles: FileWithPath[]) => {
    const formData = new FormData();
    const parsedFiles: FileWithPath[] = [];
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        const pathSplit = file.path.split('.');
        const type = pathSplit[pathSplit.length - 1].toLowerCase();
        if (allowedFileTypes.length == 0 || allowedFileTypes.includes(type)) {
          formData.append('file', file)
          parsedFiles.push(file);
        } else {
          NotificationService.Error('File type not accepted.')
        }
      })
    }
    setFileList(parsedFiles)
  }

  const confirm = () => {
    const files: Blob[] = Array.from(fileList)
    setLoading(true)

    const formData = new FormData()

    files.forEach((file, i) => {
      formData.append(i.toString(), file)
    })

    fetch(`/api/image/upload` + (isNullOrWhitespace(site) ? '' : '?partitionKey=' + site), {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(response => {
        imageDetails.rowKey = response.info;
        imageDetails.blobId = response.info;
        imageDetails.partitionKey = site;
        ApiService.image.Update__POST(imageDetails).then(() => {
          setImageDetails({})
          setLoading(false)
          setFileList([])
          onUpload(response.info, imageDetails);
        })
      })
  }

  return (
    <>
      <H4style>Upload new image to gallery</H4style>
      {fileList.length > 0 &&
        <CoreModal title={<>Confirm details <FileNameInModalTitle>({fileList.map((file: FileWithPath) => file.name).join(', ')})</FileNameInModalTitle></>} small onClose={() => setFileList([])} actionBar={<CoreButton disabled={loading} requesting={loading} onClick={confirm}>Confirm details and upload image</CoreButton>}>
          <Row>
            <Column size={6}>
              <StyledTypeahead
                unlink
                allowCustomValue
                value={imageDetails.folder}
                openOnFocus
                model='folder'
                requiredLength={0}
                items={folders ? folders.map(x => ({ value: x == 'Uncategorised' ? '' : x, text: x } as TypeaheadDataItem)) : []}
                label='Folder to keep image in'
                onTextInput={(e) => setImageDetails({ ...imageDetails, folder: e })}
                onChange={(e) => setImageDetails({ ...imageDetails, folder: e.value })}
                addNew={(e) => setImageDetails({ ...imageDetails, folder: e })}
              />
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              <StyledTextInput unlink value={imageDetails.title} label='Image title' onChange={(e) => setImageDetails({ ...imageDetails, title: e.target.value })} />
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              <StyledTextInput unlink value={imageDetails.alt} label='Alt text (description of image)' onChange={(e) => setImageDetails({ ...imageDetails, alt: e.target.value })} />
            </Column>
          </Row>
        </CoreModal>
      }
      {!loading &&
        <Dropzone onDrop={onFileChange} maxFiles={multiple ? undefined : 1}>
          {({ getRootProps, getInputProps }) => (
            <Container {...getRootProps()} active={fileList.length > 0}>
              <input {...getInputProps()} />
              {fileList.length == 0 &&
                <div><Icon name='upload' /> Drag and drop {multiple ? 'some files' : 'a file'} here, or click to select file{multiple && 's'} {allowedFileTypes.length > 0 && `(${allowedFileTypes.map(x => x.toUpperCase()).join(', ')})`}</div>
              }
              {fileList.length > 0 &&
                <ul>
                  {fileList.map((file: FileWithPath) => (
                    <li key={file.path}>
                      <Icon name='file' />{file.path} - {file.size} bytes
                    </li>
                  ))}
                </ul>
              }
            </Container>
          )}
        </Dropzone>
      }
      {loading && <Loader />}
    </>
  );
};

const FileNameInModalTitle = styled.span`
  color: ${props => props.theme.secondary};
  font-size: 0.8rem;
`

const Container = styled.div<{ active?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  border-width: 2px;
  border-radius: 2px;
  border: 2px dashed  ${props => props.active ? '#0074E1' : '#DEDEDE'};
  background-color: #FAFAFA;
  color: ${props => props.theme.secondary};
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;

  &:hover {
    border: 2px solid ${props => props.active ? '#0074E1' : '#DEDEDE'};
  }
`;

export default FildUpload;
