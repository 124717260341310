import React, { useEffect, useRef, useState } from 'react';
import { Canvas, ExtendedArea, ExtendedShape, ExtendedTable, SidePanel } from '.';
import MainStage from './MainStage';
import { H2style, H3, H3style, H4, H5 } from '../../../../../../components/Typography/Headings';
import { InfoMessage } from '../../../../../../components/Forms/Messaging';
import { Badge } from '@chakra-ui/react';
import styled from 'styled-components';
import { ApiService } from '../../../../../../api/api-connectors';
import { BaseBlockTimesAreaLink, BaseBlockTimesTableSelection } from '../../../../../../api/api-definitions';
import { cloneDeep } from 'lodash';
import { NotificationService } from '../../../../../../services/NotificationService';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import { StyledDropdown } from '../../../../../../theme/input.styles';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { DropdownItem } from '../../../../../../components/Forms/Dropdown';

interface ComponentProps {
    areas: ExtendedArea[];
    tables: ExtendedTable[];
    shapes: ExtendedShape[];
    onUpdate: (selected: BaseBlockTimesAreaLink[]) => void;
    selectedTables: BaseBlockTimesAreaLink[];
    timeBlockId: number;
    overlay?: string;
}

function getTableMap(tables: ExtendedTable[]) {
    const value: { [key: number]: ExtendedTable } = {};
    tables.forEach(table => {
        value[table.id.toString()] = table;
    });
    return value;
}

function getAreaMap(areas: ExtendedArea[]) {
    const value: { [key: number]: ExtendedArea } = {};
    areas.forEach(area => {
        value[area.id.toString()] = area;
    });
    return value;
}

const Wrapper = styled.div`
    display: flex;
    margin-top: 1rem;
`;

const BlockingTableAndAreaSelection = ({ areas, tables, shapes, selectedTables, timeBlockId, overlay, onUpdate }: ComponentProps) => {
    const [selectedArea, setSelectedArea] = useState<ExtendedArea>(areas[0])
    const [loading, setLoading] = useState(true);
    const tableMap = useRef<{ [key: number]: ExtendedTable }>(getTableMap(tables));
    const areaMap = useRef<{ [key: number]: ExtendedArea }>(getAreaMap(areas));

    useEffect(() => {
        onUpdate(areas.map(area => {
            const matchingArea = selectedTables.find(x => x.setupAreaId == area.id);
            if (matchingArea) return matchingArea;
            return {
                setupAreaId: area.id,
                timeBlockId: timeBlockId,
                id: 0,
                wholeAreaSelected: false,
                tableSelections: []
            }
        }))
    }, [])

    const selectTable = (tableId: number) => {
        let newSelectedTables = cloneDeep(selectedTables);
        const foundSavedArea = newSelectedTables.find(x => x.setupAreaId == tableMap.current[tableId].areaId);
        if (!foundSavedArea || foundSavedArea.wholeAreaSelected) return;
        if (!foundSavedArea.tableSelections) foundSavedArea.tableSelections = [];
        const foundSavedTable = foundSavedArea.tableSelections?.find(x => x.setupTableId == tableId);
        if (foundSavedTable) {
            foundSavedArea.tableSelections = foundSavedArea.tableSelections.filter(x => x.setupTableId !== tableId)
        } else {
            const newTable: BaseBlockTimesTableSelection = {
                setupTableId: tableId,
                id: 0,
                areaLinkId: foundSavedArea.id,
                timeBlockId: timeBlockId
            }
            foundSavedArea.tableSelections.push(newTable)
        }
        onUpdate(newSelectedTables)
    }

    const toggleWholeArea = (setupAreaId: number) => {
        let newSelectedTables = cloneDeep(selectedTables);
        let area = newSelectedTables.find(x => x.setupAreaId == setupAreaId);
        if (area) {
            area.wholeAreaSelected = !area.wholeAreaSelected;
        }
        onUpdate(newSelectedTables)
    }

    const selectedTableIds: number[] = [];
    selectedTables.forEach(area => {
        if (area.wholeAreaSelected) {
            tables.forEach(table => {
                if (table.areaId == area.setupAreaId) selectedTableIds.push(table.id);
            });
        } else {
            area.tableSelections?.forEach(table => {
                selectedTableIds.push(table.setupTableId);
            });
        }
    });

    const areasWithSetup: BaseBlockTimesAreaLink[] = selectedTables.filter(area => (area.wholeAreaSelected || area.tableSelections?.length > 0))

    return (
        <>
            <Wrapper>
                <Canvas>
                    <MainStage
                        tables={tables}
                        shapes={shapes}
                        selectedArea={selectedArea}
                        setLayout={() => { }}
                        setShapes={() => { }}
                        setArea={() => { }}
                        save={() => { }}
                        saveShape={() => { }}
                        selectTable={(id) => selectTable(id)}
                        selectShape={() => { }}
                        selectedTables={selectedTableIds}
                        readonly
                        mergeMode
                    />
                </Canvas>
                <SidePanel>
                    <CombinationContainer>
                        <StyledDropdown
                            onChange={e => setSelectedArea(areaMap.current[+e.target.value])}
                            value={selectedArea.id}
                            label={<strong>Selected area:</strong>}
                            addDefault={false}
                            items={areas.map(a => ({ value: a.id.toString(), text: a.name } as DropdownItem))}
                        />
                        <br />
                        {selectedTables.map(area => area.setupAreaId == selectedArea.id ? (
                            <AreaListContainer>
                                <div>
                                    <Checkbox
                                        asToggle
                                        unlink
                                        checked={area.wholeAreaSelected}
                                        label='Enable whole area'
                                        onChange={() => toggleWholeArea(area.setupAreaId)}
                                        inputName={'toggleAll-' + area.setupAreaId + (overlay ? `-${overlay}` : '')}
                                    />
                                </div>
                                {!area.wholeAreaSelected && area.tableSelections?.map(table => (
                                    <TableLabel colorScheme='teal'>{tableMap.current[table.setupTableId].tableName}</TableLabel>
                                ))}
                                {!area.wholeAreaSelected && (!area.tableSelections || area.tableSelections?.length == 0) &&
                                    <InfoMessage>No selected tables in area. Area will not be bookable.</InfoMessage>
                                }
                                {area.wholeAreaSelected && tables.map((table) => {
                                    if (table.areaId == area.setupAreaId) return (
                                        <TableLabel colorScheme='teal'>{table.tableName}</TableLabel>
                                    );
                                    return null;
                                })}
                            </AreaListContainer>
                        ): <></>)}
                        <br />
                        <hr />
                        <br />
                        <H4 marginBottom={0}>Summary</H4>
                        {areasWithSetup.map(area => (area.wholeAreaSelected || area.tableSelections?.length > 0) ? (
                            <AreaListContainer>
                                <strong>{areaMap.current[area.setupAreaId].name}</strong><br />
                                {!area.wholeAreaSelected && area.tableSelections?.map(table => (
                                    <TableLabel colorScheme='teal'>{tableMap.current[table.setupTableId].tableName}</TableLabel>
                                ))}
                                {!area.wholeAreaSelected && (!area.tableSelections || area.tableSelections?.length == 0) &&
                                    <InfoMessage>No selected tables in area. Area will not be bookable.</InfoMessage>
                                }
                                {area.wholeAreaSelected && tables.map((table) => {
                                    if (table.areaId == area.setupAreaId) return (
                                        <TableLabel colorScheme='teal'>{table.tableName}</TableLabel>
                                    );
                                    return null;
                                })}
                            </AreaListContainer>
                        ): <></>)}
                        {areasWithSetup.length == 0 &&
                            <>No area or table selections have been made.</>
                        }
                    </CombinationContainer>
                </SidePanel>
            </Wrapper>
        </>
    );
};

const AreaListContainer = styled.div`
    padding: 0.5rem 0;
`

const CombinationContainer = styled.div`
    padding: 0.5rem;
`

const TableLabel = styled(Badge)`
    font-size: 1rem !important;
    margin-right: 0.5rem;
`

export default BlockingTableAndAreaSelection;