import React, { useRef, useState } from "react";
import { Stage, Layer, Image, Line } from "react-konva";
import Table from "./Table";
import { LAYOUTHEIGHT, LAYOUTWIDTH, getTableHeightAndWidth } from "./tableLayoutUtils";
import { cloneDeep } from "lodash";
import TableLabel from "./TableLabel";
import { ExtendedArea, ExtendedShape, ExtendedTable } from ".";
import Shape from "./Shape";
import Zoom from "./Zoom";
import { BaseActiveBooking, BaseBookingTableSetup, BookingManagementResponse, BookingStatus, TableAvailabilityForDate } from "../../../../../../api/api-definitions";
import { RoundTo15, businessNowTime, createMomentFromValue } from "../../../../../../utils/date-helpers";
import { defaultColors } from "../../../../../../theme";
import { BookingStatusColours, GetStatusColour, GetStatusTextColour } from "../bookingUtils";
import useImage from "use-image";
import styled from "styled-components";
import moment from "moment";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Rect } from "react-konva";
import { getSelectedEntities } from "./utils/getSelectedEntities";

interface ComponentProps {
    tables: ExtendedTable[];
    shapes: ExtendedShape[];
    bookings?: BaseActiveBooking[];
    setLayout: (data: ExtendedTable[]) => void;
    setShapes: (data: ExtendedShape[]) => void;
    setArea: (data: ExtendedArea) => void;
    save: (item: ExtendedTable) => void;
    saveShape: (item: ExtendedShape) => void;
    selectTable: (id: number) => void;
    selectMultipleTables?: (id: number[]) => void;
    selectShape: (id: number) => void;
    dragEnd?: (x: number, y: number) => void;
    setNewBooking?: (time, table: BaseBookingTableSetup) => void;
    setViewBooking?: (booking: BaseActiveBooking) => void;
    selectedTables?: number[];
    selectedShape?: number;
    selectedArea?: ExtendedArea;
    readonly?: boolean;
    bookingManagementData?: BookingManagementResponse;
    displayGrid?: boolean;
    mergeMode?: boolean;
    browserScale?: number;
    tableLabels?: { [key: number]: string }
}

export const CanvasImage = (props) => {
    const [image] = useImage(props.url);
    if (image) image.style.color = 'white'
    return <Image image={image} {...props} ref={props.forwardRef} />;
};

const CnavasWrap = styled.div`
    fill: 'white';
`

function generateGrid() {
    const grid = 34;
    const gridWidth = 2000;
    const lineColour = '#a9a9a9';
    const lines = [];

    for (let i = 0; i < gridWidth / grid; i++) {
        lines.push(
            <Line
                strokeWidth={1}
                key={'line-h-' + i}
                stroke={lineColour}
                points={[i * grid, 0, i * grid, gridWidth]}
                name="object"
            />
        );

        lines.push(
            <Line
                strokeWidth={1}
                key={'line-v-' + i}
                stroke={lineColour}
                points={[0, i * grid, gridWidth, i * grid]}
                name="object"
            />
        );
    }

    return lines;
}

const GUIDELINE_OFFSET = 5;
type Snap = "start" | "center" | "end";
type SnappingEdges = {
    vertical: Array<{
        guide: number;
        offset: number;
        snap: Snap;
    }>;
    horizontal: Array<{
        guide: number;
        offset: number;
        snap: Snap;
    }>;
};

const MainStage = ({
    tables,
    shapes,
    setLayout,
    setShapes,
    setArea,
    save,
    saveShape,
    selectTable,
    selectMultipleTables,
    setNewBooking,
    setViewBooking,
    dragEnd,
    bookings,
    selectedTables,
    selectShape,
    selectedShape,
    selectedArea,
    readonly,
    bookingManagementData,
    displayGrid,
    mergeMode,
    tableLabels,
    browserScale = 1
}: ComponentProps) => {
    const containerRef = useRef(null);
    const stageRef = useRef(null);
    const layerRef = useRef<Konva.Layer>(null);
    const [dragId, setDragId] = useState(null);
    const [selection, setSelection] = useState(null);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const availability = bookingManagementData?.availability;
    const clonedTables = cloneDeep(tables);
    const lines = useRef<any[]>(displayGrid ? generateGrid() : []);
    if (browserScale < 0.4) browserScale = 1;
    if (selectedArea.scale < 0.4) selectedArea.scale = 1;

    clonedTables.forEach((table) => {
        table.booking = null;
        const lightBooking = availability?.tableIdAndAvailability[table.id]?.currentOrUpcomingBooking || bookings?.find(x => x.tables.includes(table.id) && (x.status === BookingStatus.Seated || x.status === BookingStatus.PartiallySeated));
        let foundBooking: BaseActiveBooking = null;
        if (lightBooking) {
            foundBooking = bookings?.find(x => x.id == lightBooking.id);
        }
        if (!foundBooking) {
            foundBooking = bookings?.find(x => x.tables.includes(table.id));
        }
        if (!mergeMode && !foundBooking && bookingManagementData && !bookingManagementData.isOpenNow) {
            table.disabled = true;
        }
        if (foundBooking?.isHeld) {
            table.disabled = true;
            table.held = true;
        }
        if (table.disabled) {
            table.fill = 'rgba(255, 255, 255, 0.3)';
            table.seatFill = 'rgba(77, 77, 77, 0.3)';
            table.textColour = '#000';
        } else if (foundBooking) {
            const upcomingSoon = foundBooking && !createMomentFromValue(foundBooking.startDateTime).isAfter(businessNowTime().add(1, 'hour'))
            var currentOrUpcoming = availability?.tableIdAndAvailability[table.id]?.currentOrUpcomingBooking;
            if (!!currentOrUpcoming) {
                availability.tableIdAndAvailability[table.id].currentOrUpcomingBooking = foundBooking;
            }
            const available = currentOrUpcoming ? availability.tableIdAndAvailability[table.id].available && !foundBooking : false;
            table.booking = foundBooking;
            const booking = foundBooking;
            if (!bookingManagementData.isOpenNow) {
                table.fill = 'rgba(255, 255, 255, 0.3)';
                table.seatFill = 'rgba(77, 77, 77, 0.3)';
                table.textColour = '#000';
                table.disabled = true;
            }
            if (available || !upcomingSoon) {
                table.fill = '#FFF';
                table.textColour = '#000';

                if (!upcomingSoon) {
                    table.free = true;
                }
            } else if (!available && booking) {
                table.booking = booking;
                table.fill = GetStatusColour(booking)
                table.textColour = GetStatusTextColour(booking);
                const chairColours: { [key: number]: string } = {};
                if (booking.status == BookingStatus.Pending) {
                    const allocatedGuests = booking.bookedTables.find(x => x.setupTableId == table.id)?.allocatedGuests;
                    for (let i = 1; i <= (allocatedGuests || booking.guests); i++) {
                        chairColours[i] = BookingStatusColours.seated.background;
                    }
                }
                table.chairColours = chairColours;
                if (createMomentFromValue(booking.endDateTime).isBefore(businessNowTime())) {
                    table.seatFill = 'red';
                }
                if (businessNowTime().isBetween(createMomentFromValue(booking.endDateTime).subtract(15, 'minutes'), createMomentFromValue(booking.endDateTime))) {
                    table.seatFill = BookingStatusColours.endingSoon.background;
                }
            } else if (!available && !booking) {
                table.fill = 'rgba(255, 255, 255, 1)';
                table.textColour = '#000';
            } else {
                table.fill = GetStatusColour({
                    status: BookingStatus.Pending,
                    guests: 0,
                    startDateTime: "",
                    endDateTime: "",
                    bookedAtUtc: ""
                })
                table.textColour = GetStatusTextColour({
                    status: BookingStatus.Pending,
                    guests: 0,
                    startDateTime: "",
                    endDateTime: "",
                    bookedAtUtc: ""
                })
            }
        } else {
            if (selectedTables.includes(table.id)) {
                table.fill = defaultColors.positive;
                table.textColour = defaultColors.positiveContrast;
            } else {
                if (displayGrid && !table.valid) {
                    table.fill = 'rgba(255, 204, 66, 1)';
                } else {
                    table.fill = '#FFF'
                    table.textColour = '#000'
                }
            }
        }
        if (tableLabels && tableLabels[table.id]) {
            table.info = tableLabels[table.id];
        }
    })

    const checkDeselect = (e: any) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty && (selectedShape || selectedTables.length > 0)) {
            selectShape(null);
            selectTable(null);
            selectMultipleTables([])
        }
    };

    const getLineGuideStops = (skipShape: Konva.Shape) => {
        const stage = skipShape.getStage();
        if (!stage) return { vertical: [], horizontal: [] };

        // we can snap to stage borders and the center of the stage
        const vertical = [0, stage.width() / 2, stage.width()];
        const horizontal = [0, stage.height() / 2, stage.height()];

        // and we snap over edges and center of each object on the canvas
        stage.find(".object").forEach((guideItem) => {
            if (guideItem === skipShape) {
                return;
            }
            const box = guideItem.getClientRect();
            // and we can snap to all edges of shapes
            vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
            horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
        });
        return {
            vertical,
            horizontal
        };
    };

    const getObjectSnappingEdges = React.useCallback(
        (node: Konva.Shape): SnappingEdges => {
            const box = node.getClientRect();
            const absPos = node.absolutePosition();

            return {
                vertical: [
                    {
                        guide: Math.round(box.x),
                        offset: Math.round(absPos.x - box.x),
                        snap: "start"
                    },
                    {
                        guide: Math.round(box.x + box.width / 2),
                        offset: Math.round(absPos.x - box.x - box.width / 2),
                        snap: "center"
                    },
                    {
                        guide: Math.round(box.x + box.width),
                        offset: Math.round(absPos.x - box.x - box.width),
                        snap: "end"
                    }
                ],
                horizontal: [
                    {
                        guide: Math.round(box.y),
                        offset: Math.round(absPos.y - box.y),
                        snap: "start"
                    },
                    {
                        guide: Math.round(box.y + box.height / 2),
                        offset: Math.round(absPos.y - box.y - box.height / 2),
                        snap: "center"
                    },
                    {
                        guide: Math.round(box.y + box.height),
                        offset: Math.round(absPos.y - box.y - box.height),
                        snap: "end"
                    }
                ]
            };
        },
        []
    );

    const getGuides = React.useCallback(
        (
            lineGuideStops: ReturnType<typeof getLineGuideStops>,
            itemBounds: ReturnType<typeof getObjectSnappingEdges>
        ) => {
            const resultV: Array<{
                lineGuide: number;
                diff: number;
                snap: Snap;
                offset: number;
            }> = [];

            const resultH: Array<{
                lineGuide: number;
                diff: number;
                snap: Snap;
                offset: number;
            }> = [];

            lineGuideStops.vertical.forEach((lineGuide) => {
                itemBounds.vertical.forEach((itemBound) => {
                    const diff = Math.abs(lineGuide - itemBound.guide);
                    if (diff < GUIDELINE_OFFSET) {
                        resultV.push({
                            lineGuide: lineGuide,
                            diff: diff,
                            snap: itemBound.snap,
                            offset: itemBound.offset
                        });
                    }
                });
            });

            lineGuideStops.horizontal.forEach((lineGuide) => {
                itemBounds.horizontal.forEach((itemBound) => {
                    const diff = Math.abs(lineGuide - itemBound.guide);
                    if (diff < GUIDELINE_OFFSET) {
                        resultH.push({
                            lineGuide: lineGuide,
                            diff: diff,
                            snap: itemBound.snap,
                            offset: itemBound.offset
                        });
                    }
                });
            });

            const guides: Array<{
                lineGuide: number;
                offset: number;
                orientation: "V" | "H";
                snap: "start" | "center" | "end";
            }> = [];

            const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
            const minH = resultH.sort((a, b) => a.diff - b.diff)[0];

            if (minV) {
                guides.push({
                    lineGuide: minV.lineGuide,
                    offset: minV.offset,
                    orientation: "V",
                    snap: minV.snap
                });
            }

            if (minH) {
                guides.push({
                    lineGuide: minH.lineGuide,
                    offset: minH.offset,
                    orientation: "H",
                    snap: minH.snap
                });
            }

            return guides;
        },
        []
    );

    const drawGuides = React.useCallback(
        (guides: ReturnType<typeof getGuides>, layer: Konva.Layer) => {
            guides.forEach((lg) => {
                if (lg.orientation === "H") {
                    const line = new Konva.Line({
                        points: [-6000, 0, 6000, 0],
                        stroke: "rgb(0, 161, 255)",
                        strokeWidth: 1,
                        name: "guid-line",
                        dash: [4, 6]
                    });
                    layer.add(line);
                    line.absolutePosition({
                        x: 0,
                        y: lg.lineGuide
                    });
                } else if (lg.orientation === "V") {
                    const line = new Konva.Line({
                        points: [0, -6000, 0, 6000],
                        stroke: "rgb(0, 161, 255)",
                        strokeWidth: 1,
                        name: "guid-line",
                        dash: [4, 6]
                    });
                    layer.add(line);
                    line.absolutePosition({
                        x: lg.lineGuide,
                        y: 0
                    });
                }
            });
        },
        []
    );

    const onDragMove = React.useCallback(
        (e: Konva.KonvaEventObject<DragEvent>) => {
            const layer = e.target.getLayer();

            // clear all previous lines on the screen
            layer.find(".guid-line").forEach((l: Konva.Shape) => l.destroy());

            // find possible snapping lines
            const lineGuideStops = getLineGuideStops(e.target as Konva.Shape);
            // find snapping points of current object
            const itemBounds = getObjectSnappingEdges(e.target as Konva.Shape);

            // now find where can we snap current object
            const guides = getGuides(lineGuideStops, itemBounds);

            // do nothing if no snapping
            if (!guides.length) {
                return;
            }

            drawGuides(guides, layer);

            const absPos = e.target.absolutePosition();
            // now force object position
            guides.forEach((lg) => {
                switch (lg.snap) {
                    case "start": {
                        switch (lg.orientation) {
                            case "V": {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                            }
                            case "H": {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                            }
                        }
                        break;
                    }
                    case "center": {
                        switch (lg.orientation) {
                            case "V": {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                            }
                            case "H": {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                            }
                        }
                        break;
                    }
                    case "end": {
                        switch (lg.orientation) {
                            case "V": {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                            }
                            case "H": {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                            }
                        }
                        break;
                    }
                }
            });
            e.target.absolutePosition(absPos);
        },
        [drawGuides, getGuides, getObjectSnappingEdges]
    );

    const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
        const layer = e.target.getLayer();
        // clear all previous lines on the screen
        layer.find(".guid-line").forEach((l: Konva.Shape) => l.destroy());
    };

    const handleMouseDown = (e) => {
        e.evt.preventDefault();
        if (selectMultipleTables && selection === null && selectedTables.length == 0 && !selectedShape) {
            const stage = e.target.getStage();
            const { x: pointerX, y: pointerY } = stage.getPointerPosition();
            const pos = {
                x: pointerX - stage.x(),
                y: pointerY - stage.y()
            };
            setSelection({
                startX: pos.x,
                startY: pos.y,
                endX: pos.x,
                endY: pos.y,
                x: pos.x,
                y: pos.y,
                width: 0,
                height: 0
            });
        }
    }

    const handleMouseMove = (e) => {
        if (selection !== null) {
            const stage = e.target.getStage();
            const { x: pointerX, y: pointerY } = stage.getPointerPosition();
            const pos = {
                x: pointerX - stage.x(),
                y: pointerY - stage.y()
            };
            setSelection({
                ...selection,
                endX: pos.x,
                endY: pos.y,
                x: Math.min(selection.startX, pos.x),
                y: Math.min(selection.startY, pos.y),
                width: Math.abs(selection.startX - pos.x),
                height: Math.abs(selection.startY - pos.y)
            });
        }
    }

    const handleMouseUp = (e) => {
        e.evt.preventDefault();
        if (selection !== null) {
            const tableShapes = stageRef.current.find('.moveable');
            const selected = tableShapes.filter((table) =>
                Konva.Util.haveIntersection(selection, table.getClientRect())
            );
            selectMultipleTables(selected.filter(x => x.attrs.table).map(x => x.attrs.table.id));
            setSelection(null);
        }
    }

    return (
        <CnavasWrap>
            <div
                style={{
                    position: "relative",
                    backgroundColor: "lightgrey",
                    height: LAYOUTHEIGHT,
                    width: LAYOUTWIDTH,
                    flex: '1 0 auto',
                }}
                ref={containerRef}
                onDrop={(e) => {
                    e.preventDefault();
                    stageRef.current.setPointersPositions(e);
                    const pos = stageRef.current.getPointerPosition();
                    dragEnd(Math.floor(pos.x), Math.floor(pos.y));
                }}
                onDragOver={(e) => e.preventDefault()}
            >
                {!readonly && <Zoom scale={selectedArea.scale} setScale={(value) => setArea({ ...selectedArea, scale: value })} />}
                {clonedTables.filter(x => x.areaId === selectedArea.id).map(table => (
                    <React.Fragment key={`tableLabel-${table.id}`}>
                        {dragId !== table.id && <TableLabel table={table} scale={selectedArea.scale} browserScale={browserScale} />}
                    </React.Fragment>
                ))}
                <Stage
                    ref={stageRef}
                    width={LAYOUTWIDTH}
                    height={LAYOUTHEIGHT}
                    scaleX={selectedArea.scale}
                    scaleY={selectedArea.scale}
                    onMouseDown={e => { checkDeselect(e); handleMouseDown(e) }}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onTouchStart={checkDeselect}
                >
                    {/* <Layer>
                        {lines.current}
                    </Layer> */}
                    <Layer ref={layerRef}>
                        {lines.current}
                        {selection &&
                            <Rect
                                fill="rgba(86, 204, 242, 0.1)"
                                stroke="#2d9cdb"
                                x={selection.x}
                                y={selection.y}
                                width={selection.width}
                                height={selection.height}
                            />
                        }
                        {shapes.filter(x => x.areaId === selectedArea.id).map((shape) => (
                            <Shape
                                shapeProps={shape}
                                key={shape.id}
                                readonly={readonly}
                                isSelected={shape.id === selectedShape}
                                onChange={(item) => {
                                    const newShapes = cloneDeep(shapes);
                                    const i = newShapes.findIndex(x => x.id === shape.id);
                                    newShapes[i] = {
                                        ...item
                                    }
                                    setShapes(newShapes)
                                    saveShape(item)
                                }}
                                onSelect={() => { selectShape(shape.id); selectTable(undefined) }}
                            />
                        ))}
                        {clonedTables.filter(x => x.areaId === selectedArea.id).map((table) => (
                            <Table
                                shape={table.shape}
                                readonly={readonly}
                                mergeMode={mergeMode}
                                key={table.id}
                                shapeProps={{
                                    ...table,
                                    fill: table.fill,
                                    ...getTableHeightAndWidth(table)
                                }}
                                isSelected={selectedTables.includes(table.id)}
                                onSelect={(e) => {
                                    if (!!!table.disabled) {
                                        if (!setNewBooking && !setViewBooking) selectTable(table.id);
                                        selectShape(undefined);
                                        if ((!table.booking || table.free) && setNewBooking) {
                                            setNewBooking(RoundTo15(new Date()), table);
                                        }
                                        if (table.booking && !table.free && setViewBooking) setViewBooking(table.booking);
                                    }
                                }}
                                onDragStart={(e: KonvaEventObject<DragEvent>) => {
                                    setDragId(table.id);
                                }}
                                onDragEnd={(e: KonvaEventObject<DragEvent>) => {
                                    setDragId(undefined)
                                    onDragEnd(e)
                                }}
                                onDragMove={onDragMove}
                                onChange={(newAttrs) => {
                                    const rects = cloneDeep(tables);
                                    const i = rects.findIndex(x => x.id === table.id);
                                    const totalChangeY = rects[i].y - newAttrs.y;
                                    const totalChangeX = rects[i].x - newAttrs.x;
                                    const totalChangeRotation = rects[i].rotation - newAttrs.rotation;
                                    rects[i].x = newAttrs.x;
                                    rects[i].y = newAttrs.y;
                                    rects[i].rotation = newAttrs.rotation;
                                    if (selectedTables.length > 1) {
                                        rects.forEach(item => {
                                            if (item.id !== table.id && selectedTables.includes(item.id)) {
                                                item.x = item.x - totalChangeX;
                                                item.y = item.y - totalChangeY;
                                                item.rotation = item.rotation - totalChangeRotation;
                                            }
                                        });
                                    }
                                    setLayout(rects);
                                    save(newAttrs)
                                }}
                            />
                        ))}
                    </Layer>
                </Stage>
            </div>
        </CnavasWrap>
    );
};

export default MainStage;
