import { Link, useParams } from 'react-router-dom';
import { NavBarTitle } from '.';
import Icon from '../../../../components/Media/Icon';
import { useBusiness } from '../../../../hooks/useBusiness';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { LocationSidebar, adminSidebar } from '../../sideBars';

interface ComponentProps {
    toggleOpen: () => void;
}

const AdminNav = ({ toggleOpen }: ComponentProps) => {
    const params: any = useParams();

    return (

        <>
            <NavBarTitle>Admin Functions</NavBarTitle>
            {adminSidebar.map((link, index) => (
                <Link key={`location-nav1-${index}`} to={`/dashboard/${link.link}`} onClick={toggleOpen}><Icon name={link.icon} /> {link.name}</Link>
            ))}
        </>
    );
};

export default AdminNav;
