import { ElementTypes } from "../../../../../DocumentTemplates/Dynamic";

interface NodeOption {
    type: ElementTypes;
    title: string;
    icon?: string;
    options?: InputOption[]
}

interface InputOption {
    type: EditType;
    label: string;
    property: string;
}

type EditType = 'text' | 'number' | 'textarea' | 'columnSelector' | 'hidden' | 'size' | 'style';

export const NodeOptions: {[key: string]: NodeOption} = {
    'text': {
        type: 'text',
        title: 'Text',
        icon: 'font',
        options: [
            {
                type: 'text',
                label: 'Value',
                property: 'value',
            },
            {
                type: 'style',
                label: 'Style',
                property: 'style',
            }
        ]
    },
    'image': {
        type: 'image',
        title: 'Image',
        icon: 'image',
        options: [
            {
                type: 'text',
                label: 'URL',
                property: 'url',
            },
            {
                type: 'size',
                label: 'Image size',
                property: 'width',
            }
        ]
    },
    'row': {
        type: 'row',
        title: 'Row/Columns',
        icon: 'table-columns',
        options: [
            {
                type: 'columnSelector',
                label: 'Columns',
                property: 'columns',
            },
            {
                type: 'hidden',
                label: '',
                property: 'columnContent'
            }
        ]
    },
}