import { Link, useParams } from 'react-router-dom';
import { NavBarTitle } from '.';
import Icon from '../../../../components/Media/Icon';
import { useBusiness } from '../../../../hooks/useBusiness';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { LocationSidebar } from '../../sideBars';
import { useBusinessPermissions } from '../../../../hooks/useBusinessPermissions';
import useOffline from '../../../../hooks/useOffline';
import { WarningMessage } from '../../../../components/Forms/Messaging';
import styled from 'styled-components';
import { VerificationStatus } from '../../../../api/api-definitions';

interface ComponentProps {
    toggleOpen: () => void;
}

const Warning = styled(WarningMessage)`
    height: 6rem;
    overflow: hidden;
`

const LocationNav = ({ toggleOpen }: ComponentProps) => {
    const offline = useOffline();
    const [loaded, data] = useBusiness();
    const params: any = useParams();

    if (!data) return null;

    return (

        <>
            {/* <NavBarTitle>Business</NavBarTitle>
            <Link to={`/dashboard/${params.parentId}/locations`} onClick={toggleOpen}><Icon name='store' /> Back to all locations</Link> */}
            <NavBarTitle title={data.locationAddress?.formattedName}>{data.locationAddress?.formattedName}</NavBarTitle>
            {offline && <Warning>Only some functions are available when offline</Warning>}
            {LocationSidebar.filter(x => isNullOrWhitespace(x.category) && (x.link == 'verify-business' ? data.verificationStatus != VerificationStatus.Verified : true) && (offline ? x.offline : true)).map((link, index) => (
                <Link key={`location-nav1-${index}`} to={`/dashboard/${params.parentId}/locations/${params.id}/${link.link}`} onClick={toggleOpen}><Icon name={link.icon} /> {link.name}</Link>
            ))}
       
            <NavBarTitle>Booking management</NavBarTitle>
            {LocationSidebar.filter(x => x.category === 'bookings' && (offline ? x.offline : true)).map((link, index) => (
                <Link key={`location-nav1-${index}`} to={`/dashboard/${params.parentId}/locations/${params.id}/${link.link}`} onClick={toggleOpen}><Icon name={link.icon} /> {link.name}</Link>
            ))}
        </>
    );
};

export default LocationNav;
