import React, { useEffect, useRef, useState } from 'react';
import { useBusiness } from '../../../../../hooks/useBusiness';
import DataTable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import Loader from '../../../../../components/Layout/Loader';
import DashboardAddButton from '../../../../../components/Dashboard/AddButton';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import CoreModal from '../../../../../components/Layout/CoreModal';
import CoreButton from '../../../../../components/Forms/Button';
import Icon from '../../../../../components/Media/Icon';
import { Column, Row } from '../../../../../components/Layout/Grid';
import Checkbox from '../../../../../components/Forms/Checkbox';
import { StyledCalendar, StyledTextInput } from '../../../../../theme/input.styles';
import Time from '../../../../../components/Forms/Time';
import { DATABASE_TIME_FORMAT, DISPLAY_DATE_FORMAT, TIMEFORMAT, formatDate } from '../../../../../utils/date-helpers';
import { NotificationService } from '../../../../../services/NotificationService';
import { BaseBusinessOpeningTimeException, ExperienceType } from '../../../../../api/api-definitions';
import { ApiService } from '../../../../../api/api-connectors';
import styled from 'styled-components';
import { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../components/Forms/Messaging';
import { FloatingActionBar } from '../../../../../components/Layout/FloatingActionBar';
import { Constants } from '../../../../../constants';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';
import { DayBox } from '../Bookings/BookingSetup';
import { Badge } from '@chakra-ui/react';
import InfoButton from '../../../../../components/Cta/InfoButton';

const GenExceptionsTable = (
    data: BaseBusinessOpeningTimeException[],
    editAction: (item: BaseBusinessOpeningTimeException) => void = null,
    deleteAction: (item: BaseBusinessOpeningTimeException) => void = null,
    toggleActiveAction: (index: number) => void = null,
): DataTableItem[] => {
    const items: DataTableItem[] = [];
    data.forEach((item, index) => {
        const dataItem: DataTableItem = {
            data: {
                'Start date': {
                    value: <>{formatDate(item.dateFrom, DISPLAY_DATE_FORMAT)}</>,
                },
                'End date': {
                    value: item.dateTo ? <>{formatDate(item.dateTo, DISPLAY_DATE_FORMAT)}</> : '--',
                },
                'Times': {
                    value: !item.closed ? getExeptionTimes(item) : 'CLOSED',
                },
                'Days': {
                    value: <>
                        {item.monday && <DayLabel colorScheme='teal'>Mon</DayLabel>}
                        {item.tuesday && <DayLabel colorScheme='teal'>Tue</DayLabel>}
                        {item.wednesday && <DayLabel colorScheme='teal'>Wed</DayLabel>}
                        {item.thursday && <DayLabel colorScheme='teal'>Thu</DayLabel>}
                        {item.friday && <DayLabel colorScheme='teal'>Fri</DayLabel>}
                        {item.saturday && <DayLabel colorScheme='blue'>Sat</DayLabel>}
                        {item.sunday && <DayLabel colorScheme='blue'>Sun</DayLabel>}
                    </>
                },
                ...(toggleActiveAction ? {
                    'Active': {
                        value: <Checkbox inputName={'activeException-' + index} unlink checked={item.bookableForEvent} asToggle onChange={() => toggleActiveAction(index)} />
                    }
                } : {}),
                ...(editAction && deleteAction ? {
                    'Edit': {
                        value: <CoreButton type='secondary' onClick={() => editAction(item)}>Edit</CoreButton>,
                        hideName: true
                    },
                    'Delete': {
                        value: <CoreButton type='danger' onClick={() => deleteAction(item)}>Delete</CoreButton>,
                        hideName: true
                    },
                } : {})
            },
        }
        items.push(dataItem);
    });
    return items;
}

interface ComponentProps {
    experienceId?: number;
    experienceType?: ExperienceType;
    onDatesUpdate?: (dates: BaseBusinessOpeningTimeException[]) => void;
}

const SpecialOpeningTimes = ({ experienceId, experienceType, onDatesUpdate }: ComponentProps) => {
    const [businessLoaded, data] = useBusiness();
    const [editRule, setEditRule] = useState<BaseBusinessOpeningTimeException>();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [exceptions, setExceptions] = useState<BaseBusinessOpeningTimeException[]>();
    const [saving, setSaving] = useState(false);
    const modalContentRef = useRef<HTMLDivElement>()
    const [overlappingIds, setOverlappingIds] = useState<number[]>();
    const [itemToDelete, setItemToDelete] = useState<BaseBusinessOpeningTimeException>();


    const allSelected = editRule?.monday && editRule?.tuesday && editRule?.wednesday && editRule?.thursday && editRule?.friday && editRule?.saturday && editRule?.sunday;
    const oneSelected = editRule?.monday || editRule?.tuesday || editRule?.wednesday || editRule?.thursday || editRule?.friday || editRule?.saturday || editRule?.sunday;

    const load = () => {
        ApiService.businessOpeningTimeException.List__GET(data.id, experienceId || 0)
            .then(response => {
                setExceptions(response)
                if (onDatesUpdate) onDatesUpdate(response);
            })
            .catch(() =>
                NotificationService.Error("Could not load opening times exceptions")
            )
    }

    useEffect(() => {
        if (data && !exceptions) {
            moment.tz.setDefault(data.locationAddress.timeZone);
            load()
        }
    }, [data]);

    if (!data || !exceptions) return <Loader />;


    const deleteItem = () => {
        let experienceType = getExperienceType();
        ApiService.businessOpeningTimeException.Delete__DELETE(itemToDelete).then(() => {
            NotificationService.Confirm(`${!experienceId ? 'Exception opening time deleted' : `${experienceType} date and time ranges deleted`}`);
            load()
            setItemToDelete(undefined)
        }).catch(() =>
            NotificationService.Error(`${!experienceId ? 'Could not remove opening times exceptions' : `Could not remove ${experienceType} date and time ranges`}`)
        )
    }

    const getExperienceType = () => {
        let type = '';
        if (!!experienceId) {
            if (experienceType == ExperienceType.Event) {
                type = 'Event';
            } else {
                type = 'Experience';
            }
        }
        return type;
    }

    const addItem = (item?: BaseBusinessOpeningTimeException) => {
        resetErrors();
        const exception = item || editRule;
        exception.businessId = data.id;
        if (exception.closed) {
            exception.exceptionTimes = [];
        }
        exception.dateFrom = formatDate(exception.dateFrom, DATABASE_TIME_FORMAT);
        exception.dateTo = !exception.dateTo && experienceType == ExperienceType.Experience ?
            undefined :
            formatDate(exception.dateTo, DATABASE_TIME_FORMAT);
        if (experienceId) {
            exception.exceptionTimes.forEach(time => {
                time.shiftLabel = !!experienceType && experienceType != ExperienceType.NotSet ?
                    experienceType :
                    'Event/Experience'
            });
        }
        if (!item) setSaving(true);
        return (exception.id ? ApiService.businessOpeningTimeException.Save__POST : ApiService.businessOpeningTimeException.Add__PUT)(exception).then((response) => {
            const experienceType = getExperienceType();
            if (!response.success) {
                setErrorMessage(response.errorMessage);
                if (response.idsOfOverlappingRecords) {
                    setOverlappingIds(response.idsOfOverlappingRecords);
                    setTimeout(() => {
                        if (modalContentRef) modalContentRef.current.scrollTo({
                            top: 1000,
                            left: 0,
                            behavior: "smooth",
                        })
                    }, 200);
                }
                if (!isNullOrWhitespace(experienceType)) {
                    NotificationService.Error(`Could not save ${experienceType} date and time ranges`);
                } else {
                    NotificationService.Error("Could not save opening times exception");
                }
            } else {
                if (!!experienceId) {
                    NotificationService.Confirm(exception.id ? `${experienceType} date and time ranges amended` : `${experienceType} date and time ranges added`);
                } else {
                    NotificationService.Confirm(exception.id ? 'Exception opening time amended' : 'Exception opening time added');
                }
                if (!item) {
                    load()
                    setEditRule(undefined)
                    setOverlappingIds(undefined)
                    setModalOpen(false)
                }
            }
        }).catch(() =>
            NotificationService.Error("Could not save opening times exception")
        ).finally(() => setSaving(false))
    }

    const openModal = (item: BaseBusinessOpeningTimeException) => {
        resetErrors();
        setOverlappingIds(undefined);
        setEditRule({
            ...item, exceptionTimes: item.exceptionTimes?.length > 0 ? item.exceptionTimes : [{
                sortOrder: 0,
                id: 0,
                businessOpeningTimeExceptionId: 0,
                shiftLabel: ''
            }]
        })
        setModalOpen(true)
    }

    const resetErrors = () => {
        setErrorMessage(null);
    }

    const addTime = () => {
        const newExceptionTime = cloneDeep(editRule);
        newExceptionTime.exceptionTimes.push({
            sortOrder: newExceptionTime.exceptionTimes.length,
            id: 0,
            businessOpeningTimeExceptionId: editRule.id,
            shiftLabel: ''
        })
        setEditRule(newExceptionTime)
    }

    const removeTime = (index: number) => {
        const newExceptionTime = cloneDeep(editRule);
        newExceptionTime.exceptionTimes.splice(index, 1)
        setEditRule(newExceptionTime)
    }

    const updateTimeSlot = (property: string, index: number, value: string) => {
        const newExceptionTime = cloneDeep(editRule);
        newExceptionTime.exceptionTimes[index][property] = value;
        setEditRule(newExceptionTime)
        setErrorMessage(null)
    }

    const formValid = !editRule || editRule?.closed || !editRule.exceptionTimes.find(x =>
        (experienceId ? false : isNullOrWhitespace(x.shiftLabel)) ||
        isNullOrWhitespace(x.timeFrom) ||
        isNullOrWhitespace(x.timeFrom) ||
        x.shiftLabel.length > 50
    );

    const toggleActive = (index: number) => {
        const newData = cloneDeep(exceptions);
        newData[index].bookableForEvent = !newData[index].bookableForEvent;
        addItem(newData[index]).then(() => {
            setExceptions(newData);
            if (onDatesUpdate) onDatesUpdate(newData);
        });
    }

    const toggleSelectAll = () => {
        setEditRule({
            ...editRule,
            monday: !allSelected,
            tuesday: !allSelected,
            wednesday: !allSelected,
            thursday: !allSelected,
            friday: !allSelected,
            saturday: !allSelected,
            sunday: !allSelected,
        })
    }
    const dateToRequired = !experienceId || experienceType == ExperienceType.Event;
    const openToLabel = 'Open until';
    return (
        <div>
            {itemToDelete &&
                <CoreModal
                    small
                    onClose={() => setItemToDelete(undefined)}
                    title='Are you sure you want to delete this rule?'
                    slimPanel
                    actionBar={<CoreButton type='danger' requesting={saving} disabled={saving} onClick={() => deleteItem()}>Confirm</CoreButton>}
                >

                </CoreModal>
            }
            {editRule && modalOpen &&
                <FormWrapper onUpdate={(formDetails) => setEditRule({ ...editRule, ...formDetails })}>
                    {({ id, valid }) => (
                        <CoreModal onClose={() => { setEditRule(undefined); setModalOpen(false) }}
                            title={data.id ? 'Edit opening time exception' : 'Add new opening time exception'}
                            isOpen
                            contentRef={modalContentRef}
                            error={errorMessage}
                            actionBar={
                                <>
                                    <CoreButton requesting={saving} disabled={!valid || !formValid || !oneSelected || saving} onClick={() => addItem()}>
                                        <Icon name='save' /> Save</CoreButton>
                                </>
                            }>
                            <Row>
                                {!experienceId && <Column size={2}></Column>}
                                <Column size={3}>
                                    <StyledCalendar required model='dateFrom' value={editRule.dateFrom} label='Start date' onChange={() => resetErrors()} />
                                </Column>
                                <Column size={3}>
                                    <StyledCalendar required={dateToRequired} placeholder={!dateToRequired ? 'Leave blank for no limit' : 'DD/MM/YYYY'} model='dateTo' minDate={editRule.dateFrom} value={editRule.dateTo} label='End date (inclusive)' onChange={() => resetErrors()} />
                                </Column>
                                {/* {experienceId &&
                                    <Column size={3} checkboxInput>
                                        <Checkbox asToggle label='Recurring yearly' model='recurringYearly' checked={editRule.recurringYearly} />
                                    </Column>
                                } */}
                            </Row>
                            <hr />
                            <br />
                            {editRule.exceptionTimes?.map((time, index) => (
                                <Row key={'time-' + time.id}>
                                    {!experienceId && index == 0 &&
                                        <Column size={2}>
                                            <br />
                                            <Checkbox asToggle label='Closed' model='closed' checked={editRule.closed} onChange={() => resetErrors()} />
                                        </Column>
                                    }
                                    {!experienceId && index != 0 &&
                                        <Column size={2}>
                                        </Column>
                                    }
                                    {!!experienceId && index == 0 &&
                                        <Column size={12}>
                                            <InfoMessage>Please specify bookable time slot ranges.</InfoMessage>
                                        </Column>
                                    }
                                    <Column size={3}>
                                        <Time unlink required={!editRule.closed} value={time.timeFrom} model='timeFrom' label={index == 0 ? (!experienceId ? 'Open from' : 'Time from') : undefined} disabled={editRule.closed} onChange={(e) => updateTimeSlot('timeFrom', index, e.target.value)} />
                                    </Column>
                                    <Column size={3}>
                                        <Time unlink required={!editRule.closed} value={time.timeTo} model='timeTo'
                                            label={index == 0 ?
                                                (!experienceId ?
                                                    <>
                                                        {openToLabel}
                                                        <InfoButton>The last slot will not be bookable. e.g. If '{openToLabel}' is set to 6pm, then bookable slots will be until 5:45pm, as 6pm would be considered as closed time.
                                                        </InfoButton>
                                                    </>
                                                    : 'Time to (inclusive)'
                                                )
                                                : undefined}
                                            disabled={editRule.closed} onChange={(e) => updateTimeSlot('timeTo', index, e.target.value)} />
                                    </Column>
                                    {!experienceId &&
                                        <Column size={3}>
                                            <StyledTextInput unlink value={time.shiftLabel} required={!editRule.closed} 
                                            label={index == 0 ? <>Shift label <InfoButton>When multiple shifts per day are configured, these labels will be visible both externally (on booking widget) and internally (on table bookings view). This will help your clients and your staff to identify which shift the bookings are made for e.g. Breakfast, Lunch, Brunch, Dinner etc. </InfoButton></> : undefined} 
                                            disabled={editRule.closed} max={50} placeholder='e.g. Dinner' onChange={(e) => updateTimeSlot('shiftLabel', index, e.target.value)} />
                                        </Column>
                                    }
                                    <Column size={1} radioInput={index == 0} hidden={editRule.exceptionTimes.length == 3}>
                                        {index < 2 && index === editRule.exceptionTimes.length - 1 && <CoreButton onClick={() => addTime()}><Icon name='plus' /></CoreButton>}
                                    </Column>
                                    <Column size={1} radioInput={index == 0}>
                                        {editRule.exceptionTimes.length != 1 && <CoreButton type='danger' onClick={() => removeTime(index)}><Icon name='minus' /></CoreButton>}
                                    </Column>
                                </Row>
                            ))}
                            {experienceId &&
                                <>
                                    <hr />
                                    <Row>
                                        <Column size={12} checkboxInput>
                                            <Checkbox asToggle label='Active' model='bookableForEvent' checked={editRule.bookableForEvent} />
                                        </Column>
                                    </Row>
                                </>
                            }
                            {overlappingIds && overlappingIds.length > 0 &&
                                <>
                                    <ErrorMessage>
                                        Cannot save the rule as it conflicts with the below rule{overlappingIds.length > 1 ? 's' : ''}.
                                        Please review and amend the rule setup before saving.
                                    </ErrorMessage>
                                    <br />
                                    <DataTable data={GenExceptionsTable(exceptions.filter(x => overlappingIds.includes(x.id)))} />
                                </>
                            }
                            <hr />
                            <br />
                            <Row>
                                <Column size={4} mobile={12}>
                                    <br />
                                    <CoreButton type='secondary' outline onClick={toggleSelectAll}>{allSelected ? 'Deselect' : 'Select'} all</CoreButton>
                                </Column>
                                <Column size={8} mobile={12}>
                                    <label> Days of week</label>
                                    <br />
                                    <DayBox onClick={() => setEditRule({ ...editRule, monday: !editRule.monday })} checked={editRule.monday}><Icon name={editRule.monday ? 'check' : 'times'} /> Mon</DayBox>
                                    <DayBox onClick={() => setEditRule({ ...editRule, tuesday: !editRule.tuesday })} checked={editRule.tuesday}><Icon name={editRule.tuesday ? 'check' : 'times'} /> Tue</DayBox>
                                    <DayBox onClick={() => setEditRule({ ...editRule, wednesday: !editRule.wednesday })} checked={editRule.wednesday}><Icon name={editRule.wednesday ? 'check' : 'times'} /> Wed</DayBox>
                                    <DayBox onClick={() => setEditRule({ ...editRule, thursday: !editRule.thursday })} checked={editRule.thursday}><Icon name={editRule.thursday ? 'check' : 'times'} /> Thu</DayBox>
                                    <DayBox onClick={() => setEditRule({ ...editRule, friday: !editRule.friday })} checked={editRule.friday}><Icon name={editRule.friday ? 'check' : 'times'} /> Fri</DayBox>
                                    <DayBox onClick={() => setEditRule({ ...editRule, saturday: !editRule.saturday })} checked={editRule.saturday}><Icon name={editRule.saturday ? 'check' : 'times'} /> Sat</DayBox>
                                    <DayBox onClick={() => setEditRule({ ...editRule, sunday: !editRule.sunday })} checked={editRule.sunday}><Icon name={editRule.sunday ? 'check' : 'times'} /> Sun</DayBox>
                                </Column>
                            </Row>
                            <br />
                        </CoreModal>
                    )}
                </FormWrapper>
            }
            {experienceId && exceptions?.length === 0 &&
                <InfoMessage>There are no date/time bands currently set up. At least 1 date/time band is required.</InfoMessage>
            }
            {(!experienceId || exceptions?.length > 0) &&
                <DataTable noResultsMessage='You do not have any exception opening hours set up yet.' data={GenExceptionsTable(exceptions, openModal, setItemToDelete, experienceId ? toggleActive : null)} />
            }
            <br />
            <DashboardAddButton onClick={() => openModal({
                businessId: data.id,
                bookableForEvent: !!experienceId, dateFrom: undefined, dateTo: undefined, closed: false, id: undefined, experienceId,
                monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, exceptionTimes: [{
                    sortOrder: 0,
                    id: 0,
                    businessOpeningTimeExceptionId: 0,
                    shiftLabel: '',
                }]
            })}>{experienceId ? 'Add date band' : 'Add opening time exception'}</DashboardAddButton>
        </div>
    );
};

export default SpecialOpeningTimes;

const getExeptionTimes = (item: BaseBusinessOpeningTimeException): React.ReactNode => {
    return <>
        {item.exceptionTimes?.map((time) => (
            <React.Fragment key={'time-' + time.timeFrom}>
                {formatDate(time.timeFrom, 'HH:mm')} to {formatDate(time.timeTo, 'HH:mm')}
                <br />
            </React.Fragment>
        ))}
    </>;
}

const DayLabel = styled(Badge)`
    margin-right: 0.5rem;
`
