import React, { useEffect, useRef, useState } from 'react';
import { Canvas, ExtendedArea, ExtendedShape, ExtendedTable, SidePanel } from '.';
import MainStage from './MainStage';
import { H2style, H3, H3style } from '../../../../../../components/Typography/Headings';
import { InfoMessage } from '../../../../../../components/Forms/Messaging';
import { Badge } from '@chakra-ui/react';
import styled from 'styled-components';
import { ApiService } from '../../../../../../api/api-connectors';
import { BaseExperienceAreaLink, BaseExperienceTableSelection, GenericResponse } from '../../../../../../api/api-definitions';
import { cloneDeep } from 'lodash';
import { NotificationService } from '../../../../../../services/NotificationService';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import { ExperienceService } from '../Experiences/experienceService';

interface ComponentProps {
    areas: ExtendedArea[];
    tables: ExtendedTable[];
    shapes: ExtendedShape[];
    selectedArea: ExtendedArea;
    experienceId: number;
}

function getTableMap(tables: ExtendedTable[]) {
    const value: { [key: number]: ExtendedTable } = {};
    tables.forEach(table => {
        value[table.id.toString()] = table;
    });
    return value;
}

function getTablesByArea(selectedTables: number[], tableMap: { [key: string]: ExtendedTable }) {
    const returnValue: { [areaId: number]: ExtendedTable[] } = {};
    selectedTables.forEach(tableId => {
        const fullTable = tableMap[tableId];
        if (!returnValue[fullTable.areaId]) {
            returnValue[fullTable.areaId] = [];
        }
        returnValue[fullTable.areaId].push(fullTable)
    });
    return returnValue;
}

function getAreaMap(areas: ExtendedArea[]) {
    const value: { [key: number]: ExtendedArea } = {};
    areas.forEach(area => {
        value[area.id.toString()] = area;
    });
    return value;
}

const ExperienceSelection = ({ areas, tables, shapes, selectedArea, experienceId }: ComponentProps) => {
    const [selectedTables, setSelectedTables] = useState<BaseExperienceAreaLink[]>([]);
    const [loading, setLoading] = useState(true);
    const tableMap = useRef<{ [key: number]: ExtendedTable }>(getTableMap(tables));
    const areaMap = useRef<{ [key: number]: ExtendedArea }>(getAreaMap(areas));

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        ApiService.experienceAreaLink.List__GET(experienceId).then(response => {
            const promises: Promise<GenericResponse>[] = []
            areas.forEach(area => {
                if (!response.find(x => x.setupAreaId == area.id)) {
                    promises.push(ApiService.experienceAreaLink.Add__PUT({
                        setupAreaId: area.id,
                        experienceId,
                        id: 0,
                        wholeAreaSelected: false,
                        experienceTableSelections: []
                    }))
                }
            });
            if (promises.length > 0) {
                Promise.all(promises).then(() => loadData()).catch(() => NotificationService.Error('Could not save area selection'))
            } else {
                setLoading(false);
                setSelectedTables(response);
                ExperienceService._selectedTables = response;
            }
        })
    }

    const selectTable = (tableId: number) => {
        let newSelectedTables = cloneDeep(selectedTables);
        const foundSavedArea = newSelectedTables.find(x => x.setupAreaId == tableMap.current[tableId].areaId);
        if (!foundSavedArea || foundSavedArea.wholeAreaSelected) return;
        if (!foundSavedArea.experienceTableSelections) foundSavedArea.experienceTableSelections = [];
        const foundSavedTable = foundSavedArea.experienceTableSelections?.find(x => x.setupTableId == tableId);
        if (foundSavedTable) {
            ApiService.experienceTableSelection.Delete__DELETE(foundSavedTable).then(() => {
                foundSavedArea.experienceTableSelections = foundSavedArea.experienceTableSelections.filter(x => x.setupTableId !== tableId)
                setSelectedTables(newSelectedTables);
                ExperienceService._selectedTables = newSelectedTables;
            }).catch(() => NotificationService.Error('Could not save table selection'))
        } else {
            const newTable: BaseExperienceTableSelection = {
                setupTableId: tableId,
                experienceId,
                id: 0,
                areaLinkId: foundSavedArea.id
            }
            ApiService.experienceTableSelection.Add__PUT(newTable).then(() => {
                foundSavedArea.experienceTableSelections.push(newTable)
                setSelectedTables(newSelectedTables);
                ExperienceService._selectedTables = newSelectedTables;
            }).catch(() => NotificationService.Error('Could not save table selection'))
        }
    }

    const toggleWholeArea = (id: number) => {
        let newSelectedTables = cloneDeep(selectedTables);
        let area = newSelectedTables.find(x => x.id == id);
        if (area) {
            area.wholeAreaSelected = !area.wholeAreaSelected;
            ApiService.experienceAreaLink.Save__POST(area).then(() => {
                setSelectedTables(newSelectedTables);
                ExperienceService._selectedTables = newSelectedTables;
            }).catch(() => NotificationService.Error('Could not save area'))
        }
    }

    const selectedTableIds: number[] = [];
    selectedTables.forEach(area => {
        if (area.wholeAreaSelected) {
            tables.forEach(table => {
                if (table.areaId == area.setupAreaId) selectedTableIds.push(table.id);
            });
        } else {
            area.experienceTableSelections?.forEach(table => {
                selectedTableIds.push(table.setupTableId);
            });
        }
    });

    return (
        <>
            <Canvas>
                <MainStage
                    tables={tables}
                    shapes={shapes}
                    selectedArea={selectedArea}
                    setLayout={() => { }}
                    setShapes={() => { }}
                    setArea={() => { }}
                    save={() => { }}
                    saveShape={() => { }}
                    selectTable={(id) => selectTable(id)}
                    selectShape={() => { }}
                    selectedTables={selectedTableIds}
                    readonly
                    mergeMode
                />
            </Canvas>
            <SidePanel>
                <CombinationContainer>
                    {selectedTables.map(area => (
                        <AreaListContainer key={'area-' + area.id}>
                            <H3 marginBottom={0}>{areaMap.current[area.setupAreaId].name}</H3>
                            <div>
                                <Checkbox asToggle unlink checked={area.wholeAreaSelected} label='Enable whole area' onChange={() => toggleWholeArea(area.id)} inputName={'toggleAll-' + area.id} />
                            </div>
                            {!area.wholeAreaSelected && area.experienceTableSelections?.map(table => (
                                <TableLabel key={'tableLabel' + table.id} colorScheme='teal'>{tableMap.current[table.setupTableId].tableName}</TableLabel>
                            ))}
                            {!area.wholeAreaSelected && (!area.experienceTableSelections || area.experienceTableSelections?.length == 0) &&
                                <InfoMessage>No selected tables in area. Area will not be bookable.</InfoMessage>
                            }
                            {area.wholeAreaSelected && tables.map((table) => {
                                if (table.areaId == area.setupAreaId) return (
                                    <TableLabel key={'table-' + table.id} colorScheme='teal'>{table.tableName}</TableLabel>
                                );
                                return null;
                            })}
                        </AreaListContainer>
                    ))}
                </CombinationContainer>
            </SidePanel>
        </>
    );
};

const AreaListContainer = styled.div`
    padding: 0.5rem 0;
`

const CombinationContainer = styled.div`
    padding: 0.5rem;
`

const TableLabel = styled(Badge)`
    font-size: 1rem !important;
    margin-right: 0.5rem;
`

export default ExperienceSelection;