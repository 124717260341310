import React from 'react';
import { ApiPage } from '../../../../../../api/api-definitions';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { WarningMessage } from '../../../../../../components/Forms/Messaging';
import CoreButton from '../../../../../../components/Forms/Button';
import { ApiService } from '../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../services/NotificationService';

interface ComponentProps {
    page: ApiPage;
    closeModal: (refresh?: boolean) => void;
}

const DeletePageModal = ({ page, closeModal }: ComponentProps) => {
    const [loading, setLoading] = React.useState(false);

    const confirmDelete = () => {
        setLoading(true);
        ApiService.page.Delete__POST(page).then(() => {
            NotificationService.Confirm('Page deleted successfully');
            closeModal(true);
        }).catch(() => {
            NotificationService.Error('Sorry, there was an error deleting your page');
        })
    }

    return (
        <CoreModal title='Delete page' small onClose={closeModal} actionBar={<CoreButton disabled={loading} text='Delete page' type='danger' onClick={confirmDelete} />}>
            Are you sure you want to delete <strong>{page.name}</strong>?
            <br />
            <WarningMessage>This action will delete any pages that are under this page.</WarningMessage>
        </CoreModal>
    );
};

export default DeletePageModal;