import React, { useEffect, useRef, useState } from 'react';
import { useBusiness } from '../../../hooks/useBusiness';
import { ApiService } from '../../../api/api-connectors';
import { BookingDayOverviewResponse } from '../../../api/api-definitions';
import { Column, Row, ScrollRow } from '../../Layout/Grid';
import { Skeleton } from '@chakra-ui/react';
import { DATEONLYFORMAT, DISPLAY_DATE_FORMAT, formatDate } from '../../../utils/date-helpers';
import styled from 'styled-components';
import { BoxShadowStyle } from '../../../theme';
import OverviewDay from '../OverviewDay';
import SevenDayOverviewList from '../OverviewDayList';
import { useRecoilValue } from 'recoil';
import { ParentIdState } from '../../../pages/BusinessDashboard/DashboardLayout';

interface ComponentProps {
}



const SevenDayOverview = ({ }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const [response, setResponse] = useState<BookingDayOverviewResponse>();
    const parentId = useRecoilValue(ParentIdState);
    const requested = useRef(false);
    const previousBusinessId = useRef<number>();

    useEffect(() => {
        if (business && (!requested.current || previousBusinessId.current != business.id)) {
            requested.current = true;
            previousBusinessId.current = business.id;
            ApiService.bookingStatistics.SevenDayOverview__GET(business.id).then(setResponse);
        }
    }, [business?.id, parentId]);

    return (
        <SevenDayOverviewList loading={!response} data={response} />
    );
};

export default SevenDayOverview;