import { BaseSupportTicket } from "../api/api-definitions";

export class SupportTicketService {
    private static _handler: (ticket?: BaseSupportTicket, full?: boolean) => void = null;

    static NewTicket(ticket?: BaseSupportTicket, full?: boolean) {
        if (this._handler) {
            this._handler(ticket, full)
        }
    }

    static RegisterHandler(handler: (ticket?: BaseSupportTicket, full?: boolean) => void) {
        this._handler = handler;
    }
}