import React, { Component, useEffect, useState } from 'react';
import { ApiService } from '../../../api/api-connectors';
import { AdminBookingDetails, BaseActiveBooking, BaseBusiness, OverlappingBooking, VerificationModel } from '../../../api/api-definitions';
import DataTable, { DataTableItem } from '../../../components/Layout/Datatable';
import { H1 } from '../../../components/Typography/Headings';
import { NotificationService } from '../../../services/NotificationService';
import { TIMEFORMAT, formatDate } from '../../../utils/date-helpers';
import DashboardHeader from '../../../components/Dashboard/Header';
import { StyledTextInput } from '../../../theme/input.styles';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { Link } from '@chakra-ui/react';
import { Column, Row } from '../../../components/Layout/Grid';
interface ComponentProps {

}

interface ComponentState {

}

const BookingsAdmin = () => {
  const [data, setData] = useState<AdminBookingDetails[]>([])

  const loadData = (reference: string) => {
    if (!isNullOrWhitespace(reference)) {
      ApiService.admin.BookingsByRef__GET(reference).then((result) => setData(result)).catch(() => {
        NotificationService.Error('Sorry, there was an error fetching data.')
      })
    } else {
      setData([]);
    }
  }

  const gentable = (): DataTableItem[] => {
    const items: DataTableItem[] = [];
    data.forEach((item) => {
      const dataItem: DataTableItem = {
        data: {
          'Booking ref': {
            value: <Link href={item.amendmentLink} target='_blank'>{item.bookingReference}</Link>,
            sortable: true,
          },
          'Status': {
            value: item.descriptiveStatus,
            sortable: true,
          },
          'Confimation status': {
            value: <Link href={item.confirmationLink} target='_blank'>{item.confirmationStatus}</Link>,
            sortable: true,
          },
          'Business name': {
            value: <Link href={item.bookingWidgetLink} target='_blank'>{item.businessName}</Link>,
            sortable: true,
          },
          // 'Location Id': {
          //   value: item.businessLocationId,
          //   sortable: true,
          // },
        }
      }
      items.push(dataItem);
    });
    return items;
  }

  return (
    <div>
        <DashboardHeader icon='user-pen' title='Bookings admin'></DashboardHeader>
        <Row>
          <Column size={4}>
            <StyledTextInput label='Booking reference:' onBlur={(e) => loadData(e.target.value)} />
          </Column>
        </Row>
        <DataTable data={gentable()} />
      </div>
  );
};

export default BookingsAdmin;
