import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ConvertCurrency } from '../../../../utils/currency-helper';
import CoreButton from '../../../../components/Forms/Button';
import { InfoMessage } from '../../../../components/Forms/Messaging';
import Icon from '../../../../components/Media/Icon';
import { ApiBusinessBookingDetails, OrderMenuResponse } from '../../../../api/api-definitions';
import { BoxShadowStyle } from '../../../../theme';
import CoreModal from '../../../../components/Layout/CoreModal';
import { useRecoilState } from 'recoil';
import { BasketState } from '..';
import BasketItem from './basketItem';

export interface BasketItem { quantity: number };

export interface IBasket { [key: number]: BasketItem };

interface ComponentProps {
  data: OrderMenuResponse;
  onClose: () => void;
  business: ApiBusinessBookingDetails;
}

const Basket = ({ data, onClose, business }: ComponentProps) => {
  const [basket, setBasket] = useRecoilState(BasketState);
  const theme = useTheme();
  let basketTotal = 0;

  Object.keys(basket).forEach(key => {
    basketTotal += data.dishes[key].price * basket[+key].quantity;
  })

  return (
    <CoreModal
      slimPanel
      onClose={onClose}
      title={'Your order'}
      hasCancel={false}
      actionBar={<>
        <BasketTotal>{ConvertCurrency({ code: 'GBP', amount: basketTotal })}</BasketTotal>
        <CoreButton
          secondaryConstrastOverride={business?.theme?.primaryContrastColour || theme.secondaryContrast}
          secondaryOverride={business?.theme?.primaryColour || theme.secondary}
        >Confirm your order</CoreButton>
      </>}
      headerTextColor={business?.theme?.primaryContrastColour || theme.secondaryContrast}
      headerBackgroundColor={business?.theme?.primaryColour || theme.secondary}
    >
      {Object.keys(basket).length == 0 && <>
        <br />
        <InfoMessage>No items added</InfoMessage>
      </>}
      {Object.keys(basket).map(key => (
        <BasketItem dish={data.dishes[key]} />
      ))}
    </CoreModal>
  );
};

const BasketTotal = styled.div`
  flex: 1;
  font-weight: 600;
`

export default Basket;