import React from 'react';
import { useBusinessPermissions } from '../../../../hooks/useBusinessPermissions';
import Loader from '../../../../components/Layout/Loader';
import ActionBox, { ErrorMessage } from '../../../../components/Forms/Messaging';
import { useParams } from 'react-router';

interface ComponentProps {
    children: React.ReactNode;
    permmission: string;
}

const PermissionsChecker = ({ children, permmission }: ComponentProps) => {
    const [loaded, data] = useBusinessPermissions();
    return <>{children}</>;
    if (!data) return <Loader />;
    if (!data[permmission]) return <PermissionCta permission={permmission} />;
    return <>{children}</>;
};

const PermissionCta = ({ permission }: { permission: string }) => {
    const params: any = useParams();
    let foundPermission = false;
    let title: string = '';
    let contents: React.ReactNode = '';
    if (permission == 'bookings') {
        foundPermission = true;
        title = 'Subscription plan required';
        contents = <>You need to be on the <strong>Basic</strong> or <strong>Pro</strong> plan to access this feature</>
    }
    if (permission == 'qrcode') {
        foundPermission = true;
        title = 'QR Code menu addon required';
        contents = 'You need to be subscribed to our QR Code menu addon to access this feature'
    }
    if (foundPermission) return (
        <ActionBox
            icon='cart-circle-arrow-up'
            url={`/dashboard/${params.parentId}/payment-center`}
            title={title}
        >
            {contents}
        </ActionBox>
    )
    return <><br /><ErrorMessage>You are not authorised to access this page.</ErrorMessage></>;
}

export default PermissionsChecker;