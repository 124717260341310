import styled from "styled-components";

const BoxOutline = styled.div<{full?: boolean}>`
    border: 1px solid ${props => props.theme.borderColor};
    border-radius: 0.2rem;
    ${props => props.full ? '' : 'display: inline-block;'};
    padding: 1rem;
    width: 100%;
`

export default BoxOutline;