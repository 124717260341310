import React, { Component } from 'react';
import { BaseBusiness, OperationalStatus, VerificationStatus } from '../../../api/api-definitions';
import TextInput from '../../../components/Forms/TextInput';
import DataTable, { DataTableItem } from '../../../components/Layout/Datatable';
import { Column, Row } from '../../../components/Layout/Grid';
import Loader from '../../../components/Layout/Loader';
import { H1 } from '../../../components/Typography/Headings';
import FetchJSON from '../../../utils/fetch';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import CoreButton from '../../../components/Forms/Button';
import Icon from '../../../components/Media/Icon';
import DashboardHeader from '../../../components/Dashboard/Header';
import { Link } from 'react-router-dom';
import { StyledTextInput } from '../../../theme/input.styles';

interface ComponentProps {

}

interface ComponentState {
    businesses: BaseBusiness[];
    filterValue?: string;
}

export default class AllBusinesses extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
        businesses: null
    }
  }

  componentDidMount() {
    FetchJSON.fetch('/api/business/list').then((businesses: BaseBusiness[]) => {
      this.setState({businesses})
    })
  }

  componentWillMount() {

  }

  gentable(): DataTableItem[] {
    const items: DataTableItem[] = [];
    this.state.businesses
      .filter(x =>
        isNullOrWhitespace(this.state.filterValue) || x.name.toLowerCase().indexOf(this.state.filterValue.toLowerCase()) > -1
      ).forEach((item) => {
      const dataItem: DataTableItem = {
        data: {
          'Business name': {
            value: <Link to={`/dashboard/${item.parentBusinessId}/locations/${item.locationId}`}>{item.name}</Link>,
            sortable: true,
            sortValue: item.name,
          },
          'Currency': {
            value: item.currencyCode,
            sortable: true,
          },
          'Verification status': {
            value: <> {item.verificationStatus === VerificationStatus.Verified ? <Icon name='check' /> :
                        (item.verificationStatus === VerificationStatus.Pending ? <Icon name='hourglass-half' /> 
                        : <Icon name='times' />)} 
                        {item.verificationStatus} </>,
            sortable: true,
            sortValue: item.verificationStatus
          },
          'Operational': {
            value: item.operationalStatus == OperationalStatus.Operational ? <Icon name='check' /> : <Icon name='times' />,
            sortable: true,
            sortValue: item.operationalStatus
          },
          'Manage': {
            value: <CoreButton small to={`/dashboard/${item.parentBusinessId}/locations/${item.locationId}`}>Manage</CoreButton>,
            hideName: true
          },
        }
      }
      items.push(dataItem);
    });
    return items;
  }

  render() {
      return (
        <div>
          <DashboardHeader icon='book-atlas' title='All businesses'></DashboardHeader>
          <Row>
            <Column size={3} mobile={12}>
              <StyledTextInput onChange={(e) => this.setState({filterValue: e.target.value})} label='Filter by name' />
            </Column>
          </Row>
          {!this.state.businesses &&
            <Loader />
          }
          {this.state.businesses &&
            <DataTable data={this.gentable()} />
          }
        </div>
      );
  }
}
