import styled from "styled-components";

interface TagProps {
    selected?: boolean;
    disabled?: boolean;
    full?: boolean;
}

const Tag = styled.div<TagProps>`
    display: inline-block;
    background-color: ${(props) => props.selected ? props.theme.primary : 'transparent'};
    color: ${(props) => props.selected ? props.theme.background : props => props.theme.primary};
    border: 1px solid ${props => props.theme.primary};
    transition: all 0.5s ease;
    cursor: pointer;
    padding: 0.3rem 1rem;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    border-radius: 3rem;
    text-align: center;
    min-width: 5rem;
    font-size: 0.9rem;
    ${props => props.full && 'width: 100%;'}

    ${(props) => props.disabled && `
        pointer-events: none;
        touch-action: none;
        opacity: 0.4;
    `}

    &:hover {
        background-color: ${props => props.theme.secondary};
        color: ${props => props.theme.background};
    }
`;

export default Tag;