import React, { useEffect, useRef, useState } from 'react';
import { ApiBusinessBookingDetails, OrderMenuResponse, OrderMenuSection, ThemeOptions } from '../../../../api/api-definitions';
import styled from 'styled-components';
import Icon from '../../../../components/Media/Icon';
import Basket, { IBasket } from './basket';
import { Constants } from '../../../../constants';
import { useRecoilState } from 'recoil';
import { BasketState } from '..';
import BREAKPOINTS from '../../../../config/breakpoints';

interface ComponentProps {
    sections: OrderMenuSection[];
    business: ApiBusinessBookingDetails;
    data: OrderMenuResponse;
}

const HeaderBar = ({ sections, business, data }: ComponentProps) => {
    const [basket, setBasket] = useRecoilState(BasketState);
    const [inView, setInView] = useState<number>(0);
    const inViewRef = useRef<number>(0);
    const scrollContainer = useRef<HTMLDivElement>();
    const [basketOpen, setBasketOpen] = useState(false);

    useEffect(() => {
        addScrollListener();

        return () => removeScrollListener();
    }, [])

    const onSectionClick = (index: number) => {
        const element = document.getElementById(`section-${index}`);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 100,
                left: 0,
                behavior: "smooth",
            });
        }
    }

    const addScrollListener = () => {
        window.addEventListener('scroll', onScroll)
    }

    const removeScrollListener = () => {
        window.removeEventListener('scroll', onScroll)
    }

    const onScroll = () => {
        const elements = document.querySelectorAll('[data-section-item]');
        elements.forEach(element => {
            if (isElementInViewport(element)) {
                const id = +element.id.split('-')[1];
                if (id != inViewRef.current) {
                    setInView(id)
                    inViewRef.current = id;
                    const option = document.getElementById('section-option-' + id);
                    if (option && scrollContainer.current) {
                        scrollContainer.current.scrollTo({
                            top: 0,
                            left: option.offsetLeft - 60,
                            behavior: "smooth",
                        });
                    }
                }
            }
        });
    }

    const isElementInViewport = (el: Element) => {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) / 2) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    const onArrowClick = (plus?: boolean) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollTo({
                top: 0,
                left: (plus ? scrollContainer.current.scrollLeft + 500 : scrollContainer.current.scrollLeft - 500),
                behavior: "smooth",
            });
        }
    }

    let basketTotalItems = 0;

    Object.keys(basket).forEach(key => {
        basketTotalItems += basket[+key].quantity
    })

    return (
        <>
            {basketOpen &&
                <Basket
                    data={data}
                    onClose={() => setBasketOpen(false)}
                    business={business}
                />
            }
            <Name widgetTheme={business.theme}>
                <HeaderImage style={{ backgroundImage: `url(${Constants.baseImageUrl}${business.imageUrl})` }} />
                <HeaderText>{business?.name}</HeaderText>
            </Name>
            <Container>
                <Sections>
                    <SectionIcon onClick={() => onArrowClick(false)}><Icon name='chevron-left' /></SectionIcon>
                    <SectionsInner ref={scrollContainer}>
                        {sections?.map((section, index) => (
                            <SectionItem widgetTheme={business.theme} id={`section-option-${index}`} onClick={() => onSectionClick(index)} key={section.id} active={index == inView}>
                                <Section firstItem={index == 0}>{section.title}</Section>
                            </SectionItem>
                        ))}
                    </SectionsInner>
                    <SectionIcon onClick={() => onArrowClick(true)}><Icon name='chevron-right' /></SectionIcon>
                </Sections>
                <BasketContainer widgetTheme={business.theme} onClick={() => setBasketOpen(true)}>
                    Basket <Icon name='basket-shopping' />
                    <BasketCount>{basketTotalItems}</BasketCount>
                </BasketContainer>
            </Container>
            <BasketContainerMobile widgetTheme={business.theme} onClick={() => setBasketOpen(true)}>
                <BasketCount>{basketTotalItems}</BasketCount>
                Basket <Icon name='basket-shopping' />
            </BasketContainerMobile>
        </>
    );
};

const HeaderText = styled.div`
    line-height: 2rem;
    display: inline-block;
`

const HeaderImage = styled.div`
    border-radius: 0.2rem;
    margin-right: 0.5rem;
    height: 2rem;
    width: 3rem;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Sections = styled.div`
    flex: 1;
    display: flex;
    border-left: 1px solid ${({ theme }) => theme.borderColor};
    border-right: 1px solid ${({ theme }) => theme.borderColor};
    position: relative;
    width: 70vw;
`

const SectionIcon = styled.div<{ hide?: boolean }>`
    padding: 1rem 0.5rem;
    width: 3rem;
    text-align: center;
    cursor: pointer;

    ${({ hide }) => hide && `opacity: 0.3;`}

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`

const SectionsInner = styled.div`
    flex: 1;
    display: flex;
    overflow-x: scroll;
    width: calc(100% - 6rem);
    max-width: calc(100% - 6rem);
    scrollbar-width: none !important;
`

const Section = styled.div<{ firstItem?: boolean }>`
    ${({ firstItem, theme }) => firstItem && `border-left: 1px solid ${theme.borderColor};`}
    ${({ theme }) => `border-right: 1px solid ${theme.borderColor};`}
    padding: 0.5rem 0.7rem;
`

const SectionItem = styled.div<{ active?: boolean, widgetTheme?: ThemeOptions }>`
    padding: 0.5rem 0rem;
    flex: 1 0 auto;
    font-size: 0.9rem;
    ${({ active, theme, widgetTheme }) => active && `border-bottom: 3px solid ${widgetTheme?.primaryColour || theme.secondary};`}
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`

const Name = styled.div<{ widgetTheme?: ThemeOptions }>`
    padding: 1rem;
    font-weight: 500;
    flex-shrink: 0;
    background-color: ${props => props.widgetTheme?.primaryColour || props.theme.secondary};
    color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.secondaryContrast};
    border-bottom: 1px solid ${props => props.widgetTheme?.primaryContrastColour || props.theme.secondaryContrast};
    display: flex;
`

const BasketContainer = styled.div<{ widgetTheme?: ThemeOptions }>`
    padding: 1rem 2rem;
    flex-shrink: 0;
    background-color: ${props => props.widgetTheme?.primaryColour || props.theme.secondary};
    color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.secondaryContrast};
    transition: all ease 0.3s;
    cursor: pointer;
    position: relative;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: none;
    }

    &:hover {
        opacity: 0.8;
    }
`

const BasketContainerMobile = styled.div<{ widgetTheme?: ThemeOptions }>`
    padding: 1rem 2rem;
    background-color: ${props => props.widgetTheme?.primaryColour || props.theme.secondary};
    color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.secondaryContrast};
    transition: all ease 0.3s;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
    text-align: center;

    @media (min-width: ${BREAKPOINTS.mobileLarge}px) {
        display: none;
    }

    &:hover {
        opacity: 0.8;
    }
`

const BasketCount = styled.div<{ widgetTheme?: ThemeOptions }>`
    position: absolute;
    top: 0.5rem;
    font-size: 0.8rem;
    right: 0.8rem;
    color: ${props => props.widgetTheme?.primaryColour || props.theme.secondary};
    background-color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.secondaryContrast};
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        position: relative;
        display: inline-block;
        top: -0.1rem;
        margin-left: 0.5rem;
    }
`

const Container = styled.div`
  position: sticky;
  background-color: white;
  display: flex;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  width: 100%;
`

export default HeaderBar;