import React, { useState } from 'react';
import { ApiComponentProperty, ApiPageComponent } from '../../../../../../api/api-definitions';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { StyledDropdown, StyledTextInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';

interface ComponentProps {
  item: ApiComponentProperty;
  onClose: (item?: ApiComponentProperty) => void;
}

const PropertyModal = ({ item, onClose }: ComponentProps) => {
  const [data, setData] = useState({ ...item });

  return (
    <CoreModal isOpen onClose={() => onClose()} title='Property settings' actionBar={<CoreButton onClick={() => onClose(data)}>Save</CoreButton>}>
      <StyledTextInput unlink label='Name' value={data.name} onChange={e => setData({ ...data, name: e.target.value})} /><br />
      <StyledDropdown unlink label='Field type' value={data.type} onChange={e => setData({ ...data, type: e.target.value})} items={[
        { value: 'text', text: 'Text' },
        { value: 'textarea', text: 'Textarea' },
        { value: 'image', text: 'Image' },
        { value: 'multiImage', text: 'Multi Image' },
        { value: 'checkbox', text: 'Checkbox' },
        { value: 'richtext', text: 'Rich Text' },
        { value: 'menu', text: 'Menu Selector' },
        { value: 'categorySelector', text: 'Category Selector' },
        { value: 'tagSelector', text: 'Tag Selector' },
        { value: 'placeholder', text: 'Placeholder' },
      ]} /><br />
      <StyledTextInput disabled unlink label='Variable name' value={data.rowKey} /><br />
    </CoreModal>
  );
};

export default PropertyModal;