import React, { useState } from 'react';
import styled from 'styled-components';
import { BoxShadowStyle, BoxShadowStyleWithHover } from '../../../../../../theme';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import Checkout from '../../../../../../components/Payments/Checkout';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import ManualPayment from './ManualPayment';
import TerminalPayment from './TerminalPayment';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { BaseActiveBooking } from '../../../../../../api/api-definitions';
import Icon from '../../../../../../components/Media/Icon';

interface ComponentProps {
    booking: BaseActiveBooking;
    onClose?: () => void;
}

const CreateCustomerPayment = ({ booking, onClose }: ComponentProps) => {
    const [paymentMethod, setPaymentMethod] = useState<1 | 2>();

    return (
        <CoreModal onClose={onClose} title={'Process customer payment'}>
            {!paymentMethod &&
                <>
                    Please select a payment method
                    <Row>
                        <Column size={6}>
                            <PyamentMethodOption onClick={() => setPaymentMethod(1)}><Icon name='globe' /> Manual card entry</PyamentMethodOption>
                        </Column>
                        <Column size={6}>
                            <PyamentMethodOption onClick={() => setPaymentMethod(2)}><Icon name='credit-card' /> Card machine</PyamentMethodOption>
                        </Column>
                    </Row>
                </>
            }
            {paymentMethod == 1 &&
                <ManualPayment booking={booking} onClose={onClose} />
            }
            {paymentMethod == 2 &&
                <TerminalPayment booking={booking} onClose={onClose} />
            }
        </CoreModal>
    );
};

const PyamentMethodOption = styled.div`
    ${BoxShadowStyleWithHover}
    line-height: 4rem;
    text-align: center;
    font-weight: bold;
    margin-top: 0.5rem;
`

export const SuccessIcon = styled(Icon)`
    background-color: ${props => props.theme.positive};
    color: ${props => props.theme.positiveContrast};
    width: 6rem;
    line-height: 6rem;
    font-size: 4rem;
    border-radius: 50%;
`

export const SuccessBox = styled.div`
    ${BoxShadowStyle};
    max-width: 30rem;
    margin: 2rem auto;
    font-size: 2rem;
    text-align: center;
    padding: 1rem;
`

export default CreateCustomerPayment;