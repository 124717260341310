import React, { useEffect, useRef, useState } from 'react';
import { useBusiness } from '../../../hooks/useBusiness';
import { ApiService } from '../../../api/api-connectors';
import { BookingDayOverviewResponse } from '../../../api/api-definitions';
import { Column, Row, ScrollRow } from '../../Layout/Grid';
import { Skeleton } from '@chakra-ui/react';
import { DATEONLYFORMAT, DISPLAY_DATE_FORMAT, formatDate } from '../../../utils/date-helpers';
import styled from 'styled-components';
import { BoxShadowStyle } from '../../../theme';
import OverviewDay from '../OverviewDay';

interface ComponentProps {
    loading: boolean;
    data?: BookingDayOverviewResponse;
    showWalkIns?: boolean;
}

const InfoHeight = '212px';

const SevenDayOverviewList = ({ loading, data, showWalkIns }: ComponentProps) => {
    if (loading) return (
        <ScrollRow>
            <Column size={1.7}>
                <Skeleton height={InfoHeight} />
            </Column>
            <Column size={1.7}>
                <Skeleton height={InfoHeight} />
            </Column>
            <Column size={1.7}>
                <Skeleton height={InfoHeight} />
            </Column>
            <Column size={1.7}>
                <Skeleton height={InfoHeight} />
            </Column>
            <Column size={1.7}>
                <Skeleton height={InfoHeight} />
            </Column>
            <Column size={1.7}>
                <Skeleton height={InfoHeight} />
            </Column>
            <Column size={1.7}>
                <Skeleton height={InfoHeight} />
            </Column>
        </ScrollRow>
    )

    return (
        <ScrollRow>
            {Object.keys(data.days).map((day) => (
                <Column size={1.7} key={day}>
                    <OverviewDay
                        date={data.days[day].date}
                        covers={data.days[day].guests}
                        bookings={data.days[day].upcoming}
                        cancellations={data.days[day].cancelled}
                        walkIns={data.days[day].walkIns}
                        web={data.days[day].web}
                        inHouse={data.days[day].inHouse}
                        showWalkIns={showWalkIns}
                        noShows={data.days[day].noShowed}
                        closed={data.days[day].closed}
                    />
                </Column>
            ))}
        </ScrollRow>
    );
};

export default SevenDayOverviewList;