import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import styled from 'styled-components';
import CoreButton from '../../../../../../components/Forms/Button';
import { ModalService } from '../../../../../../services/ModalService';
import { formatDate, DATABASE_TIME_FORMAT, DISPLAY_DATE_FORMAT } from '../../../../../../utils/date-helpers';
import { NotificationService } from '../../../../../../services/NotificationService';
import { BaseBookingStopSale, BaseBusinessOpeningTimeException, BaseBusinessOpeningTimesRange, BaseStopSaleShift, ExperiencesAndEventsForDay } from '../../../../../../api/api-definitions';
import { ApiService } from '../../../../../../api/api-connectors';
import { getShiftName } from '../bookingUtils';
import { InfoMessage } from '../../../../../../components/Forms/Messaging';
import { Link } from 'react-router-dom';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import CoreModal from '../../../../../../components/Layout/CoreModal';

interface ComponentProps {
    businessId: number;
    bookingDate: string;
    openingTimes: BaseBusinessOpeningTimesRange[];
    exceptionTimes: BaseBusinessOpeningTimeException;
    closedToday: boolean;
    events?: ExperiencesAndEventsForDay;
}

const StopSale = styled(Checkbox)`
        position: absolute;
        top: 5.3rem;
        right: 11rem;

        @media (max-width: 1023px) {
            position: unset;
        }
`

const ClosedToday = styled.div`
        position: absolute;
        top: 5.3rem;
        right: 11rem;

        @media (max-width: 1023px) {
            position: unset;
        }
`

const Hyperlink = styled(Link)`
    text-decoration: underline;
`

const StopSaleModule = ({
    businessId,
    bookingDate,
    openingTimes,
    exceptionTimes,
    closedToday,
    events
}: ComponentProps) => {
    const [stopSale, setStopSale] = useState<BaseBookingStopSale>();
    const [loaded, business] = useBusiness();
    const [stopsaleLoaded, setStopSaleLoaded] = useState<boolean>(false);

    const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: boolean }>({})
    const [modalOpen, setModalOpen] = useState(false);
    const exceptionExists = !!exceptionTimes && !!exceptionTimes.exceptionTimes && exceptionTimes.id != 0;
    const hasOneTime = (exceptionExists && exceptionTimes.exceptionTimes.length == 1) || (!exceptionExists && openingTimes.length == 1);
    const eventCount = events ? Object.keys(events.eventsAndExperiences).length : 0;

    useEffect(() => {
        if (bookingDate) {
            load()
        }
    }, [bookingDate])

    const load = () => {
        setStopSaleLoaded(false);
        ApiService.bookingStopSale.Get__GET(formatDate(bookingDate, DATABASE_TIME_FORMAT), businessId).then((response) => {
            if (response) {
                setStopSale(response);
            } else {
                setStopSale(undefined)
            }
            setStopSaleLoaded(true);
        }).catch(_ => {
            setStopSaleLoaded(true);
        })
    }

    const amendStopSale = () => {
        const stopSaleShifts: BaseStopSaleShift[] = [];
        if (hasOneTime) {
            if (!stopSale?.stopSaleShifts || stopSale?.stopSaleShifts.length == 0) {
                stopSaleShifts.push({
                    id: 0,
                    shiftSortOrder: 1,
                    bookingStopSaleId: 0
                })
            }
        } else {
            Object.keys(selectedOptions).forEach(x => {
                if (selectedOptions[x]) {
                    stopSaleShifts.push({
                        id: 0,
                        shiftSortOrder: +x,
                        bookingStopSaleId: stopSale?.stopSaleShifts?.find(y => y.shiftSortOrder == +x)?.bookingStopSaleId || 0
                    })
                }
            })
        }
        ApiService.bookingStopSale.Update__POST({
            id: stopSale?.id || 0,
            bookingDate: formatDate(bookingDate, DATABASE_TIME_FORMAT),
            businessId,
            stopSaleShifts
        }).then((response) => {
            if (response.success) {
                if (hasOneTime) {
                    ModalService.Close();
                } else {
                    toggleModal();
                }
                NotificationService.Confirm('Closed this date for online bookings successfully')
                load();
            } else {
                NotificationService.Error('Error occurred, could not save changes')
            }
        }).catch(() => {
            NotificationService.Error('Error occurred, could not save changes')
        })
    }

    const toggleModal = () => {
        if (modalOpen) {
            setSelectedOptions({});
            setModalOpen(false);
        } else {
            const selected: { [key: number]: boolean } = {};
            if (stopSale?.stopSaleShifts) stopSale.stopSaleShifts.forEach(x => {
                selected[x.shiftSortOrder] = true;
            })
            setSelectedOptions(selected);
            setModalOpen(true);
        }
    }

    const getCheckboxLabel = () => {
        if (hasOneTime && !!stopSale) {
            return 'Online bookings closed';
        } else if (hasOneTime && !stopSale) {
            return 'Close online bookings';
        } else if (stopSale && !hasOneTime && stopSale.stopSaleShifts.length == (exceptionExists ? exceptionTimes.exceptionTimes.length : openingTimes.length)) {
            return 'Online bookings closed';
        } else if (stopSale && !hasOneTime && stopSale.stopSaleShifts.length != (exceptionExists ? exceptionTimes.exceptionTimes.length : openingTimes.length)) {
            return 'Online bookings partially closed';
        }
        return 'Close online bookings';
    }

    const eventInfo = <>
        {eventCount === 1 && <>
            <br />
            <br />
            <InfoMessage>Applying stop sale will not stop the event on this day being bookable. If you need to stop bookings for the event please&nbsp;<Hyperlink to={`/dashboard/${business.parentBusinessId}/locations/${business.locationId}/experiences/${Object.keys(events.eventsAndExperiences)[0]}`}>adjust the date bands here.</Hyperlink></InfoMessage>
        </>}
        {eventCount > 1 && <>
            <br />
            <br />
            <InfoMessage>Applying stop sale will not stop the events on this day being bookable. If you need to stop bookings for any of the events please&nbsp;<Hyperlink to={`/dashboard/${business.parentBusinessId}/locations/${business.locationId}/experiences`}>adjust the date bands here.</Hyperlink></InfoMessage>
        </>}
    </>

    if (hasOneTime && closedToday === false && stopsaleLoaded) return (
        <StopSale inputName='stopSale' checked={!!stopSale} label={getCheckboxLabel()} asToggle onChange={() => ModalService.Open({
            small: true,
            title: stopSale ? `Are you sure you want to open ${formatDate(bookingDate, DISPLAY_DATE_FORMAT)} for online bookings?` : `Are you sure you want to close ${formatDate(bookingDate, DISPLAY_DATE_FORMAT)} for online bookings?`,
            children: [
                stopSale ? 'This will enable clients making online bookings for this date.' : 'This will prevent clients from making online bookings for this date. You will still be able to make bookings in-house.',
                <>
                    <br />
                    <br />
                    <InfoMessage>If you are closed for this day we suggest&nbsp;<Hyperlink to={`/dashboard/${business.parentBusinessId}/locations/${business.locationId}/opening-times`}>setting up an exception day.</Hyperlink></InfoMessage>
                    {eventInfo}
                </>
            ],
            actionBar: <CoreButton onClick={amendStopSale}>Confirm</CoreButton>
        })} />
    )

    const checkBoxLabel = getCheckboxLabel();
    const disableSave = checkBoxLabel == 'Close online bookings' && !Object.entries(selectedOptions).find(([key, entry]) => entry);

    return (
        <>
            {modalOpen &&
                <CoreModal
                    small
                    tall
                    title={`Close online bookings for ${formatDate(bookingDate, DISPLAY_DATE_FORMAT)}`}
                    onClose={toggleModal} actionBar={<CoreButton disabled={disableSave} onClick={amendStopSale}>Confirm</CoreButton>}
                >
                    <>
                        Turning this on for shift(s) will prevent clients from making online bookings for this date/shift(s).
                        <br />
                        You will still be able to make bookings in-house.
                        <br />
                        <InfoMessage>If you are closed for this day we suggest&nbsp;<Hyperlink to={`/dashboard/${business.parentBusinessId}/locations/${business.locationId}/opening-times`}>setting up an exception day.</Hyperlink></InfoMessage>
                        {eventInfo}
                        <br />
                        Please select one or more shifts that need online bookings closed:
                        <br />
                        <br />
                        {exceptionExists && exceptionTimes.exceptionTimes.map(x => (
                            <div>
                                <Checkbox
                                    inputName={x.shiftLabel}
                                    asToggle
                                    label={x.shiftLabel}
                                    checked={selectedOptions[x.sortOrder]}
                                    onChange={(e) => setSelectedOptions({ ...selectedOptions, [x.sortOrder]: e.target.checked })}
                                />
                            </div>
                        ))}
                        {!exceptionExists && openingTimes.map(x => (
                            <div>
                                <Checkbox
                                    inputName={x.shiftLabel}
                                    asToggle
                                    label={x.shiftLabel}
                                    checked={selectedOptions[x.sortOrder]}
                                    onChange={(e) => setSelectedOptions({ ...selectedOptions, [x.sortOrder]: e.target.checked })}
                                />
                            </div>
                        ))}
                    </>
                </CoreModal>
            }
            {closedToday === false && stopsaleLoaded &&
                <StopSale inputName='stopSale' checked={!!stopSale} label={checkBoxLabel} asToggle onChange={toggleModal} />
            }
            {closedToday === true && stopsaleLoaded &&
                <ClosedToday>Business is closed on this day</ClosedToday>
            }
        </>
    );
};

export default StopSaleModule;