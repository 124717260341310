import { Font } from "@react-pdf/renderer";
import { useState } from "react";

export interface FontItem {
  name: string;
  src: string;
}

export const useInitialiseDocument = (fonts: FontItem[], basePath: string): boolean => {
  const [registeredFont, setRegisteredFont] = useState<boolean>(fonts.length === 0 || false);

  const registerBaseFont = () => {
    fonts.forEach(font => {
      const path = basePath + font.src
      Font.register({
        family: font.name,
        src: path
      });
      setRegisteredFont(true)
    });
    
    Font.registerHyphenationCallback(word => [word])
  };

  if (!registeredFont) {
    registerBaseFont();
  }

  return registeredFont;
}