import { ApiService } from "../api/api-connectors";
import { AllergenStatus, BaseMenu, BaseMenuSection, GenericResponse, SpiceLevel } from "../api/api-definitions";
import { wixData } from "../pages/BusinessDashboard/modules/ParentBusinessModules/MenuManagement/ViewMenu/wixData";
import { isNullOrWhitespace } from "../utils/text-helpers";

interface ExtendedBaseMenuSection extends BaseMenuSection {
    categoryId: string;
    itemIds?: string[];
}

export default class MenuImportService {
    private static _promises: Promise<GenericResponse>[] = [];

    public static async ImportDeliveroo(url: string, menu: BaseMenu, callback: () => void) {
        await this.GetMarkup(url).then(async dom => {
            const response = this.ProcessDeliveroo(dom, menu);
            await this.ProcessAddToMenu(response, menu);
            this._promises.forEach(async promise => {
                await promise;
            });
            callback();
            // await Promise.all(this._promises).then(() => {
            //     callback();
            // })
        })
    }

    public static async ImportWix(url: string, menu: BaseMenu, callback: () => void) {
        const response = this.ProcessWix(menu);
        console.log(response)
        await this.ProcessAddToMenu(response, menu);
        this._promises.forEach(async promise => {
            await promise;
        });
        callback();
        await Promise.all(this._promises).then(() => {
            callback();
        })
    }

    private static async GetMarkup(url: string): Promise<HTMLHtmlElement> {
        return ApiService.devtest.GetMarkup__POST({ info: url }).then(data => {
            var dom = document.createElement('html');
            dom.innerHTML = data.info;
            return dom;
        });
    }

    private static async ProcessAddToMenu(response: BaseMenuSection[], menu: BaseMenu) {
        response.forEach(async addedSection => {
            const existingSection = menu.menuSections?.find(x => x.name.toLowerCase() === addedSection.name.toLowerCase());
            if (existingSection) {
                addedSection.dishes.forEach(dish => {
                    dish.menuSectionId = existingSection.id;
                });
                this._promises.push(ApiService.dish.AddMany__PUT(addedSection.dishes.filter(x => !!existingSection.dishes.find(y => y.name.toLowerCase() === x.name.toLowerCase()))))
            } else {
                this._promises.push(ApiService.menuSection.Add__PUT(addedSection))
            }
        });
    }

    private static ProcessWix(menu: BaseMenu): BaseMenuSection[] {
        let response: ExtendedBaseMenuSection[] = [];
            const meta = wixData.menu;
            meta.sections.find(x => x.title.en_GB == 'Full Menu').children.forEach((section, index) => {
                if (section.displayCondition.conditions[0].type == 'true') response.push({
                    name: section.title.en_GB,
                    dishes: [],
                    menuId: menu.id,
                    sortOrder: (menu.menuSections || []).length + index + 1,
                    businessId: menu.businessId,
                    parentBusinessId: menu.parentBusinessId,
                    categoryId: section.id,
                    itemIds: section.itemIds
                });
            });
            meta.items.forEach(dish => {
                const sections = response.filter(x => x.itemIds.includes(dish.id));
                sections.forEach(section => {
                    section.dishes.push({
                        name: dish.title.en_GB,
                        description: dish.description?.en_GB,
                        calorieCount: undefined,
                        price: dish.price ? dish.price / 100 : 0,
                        businessId: menu.businessId,
                        parentBusinessId: menu.parentBusinessId,
                        menuId: menu.id,
                        menuSectionId: 0,
                        allergenStatus: AllergenStatus.NotSet,
                        spiceLevel: SpiceLevel.NotSpicy,
                        grabImage: dish.media?.logo
                    })
                });
            });
        return response;
    }

    private static ProcessDeliveroo(dom: HTMLHtmlElement, menu: BaseMenu): BaseMenuSection[] {
        let response: ExtendedBaseMenuSection[] = [];
        const scriptDom = dom.querySelector('#__NEXT_DATA__')
        if (scriptDom) {
            const data = JSON.parse(scriptDom.textContent);
            const meta = data.props.initialState.menuPage.menu.meta;
            meta.categories.forEach((section, index) => {
                response.push({
                    name: section.name,
                    dishes: [],
                    menuId: menu.id,
                    sortOrder: (menu.menuSections || []).length + index + 1,
                    businessId: menu.businessId,
                    parentBusinessId: menu.parentBusinessId,
                    categoryId: section.id
                });
            });
            meta.items.forEach(dish => {
                if (!isNullOrWhitespace(dish.categoryId)) {
                    const section = response.find(x => x.categoryId === dish.categoryId);
                    if (section) {
                        section.dishes.push({
                            name: dish.name,
                            description: dish.description,
                            calorieCount: +dish?.nutritionalInfo?.energyFormatted?.replace(' kcal', ''),
                            price: +dish.price.formatted.replace('£', ''),
                            businessId: menu.businessId,
                            parentBusinessId: menu.parentBusinessId,
                            menuId: menu.id,
                            menuSectionId: 0,
                            allergenStatus: AllergenStatus.NotSet,
                            spiceLevel: SpiceLevel.NotSpicy,
                            grabImage: dish.image?.url?.split('?')[0]
                        })
                    }
                }
            });
        }
        return response;
    }
}