import React from 'react';
import Icon from '../../../../components/Media/Icon';
import { times } from 'lodash';
import moment, { Moment } from 'moment';
import { desktopSize, tabletSize, mobileSize, BookingFormData } from '..';
import { DropdownItem } from '../../../../components/Forms/Dropdown';
import FormWrapper from '../../../../components/Forms/FormWrapper';
import { Column } from '../../../../components/Layout/Grid';
import Loader from '../../../../components/Layout/Loader';
import { StyledDropdown, StyledCalendar } from '../../../../theme/input.styles';
import { createMomentFromValue, DATABASE_TIME_FORMAT, DEFAULT_DATE_FORMAT, formatDate, TIMEFORMAT } from '../../../../utils/date-helpers';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { FormRow, InfoMessage, ExperienceOptionContainer, ExperienceOption, ButtonBack, TimeRow, ButtonTime, ButtonOutline, Button, Message } from '../../../booking.styles';
import { ApiBookingTimesWidget, ApiBusinessBookingDetails, BookingAvailabilityTimesWidget, BookingAvailabilityWidget, BookingTimeSlots } from '../../../../api/api-definitions';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    loading: boolean;
    formData: BookingFormData;
    nextAvail: ApiBookingTimesWidget;
    partyTooBig: boolean;
    searchableExperience: number;
    nextAvailLoading: boolean;
    bookingReference: string;
    specialEventDates: React.MutableRefObject<Date[]>;
    nextAvailHasMultipleShifts: BookingAvailabilityWidget;
    hasTimeSlots: boolean;
    selectedTimeDropdown: string;
    bookingError: string;
    bookingRef: string;
    timeOptions: DropdownItem[];
    previewLocation: string;
    availableTimes: React.MutableRefObject<BookingTimeSlots>;
    setFormData: (value: React.SetStateAction<BookingFormData>) => void;
    generatePeopleList: (limit: number) => DropdownItem[];
    onUpdateSearchableExperience: (experienceId?: number) => void;
    setNextAvail: (value: React.SetStateAction<ApiBookingTimesWidget>) => void;
    setSelectedTimeDropdown: (value: React.SetStateAction<string>) => void;
    hold: (date?: Moment, time?: string, slot?: BookingAvailabilityTimesWidget) => void;
    nextAvailSearch: (experienceId?: number, guestsOverride?: number, isEventSearch?: boolean) => void;
    dateChange: (date: Date | Moment, firstLoad?: boolean, guests?: number, experienceId?: number, noExperience?: boolean) => void;
}

const SearchForm = ({
    business,
    loading,
    formData,
    nextAvail,
    partyTooBig,
    searchableExperience,
    nextAvailLoading,
    bookingReference,
    specialEventDates,
    nextAvailHasMultipleShifts,
    hasTimeSlots,
    selectedTimeDropdown,
    bookingError,
    bookingRef,
    timeOptions,
    previewLocation,
    availableTimes,
    setFormData,
    generatePeopleList,
    onUpdateSearchableExperience,
    setNextAvail,
    setSelectedTimeDropdown,
    hold,
    nextAvailSearch,
    dateChange,
}: ComponentProps) => {

    const isValidDate = (date): boolean => {
        const momentDate = createMomentFromValue(date);
        if (business.specialEventDates && business.specialEventDates[formatDate(momentDate, DATABASE_TIME_FORMAT)]) return true;
        if (momentDate.isBefore(moment().subtract(1, 'days'))) {
            return false;
        }
        if (business.maximumFutureDaysForCalendar && momentDate.isAfter(moment().add(business.maximumFutureDaysForCalendar - 1, 'days'))) {
            return false;
        }
        if (business.closedExceptionDays && business.closedExceptionDays.hasOwnProperty(momentDate.format('YYYY-MM-DD') + 'T00:00:00')) return false;
        const slot = availableTimes.current?.timeSlots[momentDate.format('dddd')];
        return slot;
    }
    
    return (
        <FormWrapper onUpdate={(newData) => loading ? {} : setFormData({ ...(nextAvail ? formData : newData) })}>
            {({ id, valid }) => (
                <>
                    {!loading &&
                        <FormRow widgetTheme={business?.theme}>
                            <StyledDropdown icon='users' model='guests' value={formData.guests} items={generatePeopleList(business.maximumBookableParty || 20)} onChange={() => setNextAvail(undefined)} />
                        </FormRow>
                    }
                    {partyTooBig && !loading &&
                        <InfoMessage marginTop widgetTheme={business?.theme}>
                            For a party of this size please contact us {isNullOrWhitespace(business.phoneNumber) ? 'directly' : `on ${business.phoneNumber}`} to make this booking.
                        </InfoMessage>
                    }
                    {business?.experiences && !loading && Object.keys(business?.experiences).length > 0 &&
                        <>
                            {Object.keys(business?.experiences).length == 1 &&
                                <ExperienceOptionContainer>
                                    <ExperienceOption
                                        widgetTheme={business?.theme}
                                        selected={!searchableExperience}
                                        onClick={() => onUpdateSearchableExperience(undefined)}>
                                        {isNullOrWhitespace(business?.standardBookingName) ? 'Standard booking' : business?.standardBookingName}
                                    </ExperienceOption>
                                    {Object.keys(business?.experiences).map((key, index) => (
                                        <ExperienceOption
                                            widgetTheme={business?.theme}
                                            selected={searchableExperience == +key}
                                            last={index == Object.keys(business?.experiences).length - 1}
                                            onClick={() => onUpdateSearchableExperience(+key)}>
                                            {business?.experiences[key].name}
                                        </ExperienceOption>
                                    ))}
                                </ExperienceOptionContainer>
                            }
                            {Object.keys(business?.experiences).length > 1 &&
                                <FormRow widgetTheme={business?.theme} style={{ marginTop: '0.5rem' }}>
                                    <StyledDropdown icon='sparkles' unlink value={searchableExperience} defaultText={isNullOrWhitespace(business?.standardBookingName) ? 'Standard booking' : business?.standardBookingName} items={Object.keys(business?.experiences).map(key => ({ value: key, text: business?.experiences[key].name } as DropdownItem))} onChange={(e) => onUpdateSearchableExperience(isNullOrWhitespace(e.target.value) ? undefined : +e.target.value)} />
                                </FormRow>
                            }
                        </>
                    }
                    {!loading && !nextAvailLoading && !nextAvail && !partyTooBig &&
                        <>
                            {!bookingReference && business.firstAvailableDate && createMomentFromValue(business.firstAvailableDate).format(DEFAULT_DATE_FORMAT) !== moment().format(DEFAULT_DATE_FORMAT) &&
                                <InfoMessage marginTop widgetTheme={business?.theme}>
                                    The first available date has been pre-selected.
                                </InfoMessage>
                            }
                            <StyledCalendar onChange={dateChange} inline placeholder='Select date' model='date' value={formData.date} months={1} calendarProps={{
                                filterDate: isValidDate,
                                highlightDates: business.specialEventDates && Object.keys(business.specialEventDates).length > 0 ? [
                                    {
                                        'datepicker__event-day': specialEventDates.current
                                    }
                                ] : undefined
                            }} />
                        </>
                    }

                    {nextAvailLoading && <Loader />}

                    {nextAvail && !loading && !nextAvailLoading &&
                        <>
                            {nextAvail.availability?.length > 0 && searchableExperience &&
                                <div style={{ textAlign: 'center' }}>
                                    <br />
                                    <strong>Next availability for {business?.experiences[searchableExperience].name}</strong>
                                </div>
                            }
                            <ButtonBack style={{ marginTop: '1rem', marginBottom: '1rem' }} type='button' widgetTheme={business?.theme} onClick={() => setNextAvail(undefined)}>
                                <Icon name='arrow-left' duo doNotStyleDuo /> Back
                            </ButtonBack>
                            {nextAvail.availability?.length === 0 &&
                                <>
                                    <InfoMessage widgetTheme={business?.theme}>
                                        There is no availability{searchableExperience && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} in the near future. Please check again later.
                                    </InfoMessage>
                                </>
                            }
                            {nextAvail.availability.map((slot, index) => (
                                <>
                                    <Message widgetTheme={business?.theme}>
                                        <strong>{formatDate(moment(slot.date), 'dddd, Do MMMM YYYY')}</strong>
                                    </Message>
                                    <br />
                                    <TimeRow key={'next-avil-' + index}>
                                        {slot && Object.keys(slot.shiftLabelAndTimes).map(shiftLabel => (
                                            <>
                                                {nextAvailHasMultipleShifts &&
                                                    <Column size={12}>
                                                        <strong>{shiftLabel}</strong>
                                                    </Column>
                                                }
                                                {slot.shiftLabelAndTimes[shiftLabel].slots.map(time => (
                                                    <Column size={desktopSize} tablet={tabletSize} mobile={mobileSize} key={`next-avail-${index}-${time.time}`}>
                                                        <ButtonTime
                                                            onClick={() => {
                                                                setFormData({ ...formData, date: moment(slot.date) });
                                                                setSelectedTimeDropdown(time.time)
                                                                hold(moment(slot.date), time.time, time)
                                                            }}
                                                            type='button'
                                                            widgetTheme={business?.theme}>
                                                            {moment(time.time).format(TIMEFORMAT)}
                                                        </ButtonTime>
                                                    </Column>
                                                ))}
                                            </>
                                        ))}
                                    </TimeRow>
                                </>
                            ))}
                        </>
                    }

                    {!nextAvail && !partyTooBig && !loading && !nextAvailLoading && times && hasTimeSlots &&
                        <>
                            <FormRow widgetTheme={business?.theme}>
                                <StyledDropdown icon='clock' value={selectedTimeDropdown} items={timeOptions} onChange={(e) => setSelectedTimeDropdown(e.target.value)} defaultText='Please select a time' />
                            </FormRow>
                            {bookingError && !bookingRef &&
                                <>
                                    <InfoMessage marginTop widgetTheme={business?.theme}>{bookingError}</InfoMessage>
                                </>
                            }
                            <Button disabled={isNullOrWhitespace(selectedTimeDropdown) || !isNullOrWhitespace(previewLocation)} widgetTheme={business?.theme} type='button' onClick={() => hold()}>
                                {isNullOrWhitespace(business.reserveButtonTextStepOne) ? 'Reserve' : business.reserveButtonTextStepOne}
                            </Button>

                        </>
                    }
                    {!nextAvail && !partyTooBig && !loading && !nextAvailLoading && times && !hasTimeSlots &&
                        <div>
                            <InfoMessage widgetTheme={business?.theme}>
                                No availability{searchableExperience && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} found on this day.
                            </InfoMessage>
                            <ButtonOutline widgetTheme={business?.theme} type='button' onClick={() => nextAvailSearch(searchableExperience)}>
                                <Icon name='search' /> Search next available
                            </ButtonOutline>
                        </div>
                    }
                    {loading && <Loader />}
                </>
            )}
        </FormWrapper>
    );
};

export default SearchForm;