import { BaseBusiness } from "../api/api-definitions";
import DatabaseService from "./DatabaseService";

export default class BusinessListCacheService {
    private static readonly _key = 'businessList';
    private static readonly _indexKey = 'businessListIndex';
    private static readonly _indexName = 'businessListId';

    public static async GetBusinessList(id: string) {
        return new Promise<BaseBusiness[]>((resolve, reject) => {
            resolve([])
            // DatabaseService.Database().then(db => {
            //     let transaction = db.transaction(this._key, "readonly");
            //     let objectStore = transaction.objectStore(this._key);
            //     let index = objectStore.index(this._indexKey);

            //     let getRequest = index.get(id);

            //     getRequest.onsuccess = function (event) {
            //         let result = (event.target as any).result;
            //         if (result?.response) {
            //             resolve(JSON.parse(result.response) as BaseBusiness[])
            //         } else {
            //             resolve(null)
            //         }
            //     };
            // });
        })
    }

    public static async UpsertBusinessList(id: string, response: BaseBusiness[]) {
        DatabaseService.Database().then(db => {
            const transaction = db.transaction(this._key, "readwrite");
            const objectStore = transaction.objectStore(this._key);
            objectStore.put({
                [this._indexName]: id,
                response: JSON.stringify(response)
            }, id)
        });
    }
}