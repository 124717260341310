import React from 'react';
import { ApiBusinessBookingDetails, HeldBookingResponse } from '../../../../api/api-definitions';
import { InfoMessage } from '../../../booking.styles';
import { Constants } from '../../../../constants';
import Checkout from '../../../../components/Payments/Checkout';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    holdDetails: HeldBookingResponse;
    paymentIntent: string;
}

const PaymentForm = ({
    business,
    holdDetails,
    paymentIntent,
}: ComponentProps) => {
    return (
        <>
            {holdDetails?.depositRequired && !holdDetails?.takeDepositNow &&
                <>
                    <InfoMessage marginTop widgetTheme={business?.theme}>
                        No show charges apply for this booking. The no show charge for this booking is: £{holdDetails.depositAmount.toFixed(2)}. Your card will only be charged if you don't attend your booking or fail to cancel you booking in accordance with the cancellation policy of the venue.
                    </InfoMessage>
                </>
            }
            {holdDetails?.depositRequired && holdDetails?.takeDepositNow &&
                <>
                    <InfoMessage marginTop widgetTheme={business?.theme}>
                        A deposit is required for this booking. The deposit for this booking is: £{holdDetails.depositAmount.toFixed(2)}
                    </InfoMessage>
                </>
            }
            <InfoMessage marginTop widgetTheme={business?.theme}>
                By completing the the payment, you authorise {Constants.businessName} and {business.name} to send instructions to the financial institution that issued your card to take payments from your card account in accordance with the terms of {business.name}.
            </InfoMessage>
            <Checkout
                intentSecret={paymentIntent}
                payNow={holdDetails.takeDepositNow}
                accountId={holdDetails.accountId}
                widgetTheme={business?.theme}
                returnUrl={window.location.href + `?holdRef=${holdDetails.holdReference}&takeNow=${holdDetails?.takeDepositNow ? 'true' : 'false'}`}
                widget
            />
            <br />
        </>
    );
};

export default PaymentForm;