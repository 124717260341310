import { Button } from '@chakra-ui/react';
import React, { Children, useRef } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import Icon from '../../Media/Icon';

interface ComponentProps {
  disabled?: boolean;
  requesting?: boolean;
  type?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger';
  text?: string;
  onClick?: (e: any) => void;
  children?: React.ReactNode;
  outline?: boolean;
  noOutline?: boolean;
  full?: boolean;
  to?: string;
  small?: boolean;
  inverse?: boolean;
  floatRight?: boolean;
  floatLeft?: boolean;
  className?: string;
  testingId?: string;
  icon?: string;
  duoIcon?: boolean;
  secondaryOverride?: string;
  secondaryConstrastOverride?: string;
  buttonType?: 'button' | 'submit' | 'reset';
}

const CoreButton = ({
  text,
  testingId,
  children,
  disabled,
  requesting,
  className,
  outline,
  noOutline,
  small,
  inverse,
  icon,
  duoIcon,
  floatRight,
  floatLeft,
  secondaryOverride,
  secondaryConstrastOverride,
  onClick,
  to,
  type = 'secondary',
  full = false,
  buttonType = 'button'
}: ComponentProps) => {
  const navigate = useHistory();
  const theme = useTheme();

  let float = null;
  if (floatRight) float = 'right';
  if (floatLeft) float = 'left';

  const handleClick = (e) => {
    if (onClick) onClick(e);
    if (to) navigate.push(to);
  }

  const getBackground = () => {
    switch (type) {
      case 'primary':
        return theme.primary;
      case 'secondary':
        return secondaryOverride || theme.secondary;
      case 'tertiary':
        return theme.tertiary;
      case 'success':
        return theme.positive;
      case 'warning':
        return theme.warning;
      case 'danger':
        return theme.negative;
      default:
        return secondaryOverride || theme.secondary;
    }
  }

  const getText = () => {
    switch (type) {
      case 'primary':
        return theme.primaryContrast;
      case 'secondary':
        return secondaryConstrastOverride || theme.secondaryContrast;
      case 'tertiary':
        return theme.tertiaryContrast;
      case 'success':
        return theme.positiveContrast;
      case 'warning':
        return theme.warningContrast;
      case 'danger':
        return theme.negativeContrast;
      default:
        return secondaryConstrastOverride || theme.secondaryContrast;
    }
  }

  // if (button.current) {
  //   button.current.dataset.testid = testingId ? testingId : text;
  // }

  return (
    <Button
      onClick={handleClick}
      data-testid={testingId ? testingId : text}
      isDisabled={disabled}
      isLoading={requesting}
      variant={noOutline ? 'link' : outline ? 'outline' : 'solid'}
      bg={(outline || noOutline) ? 'tansparent' : getBackground()}
      borderWidth={outline ? '2px' : 0}
      fontSize={'0.8rem'}
      fontWeight={'normal'}
      textTransform='uppercase'
      borderRadius={'0.2rem'}
      boxShadow={noOutline ? undefined : '0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)'}
      borderColor={inverse ? undefined : getBackground()}
      textColor={(outline || noOutline) ? (inverse ? '#FFF' : getBackground()) : getText()}
      _hover={outline ? { background: getBackground(), color: getText() } : { opacity: '0.8' }}
      size={small ? 'sm' : 'md'}
      style={{ float, width: full ? '100%' : null }}
      className={inverse ? className + ' button-outline-inverse' : className}
      type={buttonType}
      loadingText={text || children}
    >{icon && <><Icon name={icon} duo={duoIcon} />&nbsp;</>}{text || children}</Button>
  );
};

export default CoreButton;