import React, { Component, CSSProperties } from "react";

import ClassNames from 'classnames';
import styled from "styled-components";
import FormController from "../../../controllers/form";
import { dynamicSort } from "../../../utils/sort";
import { BoxShadowStyle } from "../../../theme";
import Icon from "../../Media/Icon";
import BREAKPOINTS from "../../../config/breakpoints";
import CoreButton from "../../Forms/Button";

export interface DataTableProps {
  data: DataTableItem[];
  hideNoResultsMessage?: boolean;
  isNested?: boolean;
  noResultsMessage?: string;
  reverseSort?: boolean;
  slimline?: boolean;
  className?: string;
  defaultSort?: string;
}

export interface DataTableState {
  limit: number;
  currentSort?: string | null;
  reverseSort: boolean
}

export interface DataTableItem {
  data: DataTableDataItem;
  headerTitle?: any;
  headers?: boolean;
  className?: string;
  extraContent?: any[];
}

export interface DataTableDataItem {
  [key: string]: DataTableItemProperty;
}

export interface DataTableItemProperty {
  value: React.ReactNode;
  sortable?: boolean;
  sortValue?: any;
  hideName?: boolean;
  hideColumn?: boolean;
  hasWarning?: boolean;
  size?: number | null;
  rowspan?: number | null;
  textLeft?: boolean;
  icon?: string;
  style?: CSSProperties;
  className?: string;
  filterType?: FilterTypes;
  filterRange?: number[];
  filterCustomValues?: string[] | number[];
  filterOrder?: number;
  sortThenBy?: string;
}

export enum FilterTypes {
  Typeahead,
  Checkbox,
  ToAndFrom
}

const TableStyle = styled.table`
  border: 1px solid ${props => props.theme.borderColor};
  width: 100%;
  border-collapse: collapse;
  text-align: center;

  tbody:nth-child(odd) {
    background: #FFF
  }

  .warning {
    background-color: ${props => props.theme.warning};
  }

  .left {
    text-align: left;
  }

  th, td {
    padding: 0.5rem;
  }

  .headerSortUp, .headerSortDown {
    text-decoration: underline;
    position: relative;
  }

  .headerSortUp a::after {
    font-family: "Font Awesome 5 Free"; font-weight: 900;
    content: "\f106";
    margin-left: 0.5rem;
    position: absolute;
  }

  .headerSortDown a::after {
    font-family: "Font Awesome 5 Free"; font-weight: 900;
    content: "\f107";
    margin-left: 0.5rem;
    position: absolute;
  }

  .headerSort {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    border: 0;

    tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }

    td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 1em;
      text-align: right;
    }

    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    td:last-child {
      border-bottom: 0;
    }

    .button {
      width: 100%;
    }
  }
`;

const TheadStyle = styled.thead`
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding: 0.3rem 1rem;
  font-weight: bold;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.primaryContrast};
  font-size: 0.9rem;

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;


const TableWrap = styled.div`
  background-color: ${props => props.theme.background};
`

export default class DataTable extends Component<DataTableProps, DataTableState> {
  constructor(props: DataTableProps) {
    super(props);

    this.state = {
      limit: 30,
      currentSort: this.props.defaultSort || null,
      reverseSort: props.reverseSort,
    };
  }

  componentDidUpdate(prevProps) {
    this.handleSort(prevProps);
  }

  componentDidMount() {
    this.handleSort(this.props);
  }

  handleSort(prevProps) {
    if (this.props.data.length > 0 && !this.state.currentSort) {
      const object = this.props.data[0].data;
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i++) {
        if (object[keys[i]].sortable) this.setState({ currentSort: keys[i] }, () => FormController.forceUpdateFormElements());
        break;
      }
    }

    if (this.props.data.length !== prevProps.data.length) {
      FormController.forceUpdateFormElements()
    }
  }

  getHeaderClass(item: DataTableItemProperty, key: string): string {
    const cssClass = item.textLeft ? 'left ' : '';
    if (item.sortable && this.state.currentSort === key && this.state.reverseSort) return cssClass + 'headerSort';
    if (item.sortable && this.state.currentSort === key) return cssClass + 'headerSort';
    if (item.sortable) return cssClass + 'headerSort';
    return cssClass;
  }

  getColumnClassName(warning: boolean, size: number | null, icon: string, textLeft: boolean, addClass: string | null) {
    let className = warning ? 'warning' : '';

    if (addClass) {
      className += ' ' + addClass;
    }

    if (size) {
      className += ' ' + `c-${size}-12`;
    }

    if (textLeft) {
      className += ' ' + 'left';
    }

    if (icon) {
      className += ' icon icon-' + icon;
    }

    return className;
  }

  getValueToSort(property) {
    if (property.sortValue !== null && property.sortValue !== undefined) return property.sortValue;
    return property.value;
  }

  render() {
    const { reverseSort, currentSort, limit } = this.state;
    if (this.props.data.length == 0) return <div>{this.props.noResultsMessage ? this.props.noResultsMessage : 'No results to display'}</div>
    let foundItemWithHeaders = this.props.data.find(x => x.headers);
    const object = foundItemWithHeaders ? foundItemWithHeaders.data : this.props.data[0].data;
    const keys = Object.keys(object);
    let data = this.props.data;
    const tableClassNames = ClassNames({
      'nested-table mt': this.props.isNested,
      'tr-groups': !this.props.isNested,
      'slimline-table': this.props.slimline
    });

    if (currentSort) data = data.sort(dynamicSort((reverseSort ? '-' : '') + currentSort));
    return (
      <TableWrap className={this.props.isNested ? this.props.className : this.props.className + ' table-wrap mt'}>
        <TableStyle className={tableClassNames}>
          <TheadStyle>
            <tr>
              {keys.map((key, index) => {
                if (object[key].hideColumn) return null;
                return (
                  <th
                    key={index}
                    scope="col"
                    className={this.getHeaderClass(object[key], key)}
                    onClick={() => this.setState({ currentSort: object[key].sortable ? key : null, reverseSort: currentSort === key ? !reverseSort : false }, () => FormController.forceUpdateFormElements())}
                  >
                    {object[key].sortable &&
                      <a>
                        <span dangerouslySetInnerHTML={{ __html: object[key].hideName ? '' : key }}></span>
                        {object[key].sortable && <>
                          {this.state.currentSort === key && this.state.reverseSort ? <Icon name='chevron-up' /> : <Icon name='chevron-down' />}
                        </>}
                      </a>
                    }
                    {!object[key].sortable &&
                      <span dangerouslySetInnerHTML={{ __html: object[key].hideName ? '' : key }}></span>
                    }
                  </th>
                )
              })}
            </tr>
          </TheadStyle>
          {data.slice(0, limit).map((item, index) => {
            if (item.headerTitle) return (
              <tbody key={index}>
                <tr>
                  <td colSpan={keys.length} className='typo-heading phv txtl'>{item.headerTitle}</td>
                </tr>
              </tbody>
            )
            return (
              <tbody key={index} className={item.className}>
                <tr>
                  {keys.map((key, index) => {
                    if (item.data[key].hideColumn) return null;
                    return (
                      <td
                        key={index}
                        className={item.data[key] ? this.getColumnClassName(item.data[key].hasWarning, item.data[key].size, item.data[key].icon, item.data[key].textLeft, item.data[key].className) : ''}
                        style={item.data[key].style ? item.data[key].style : null}
                        rowSpan={item.data[key].rowspan}
                        data-label={object[key].hideName ? '' : key}
                      >
                        {item.data[key].hasWarning && <Icon name='exclamation' />}
                        {item.data.hasOwnProperty(key) ? item.data[key].value : ''}
                      </td>
                    )
                  })}
                </tr>
                {(item.extraContent ? item.extraContent : []).map((content, index) => content ? (
                  <tr key={index}>
                    <td className="txtl" colSpan={keys.length}>
                      {content}
                    </td>
                  </tr>
                ) : null)}
              </tbody>
            )
          })}
        </TableStyle>
        {limit < data.length &&
          <CoreButton full type='tertiary' onClick={() => this.setState({ limit: this.state.limit + 30 })}>Show 30 more results</CoreButton>
        }
      </TableWrap>
    );
  }
}
