import React from 'react';
import { BookingQuickInfoItem, BookingQuickInfoTwo, Button, InfoMessage, SubTitle } from '../../../booking.styles';
import Icon from '../../../../components/Media/Icon';
import moment, { Moment } from 'moment';
import { WidgetImageLayout, ExperienceType, ApiBusinessBookingDetails, EventsAvailabilityResponse } from '../../../../api/api-definitions';
import { DATE_WITH_DAY_OF_WEEK_FORMAT, TIMEFORMAT } from '../../../../utils/date-helpers';
import BookingExperienceOption from './BookingExperienceOption';
import { BookingFormData } from '..';
import Loader from '../../../../components/Layout/Loader';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    formData: BookingFormData;
    selectedTimeForExperienceSelection: string;
    eventsAvailabilityResponse: EventsAvailabilityResponse;
    selectedExperience: number;
    bookingError: string;
    loading: boolean;
    updateSelectedExperience: (experienceId?: number) => void;
    confirmHold: (date?: Moment, time?: string) => void;
}

const ExperienceSelection = ({
    business,
    formData,
    selectedTimeForExperienceSelection,
    eventsAvailabilityResponse,
    selectedExperience,
    bookingError,
    loading,
    updateSelectedExperience,
    confirmHold,
}: ComponentProps) => {
    if (loading) return <Loader />
    return (
        <>
            <BookingQuickInfoTwo>
                <BookingQuickInfoItem expand>
                    <Icon name='calendar' /> {moment(formData.date).format(DATE_WITH_DAY_OF_WEEK_FORMAT)}
                </BookingQuickInfoItem>
                <BookingQuickInfoItem>
                    <Icon name='clock' /> {moment(selectedTimeForExperienceSelection).format(TIMEFORMAT)}
                </BookingQuickInfoItem>
                <BookingQuickInfoItem>
                    <Icon name='user-friends' /> {formData.guests}
                </BookingQuickInfoItem>
            </BookingQuickInfoTwo>
            <SubTitle widgetTheme={business?.theme}>Please select an option:</SubTitle>
            <BookingExperienceOption
                onSelect={eventsAvailabilityResponse.standardAvailable ? updateSelectedExperience : undefined}
                id={-1}
                isSelected={selectedExperience == -1}
                business={business}
                layout={business?.standardBookingImageLayout}
                unavailable={!eventsAvailabilityResponse.standardAvailable}
                title={isNullOrWhitespace(business?.standardBookingName) ? 'Standard booking' : business?.standardBookingName}
                description={business?.standardBookingDescription}
                image={business?.standardBookingImageUrl}
                price={0}
            />
            {Object.keys(business.specialEvents).map(experienceId => {
                if (!eventsAvailabilityResponse.availableIds[experienceId]) return null;
                return (
                    <BookingExperienceOption
                        id={+experienceId}
                        onSelect={eventsAvailabilityResponse.availableIds[experienceId] ? updateSelectedExperience : undefined}
                        isSelected={selectedExperience == +experienceId}
                        key={'select-event-' + experienceId}
                        business={business}
                        location={business?.specialEvents[experienceId].location}
                        layout={business?.specialEvents[experienceId].layout}
                        unavailable={!eventsAvailabilityResponse.availableIds[experienceId]}
                        title={business?.specialEvents[experienceId].name}
                        image={business?.specialEvents[experienceId].imageUrl}
                        description={business?.specialEvents[experienceId].description}
                        price={business?.specialEvents[experienceId].price}
                    />
                )
            })}
            {Object.keys(eventsAvailabilityResponse.availableIds).map(experienceId => {
                const matchingExpeirence = business.experiences[experienceId];
                if (!matchingExpeirence || matchingExpeirence.type != ExperienceType.Experience) return null;
                return (
                    <BookingExperienceOption
                        id={+experienceId}
                        onSelect={updateSelectedExperience}
                        isSelected={selectedExperience == +experienceId}
                        key={'select-event-' + experienceId}
                        business={business}
                        location={business?.experiences[experienceId].location}
                        layout={business?.experiences[experienceId].layout}
                        title={business?.experiences[experienceId].name}
                        image={business?.experiences[experienceId].imageUrl}
                        description={business?.experiences[experienceId].description}
                        price={business?.experiences[experienceId].price}
                    />
                )
            })}
            {bookingError &&
                <>
                    <InfoMessage marginTop widgetTheme={business?.theme}>{bookingError}</InfoMessage>
                </>
            }
            <Button disabled={!selectedExperience} widgetTheme={business?.theme} type='button' onClick={() => confirmHold()}>
                Confirm selection
            </Button>
        </>
    );
};

export default ExperienceSelection;