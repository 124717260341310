export function pad(n: string | number, width: number, z: string) {
  z = z || '0';
  n = String(n);

  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function capitalizeFirst(s: string) {
  if (typeof s !== 'string') return null;

  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function capitalizeAll(s: string) {
  const words = s.split(' ');
  return words.map(x => capitalizeFirst(x)).join(' ')
}

export function usernameHasValidLength(stringToCheck: string) {
  return !!stringToCheck && stringToCheck.length >= 2 && stringToCheck.length <= 40;
}

export function hasSpaces(stringToCheck: string) {
  var pattern = new RegExp(/^\S*$/);
  let noSpaces = pattern.test(stringToCheck);
  return !noSpaces;
}

export function hasSpecialCharacters(stringToCheck: string) {
  var pattern = new RegExp(/[~¬`!#$%\^&*+=\-\[\]\\';,/{}()£$|\\":<>\?]/); //unacceptable chars
  let answer = pattern.test(stringToCheck)
  return answer;
}

export function findArrayIndex(array, predicateFunction) {
  const length = array === null ? 0 : array.length;

  if (!length) {
    return -1;
  }

  let index = -1;

  for (var i = 0; i < length; ++i) {
    if (predicateFunction(array[i])) {
      index = i;
      break;
    }
  }

  return index;
}

export function replaceUrlParam(url: string, paramName: string, paramValue: string) {
  const href = new URL(url);
  href.searchParams.set(paramName, paramValue);
  return href;
}
