import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { ApiService } from "../api/api-connectors";
import { BusinessPermissionsResponse } from "../api/api-definitions";
import BusinessService from "../services/BusinessService";
import BusinessPermissionsService from "../services/BusinessPermissionsService";
import useParentBusiness from "./useParentBusiness";
import { ParentIdState } from "../pages/BusinessDashboard/DashboardLayout";
import useOffline from "./useOffline";
import BusinessPermissionsCacheService from "../services/BusinessPermissionsCacheService";

export const LoadedPermissionSet = atom<BusinessPermissionsResponse>({
    key: 'BusinessPermissionsResponse',
    default: null
})

export const useBusinessPermissions = function(): [boolean, BusinessPermissionsResponse, (force?: boolean) => void] {
    const offline = useOffline();
    const [businessData, setBusinessData] = useRecoilState(LoadedPermissionSet);
    const [loading, setLoading] = useState<boolean>(true);
    const previousParentId = useRecoilValue(ParentIdState);
    const params: any = useParams();

    useEffect(() => {
        try {
            if (params.parentId !== previousParentId) load(true);
            if (!params.parentId) setLoading(false)
        }
        catch (e) {
            console.error(e)
        }
    }, [params.parentId]);

    const load = (force?: boolean) => {
        if (params.parentId) {
            setLoading(true);
            (offline ? BusinessPermissionsCacheService.GetBusinessPermissions(params.parentId) : BusinessPermissionsService.GetBusinessPermissions(params.parentId, force)).then(data => {
                setBusinessData(data);
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }

    return [!loading, cloneDeep(businessData), load];
};