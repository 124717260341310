import React, { useEffect } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import DataTable, { DataTableItem } from '../../../../../../components/Layout/Datatable';
import { Button } from '@chakra-ui/react';
import base from '@emotion/styled/types/base';
import { ApiPage, PageRequest, PagesListResponse } from '../../../../../../api/api-definitions';
import { DashboardPage } from '../../../../DashboardLayout';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import PageItem from './pageItem';
import { cloneDeep } from 'lodash';
import { NotificationService } from '../../../../../../services/NotificationService';
import AddPageModal from './addPageModal';
import DeletePageModal from './deletePageModal';
import useParentBusiness from '../../../../../../hooks/useParentBusiness';
import { useParams } from 'react-router-dom';

const CmsPages = () => {
    const params: any = useParams();
    const [response, setResponse] = React.useState<PagesListResponse>();
    const [newPageModalOpen, setNewPageModalOpen] = React.useState(false);
    const [deletePageModalOpen, setDeletePageModalOpen] = React.useState(false);
    const [selectedPage, setSelectedPage] = React.useState<ApiPage>();

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        ApiService.page.List__GET(params.parentId).then((response) => {
            if (response.pages.length == 0) {
                const request: PageRequest = {
                    name: 'Home page',
                    url: '/',
                    useMaster: true,
                    partitionKey: params.parentId.toString()
                }
                const requestMaster: PageRequest = {
                    name: 'Master layout',
                    url: '{m}',
                    useMaster: true,
                    partitionKey: params.parentId.toString()
                }
                Promise.all([
                    ApiService.page.New__POST(requestMaster),
                    ApiService.page.New__POST(request)
                ]).then(() => load())
            } else {
                setResponse(response)
            }
        })
    }

    const closeModals = (refresh: boolean) => {
        setNewPageModalOpen(false);
        setDeletePageModalOpen(false);
        if (refresh) load();
    }

    const openAddModal = (page: ApiPage) => {
        setSelectedPage(page);
        setNewPageModalOpen(true);
    }

    const openDeleteModal = (page: ApiPage) => {
        setSelectedPage(page);
        setDeletePageModalOpen(true);
    }

    const getChildren = (id: string) => {
        return cloneDeep(response.pages).filter(page => page.parent === id);
    }

    const masterLayout = response?.pages.find(x => x.url === '{m}');
    const homePage = response?.pages.find(x => x.url === '/');
    const children = response ? getChildren(homePage?.rowKey) : [];

    return (
        <>
            <DashboardHeader title='Pages' icon='grid-2-plus' />
            {newPageModalOpen && <AddPageModal closeModal={closeModals} layouts={response?.pages} selectedPage={selectedPage} />}
            {deletePageModalOpen && <DeletePageModal closeModal={closeModals} page={selectedPage} />}
            {response && homePage &&
                <>
                    <PageItem
                        page={homePage}
                        getChildren={getChildren}
                        children={children}
                        openAddModal={openAddModal}
                        openDeleteModal={openDeleteModal}
                    />
                    <PageItem page={masterLayout} getChildren={getChildren} />
                </>
            }
        </>
    );
};

export default CmsPages;