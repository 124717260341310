import { ApiService } from "../api/api-connectors";
import { BusinessPermissionsResponse } from "../api/api-definitions";
import BusinessPermissionsCacheService from "./BusinessPermissionsCacheService";
import { NotificationService } from "./NotificationService";

export default class BusinessPermissionsService {
    private static _promise: Promise<BusinessPermissionsResponse> = null;
    private static _data: BusinessPermissionsResponse = null;

    public static Clear(): void {
        this._data = null;
        this._promise = null;
    }

    public static async GetBusinessPermissions(parentId: number, force?: boolean): Promise<BusinessPermissionsResponse> {
        if (force) {
            this._data = null;
            this._promise = null;
        }
        if (this._data) {
            return new Promise(resolve => {
                resolve(this._data)
            });
        } else {
            this._data = null;
            if (!this._promise) {
                this._promise = ApiService.parentBusiness.GetPermissions__GET(parentId);
            }
            await this._promise.then(data => {
                BusinessPermissionsCacheService.UpsertBusinessPermissions(parentId.toString(), data)
                this._data = data;
            }).catch(() => {
                NotificationService.Error('We encountered an error when trying to load permissions.')
            })

            return this._data;
        }
    }
}