import { heights, widths } from "./Table";

export const SEATHEIGHT = 40;
export const SEATMARGIN = 5;
export const DEFAULTXY = 30;
export const TABLEWIDTH = 50;
export const SEATWIDTH = 10;
export const DEFAULTSEATS = 4;
export const LAYOUTWIDTH = 800;
export const LAYOUTHEIGHT = 650;

export const getTableHeightAndWidth = (table) => {
    const seats = (+table.seats % 2 == 0) ? +table.seats : (+table.seats + 1);
    return {
        height: (((SEATHEIGHT * seats) / 2) + (SEATMARGIN * seats)) - (SEATMARGIN * 2),
        width: table.width
    }
    // const size = ((4.0 * Math.ceil(table.seats / 4.0)) / 4) * TABLEWIDTH;
    // return {
    //     height: size,
    //     width: size
    // }
}

export const getSeatPositions = (table: any): any[] => {
    const totalSeats = []
    for (let i = 0; i < table.seats; i++) {
        const leftHandSide = i % 2 == 0;
        const yIndex = leftHandSide ? i : i - 1;

        const x = leftHandSide ?
            table.x - (table.width / 2) + (SEATMARGIN * 3) - table.rotation :
            table.x + (table.width * 2) - ((SEATMARGIN * 10)) - table.rotation

        const y = table.y + (((SEATMARGIN * 2) + SEATHEIGHT) * (yIndex / 2)) + table.rotation

        totalSeats.push({
            x,
            y,
            width: SEATWIDTH,
            height: SEATHEIGHT,
        })
    }
    return totalSeats;
}

export function degToRad(angle) {
    return angle / 180 * Math.PI;
}

export function getCenter(shape) {
    const height = heights[`${shape.seats}-${shape.shape}`];
    const width = widths[`${shape.seats}-${shape.shape}`];
    const angleRad = degToRad(shape.rotation || 0);
    const xCalculation = shape.x + width / 2 * Math.cos(angleRad) + height / 2 * Math.sin(-1 * angleRad);
    const yCalculation = shape.y + height / 2 * Math.cos(angleRad) + width / 2 * Math.sin(angleRad);

    return {
        x: xCalculation,
        y: yCalculation
    };
}