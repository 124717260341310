import React, { useState } from 'react';
import { ApiBusinessBookingDetails, BaseDish } from '../../../../api/api-definitions';
import CoreModal from '../../../../components/Layout/CoreModal';
import styled, { useTheme } from 'styled-components';
import { Constants } from '../../../../constants';
import CoreButton from '../../../../components/Forms/Button';
import { StyledNumberInput } from '../../../../theme/input.styles';
import { useSetRecoilState } from 'recoil';
import { BasketState, FoodItemState } from '..';
import { cloneDeep } from 'lodash';
import BREAKPOINTS from '../../../../config/breakpoints';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';

interface ComponentProps {
    selectedItem: BaseDish;
    setActiveItemId: (id: number) => void;
    business: ApiBusinessBookingDetails;
}

const AddToBasketModal = ({ selectedItem, setActiveItemId, business }: ComponentProps) => {
    const theme = useTheme();
    const [quantity, setQuantity] = useState(1);
    const setBasketState = useSetRecoilState(BasketState);
    const setValue = useSetRecoilState(FoodItemState([selectedItem.id, 0]));

    const addToBasket = () => {
        let newValue: number = 0;
        setBasketState(basket => {
            const current = cloneDeep(basket);
            if (current[selectedItem.id]) {
                current[selectedItem.id].quantity += quantity;
            } else {
                current[selectedItem.id] = {
                    quantity: quantity
                }
            }
            if (current[selectedItem.id].quantity == 0) {
                delete current[selectedItem.id];
            }
            newValue = current[selectedItem.id].quantity;
            return current;
        })
        setValue(newValue)
        setActiveItemId(undefined)
    }

    return (
        <CoreModal
            title={selectedItem.name}
            onClose={() => setActiveItemId(undefined)}
            hasCancel={false}
            small
            mediumPanel
            noFooterFlex
            actionBar={
                <ActionBar>
                    <AddToBasketQuantity>
                        <StyledNumberInput value={quantity} hasPlusMinus min={1} onChange={(e) => setQuantity(+e.target.value)} />
                    </AddToBasketQuantity>
                    <CoreButton
                        full
                        onClick={addToBasket}
                        secondaryConstrastOverride={business?.theme?.primaryContrastColour || theme.secondaryContrast}
                        secondaryOverride={business?.theme?.primaryColour || theme.secondary}
                    >Add to basket</CoreButton>
                </ActionBar>
            }
        >
            {!isNullOrWhitespace(selectedItem.imageUrl) &&
                <ModalImage style={{ backgroundImage: `url(${Constants.baseImageUrl}${selectedItem.imageUrl})` }} />
            }
            <Description dangerouslySetInnerHTML={{__html: selectedItem.description.replaceAll('\\n', '<br />')}} />
        </CoreModal>
    );
};

const Description = styled.div`
    white-space: pre-wrap;
`

const ActionBar = styled.div`
    display: flex;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
`

const AddToBasketQuantity = styled.div`
  flex: none;
  width: 50%;
  padding-right: 1rem;
`

const ModalImage = styled.div`
  width: 100%;
  height: 15rem;
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1rem;
`

export default AddToBasketModal;