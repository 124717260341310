import React from 'react';
import Checkout from '../../components/Payments/Checkout';
import { getQueryParams } from '../../utils/data-helpers';
import { isNullOrWhitespace } from '../../utils/text-helpers';
import styled, { createGlobalStyle } from 'styled-components';
import { BoxShadowStyle } from '../../theme';

const MakePayment = () => {
    const queryParams = getQueryParams();
    const stripeReturnValue = queryParams.setup_intent || queryParams.payment_intent;

    if (!isNullOrWhitespace(stripeReturnValue)) {
        window.opener.processPaymentConfirmation(stripeReturnValue);
        window.opener.focus();
        window.close();
    }

    return (
        <>
            <PageStyle />
            <PaymentContainer>
                {isNullOrWhitespace(stripeReturnValue) &&
                    <Checkout
                        intentSecret={queryParams.intent}
                        payNow={queryParams.payNow == 'true'}
                        accountId={queryParams.account}
                        returnUrl={window.location.href}
                    />
                }
            </PaymentContainer>
        </>
    );
};

const PageStyle = createGlobalStyle`
    body, html, #pageContent {
        background-color: ${({ theme }) => theme.primary} !important;
        --ion-background-color: ${({ theme }) => theme.primary} !important;
    }
`

const PaymentContainer = styled.div`
    background-color: white;
    padding: 1rem;
    max-width: 50rem;
    margin: 3rem auto;
    ${BoxShadowStyle}
`

export default MakePayment;