import { useEffect, useRef, useState } from "react";
import { Rect, Circle, Transformer, Text } from "react-konva";
import { ExtendedShape } from ".";
import { KonvaEventObject } from "konva/lib/Node";
import { LAYOUTWIDTH, LAYOUTHEIGHT } from "./tableLayoutUtils";

interface ComponentProps {
    shapeProps: ExtendedShape;
    isSelected: boolean;
    onSelect: (id: KonvaEventObject<MouseEvent>) => void;
    onChange: (item: ExtendedShape) => void;
    readonly: boolean;
}

const Shape = ({ shapeProps, isSelected, onSelect, onChange, readonly }: ComponentProps) => {
    const [dragging, setDragging] = useState(false)
    const shapeRef = useRef();
    const trRef = useRef<any>();

    const Component = shapeProps.shape === 'rect' ? Rect : (shapeProps.shape === 'circle' ? Circle : Text);

    useEffect(() => {
        if (isSelected && trRef?.current) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <>
            <Component
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                width={shapeProps.width}
                height={shapeProps.height}
                x={shapeProps.x}
                y={shapeProps.y}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "pointer";
                  }}
                  onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "default";
                  }}
                rotation={shapeProps.rotation}
                attrs={{shape: shapeProps}}
                fill={shapeProps.fill}
                draggable={!readonly}
                text={shapeProps.text}
                fontSize={18}
                name="moveable"
                onDragStart={() => readonly ? {} : setDragging(true)}
                // dragBoundFunc={_pos => {
                //     let w = shapeProps.width, h = shapeProps.height, gW = LAYOUTWIDTH, gH = LAYOUTHEIGHT;
                //     return {
                //         x: _pos.x < 0 ? 0 : (_pos.x + w > gW ? gW - w : _pos.x),
                //         y: _pos.y < 0 ? 0 : (_pos.y + h > gH ? gH - h : _pos.y)
                //     };
                // }}
                onDragEnd={(e) => {
                    setDragging(false)
                    onChange({
                        ...shapeProps,
                        x: Math.round(e.target.x()),
                        y: Math.round(e.target.y()),
                    });
                }}
                onTransformEnd={(e) => {
                    const node: any = shapeRef.current;
                    if (node) {
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
                        node.scaleX(1);
                        node.scaleY(1);
                        onChange({
                            ...shapeProps,
                            x: Math.round(node.x()),
                            y: Math.round(node.y()),
                            width: Math.round(Math.max(node.width() * scaleX)),
                            height: Math.round(Math.max(node.height() * scaleY)),
                            rotation: Math.ceil(node.attrs.rotation / 15) * 15
                        });
                    }
                }}
            />
            {isSelected && (
                <Transformer
                    resizeEnabled={!readonly}
                    rotateEnabled={!readonly}
                    rotationSnaps={[0, 90, 180, 270]}
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};

export default Shape;