import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { AuthCodeRequest } from '.';
import { Column, Row } from '../../../components/Layout/Grid';
import { Constants } from '../../../constants';

interface ComponentProps {
    data: AuthCodeRequest;
}

const Title = styled.div`
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.background};
    background-image: url('/images/logo.png');
    background-position: right center;
    background-size: 125px;
    background-repeat: no-repeat;
    padding: 20px;
    font-family: serif;
    margin-top: 0;
    font-size: 30px;
`;

const Container = styled(Row)`
    font-size: 20px;
`;

const CodeContainer = styled(Column)`
    border-left: 1px solid grey;
    text-align: center;
    padding: 40px 0;
`;

const Code = styled.span`
    color: ${props => props.theme.primary};
    font-weight: bold;
    font-size: 50px;
    font-family: serif;
`;

const StepList = styled.ol`
    margin-left: 2rem;

    li::before {
        color: ${props => props.theme.primary};
    }
    li {
        margin-bottom: 5px;
    }
`;

const PrintContainer = styled.div`
    border: 1px solid grey;
`;

const InnerContainer = styled.div`
    padding: 15px;
`;

const CodeLabel = styled.div`
    color: ${props => props.theme.secondary};
    padding-bottom: 15px;
`;


const Postcard = ({ data }: ComponentProps) => {
    return (
        <PrintContainer id='section-to-print'>
            <Container>
                <Column size={12}>
                    <Title>Your business verification code</Title>
                    <InnerContainer>
                        The verification code requested for {data.business.name} is below! Just follow the steps below and {data.business.name} will be open for business on {Constants.businessName}!
                    </InnerContainer>
                </Column>
                <Column size={6}>
                    <StepList>
                        <li>Log into your {Constants.businessName} account</li>
                        <li>Find {data.business.name} in your business list</li>
                        <li>Enter your verification code in the text box and click verify</li>
                    </StepList>
                </Column>
                <CodeContainer size={6}>
                    <CodeLabel>
                        Verification code
                    </CodeLabel>
                    <Code>{data.code}</Code>
                </CodeContainer>
                <Column size={12}>
                    <InnerContainer>
                        Need help? Contact us at business-support@forager.com
                    </InnerContainer>
                </Column>
            </Container>
        </PrintContainer>
    );
};

export default Postcard;