import React from 'react';
import { Constants } from '../../../../constants';
import { Logo } from '../../../booking.styles';
import { ApiBusinessBookingDetails, ThemeOptions } from '../../../../api/api-definitions';
import styled from 'styled-components';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
}

const PoweredBy = ({ business }: ComponentProps) => {
    return (
        <PoweredByStyle onClick={() => window.top.open('https://www.dishforager.com/', '_blank')} widgetTheme={business?.theme}>
            Powered by
            <br />
            <Logo title={Constants.businessName} widgetTheme={business?.theme}><SVG /></Logo>
        </PoweredByStyle>
    );
};

const PoweredByStyle = styled.div<{ widgetTheme?: ThemeOptions }>`
    background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.buttonTextColour || props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    text-align: center;
    cursor: pointer;

    img {
        height: 0.7rem;
        display: inline;
        margin-left: 1rem;
    }
`

const SVG = () =>
    <svg version="1.1" id="Layer_2_00000008142285360707904690000010961109162998869938_"
        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 928.6 99.9"
        xmlSpace="preserve">
        <style type="text/css">
            {`.st0{fill:#EFEFEF;}
	.st1{fill:#FFFFFF;}
	.st2{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1-2">
            <g>
                <path className="st0" d="M0,98.1l2-33.6L3.9,9.4C4,7.3,4,4.7,4,1.5l15.6,0.1l19.4-0.1c9.1,0,16.4,0.7,21.9,2.1s10.4,4.3,14.7,8.7
			c4.3,4.4,7.3,9.4,9.1,15c1.8,5.6,2.7,11.6,2.7,18.1c0,7.7-1.4,15.1-4.1,22.3s-6.8,13.2-12.2,18s-11.5,8.1-18.3,9.8
			s-14.3,2.6-22.3,2.6l-15-0.1C15.4,98.1,0,98.1,0,98.1z M6.1,93.7c8.4,0.1,14.9,0.2,19.8,0.2s9.7-0.2,14.7-0.7
			c5-0.5,9.5-1.3,13.5-2.6s7.8-3.5,11.7-6.7S72.6,77,75,73c2.4-4,4.2-8.5,5.4-13.4s1.8-9.8,1.8-14.8s-0.6-9.5-1.7-13.9
			c-0.8-3.2-2-6.2-3.7-9c-1.8-3.2-4-5.9-6.4-8c-2.4-2.2-5-3.8-7.6-5s-6-1.9-9.9-2.4C49.3,6,45.1,5.8,40.3,5.7H23.4L9.8,5.8L6.1,93.7
			L6.1,93.7z"/>
                <path className="st0" d="M104.6,98.1l1.6-28.4l1.4-42.5l0.3-14.7c0.1-3.9,0.1-7.6,0.1-11c1.2,0,2.3,0.1,3.2,0.1c0.9,0,2,0,3.2-0.1
			L113,27.1l-1.7,52.7L111,98.1c-1.2-0.1-2.2-0.1-3.2-0.1S105.8,98.1,104.6,98.1L104.6,98.1z"/>
                <path className="st0" d="M129.6,95.2c0-1.8-0.1-4-0.4-6.4c8.9,4.2,17.1,6.4,24.5,6.4c4.2,0,8-0.7,11.4-2.1c3.4-1.4,6-3.4,7.9-6.1
			c1.9-2.7,2.8-5.8,2.8-9.3c0-1.7-0.3-3.4-0.9-4.9c-0.6-1.6-1.6-3.2-2.9-4.8c-1.3-1.7-3.3-3.8-5.8-6.3c-2.5-2.6-7-6.6-13.6-12
			l-5.6-4.7c-1.9-1.7-3.8-3.5-5.6-5.3c-1.8-1.8-3.3-3.5-4.5-5.1c-1.2-1.6-2.1-3-2.7-4.2s-1.1-2.6-1.4-4.1c-0.3-1.5-0.5-3.1-0.5-4.7
			c0-3.8,1-7.4,3.1-10.7s5.2-5.9,9.3-7.9C149,1,154.2,0,160.5,0c4.4,0,10,0.6,16.8,1.9c0.1,1.8,0.3,3.8,0.6,5.9
			c-4.4-1.4-8-2.3-10.9-2.8s-5.6-0.7-8.2-0.7c-4.7,0-8.5,0.8-11.6,2.3c-3,1.5-5.4,3.6-7,6.2c-1.6,2.6-2.5,5.3-2.5,8.1
			c0,1.5,0.2,3,0.7,4.4s1.2,2.9,2.3,4.4c1.1,1.5,2.5,3.1,4.3,4.9c1.8,1.8,3.9,3.8,6.4,6.1c2.4,2.3,5.7,5.2,9.8,8.7
			c4.1,3.5,6.9,6.1,8.6,7.5c1.7,1.5,3.3,3.1,4.8,4.8c1.5,1.7,2.7,3.4,3.7,5.1c0.9,1.7,1.6,3.4,2.1,5.2c0.4,1.8,0.7,3.7,0.7,5.7
			c0,4.4-1.1,8.3-3.4,11.7s-5.6,5.9-9.9,7.6c-4.4,1.7-9.3,2.6-14.7,2.6c-3.5,0-6.9-0.3-10.2-0.8C139.7,98.3,135.2,97.1,129.6,95.2
			L129.6,95.2z"/>
                <path className="st0" d="M198.9,98.1c1.1-20.3,1.9-39.3,2.5-57s0.8-30.9,0.8-39.7c1.2,0,2.3,0.1,3.1,0.1s1.9,0,3.1-0.1l-1.9,45.2h65.8
			l1.3-35.5c0.1-2,0.1-5.2,0.1-9.7c1.2,0,2.3,0.1,3.1,0.1s1.9,0,3.1-0.1c-1.8,29.3-3,61.5-3.8,96.6c-1.2-0.1-2.2-0.1-3.1-0.1
			c-0.9,0-2,0-3.1,0.1c1.1-16.4,1.8-32.1,2.1-47.1h-65.8l-1.1,34.8l-0.1,12.3c-1.2-0.1-2.3-0.1-3.1-0.1
			C201.1,98.1,200,98.1,198.9,98.1L198.9,98.1z"/>
                <path className="st1" d="M336.7,98.1l1.3-24.6l1.5-47.4l0.3-14c0-3.4,0.1-7,0.1-10.6c9.9,0,19.3,0.1,28.3,0.1l24.6-0.1
			c-0.2,1.9-0.3,3.6-0.3,5c-4.1-0.4-8.6-0.6-13.7-0.6s-9.7-0.1-13.8-0.1h-19.5l-1.3,40.8l15.1,0.2c12.8,0,22.4-0.1,28.6-0.2
			c-0.2,1.4-0.3,3-0.4,4.9l-12.6-0.1c-5-0.1-8.9-0.1-11.8-0.1c-6.1,0-12.5,0.1-19.2,0.3c-0.6,20.3-0.9,35.8-0.9,46.7
			c-1.2-0.1-2.2-0.1-3.1-0.1S337.9,98.1,336.7,98.1z"/>
                <path className="st1" d="M457.2,0c6.8,0,13.1,1,19.1,3c6,2,11.3,5.3,15.8,9.9s7.7,9.8,9.6,15.8s2.9,12.2,2.9,18.9
			c0,7.3-1.1,14.3-3.3,21.1s-5.5,12.4-9.8,17c-4.4,4.6-9.9,8.1-16.5,10.4c-6.6,2.4-13.5,3.5-20.7,3.5s-13.6-1.1-20-3.3
			c-6.4-2.2-11.7-5.4-16-9.7c-4.3-4.3-7.5-9.4-9.5-15.4c-2-6-3-12.3-3-18.9c0-7.4,1.2-14.6,3.7-21.6c2.5-7,6-12.7,10.7-17.3
			s10.2-7.9,16.7-10.1C443.3,1.1,450.1,0,457.2,0L457.2,0z M456.9,4.4c-6.5,0-12.7,1-18.4,3s-10.8,5.3-15,9.8s-7.4,9.8-9.4,15.9
			s-3,12.5-3,19.2c0,6.3,1,12.3,3.1,18.1c2.1,5.8,5.1,10.5,9.1,14.3c4,3.8,8.7,6.5,14.1,8.1s11.1,2.5,17.1,2.5
			c6.3,0,12.3-0.9,18-2.8c5.6-1.8,10.5-4.9,14.7-9.2c4.1-4.3,7.2-9.4,9.1-15.5s2.9-12.4,2.9-19.1s-0.9-12.4-2.7-18.2
			s-4.6-10.7-8.5-14.7c-3.9-4-8.6-6.9-14-8.7C468.7,5.3,463,4.4,456.9,4.4L456.9,4.4z"/>
                <path className="st1" d="M521.7,98.1l1.1-17.1l2-53.3l0.3-15.1l0.1-11l14.1,0.1l16.2-0.1c6.7,0,11.6,0.9,14.7,2.7
			c3.1,1.8,5.5,4.3,7.3,7.6s2.7,7,2.7,11.3c0,5.3-1.3,10.1-4,14.5s-6.3,7.8-10.9,10.1s-9.9,3.5-15.9,3.5c-0.8,0-1.9,0-3.4-0.1
			l33.9,47.1c-1.2-0.1-2.4-0.1-3.7-0.1s-2.5,0-3.7,0.1l-9.3-14L536,46c4.2,0.8,7.8,1.1,10.9,1.1c3.7,0,7.3-0.5,10.8-1.5
			c3.5-1,6.5-2.5,9-4.6s4.4-4.6,5.8-7.6c1.4-3,2.1-6.4,2.1-10c0-2.4-0.3-4.7-1-6.7s-1.7-3.9-3.2-5.5c-1.5-1.6-3-2.7-4.5-3.4
			s-3.5-1.2-5.9-1.4c-2.4-0.3-5-0.5-7.7-0.5L540.8,6c-2.5,0-5.8-0.1-9.8-0.1c-1.6,37.4-2.5,68.1-2.7,92.3c-1.2-0.1-2.3-0.1-3.3-0.1
			S522.9,98.1,521.7,98.1L521.7,98.1z"/>
                <path className="st1" d="M582.3,98.1L606,51.6l20.9-41.2l4.3-8.9c1.2,0,2.5,0.1,3.8,0.1s2.7,0,3.8-0.1l13.7,38.9l20.9,57.7
			c-1.2-0.1-2.3-0.1-3.2-0.1c-1,0-2,0-3.2,0.1c-1.3-4.5-3.8-12-7.4-22.5c-3.6-10.4-6.5-18.3-8.6-23.7h-40l-22.3,46.2
			c-1.2-0.1-2.3-0.1-3.2-0.1S583.4,98.1,582.3,98.1L582.3,98.1z M613.3,47.4h36.3L634.5,5.9L613.3,47.4z"/>
                <path className="st1" d="M742.3,44.9c8.6,0,15,0.1,19,0.1l12.4-0.1l-1.2,22.4l-0.8,27.3c-7.4,1.8-14.1,3.1-20,3.9
			c-5.9,0.8-11,1.2-15.1,1.2c-7.2,0-14.1-1-20.7-3c-6.6-2-12.2-5.1-16.9-9.2s-8.1-9.2-10.3-15.3s-3.3-12.5-3.3-19.3
			c0-7.8,1.5-15.2,4.3-22.2c2.9-7,6.9-12.8,12-17.5c5.1-4.6,11-8,17.6-10.1C726.1,1,733.3,0,741,0c4.3,0,8.8,0.3,13.3,0.8
			c4.5,0.5,9.8,1.5,15.9,2.8c0.1,2.4,0.3,4.6,0.6,6.5c-6.8-2.2-12.5-3.7-17.3-4.5c-4.8-0.8-9.2-1.2-13.4-1.2c-7,0-13.6,1.1-19.9,3.3
			s-11.7,5.4-16,9.8c-4.4,4.3-7.7,9.7-10,16.1s-3.4,12.9-3.4,19.7c0,6.4,1,12.3,3,17.8c2,5.5,5,10,9,13.4c4,3.5,8.9,6.2,14.7,8
			c5.8,1.9,12.1,2.8,19,2.8c2.4,0,4.9-0.1,7.7-0.4c2.8-0.3,5.6-0.6,8.4-1.1c2.8-0.4,5.1-0.9,6.8-1.3c1.8-0.4,4.1-1.1,7-2l1.7-41.2
			h-8.4c-2.3,0-4.5,0-6.8,0.1l-10.7,0.5c0.1-1.5,0.2-2.8,0.2-4.1L742.3,44.9L742.3,44.9z"/>
                <path className="st1" d="M794.3,98.1c0.9-12.8,1.7-29.3,2.5-49.5c0.7-20.2,1.1-35.9,1.1-47.1c10.5,0,20.3,0.1,29.5,0.1l25.8-0.1
			c-0.2,1.9-0.3,3.6-0.3,5L847.3,6c-1.5-0.2-4-0.2-7.7-0.3c-3.6,0-7.2,0-10.7,0c-11.2,0-19.7,0-25.5,0.1L802,46.4
			c7.1,0.1,13.7,0.2,19.6,0.2c7.1,0,15.8-0.1,26.2-0.2c-0.2,1.4-0.3,3-0.3,4.7l-26.7-0.2c-3.1,0-9.5,0.1-19,0.2l-1.4,42.6l20.3,0.1
			c8,0,13.5,0,16.6-0.1s7.3-0.2,12.6-0.5c-0.2,1.9-0.3,3.6-0.3,5c-11.3-0.1-19.6-0.1-24.8-0.1L794.3,98.1L794.3,98.1z"/>
                <path className="st1" d="M870.3,98.1l1.1-17.1l2-53.3l0.3-15.1l0.1-11l14.1,0.1L904,1.5c6.7,0,11.6,0.9,14.7,2.7
			c3.1,1.8,5.5,4.3,7.3,7.6s2.7,7,2.7,11.3c0,5.3-1.3,10.1-4,14.5s-6.3,7.8-10.9,10.1s-9.9,3.5-15.9,3.5c-0.8,0-1.9,0-3.4-0.1
			l33.9,47.1c-1.2-0.1-2.4-0.1-3.7-0.1s-2.5,0-3.7,0.1l-9.3-14l-27-38.2c4.2,0.8,7.8,1.1,10.9,1.1c3.7,0,7.3-0.5,10.8-1.5
			c3.5-1,6.5-2.5,9-4.6s4.4-4.6,5.8-7.6c1.4-3,2.1-6.4,2.1-10c0-2.4-0.3-4.7-1-6.7s-1.7-3.9-3.2-5.5c-1.5-1.6-3-2.7-4.5-3.4
			s-3.5-1.2-5.9-1.4c-2.4-0.3-5-0.5-7.7-0.5L889.4,6c-2.5,0-5.8-0.1-9.8-0.1c-1.5,37.4-2.5,68.1-2.7,92.3c-1.2-0.1-2.3-0.1-3.3-0.1
			S871.5,98.1,870.3,98.1L870.3,98.1z"/>
                <path className="st1" d="M457.4,9.2c5.4,0,10.5,0.8,15.4,2.4s9,4.3,12.6,7.9s6.2,7.9,7.7,12.6c1.5,4.8,2.3,9.8,2.3,15.1
			c0,5.9-0.9,11.5-2.6,16.9s-4.4,10-7.9,13.6c-3.5,3.7-7.9,6.5-13.2,8.3s-10.8,2.8-16.6,2.8s-10.9-0.9-16-2.6s-9.4-4.3-12.8-7.8
			c-3.5-3.4-6-7.6-7.6-12.4c-1.6-4.8-2.4-9.9-2.4-15.2c0-6,1-11.7,3-17.3s4.8-10.2,8.5-13.9c3.7-3.7,8.2-6.4,13.4-8.1
			S451.8,9.2,457.4,9.2L457.4,9.2z M457.2,12.7c-5.2,0-10.2,0.8-14.8,2.4c-4.6,1.6-8.6,4.2-12,7.8c-3.4,3.6-5.9,7.9-7.5,12.8
			c-1.6,4.9-2.4,10-2.4,15.4c0,5,0.8,9.9,2.5,14.5s4.1,8.4,7.3,11.5c3.2,3,7,5.2,11.3,6.5c4.3,1.3,8.9,2,13.7,2
			c5.1,0,9.9-0.7,14.4-2.2s8.5-3.9,11.8-7.4c3.3-3.4,5.7-7.6,7.3-12.4c1.5-4.8,2.3-9.9,2.3-15.3s-0.7-10-2.1-14.6s-3.7-8.5-6.9-11.8
			c-3.1-3.2-6.9-5.6-11.2-7S462.1,12.7,457.2,12.7L457.2,12.7z"/>
                <path className="st2" d="M411.3,74.2l-31.9,16.9c-1,0.6-1.8,1.4-1.5,1.9l2.3,5.2c0.2,0.3,1,0.3,1.8-0.2l35.1-19.8" />
            </g>
        </g>
    </svg>

export default PoweredBy;