import React, { useEffect, useState } from 'react';
import { BaseBusiness, BaseBusinessTag, OperationalStatus } from '../../../../../api/api-definitions';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { ApiService } from '../../../../../api/api-connectors';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import TextInput from '../../../../../components/Forms/TextInput';
import { FloatingActionBar } from '../../../../../components/Layout/FloatingActionBar';
import Loader from '../../../../../components/Layout/Loader';
import { NotificationService } from '../../../../../services/NotificationService';
import { Row, Column } from '../../../../../components/Layout/Grid';
import CoreButton from '../../../../../components/Forms/Button';
import Icon from '../../../../../components/Media/Icon';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../components/Dashboard/Panel';
import useParentBusiness from '../../../../../hooks/useParentBusiness';
import CoreModal from '../../../../../components/Layout/CoreModal';
import { WarningMessage } from '../../../../../components/Forms/Messaging';
import { StyledTextInput } from '../../../../../theme/input.styles';


const ParentBusinessDetails = () => {
    const [businessLoaded, data, setData, reload] = useParentBusiness();
    const [modalOpen, setModalOpen] = useState(false);


    if (!businessLoaded || !data) return <Loader />;

    const saveData = () => {
        ApiService.parentBusiness.Save__POST(data).then(() => {
            reload();
            NotificationService.Confirm('Details saved')
        })
    }

    return (
        <>
            <DashboardHeader title='Business details' />
            <FormWrapper onUpdate={(updateData) => setData({...data, ...updateData})}>
                {({ id, valid }) => (
                    <>
                        <DashboardPanel title='Business information'>
                            <Row>
                                <Column size={4}>
                                    <StyledTextInput value={data.name} model='name' label='Business name:' required />
                                </Column>
                            </Row>
   
                        </DashboardPanel>
                        <CoreButton type='danger' onClick={() => setModalOpen(true)}><Icon name='trash' /> Delete business</CoreButton>
                        <FloatingActionBar>
                            <CoreButton small disabled={!valid} onClick={saveData} floatRight>
                                Save details <Icon name='save' />
                            </CoreButton>
                        </FloatingActionBar>
                    </>
                )}
            </FormWrapper>
            <CoreModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                title={`Are you sure you want to delete ${data?.name}?`}
                actionBar={<CoreButton type='danger'>Confirm deletion</CoreButton>}>
                    <WarningMessage>
                        This is a dangerous action and will make your business not return in search results.
                        <br />
                        This will also delete all information attached to the business including all locations, menus and location bookings.
                    </WarningMessage>
            </CoreModal>
        </>
    );
};

export default ParentBusinessDetails;

