import { QRCodeSVG } from "qrcode.react";
import { H3 } from "../../../../../components/Typography/Headings";
import { useBusiness } from "../../../../../hooks/useBusiness";

interface Props {
    locationId: string;
}

const QrReference = ({locationId}: Props) => {
    return (
        <>
            <H3>QR code used to view your menu by itself</H3>
            <QRCodeSVG size={300} value={'http://localhost:3000/standalone/' + locationId} />
        </>
    );
};

export default QrReference;