import React from 'react';
import { SupportTicketStatus } from '../../../../../api/api-definitions';
import styled from 'styled-components';
import { Constants } from '../../../../../constants';
import { Badge } from '@chakra-ui/react';

interface ComponentProps {
    status: SupportTicketStatus;
}

const BaseLabel = styled(Badge)`
  display: inline-block;
`

const Resolved = styled(BaseLabel)`
  background-color: #008600;
  color: white;
`

const WithCustomer = styled(BaseLabel)`
  background-color: #0092f9;
  color: white;
`

const WithUs = styled(BaseLabel)`
  background-color: ${props => props.theme.primary};
  color: white;
`

const WontDo = styled(BaseLabel)`
  background-color: grey;
  color: white;
`

const SupportTicketStatusLabel = ({ status }: ComponentProps) => {
  if (status === SupportTicketStatus.Resolved) return <Resolved>Resolved</Resolved>;
  if (status === SupportTicketStatus.WithCustomer) return <WithCustomer>With customer</WithCustomer>;
  if (status === SupportTicketStatus.WithUs) return <WithUs>With {Constants.businessName}</WithUs>;
  if (status === SupportTicketStatus.WontDo) return <WontDo>Won't do</WontDo>;
  return <></>;
};

export default SupportTicketStatusLabel;