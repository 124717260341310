import React from "react";
import { ErrorMessage } from "../Forms/Messaging";
import BugButton from "../../pages/BusinessDashboard/modules/Shared/bugButton";
import { SupportTicketService } from "../../services/SupportTicketService";
import { LoggingService } from "../../services/LoggingService";

interface State {
    hasError: boolean;
    error?: any;
}

interface Props {
    children: React.ReactNode;
    parentBusinessId?: number;
}

export default class DashboardErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, info) {
        LoggingService.Log(JSON.stringify({error, info}))
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <>
                <BugButton onClick={() => SupportTicketService.NewTicket({
                    id: undefined,
                    parentBusinessId: this.props.parentBusinessId,
                    businessId: null,
                    dateRaisedUtc: undefined,
                    priority: undefined,
                    status: undefined,
                    summary: 'Application crash',
                    devLogs: JSON.stringify(this.state.error)
                })} />
                <br />
                <br />
                <br />
                <ErrorMessage>Sorry, the application has encountered en error and is unable to continue. Please refresh the page or clear browser cache. If the problem persists, please contact us.</ErrorMessage>
            </>
        }

        return this.props.children;
    }
}