import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CoreButton from '../../Forms/Button';
import { WarningMessage, ErrorMessage } from '../../Forms/Messaging';
import Icon from '../../Media/Icon';
import BREAKPOINTS from '../../../config/breakpoints';
import { HEADING_FONT } from '../../../constants';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Modal as ChakraModal,
    ModalOverlay,
    ModalContent as ChakraModalContent,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Portal,
    DrawerFooter
} from '@chakra-ui/react';

interface ComponentProps {
    children?: any;
    isOpen?: boolean;
    title?: React.ReactNode;
    noTitleMarginBottom?: boolean;
    actionBar?: React.ReactNode;
    warning?: React.ReactNode;
    error?: React.ReactNode;
    onClose: () => void;
    hasCancel?: boolean;
    small?: boolean;
    mediumPanel?: boolean;
    headerTextColor?: string;
    headerBackgroundColor?: string;
    slimPanel?: boolean;
    noFooterFlex?: boolean;
    tall?: boolean;
    cancelText?: string;
    contentRef?: React.MutableRefObject<HTMLDivElement>;
}

const ModalWarning = styled(WarningMessage)`
    flex: 1;
    padding: 0.3rem 0.5rem !important;
    margin-top: 0.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    float: left;
    width: auto !important;
    text-align: start !important;
`

const ModalError = styled(ErrorMessage)`
    flex: 1;
    padding: 0.3rem 0.5rem !important;
    margin-top: 0.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    float: left;
    width: auto !important;
    text-align: start !important;
`

const ModalWarningBar = styled.div`
    flex: 1;
`

export const ModalContext = createContext<HTMLDivElement>(null);
export const ModalContentContext = createContext<HTMLDivElement>(null);

const customSidebarWidth = '80vw';

const CoreModal = ({
    title,
    actionBar,
    noTitleMarginBottom,
    small,
    tall,
    contentRef,
    warning,
    error,
    slimPanel,
    mediumPanel,
    headerTextColor,
    headerBackgroundColor,
    noFooterFlex,
    children = <></>,
    onClose,
    isOpen = true,
    hasCancel = true,
    cancelText = 'Cancel'
}: ComponentProps) => {
    const context = useContext(ModalContext);
    const portalRef = useRef<HTMLDivElement>(context)
    const drawerRef = useRef<HTMLDivElement>()
    const [active, setActive] = useState<boolean>(isOpen);

    const modalClose = () => {
        setActive(false)
        setTimeout(() => {
            onClose();
        }, 200);
    }

    if (!isOpen) return null;

    const isMobile = window.innerWidth < BREAKPOINTS.mobileLarge;

    const drawerWidth = isMobile ? '100vw' : slimPanel ? '40vw' : customSidebarWidth;

    if (!small) return (
        <Portal containerRef={portalRef.current ? portalRef : undefined}>
            <ModalContentContext.Provider value={contentRef?.current || drawerRef.current}>
                <Drawer onClose={onClose} isOpen={active}>
                    <DrawerOverlay />
                    <DrawerContent ref={drawerRef} w={drawerWidth} maxW={drawerWidth}>
                        <DrawerCloseButton color={headerTextColor} />
                        <DrawerHeader borderBottom='1px solid #d5d5d5' backgroundColor={headerBackgroundColor} color={headerTextColor}>{title}</DrawerHeader>
                        <DrawerBody paddingTop={noTitleMarginBottom ? 0 : undefined} ref={contentRef}>
                            {children}
                        </DrawerBody>
                        <DrawerFooter borderTop='1px solid #d5d5d5'>
                            {hasCancel && <>
                                <CoreButton outline onClick={modalClose} floatLeft>{cancelText}</CoreButton>
                                {!(error || warning) && <Spacer />}
                            </>}
                            {(error || warning) &&
                                <ModalWarningBar>
                                    {error && <ModalError>{error}</ModalError>}
                                    {warning && <ModalWarning>{warning}</ModalWarning>}
                                </ModalWarningBar>
                            }
                            {actionBar}
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </ModalContentContext.Provider>
        </Portal>
    )

    let modalWidth = 'xl';
    if (isMobile) {
        modalWidth = 'full'
    } else if (slimPanel) {
        modalWidth = 'sm'
    } else if (mediumPanel) {
        modalWidth = 'xl'
    }

    return (
        <ModalContentContext.Provider value={contentRef?.current || drawerRef.current}>
            <ChakraModal onClose={onClose} isOpen={isOpen} size={modalWidth} scrollBehavior='inside'>
                <ModalOverlay />
                <ChakraModalContent ref={drawerRef} minW={(slimPanel || mediumPanel) ? undefined : '80vw'} minH={tall ? '80vh' : undefined}>
                    <ModalHeader borderBottom='1px solid #d5d5d5'>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody paddingTop={noTitleMarginBottom ? 0 : undefined} ref={contentRef}>
                        {children}
                    </ModalBody>
                    <ModalFooter display={noFooterFlex ? 'block' : undefined} borderTop={children && children.length > 0 ? '1px solid #d5d5d5' : undefined}>
                        {hasCancel && <CoreButton outline onClick={modalClose} floatLeft>{cancelText}</CoreButton>}
                        <ModalWarningBar>
                            {error && <ModalError>{error}</ModalError>}
                            {warning && <ModalWarning>{warning}</ModalWarning>}
                        </ModalWarningBar>
                        {actionBar}
                    </ModalFooter>
                </ChakraModalContent>
            </ChakraModal>
        </ModalContentContext.Provider>
    )
};

const Spacer = styled.span`
    flex: 1;
`

export default CoreModal;