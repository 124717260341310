import { Input, NumberInput, NumberInputField, Select, Textarea } from "@chakra-ui/react";
import styled from "styled-components";

const base = `
    //padding: 0.5rem;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #d6d6d6;
    transition: all ease 0.5s;
    outline: none;

    &.error {
        border: 1px solid red;
    }

    &:focus {
        border-color: black;
    }
`

export const BaseFormReadonlyValue = styled.div`
    line-height: 2rem;
    margin: 0.5rem;
    flex: 1 1 auto;
`

const BaseInputButton = styled.div`
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    width: 2rem;
    text-align: center;
    cursor: pointer;
    flex: none;
    border-radius: 0.5rem;
    margin: 0.5rem;
`

export const BaseInputPrimaryButton = styled(BaseInputButton)`
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.primaryContrast};

    &:hover {
        opacity: 0.8;
    }
`

export const BaseInputCancelButton = styled(BaseInputButton)`
    border: 1px solid ${props => props.theme.negative};
    color: ${props => props.theme.negative};

    &:hover {
        opacity: 0.8;
    }
`

export const BaseNumberInputStyle = styled(NumberInputField)`
    ${base}
    
    &:disabled {
        background-color: #edebeb;
    }
`;

export const BaseInputStyle = styled(Input)`
    ${base}
    
    &:disabled {
        background-color: #edebeb;
    }
`;

export const BaseSelectStyle = styled(Select)`
    ${base}
`;

export const BaseIconStyle = styled.div`
    ${base}
    flex: 0;
    padding: 0 0.5rem;
    min-width: 3rem;
    line-height: 2.4rem;
    border-radius: 0.2rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #aeaeae !important;
`;

export const BaseInputWrapper = styled.div<{hasLeftIcon?: boolean}>`
    display: flex;

    ${props => props.hasLeftIcon && `
        select, input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-left: none  !important;
        }
    `}
`

export const BaseCalendarStyle= styled.div`
    input {
        ${base}
    }

    .react-datepicker__triangle {
        transform: translate(20px, 0px) !important;
    }

    .calendarContainerCustom > div:not(.plusMinusToggle) {
        width: 100%;
    }
`;

export const BaseLabelStyle = styled.label<{required?: boolean}>`
    margin-bottom: 0.5rem;
    display: block;

    &.required::after {
        content: ' *';
        color: red;
    }

    ${props => props.required ? `
        ::after {
            content: ' *';
            color: red;
        }
    ` : ''}
`

export const CheckboxLabelStyle = styled.label`
    padding-left: 0.5rem;

    &.required::after {
        content: ' *';
        color: red;
    }
`

export const BaseErrorMessage = styled.div`
    color: red;
    font-size: 0.9em;
`

export const BaseTextareaStyle = styled(Textarea)`
    ${base}
    height: auto !important;
`;