import React, { useState } from 'react';
import { FullClientModel } from '../../../../../api/api-definitions';
import CoreButton from '../../../../../components/Forms/Button';
import CoreModal from '../../../../../components/Layout/CoreModal';
import { StyledTextarea } from '../../../../../theme/input.styles';
import { ApiService } from '../../../../../api/api-connectors';
import { NotificationService } from '../../../../../services/NotificationService';
import styled from 'styled-components';
import Icon from '../../../../../components/Media/Icon';
import { useBusiness } from '../../../../../hooks/useBusiness';
import useParentBusiness from '../../../../../hooks/useParentBusiness';
import { InfoMessage } from '../../../../../components/Forms/Messaging';

interface ComponentProps {
    client: FullClientModel;
    updateClient: (client: FullClientModel) => void;
    parentId: number;
    businessId: number;
}

const EditNotesModal = ({ client, parentId, businessId, updateClient }: ComponentProps) => {
    const [loaded, parentBusiness] = useParentBusiness();
    const [notes, setNotes] = useState(client.notes);
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);

    const closeModal = () => {
        setNotes(client.notes);
        setOpen(false)
    }

    const save = () => {
        setSaving(true)
        ApiService.businessClient.UpdateClientNotes__POST({...client, notes, businessId}, parentId).then((response) => {
            if (response.success) {
                NotificationService.Confirm('Client notes updated');
                updateClient({...client, notes});
                setOpen(false)
                setSaving(false)
            } else {
                NotificationService.Error('Unable to save client notes');
                setSaving(false)
            }
        }).catch(() => {
            NotificationService.Error('Unable to save client notes');
            setSaving(false)
        })
    }

    return (
        <>
            {open &&
                <CoreModal title='Edit client notes' onClose={closeModal} small actionBar={<CoreButton requesting={saving} disabled={saving} onClick={save}>Save</CoreButton>}>
                    {parentBusiness.locations.length > 1 && <Info>These notes persist across all {parentBusiness.name} locations.</Info>}
                    <StyledTextarea rows={parentBusiness.locations.length > 1 ? 8 : 10} disabled={saving} value={notes} onChange={e => setNotes(e.target.value)} />
                </CoreModal>
            }
            <IconButton onClick={() => setOpen(true)}><Icon name='pencil' duo /></IconButton>
        </>
    );
};

const Info = styled(InfoMessage)`
    margin-bottom: 0.5rem;
`

const IconButton = styled(CoreButton)`
    width: 2rem;
    span {
        padding: 0;
    }
`

export default EditNotesModal;