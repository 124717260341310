import React, { useEffect, useRef, useState } from 'react';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledCurrencyInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';
import Checkout from '../../../../../../components/Payments/Checkout';
import { ApiService } from '../../../../../../api/api-connectors';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import styled from 'styled-components';
import { NotificationService } from '../../../../../../services/NotificationService';
import { BaseActiveBooking, BaseBookingPayment, BaseDepositRule, DepositPaymentType, DepositRuleType } from '../../../../../../api/api-definitions';
import { BoxShadowStyle } from '../../../../../../theme';
import Icon from '../../../../../../components/Media/Icon';
import { SuccessBox, SuccessIcon } from '.';
import { InfoMessage } from '../../../../../../components/Forms/Messaging';
import { ConvertCurrency } from '../../../../../../utils/currency-helper';
import { H2, H3 } from '../../../../../../components/Typography/Headings';

interface ComponentProps {
    booking: BaseActiveBooking;
    onClose: (payment: BaseBookingPayment) => void;
    lockForm?: boolean;
    amount?: number;
    takeNow?: boolean;
    depositRuleToApply?: BaseDepositRule;
}

const ManualPayment = ({ booking, onClose, depositRuleToApply, lockForm = false, amount = undefined, takeNow = false }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const [charge, setCharge] = useState<number>(amount);
    const [chargeDeposit, setChargeDeposit] = useState<number>(amount);
    const [chargeNow, setChargeNow] = useState(takeNow);
    const [chargeNowDeposit, setChargeNowDeposit] = useState(takeNow);
    const [loading, setLoading] = useState(false)
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [depositRule, setDepositRule] = useState<BaseDepositRule>(depositRuleToApply);
    const paymentObject = useRef<BaseBookingPayment>();

    useEffect(() => {
        // @ts-ignore
        window.processPaymentConfirmation = processPaymentConfirmation;

        if (!lockForm && !depositRuleToApply) {
            ApiService.bookings.GetDepositRuleForBooking__POST(booking).then((rule) => {
                setDepositRule(rule)
                setChargeDeposit(rule.amount * (rule.type == DepositRuleType.PerPerson ? booking.guests : 1))
                setChargeNowDeposit(rule.paymentType == DepositPaymentType.TakeNow)
            })
        }
    }, [])

    const createCharge = (applyDepositRule?: boolean) => {
        setLoading(true);
        ((applyDepositRule ? chargeNowDeposit : chargeNow) ? ApiService.checkout.CreateInHousePaymentIntent__POST : ApiService.checkout.CreateInHouseSetupIntent__POST)({
            clientId: booking.clientId,
            businessId: business.id,
            amount: (applyDepositRule ? chargeDeposit : charge)
        }).then((response) => {
            setLoading(false)
            paymentObject.current = {
                businessId: booking.businessId,
                clientId: booking.clientId,
                bookingId: booking.id,
                id: 0,
                intentId: '',
                setupIntent: !(applyDepositRule ? chargeNowDeposit : chargeNow),
                amount: (applyDepositRule ? chargeDeposit : charge),
                currency: business.currencyCode,
                fufilled: (applyDepositRule ? chargeNowDeposit : chargeNow),
                depositRuleId: applyDepositRule ? depositRule.id : undefined
            }
            window.open(`/dashboard/make-payment?intent=${response.info}&payNow=${chargeNow}&account=${business.stripeAccountKey}`, '_blank')
        })
    }

    const processPaymentConfirmation = (code: string) => {
        if (code) {
            paymentObject.current.intentId = code;
            ApiService.bookingPayment.Add__PUT(paymentObject.current).then(() => {
                setConfirmed(true);
            })
        } else {
            NotificationService.Error('Payment processing failed');
        }
    }

    const getChargeableText = () => {
        if (depositRule.paymentType == DepositPaymentType.TakeNow) return 'now';
        if (depositRule.paymentType == DepositPaymentType.TakeOnCancelAndNoShow) return 'when the customer cancels or no-shows';
        return 'when the customer no-shows';
    }

    return (
        <div>
            {!confirmed && depositRule &&
                <>
                    <H3>Deposit rule</H3>
                    <InfoMessage>
                        <div>
                            Based on payment rule setup, the recommended payment amount is&nbsp;<strong>{ConvertCurrency({ code: business.currencyCode, amount: depositRule.amount * (depositRule.type == DepositRuleType.PerPerson ? booking.guests : 1) })}</strong>&nbsp;based on&nbsp;<strong>{ConvertCurrency({ code: business.currencyCode, amount: depositRule.amount })} {depositRule.type == DepositRuleType.PerPerson ? 'per person' : 'per booking'}</strong>&nbsp;chargeable&nbsp;<strong>{getChargeableText()}</strong>
                        </div>
                    </InfoMessage>
                    <br />
                    <Row>
                        {depositRuleToApply &&
                            <Column size={3}>
                                <CoreButton type='warning' disabled={loading || isNullOrWhitespace(charge)} onClick={onClose}>Skip taking card details</CoreButton>
                            </Column>
                        }
                        <Column size={3}>
                            <CoreButton disabled={loading} onClick={() => createCharge(true)}>Apply deposit rule</CoreButton>
                        </Column>
                    </Row>
                    <br />
                    <br />
                    <br />
                </>
            }
            {!depositRuleToApply && !confirmed && <>
                <H3>Custom payment amount</H3>
                <Row>
                    <Column size={4}>
                        <StyledCurrencyInput value={charge} disabled={loading || lockForm} onChange={(e) => setCharge(+e.target.value)} currencySymbol='£' label='Payment amount' />
                    </Column>
                    <Column size={2} checkboxInput>
                        <Checkbox label='Charge now' disabled={loading || lockForm} inputName='chargeNow' asToggle checked={chargeNow} unlink onChange={() => setChargeNow(!chargeNow)} />
                    </Column>
                </Row>
                {!lockForm && !loading &&
                    <Row>

                        <Column size={3}>
                            <CoreButton disabled={loading || isNullOrWhitespace(charge)} onClick={() => createCharge()}>Create charge</CoreButton>
                        </Column>
                    </Row>
                }
                {lockForm &&
                    <Row>
                        <Column size={3}>
                            <CoreButton type='warning' disabled={loading || isNullOrWhitespace(charge)} onClick={onClose}>Skip taking card details</CoreButton>
                        </Column>
                        <Column size={3}>
                            <CoreButton disabled={loading || isNullOrWhitespace(charge)} onClick={() => createCharge()}>Take card details</CoreButton>
                        </Column>
                    </Row>
                }
            </>}
            {confirmed &&
                <SuccessBox>
                    <SuccessIcon name='check' />
                    <br />
                    <br />
                    <br />
                    Payment confirmed
                    <br />
                    <br />
                    <CoreButton onClick={() => onClose(paymentObject.current)}>Back to booking</CoreButton>
                </SuccessBox>
            }
        </div>
    );
};

export default ManualPayment;