import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import Icon from '../../../../../../components/Media/Icon';
import { BoxShadowStyle, defaultColors } from '../../../../../../theme';
import { useTimer } from '../../../../../../hooks/useTimer';
import { ApiService } from '../../../../../../api/api-connectors';
import { LightBookingNotification, NotificationsCountParameters } from '../../../../../../api/api-definitions';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../../services/NotificationService';
import { InfoMessage } from '../../../../../../components/Forms/Messaging';
import Loader from '../../../../../../components/Layout/Loader';
import InfoButton from '../../../../../../components/Cta/InfoButton';
import CoreButton from '../../../../../../components/Forms/Button';
import { TIMEFORMAT, formatDate } from '../../../../../../utils/date-helpers';
import { Badge } from '@chakra-ui/react';


interface ComponentProps {
    disabled: boolean;
}

const Counter = styled.div`
    position: absolute;
    background-color: ${props => props.theme.negative};
    color: ${props => props.theme.negativeContrast};
    border-radius: 50%;
    top: -8px;
    left: -8px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
`

const ControlButton = styled.div<{ floatRight?: boolean; primary?: boolean; margin?: boolean; }>`
    ${props => props.floatRight && 'float: right;'}
    ${props => props.margin && 'margin-left: 0.5rem;'}
    border: 1px solid ${props => props.theme.borderColor};
    padding: 0 0.5rem;
    text-align: center;
    border-radius: 3px;
    height: 3rem;
    min-width: 3rem;
    line-height: 3rem;
    cursor: pointer;
    transition: all ease 0.5s;
    display: inline-block;
    position: relative;

    ${props => props.primary && `
        background-color: ${props.theme.secondary};
        color: ${props.theme.secondaryContrast};

        &:hover {
            background-color: ${props.theme.primary};
            color: ${props.theme.primaryContrast};
        }
    `}

    &:hover {
        border: 1px solid #000;
    }
    @media (max-width: 1280px) {
        max-width: 3rem;
        overflow: hidden;
    }
`

const NotificationsModalButton = styled(ControlButton) <{ disabled: boolean; }>`
    overflow: visible !important;
    margin-right: auto;
    margin-left: auto;
    ${props => props.disabled && 'opacity: 0.5;'}
`

const NotificationItem = styled.div<{ seen?: boolean }>`
    ${BoxShadowStyle}
    margin-bottom: 1rem;
    text-align: left;
    padding: 0.5rem 1rem;
    position: relative;
    white-space: pre-line;
    ${props => !props.seen && `
        padding-right: 1.5rem;

        &:before {
            content: '';
            position: absolute;
            right: 0.6rem;
            top: calc(50% - 5px);
            background-color: ${props.theme.negative};
            height: 10px;
            width: 10px;
            border-radius: 50%;
        }
    `}
    border-left: 5px solid ${props => props.theme.secondary};
`

const NotificationsModal = ({ disabled }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const timer = useTimer(() => refreshCount(), 60000);
    const [newCount, setNewCount] = useState(0);
    const countRef = useRef(0)
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<LightBookingNotification[]>();
    const modalRef = useRef<HTMLDivElement>()

    useEffect(() => {
        refreshCount().then(() => timer(true));
    }, [])

    const refreshCount = () => {
        return ApiService.bookingNotification.GetCount__POST({
            businessId: business.id,
            timezone: business.locationAddress.timeZone
        } as NotificationsCountParameters).then((response) => {
            const newResponse = response.newNotificationsCount || 0;
            if (newResponse != 0 && newResponse != countRef.current) {
                NotificationService.Info('You have new booking notifications');
            }
            if (open && newResponse != 0 && newResponse != countRef.current) {
                getNotificationList()
            } else {
                setNewCount(newResponse)
                countRef.current = newResponse;
            }
        }).catch(() => {
            NotificationService.Error('Could not fetch notification count')
        })
    }

    const getNotificationList = () => {
        setData(undefined)
        ApiService.bookingNotification.List__GET(business.id).then(response => {
            setData(response);
            setNewCount(0)
            countRef.current = 0;
        }).catch(() => {
            NotificationService.Error('Could not fetch notification list')
        })
    }

    const onOpen = () => {
        if (disabled) return;
        getNotificationList()
        setOpen(true)
    }

    return (
        <>
            {open &&
                <CoreModal slimPanel onClose={() => setOpen(false)} title={<>
                    Today's notifications
                    <InfoButton portalProps={{ containerRef: modalRef }}>
                        Here you will see changes made by your guests online that affect today's bookings.
                        If any of today's bookings are cancelled, amended, or created online, you will be notified here.
                    </InfoButton>
                </>} cancelText='Close' contentRef={modalRef}>
                    {data && data.map((item: LightBookingNotification) => (
                        <NotificationItem seen={item.seen}>
                            <div><Badge style={{float: 'right'}} colorScheme='cyan'>{formatDate(item.dateAdded, TIMEFORMAT)}</Badge></div>
                            <div dangerouslySetInnerHTML={{ __html: item.text }} />
                            {!!item.supportTicketId && <CoreButton to={`/dashboard/support?ref=${item.supportTicketId}`}>View support desk</CoreButton>}
                        </NotificationItem>
                    ))}
                    {data && data.length == 0 &&
                        <InfoMessage>No notifications to show</InfoMessage>
                    }
                    {!data && <Loader />}
                </CoreModal>
            }
            <NotificationsModalButton onClick={onOpen} disabled={disabled}>
                <Icon name='bell' />
                {newCount > 0 && <Counter>{newCount}</Counter>}
            </NotificationsModalButton>
        </>
    );
};

export default NotificationsModal;