import React from 'react';
import styled from 'styled-components';

interface ComponentProps {
    name: string;
    children: React.ReactNode;
}

const Container = styled.div`
    border: 1px solid ${props => props.theme.borderColor};
    margin-bottom: 1rem;
`

const Title = styled.div`
    border-bottom: 1px solid ${props => props.theme.borderColor};
    padding: 0.3rem 1rem;
    font-weight: bold;
    background-color: #d5d5d5;
    color: #393939;
    font-size: 0.9rem;
`

const Body = styled.div`
    padding: 1rem;
`

const SectionGroup = ({ name, children }: ComponentProps) => {
  return (
    <Container>
        <Title>{name}</Title>
        <Body>
            {children}
        </Body>
    </Container>
  );
};

export default SectionGroup;