import React, { useState } from 'react';
import { BaseMenuSection, GenericResponse } from '../../../../../../api/api-definitions';
import FormWrapper from '../../../../../../components/Forms/FormWrapper';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledTextInput, StyledTextarea } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';
import Icon from '../../../../../../components/Media/Icon';
import { ApiService } from '../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../services/NotificationService';
import { FloatingActionBar } from '../../../../../../components/Layout/FloatingActionBar';
import Loader from '../../../../../../components/Layout/Loader';
import { clone } from 'lodash';
import { MenuSectionDescriptionSize, MenuSectionNameSize } from '../../../../../../constants';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';

interface ComponentProps {
    onSubmit: (item: BaseMenuSection) => void;
    defaultData: BaseMenuSection;
}

const NewSectionForm = ({ onSubmit, defaultData }: ComponentProps) => {
    const [data, setData] = useState<BaseMenuSection>(clone(defaultData));
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);

    const save = () => {
        setSaving(true)
        ApiService.menuSection.Add__PUT(data).then((response: GenericResponse) => {
            data.id = parseInt(response.info);
            NotificationService.ConfirmOrError('Added new menu section', 'Failed to add menu section', response.success);
            onSubmit(data)
            close()
            const container: any = document.getElementById('pageContent');
            container.scrollToBottom()
        }).catch(() => {
            NotificationService.Error('Failed to add menu section');
        }).finally(() => {
            setSaving(false)
        })
    }

    const close = () => {
        setOpen(false)
        setData(clone(defaultData))
    }

    return (
        <>
            <DashboardAddButton onClick={() => setOpen(true)}>
                Add new section
            </DashboardAddButton>
            {open &&
                <FormWrapper onUpdate={formData => setData({ ...data, ...formData })}>
                    {({ id, valid }) => (
                        <CoreModal isOpen small tall title='Add new menu section' onClose={close} actionBar={
                            <CoreButton onClick={save} requesting={saving} disabled={saving}><Icon name='save' /> Add section</CoreButton>
                        }>
                            <Row hidden={saving}>
                                <Column size={6} tablet={12}>
                                    <StyledTextInput max={MenuSectionNameSize} value={data?.name} model='name' label='Name' />
                                </Column>
                                <Column size={12}>
                                    <StyledTextarea maxLength={MenuSectionDescriptionSize} value={data?.description} model='description' label='Description' />
                                </Column>
                            </Row>
                            {saving && <Loader />}
                        </CoreModal>
                    )}
                </FormWrapper>
            }
        </>
    );
};

export default NewSectionForm;