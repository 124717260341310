import React, { useState } from 'react';
import { Row, Column } from '../../../../../../components/Layout/Grid';
import { StyledTextInput } from '../../../../../../theme/input.styles';
import styled from 'styled-components';
import CoreButton from '../../../../../../components/Forms/Button';
import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { WarningMessage } from '../../../../../../components/Forms/Messaging';
import { createUUID } from '../../../../../../utils/data-helpers';

interface ComponentProps {
    value: string;
    onChange: (value: string) => void;
}

const CustomFieldValueSelector = ({ value, onChange }: ComponentProps) => {
    const [inputvalue, setInputValue] = useState('');

    const onAdd = () => {
        if (isNullOrWhitespace(value)) {
            onChange(inputvalue);
        } else {
            const newValue = value.split('|');
            newValue.push(inputvalue);
            onChange(newValue.join('|'))
        }
        setInputValue('');
    }

    const onRemove = (index: number) => {
        const newValue = value.split('|');
        newValue.splice(index, 1);
        onChange(newValue.join('|'))
    }

    return (
        <>
            <Row>
                <Column size={9}>
                    <StyledTextInput label='Values' value={inputvalue} onChange={(e) => setInputValue(e.target.value)} />
                </Column>
                <Column size={3}>
                    <FieldButton onClick={onAdd}>Add</FieldButton>
                </Column>
            </Row>
            {isNullOrWhitespace(value) &&
                <WarningMessage>Please add at least 1 value</WarningMessage>
            }
            {!isNullOrWhitespace(value) && value.split('|').map((val, index) => (
                <Tag
                    marginRight='1'
                    marginBottom='1'
                    size='lg'
                    borderRadius='full'
                    variant='solid'
                    colorScheme='blue'
                    key={createUUID()}
                    onClick={() => onRemove(index)}
                >
                    <TagLabel>{val}</TagLabel>
                    <TagCloseButton />
                </Tag>
            ))}
        </>
    );
};

const FieldButton = styled(CoreButton)`
    margin-top: 2rem;
`

export default CustomFieldValueSelector;