import { ToastId, UseToastOptions } from "@chakra-ui/react";
import { NotificationType } from "../components/Forms/Notifications/Notification";

export class NotificationService {
    public static _toastCmd: (options?: UseToastOptions) => ToastId = null;
    private static _handler: Function = null;
    private static _audio = new Audio('/assets/sounds/ding.mp3');

    static Confirm(text: string) {
        if (this._handler) {
            this._handler(text, NotificationType.Success)
        }
    }

    static Info(text: string) {
        if (this._handler) {
            this._handler(text, NotificationType.Info)
        }
        this._audio.play();
    }

    static Error(text: string) {
        if (this._handler) {
            this._handler(text, NotificationType.Error)
        }
    }

    static ConfirmOrError(confirmText: string, errorText: string, success: boolean) {
        if (this._handler) {
            success ?
            this._handler(confirmText, NotificationType.Success) :
            this._handler(errorText, NotificationType.Error)
        }
    }

    static RegisterHandler(handler: Function) {
        this._handler = handler;
    }
}