import { useEffect, useState } from 'react';
import DataTable, { DataTableItem } from '../../../../../../../components/Layout/Datatable';
import { ApiService } from '../../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../../services/NotificationService';
import { DISPLAY_DATE_TIME_FORMAT, createMomentFromValue, formatDate } from '../../../../../../../utils/date-helpers';
import Loader from '../../../../../../../components/Layout/Loader';
import { InfoMessage } from '../../../../../../../components/Forms/Messaging';
import { LightBookingHistory, UpdatedByUser } from '../../../../../../../api/api-definitions';

interface ComponentProps {
    bookingId: number;
}

const BookingHistory = ({ bookingId }: ComponentProps) => {
    const [data, setData] = useState<LightBookingHistory[]>();

    useEffect(() => {
        ApiService.auditLog.GetBookingHistory__GET(bookingId).then(setData).catch(() => {
            NotificationService.Error('Unable to get booking history');
            setData([])
        })
    }, [])

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        data.forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'Date/Time': {
                        value: formatDate(item.dateHappened, DISPLAY_DATE_TIME_FORMAT),
                        sortValue: createMomentFromValue(item.dateHappened).unix()
                    },
                    'User': {
                        value: item.updatedBy == UpdatedByUser.Business ? 'Business' : 'Guest',
                    },
                    'Description': {
                        value: <div style={{whiteSpace: 'pre-line'}}>{item.description}</div>,
                    },
                },
            }
            items.push(dataItem);
        });
        return items;
    }

    if (!data) return <Loader />;

    if (data && data.length == 0) return <InfoMessage>No booking history to show</InfoMessage>

    return (
        <DataTable defaultSort='Date/Time' data={gentable()} noResultsMessage='No booking history to show' />
    );
};

export default BookingHistory;