import React, { useState } from 'react';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { Constants } from '../../../../../../constants';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { BaseEventAddress, ExtendedBaseExperience } from '../../../../../../api/api-definitions';
import { clone, cloneDeep } from 'lodash';
import { NotificationService } from '../../../../../../services/NotificationService';
import CoreButton from '../../../../../../components/Forms/Button';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledTextInput } from '../../../../../../theme/input.styles';
import { ApiService } from '../../../../../../api/api-connectors';

interface ComponentProps {
    typeName: string;
    data: ExtendedBaseExperience;
}

const DefaultModalData: BaseEventAddress = { id: undefined, longitude: undefined, latitude: undefined };

const ExperienceLocation = ({ typeName, data }: ComponentProps) => {
    const [businessLoaded, businessData] = useBusiness();
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [modalData, setModalData] = useState<BaseEventAddress>(clone(DefaultModalData));
    const [placeId, setPlaceId] = useState<string>();
    const [manualAddress, setManualAddress] = useState(false);
    const [saving, setSaving] = useState(true)

    const closeModal = () => {
        setAddModalOpen(false)
        setModalData(clone(DefaultModalData))
        setManualAddress(false)
        setPlaceId(undefined)
    }

    const setValue = (googleData) => {
        const id = googleData.value.place_id;
        geocodeByPlaceId(id)
            .then(results => {
                const newData = cloneDeep(modalData)
                const location = results[0].geometry.location;
                const addressArray = results[0].address_components;
                newData.formattedName = googleData.value.structured_formatting.main_text;
                newData.road = addressArray.find(x => x.types.find(y => y == 'route'))?.long_name;
                if (!newData.road) {
                    newData.road = addressArray.find(x => x.types.find(y => y == 'sublocality'))?.long_name;
                }
                newData.city = addressArray.find(x => x.types.find(y => y == 'postal_town'))?.long_name;
                newData.county = addressArray.find(x => x.types.find(y => y == 'administrative_area_level_1'))?.long_name;
                newData.country = addressArray.find(x => x.types.find(y => y == 'country'))?.long_name;
                newData.postCode = addressArray.find(x => x.types.find(y => y == 'postal_code'))?.long_name;
                setPlaceId(id);
                newData.longitude = location.lng();
                newData.latitude = location.lat();
                setModalData(newData);
            })
            .catch(error => {
                console.error(error)
                NotificationService.Error('Failed to process API request')
            });
    }

    const send = () => {
        ApiService.googleaddress.AddEventAddress__PUT({
            eventId: data.id,
            manualAddress: modalData
        })
    }

    return (
        <div>
            {addModalOpen &&
                <CoreModal title='Add location' onClose={closeModal} small mediumPanel actionBar={<CoreButton onClick={send}>Yup.</CoreButton>}>
                    {!manualAddress &&
                        <GooglePlacesAutocomplete
                            apiKey={Constants.googleMapsApiKey}
                            autocompletionRequest={{
                                types: ['restaurant', 'cafe'],
                                componentRestrictions: {
                                    country: ['uk'],
                                }
                            }}
                            selectProps={{
                                onChange: setValue,
                                placeholder: 'Start typing for suggestions...'
                            }}
                            withSessionToken
                        />
                    }
                    {!manualAddress && placeId &&
                        <>
                            <br />
                            <br />
                            {modalData.formattedName}<br />
                            {modalData.road}<br />
                            {modalData.city}<br />
                            {modalData.county}<br />
                            {modalData.country}<br />
                            {modalData.postCode}<br />
                            <CoreButton icon='pencil' noOutline onClick={() => setManualAddress(true)}>Not the right details? Enter manually.</CoreButton>
                            <br /><br />
                        </>
                    }
                    {!manualAddress && !placeId &&
                        <>
                            <br />
                            <CoreButton icon='pencil' noOutline onClick={() => setManualAddress(true)}>Can't find the address? Enter manually.</CoreButton>
                            <br /><br />
                        </>
                    }
                    {manualAddress &&
                        <Row>
                            <Column size={6}>
                                <StyledTextInput label='Business name' value={modalData.formattedName} />
                            </Column>
                            <Column size={6}>
                                <StyledTextInput required label='Street name' value={modalData.road} />
                            </Column>
                            <Column size={6}>
                                <StyledTextInput required label='City' value={modalData.city} />
                            </Column>
                            <Column size={6}>
                                <StyledTextInput label='County' value={modalData.county} />
                            </Column>
                            <Column size={6}>
                                <StyledTextInput required label='Country' value={modalData.country} />
                            </Column>
                            <Column size={6}>
                                <StyledTextInput required label='Post code' value={modalData.postCode} />
                            </Column>
                        </Row>
                    }
                </CoreModal>
            }
            {businessData.locationAddress?.formattedAddress}
            <br />
            <br />
            <DashboardAddButton onClick={() => setAddModalOpen(true)}>Link {typeName} to a different address</DashboardAddButton>
        </div>
    );
};

export default ExperienceLocation;