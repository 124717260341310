import React, { useEffect, useState } from 'react';
import Icon from '../../../../../../../components/Media/Icon';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import { BaseActiveBooking, BaseBookingTableSetup, BaseShiftNote, BookingStatus } from '../../../../../../../api/api-definitions';
import styled from 'styled-components';
import { TIMEFORMAT, createMomentFromValue, formatDate } from '../../../../../../../utils/date-helpers';
import { Column, Row } from '../../../../../../../components/Layout/Grid';
import { isNullOrWhitespace } from '../../../../../../../utils/text-helpers';
import { InfoMessage } from '../../../../../../../components/Forms/Messaging';
import CoreButton from '../../../../../../../components/Forms/Button';
import { StyledTextInput } from '../../../../../../../theme/input.styles';
import { ApiService } from '../../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../../services/NotificationService';
import { H3 } from '../../../../../../../components/Typography/Headings';
import useOffline from '../../../../../../../hooks/useOffline';

interface ComponentProps {
    bookings: BaseActiveBooking[];
    tables: BaseBookingTableSetup[];
    selectedDate: string;
    businessId: number;
    refreshData: () => void;
}

const Note = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${props => props.theme.borderColor};
`

const ShiftNotesInfoMessage = styled(InfoMessage)`
    margin-bottom: 1rem;
`

const ShiftNotesInput = styled(StyledTextInput)`
    input {
        text-align: left !important;
    }
`

const NotesContainer = styled.div`
    width: 95%;
    text-align: left !important;
`

const Counter = styled.div`
    position: absolute;
    background-color: ${props => props.theme.negative};
    color: ${props => props.theme.negativeContrast};
    border-radius: 50%;
    top: -8px;
    left: -8px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
`

const ControlButton = styled.div<{ floatRight?: boolean; primary?: boolean; margin?: boolean; }>`
    ${props => props.floatRight && 'float: right;'}
    ${props => props.margin && 'margin-left: 0.5rem;'}
    border: 1px solid ${props => props.theme.borderColor};
    padding: 0 0.5rem;
    text-align: center;
    border-radius: 3px;
    height: 3rem;
    min-width: 3rem;
    line-height: 3rem;
    cursor: pointer;
    transition: all ease 0.5s;
    display: inline-block;
    position: relative;

    ${props => props.primary && `
        background-color: ${props.theme.secondary};
        color: ${props.theme.secondaryContrast};

        &:hover {
            background-color: ${props.theme.primary};
            color: ${props.theme.primaryContrast};
        }
    `}

    &:hover {
        border: 1px solid #000;
    }
    @media (max-width: 1280px) {
        max-width: 3rem;
        overflow: hidden;
    }
`

const ShiftNotesButton = styled(ControlButton)`
    overflow: visible !important;
    margin-right: auto;
    margin-left: auto;
`

interface CustomNote {
    note: React.ReactNode;
    time: number;
}

const ShiftNotes = ({ bookings, tables, selectedDate, businessId, refreshData }: ComponentProps) => {
    const offline = useOffline();
    const [open, setOpen] = useState(false);
    const [newNoteText, setNewNoteText] = useState('');
    const [notes, setNotes] = useState<BaseShiftNote[]>();

    useEffect(() => {
        load()
    }, [selectedDate])

    const load = () => {
        if (offline) {
            setNotes([])
        } else {
            ApiService.shiftNotes.List__GET(businessId, selectedDate).then(setNotes);
        }
    }

    const addNote = () => {
        ApiService.shiftNotes.Add__PUT({
            businessId,
            bookingDate: selectedDate,
            note: newNoteText,
            id: 0,
        }).then(() => {
            NotificationService.Confirm('Shift note added')
            setNewNoteText('')
            load()
        })
    }

    const deleteNote = (noteToDelete: BaseShiftNote) => {
        ApiService.shiftNotes.Delete__DELETE(noteToDelete).then(() => {
            NotificationService.Confirm('Shift note deleted')
            setNotes(notes.filter(note => note.id !== noteToDelete.id))
        })
    }

    const getBookingActions = () => {
        const tableNotes: CustomNote[] = [];

        bookings.forEach(booking => {
            if (!booking.isHeld && booking.status === BookingStatus.Pending || booking.status === BookingStatus.Seated) {
                if (booking.status === BookingStatus.Pending && booking.tables.length > 1) tableNotes.push({
                    time: createMomentFromValue(booking.startDateTime).toDate().getTime(),
                    note: <>Tables <strong>({booking.tables.map(x => tables.find(t => x === t.id).tableName).join(', ')})</strong> need to be joined for a booking at <strong>{formatDate(booking.startDateTime, TIMEFORMAT)}</strong> to seat <strong>{booking.client.firstName} {booking.client.surname ? booking.client.surname : ''}</strong> for <strong>{booking.guests}</strong> people.</>
                })
                if (!isNullOrWhitespace(booking.specialRequests)) {
                    tableNotes.push({
                        time: createMomentFromValue(booking.startDateTime).toDate().getTime(),
                        note: <>
                            <strong>{booking.client.firstName} {booking.client.surname ? booking.client.surname : ''}</strong> at <strong>{formatDate(booking.startDateTime, TIMEFORMAT)}</strong> has a special request: <br />
                            <strong>"{booking.specialRequests}"</strong>
                        </>
                    })
                }
                if (!isNullOrWhitespace(booking.notes)) {
                    tableNotes.push({
                        time: createMomentFromValue(booking.startDateTime).toDate().getTime(),
                        note: <>
                            <strong>{booking.client.firstName} {booking.client.surname ? booking.client.surname : ''}</strong> at <strong>{formatDate(booking.startDateTime, TIMEFORMAT)}</strong> has a booking note: <br />
                            <strong>"{booking.notes}"</strong>
                        </>
                    })
                }
            }
        });
        return tableNotes;
    }

    const bookingActions = getBookingActions();

    return (
        <>
            {open &&
                <CoreModal onClose={() => setOpen(false)} title='Shift notes' cancelText='Close'>
                    <NotesContainer>
                        <Row reverseMobile>
                            <Column size={6} mobile={12} noMarginBottom>
                                <H3>Booking notes</H3>
                                {bookingActions.length < 1 &&
                                    <ShiftNotesInfoMessage>
                                        No booking notes to show
                                    </ShiftNotesInfoMessage>
                                }
                                {bookingActions.sort((a, b) => a.time - b.time).map((action, index) => (
                                    <Note key={`note-${index}`}>
                                        <Row>
                                            <Column noMarginBottom size={1}><Icon name='memo-pad' /></Column>
                                            <Column noMarginBottom size={11}>{action.note}</Column>
                                        </Row>
                                    </Note>
                                ))}
                            </Column>
                            <Column size={6} mobile={12} noMarginBottom>
                                <H3>Other notes</H3>
                                {notes.length < 1 &&
                                    <ShiftNotesInfoMessage>
                                        No other notes to show
                                    </ShiftNotesInfoMessage>
                                }
                                <Row>
                                    <Column size={8} mobile={12}>
                                        <ShiftNotesInput value={newNoteText} onChange={event => setNewNoteText(event.target.value)} />
                                    </Column>
                                    <Column size={4} mobile={12}>
                                        <CoreButton full disabled={isNullOrWhitespace(newNoteText)} onClick={addNote}><Icon customMargin={0.3} name='plus' />&nbsp;Add note</CoreButton>
                                    </Column>
                                </Row>
                                {notes.map((note, index) => (
                                    <Note key={`note-${index}`}>
                                        <Row>
                                            <Column size={1} noMarginBottom><Icon name='memo-pad' /></Column>
                                            <Column size={10} noMarginBottom>{note.note}</Column>
                                            <Column size={1} noMarginBottom><CoreButton type='danger' onClick={() => deleteNote(note)}><Icon name='trash' /></CoreButton></Column>
                                        </Row>
                                    </Note>
                                ))}
                            </Column>
                        </Row>
                    </NotesContainer>
                </CoreModal>
            }
            <ShiftNotesButton onClick={() => setOpen(true)}>
                <Icon name='memo-pad' />
                {((bookingActions?.length || 0) + (notes?.length || 0)) > 0 && <Counter>{(bookingActions?.length || 0) + (notes?.length || 0)}</Counter> }
            </ShiftNotesButton>
        </>
    );
};

export default ShiftNotes;