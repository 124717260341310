import React, { useState } from 'react';
import styled from 'styled-components';
import { Node } from './nodes';
import BaseDocument from './baseDocument';
import { DragAndDropLocation } from '../../pages/BusinessDashboard/modules/ParentBusinessModules/DocumentBuilder';
import Icon from '../../components/Media/Icon';

export type ElementTypes = 'text' | 'image' | 'row' | 'page';

export interface DocumentContentNode {
    Element: ElementTypes;
    Children?: DocumentContentNode[];
    Values?: { [key: string]: any };
    Id: string;
}

export interface BaseDocumentProps {
    data: DocumentContentNode[];
    basePath?: string;
}

const NoPointer = styled.div`
    pointer-events: none;
    touch-action: none;
`

const HoverContainer = styled.div<{ hoverActive?: boolean; clickable?: boolean, selected?: boolean }>`
    position: relative;
    border: 1px solid transparent;
    ${props => props.hoverActive && 'border: 1px dashed #519dc7 !important;'}
    ${props => props.selected && 'border: 1px solid #519dc7 !important;'}
    
    ${props => props.clickable && `
        cursor: pointer;
    `}
`

export const Placeholder = styled.div<{ small?: boolean, collapsed?: boolean; }>`
    background-color: ${props => props.theme.borderColor};
    text-align: center;
    ${props => props.small ? 'padding: 0.8rem;' : 'padding: 3rem;'}
    overflow: hidden;
    transition: all 0.5s ease;
    ${props => props.collapsed && 'height: 0; padding: 0;'}
    font-size: 0.8rem;
`

export const AddPlaceholder = styled.div<{ collapsed?: boolean; }>`
    background-color: ${props => props.theme.borderColor};
    text-align: center;
    overflow: hidden;
    transition: all 0.5s ease;
    height: 1rem;
    ${props => props.collapsed && 'height: 0; padding: 0;'}
    font-size: 0.8rem;
`

export const HoverNode = ({
    children,
    onHover,
    onDragOver,
    setDrag,
    onSelect,
    node,
    selected
}: {
    children: React.ReactNode,
    onHover?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, enter: boolean) => void,
    onDragOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, enter: boolean) => void,
    setDrag: (id: string) => void,
    onSelect: (node: DocumentContentNode) => void;
    node?: DocumentContentNode;
    selected?: boolean;
}) => {
    const [hover, setHover] = useState<boolean>();

    const dragStart = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setHover(true)
        onDragOver(event, true)
    }
    const dragEnd = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setHover(false)
        onDragOver(event, false)
    }
    const click = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, node: DocumentContentNode) => {
        if (node) {
            event.stopPropagation();
            onSelect(node)
        }
    }

    // const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    //     event.dataTransfer.setData('component', JSON.stringify(component))
    //     event.dataTransfer.setData('placeholderId', placeholderId ? placeholderId : '')
    //     setIsDragging(true)
    // }

    // const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    //     event.preventDefault();
    //     const rect = event.currentTarget.getBoundingClientRect();
    //     const containerTop = rect.y;
    //     const middle = containerTop + (rect.height / 2);

    //     setDropLine(event.pageY > middle ? 'bottom' : 'top')
    // }

    // const onDrop = (event: React.DragEvent<HTMLDivElement>, alternativePlaceholder?: string) => {
    //     setDropLine(undefined)
    //     event.preventDefault();
    //     const rect = event.currentTarget.getBoundingClientRect();
    //     const containerTop = rect.y;
    //     const middle = containerTop + (rect.height / 2);
    //     const draggedComponent: ApiPageComponent = JSON.parse(event.dataTransfer.getData('component'));
    //     const position = event.pageY > middle ? 'bottom' : 'top';
    //     onComponentDrop(draggedComponent, component, position, event.dataTransfer.getData('placeholderId'), alternativePlaceholder || placeholderId);
    // }

    return (
        <HoverContainer
            clickable={!!node}
            onClick={(event) => click(event, node)}
            draggable
            hoverActive={!node ? hover : false}
            onDragEnter={onDragOver ? dragStart : null}
            onDragLeave={onDragOver ? dragEnd : null}
            onMouseMove={onHover ? (e) => onHover(e, true) : null}
            onMouseLeave={onHover ? (e) => onHover(e, false) : null}
            selected={selected}
            // onDragStart={onDragStart}
        >
            {!node &&
                <NoPointer>
                    {children}
                </NoPointer>
            }
            {!!node && <>{children}</>}
        </HoverContainer>
    )
}

interface ICustomDoc extends BaseDocumentProps {
    onHover: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: DragAndDropLocation) => void;
    onDragOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: DragAndDropLocation) => void;
    setDrag: (id: string) => void;
    onSelect: (node: DocumentContentNode) => void;
    hoverActive: boolean;
    selectedId?: string;
    hoverItem?: DragAndDropLocation;
}

const DynamicDocument = ({ data, basePath, onHover, onDragOver, setDrag, onSelect, hoverActive, selectedId, hoverItem }: ICustomDoc) => (
    <BaseDocument title='Custom doc' fonts={[]} basePath={basePath} data={data}>
        {data.length > 0 &&
            <HoverNode onDragOver={(event, enter) => onDragOver(event, enter ? { index: -1 } : undefined)} setDrag={() => { }} onSelect={() => { }}>
                <AddPlaceholder collapsed={!hoverActive}>
                    <Icon name='plus' />
                </AddPlaceholder>
            </HoverNode>
        }
        {data.map((node, index) => (
            <React.Fragment key={node.Id}>
                <HoverNode
                    onHover={(event, enter) => onHover(event, enter ? { id: node.Id } : undefined)}
                    setDrag={setDrag}
                    onSelect={onSelect}
                    node={node}
                    selected={node.Id === selectedId || hoverItem?.id === node.Id}
                >
                    <Node hoverItem={hoverItem} selectedId={selectedId} hoverActive={hoverActive} index={index} node={node} onHover={onHover} onDragOver={onDragOver} setDrag={setDrag} onSelect={onSelect} />
                </HoverNode>
                <HoverNode onDragOver={(event, enter) => onDragOver(event, enter ? { index: index } : undefined)} setDrag={() => { }} onSelect={() => { }}>
                    <AddPlaceholder collapsed={!hoverActive}>
                        <Icon name='plus' />
                    </AddPlaceholder>
                </HoverNode>
            </React.Fragment>
        ))}
        {data.length < 1 &&
            <HoverNode onDragOver={(event, enter) => onDragOver(event, enter ? { index: -1 } : undefined)} setDrag={() => { }} onSelect={() => { }}>
                <Placeholder>
                    Drag an element here to get started!
                </Placeholder>
            </HoverNode>
        }
    </BaseDocument>
);

export default DynamicDocument;