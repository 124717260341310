import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { BoxShadowStyleWithHover } from "../../theme";
import { isNullOrWhitespace } from '../../utils/text-helpers';
import Icon from '../Media/Icon';

interface ComponentProps {
    url: string;
    name: React.ReactNode;
    description?: React.ReactNode;
}

export const LocationContainer = styled(Link)`
    ${BoxShadowStyleWithHover};
    background-color: ${props => props.theme.background};
    padding: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
    display: block;

    span {
        float: right;
        margin-right: 0.5rem;
        transition: all ease 0.5s;
    }

    &:hover {
        span {
            margin-right: 0rem;
        }
    }
`

const LocationLink = ({ url, name, description }: ComponentProps) => {
    return (
        <LocationContainer to={url}>
            <strong>{name}</strong>
            {!isNullOrWhitespace(description) &&
                <>
                    <br />
                    {description}
                </>
            }
            <Icon name='chevron-right' />
        </LocationContainer>
    );
};

export default LocationLink;