import React from 'react';
import DashboardHeader from '../../../../components/Dashboard/Header';
import { PanelLink } from '../../../../components/Dashboard/PanelLink';
import { Row, Column } from '../../../../components/Layout/Grid';
import Loader from '../../../../components/Layout/Loader';
import Icon from '../../../../components/Media/Icon';
import useBusinessList from '../../../../hooks/useBusinessList';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { BusinessSidebar } from '../../sideBars';

interface ComponentProps {
    match: any;
}

const ParentDashboard = ({ match }: ComponentProps) => {
    const [loaded, data] = useBusinessList(match.params.parentId);

    if (!loaded) return <Loader />;
  
    const business = data && data.length > 0 ? data[0] : null;
    return (
        <div>
            <DashboardHeader title={business.name}></DashboardHeader>
            <Row>
                {BusinessSidebar.filter(x => isNullOrWhitespace(x.category)).map(link => (
                    <Column size={4} tablet={6} mobile={12}>
                        <PanelLink to={`/dashboard/${match.params.parentId}/${link.link}`}><Icon name={link.icon} /> {link.name}</PanelLink>
                    </Column>
                ))}
            </Row>
        </div>
    );
};

export default ParentDashboard;