import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../../../../api/api-connectors';
import { BookingDayOverviewResponse } from '../../../../../api/api-definitions';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { Column, Row } from '../../../../../components/Layout/Grid';
import BookingDayReport from './BookingDay';

const LocationReports = () => {
    const [businessLoaded, business] = useBusiness();

    return (
        <div>
            <Row>
                <Column size={12}>
                    <BookingDayReport />
                </Column>
            </Row>
        </div>
    );
};

export default LocationReports;