import React from 'react';
import { CoreView } from '../shared/CoreModules/coreComponents';

type ColumnRange = 1|2|3|4|5|6|7|8|9|10|11|12;

interface ComponentProps {
    children: React.ReactNode;
    size: ColumnRange;
    flex?: boolean;
    paddedRight?: boolean;
}

const Column = ({ children, size, flex, paddedRight }: ComponentProps) => {
    const width: string = `${(100 / 12) * size}%`;
    return (
        <CoreView style={[{ width: "25%", borderStyle: "solid", borderWidth: 0, borderLeftWidth: 0, borderTopWidth: 0, textAlign: "left" }, {width: width, maxWidth: width, paddingRight: paddedRight ? 5 : 0}]}>
            <CoreView style={flex ? { flexDirection: 'row' } : null}>
                {children}
            </CoreView>
        </CoreView>
    );
};

export default Column;