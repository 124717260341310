import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Container = styled.div`
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.primaryContrast};
    text-align: center;
    font-size: 2rem;
    width: 100%;
    max-height: 100%;
    display:table;
    aspect-ratio : 1 / 1;

    span {
      display:table-cell;
      vertical-align:middle;
    }
`;

const NoImage = () => {
  return (
    <Container>
        <Icon name='camera' />
    </Container>
  );
};

export default NoImage;