import { BusinessPermissionsResponse } from "../api/api-definitions";
import DatabaseService from "./DatabaseService";

export default class BusinessPermissionsCacheService {
    private static readonly _key = 'businessPermissions';
    private static readonly _indexKey = 'businessPermissionsIndex';
    private static readonly _indexName = 'businessPermissionsId';

    public static async GetBusinessPermissions(id: string) {
        return new Promise<BusinessPermissionsResponse>((resolve, reject) => {
            DatabaseService.Database().then(db => {
                let transaction = db.transaction(this._key, "readonly");
                let objectStore = transaction.objectStore(this._key);
                let index = objectStore.index(this._indexKey);

                let getRequest = index.get(id);

                getRequest.onsuccess = function (event) {
                    let result = (event.target as any).result;
                    if (result?.response) {
                        resolve(JSON.parse(result.response) as BusinessPermissionsResponse)
                    } else {
                        resolve(null)
                    }
                };
            });
        })
    }

    public static async UpsertBusinessPermissions(id: string, response: BusinessPermissionsResponse) {
        DatabaseService.Database().then(db => {
            const transaction = db.transaction(this._key, "readwrite");
            const objectStore = transaction.objectStore(this._key);
            objectStore.put({
                [this._indexName]: id,
                response: JSON.stringify(response)
            }, id)
        });
    }
}