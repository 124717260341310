import { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { BoxShadowStyle } from '../../../theme';
import { DATEONLYFORMAT, formatDate } from '../../../utils/date-helpers';
import Icon from '../../Media/Icon';

interface ComponentProps {
    date: string | Moment | Date;
    covers: number;
    bookings: number;
    cancellations: number;
    walkIns: number;
    web: number;
    inHouse: number;
    showWalkIns?: boolean;
    noShows?: number;
    closed?: boolean;
}

const DayContainer = styled.div<{today?: boolean, closed?: boolean}>`
    ${BoxShadowStyle};
    background-color: #FFF;
    padding: 1rem;
    min-width: max-content;

    ${props => props.today && `
        background-color: #ddeaf7;
    `}

    ${props => props.closed && `
        background: rgba(229, 229, 229, 0.1);
    `}
`

const DayTitle = styled.div`
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`

const DayValue = styled.span`
    font-size: 1rem;
`

const DayLabel = styled.span`
    font-size: 0.9rem;  
    color: #767676;
    padding-left: 0.5rem;
`

const Divider = styled.div`
    border-bottom: 1px solid ${props => props.theme.borderColor};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`

const IndentedList = styled.ul`
    padding-left: 1rem;
`

const OverviewDay = ({ date, covers, bookings, web, inHouse, cancellations, walkIns, showWalkIns, noShows, closed }: ComponentProps) => {
    return (
        <DayContainer closed={closed} today={formatDate(new Date(), DATEONLYFORMAT) === formatDate(date, DATEONLYFORMAT)}>
            <DayTitle>{formatDate(date, 'dd DD MMM')}</DayTitle>
            {closed ? <DayLabel>&nbsp;&nbsp;&nbsp;&nbsp;CLOSED</DayLabel> : <>
            <DayValue>{closed ? "-" : covers}</DayValue><DayLabel>Cover{covers != 1 && 's'}</DayLabel>
            </>}
            <Divider />
            <DayValue>{bookings}</DayValue><DayLabel>Booking{bookings != 1 && 's'}</DayLabel>
            <IndentedList>
                <li><DayValue>{web}</DayValue><DayLabel>Online</DayLabel></li>
                <li><DayValue>{inHouse}</DayValue><DayLabel>In-house</DayLabel></li>
            </IndentedList>
            <Divider />
            <DayValue>{closed ? "-" : cancellations}</DayValue><DayLabel>Cancellation{cancellations != 1 && 's'}</DayLabel>
            <Divider />
            <DayValue>{closed ? "-" : noShows}</DayValue><DayLabel>No show{noShows != 1 && 's'}</DayLabel>
            {/* <Divider />
            <DayValue>{inHouse}</DayValue><DayLabel>In-house booking{(inHouse) != 1 && 's'}</DayLabel> */}
        </DayContainer>
    );
};

export default OverviewDay;