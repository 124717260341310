import React, { useRef, useState } from 'react';
import { BaseActiveBooking, AreaAndTablesForTimeline, BookingStatus, SpecialOccasion, ExperienceType, ExperienceData } from '../../../../../../api/api-definitions';
import styled from 'styled-components';
import { TIMEFORMAT, businessNowTime, createMomentFromValue, formatDate } from '../../../../../../utils/date-helpers';
import { BookingStatusColours, GetStatusColour } from '../bookingUtils';
import Icon from '../../../../../../components/Media/Icon';
import { ExtendedTable } from '../TableLayout';
import { bookingTables } from '../../../../../../svgs/bookingTables';
import { svgToURL } from '../../../../../../utils/data-helpers';
import { ErrorMessage } from '../../../../../../components/Forms/Messaging';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { BookingSpecialOccasions } from '../../../../../../constants';
import { Badge } from '@chakra-ui/react';

const SectionTitle = styled.div`
    padding: 0.5rem;
    font-weight: bold;
    background-color: ${props => props.theme.borderColor};
`

const BookingBlock = styled.div<{ statusColor: string }>`
    padding: 0.5rem;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    border-left: 6px solid ${props => props.statusColor};
    cursor: pointer;
`

const InfoBlock = styled.div`
    padding: 0.5rem;
`

const MergeIcons = styled.span<{ textColor: string }>`
    font-size: 1.8rem;
    float: right;

    span {
        margin: 0.5rem 0.1rem;
    }
`

const TableLabelContainer = styled.span`
    float: right;
    text-align: center;

    img {
        height: 2.5rem;
        max-width: 6rem;
        margin: 0 auto;
    }
`

const AreaLabel = styled.span`
    color: ${props => props.theme.primary};
    font-size: 0.9rem;
    font-weight: bold;
`

const RunningLate = styled.div`
    background-color: ${props => props.theme.negative};
    color: ${props => props.theme.negativeContrast};
    padding: 0.2rem;
`

const buildTableMap = (tables: ExtendedTable[]): { [key: number]: ExtendedTable } => {
    const map: { [key: number]: ExtendedTable } = {};
    tables.forEach(table => {
        map[table.id] = table;
    })
    return map;
}

interface BookingItemProps {
    booking: BaseActiveBooking;
    bookings: BaseActiveBooking[];
    areas: AreaAndTablesForTimeline;
    setViewBooking: (booking: BaseActiveBooking) => void;
    tableMap: { [key: number]: ExtendedTable };
    experience?: ExperienceData
}

const BookingItem = ({ booking, bookings, areas, setViewBooking, tableMap, experience }: BookingItemProps) => {
    const overrunning = bookingIsOverrunning(booking);
    const endingSoon = booking.status == BookingStatus.Seated && businessNowTime().isBetween(createMomentFromValue(booking.endDateTime).subtract(15, 'minutes'), createMomentFromValue(booking.endDateTime));
    let seatColor: string = undefined;
    if (overrunning) seatColor = 'red';
    if (endingSoon) seatColor = BookingStatusColours.endingSoon.background;
    let tableNames: string[] = [];
    booking.tables.forEach(tableId => {
        tableNames.push(tableMap[tableId]?.tableName)
    });
    const tableName = tableNames.join(', ')

    let area = booking.tables.length > 0 ? areas[tableMap[booking.tables[0]].areaId] : undefined;
    let tableSvgString = '';
    let tableUrl = '#';

    if (booking.tables.length === 1) {
        let table = tableMap[booking.tables[0]];
        tableSvgString = tableNames.length === 0 ? '' : bookingTables[`${table?.seats}-${table?.shape}`](GetStatusColour(booking), seatColor);
        tableUrl = !tableSvgString ? '#' : svgToURL(tableSvgString);
    }

    return (
        <BookingBlock statusColor={GetStatusColour(booking)} onClick={() => setViewBooking(booking)}>
            {booking.tables.length < 2 &&
                <TableLabelContainer>
                    {tableSvgString && <img src={tableUrl} />}
                    {!tableSvgString && <Icon name='ban'></Icon>}
                </TableLabelContainer>
            }
            {booking.tables.length > 1 && <MergeIcons textColor={GetStatusColour(booking)}>
                <Icon name='arrows-to-line' duo />
            </MergeIcons>}
            {(!isNullOrWhitespace(booking.specialRequests) || !isNullOrWhitespace(booking.notes)) && <><Icon name='memo-pad' />&nbsp;</>}
            {booking.specialOccasion != SpecialOccasion.NotSet && <Icon name={BookingSpecialOccasions()[booking.specialOccasion].icon} />}<strong>{booking.isWalkIn ? 'Walk in' : `${booking.client.firstName} ${booking.client.surname ? booking.client.surname : ''}`}</strong> {overrunning ? <> - <Badge colorScheme='red'>Overrunning</Badge></> : null}{endingSoon ? <> - <Badge colorScheme='orange'>Ending soon</Badge></> : null}
            {experience &&
                <Badge colorScheme='messenger'><Icon name={experience.type == ExperienceType.Event ? 'calendar' : 'sparkles'} /> {experience.name}</Badge>
            }
            <br />
            {booking.status === BookingStatus.Pending &&
                createMomentFromValue(booking.startDateTime).add(-5, 'minutes').isBefore(businessNowTime()) &&
                booking.tables && bookings.filter(x => booking.tables.some(t => x.tables.includes(t)) && bookingIsOverrunning(x)).length > 0 &&
                <>
                    <Badge colorScheme='orange'>Booking due to start, but previous booking overrunning</Badge><br />
                </>
            }
            {booking.status === BookingStatus.Seated &&
                booking.tables && bookings.filter(x => booking.tables.some(t => x.tables.includes(t)) && x.startDateTime == booking.endDateTime).length > 0 &&
                <>
                    <Badge colorScheme='cyan'>Has another booking immediately after</Badge><br />
                </>
            }
            {formatDate(booking.startDateTime, TIMEFORMAT)} to {formatDate(booking.endDateTime, TIMEFORMAT)} - <span><Icon name='users' duo /> {booking.guests} </span> {tableName && <>- <span><Icon name='dining-table' duo /> {tableName}</span></>}
            {area && Object.keys(areas).length > 1 &&
                <>
                    <br />
                    <AreaLabel>{area.areaName}</AreaLabel>
                </>
            }
            {booking.status === BookingStatus.Pending && createMomentFromValue(booking.startDateTime).add(15, 'minutes').isBefore(businessNowTime()) &&
                <ErrorMessage>
                    Booking is more than 15 mins late. Mark as no show?
                </ErrorMessage>
            }
        </BookingBlock>
    );

    function bookingIsOverrunning(booking: BaseActiveBooking) {
        return booking.status == BookingStatus.Seated &&
            createMomentFromValue(booking.endDateTime).isBefore(businessNowTime());
    }
};

interface ComponentProps {
    bookings: BaseActiveBooking[];
    tables: ExtendedTable[];
    areas: AreaAndTablesForTimeline;
    setViewBooking: (booking: BaseActiveBooking) => void;
    selectedExperience?: number;
    experiences?: {
        [key: string]: ExperienceData;
        [key: number]: ExperienceData;
    }
}

const BookingListPanel = ({ bookings, tables, areas, setViewBooking, selectedExperience, experiences = {} }: ComponentProps) => {
    const [activeTab, selectTab] = useState<number>(0);
    const tableMap = useRef<{ [key: number]: ExtendedTable }>(buildTableMap(tables));

    const seatedBookings: BaseActiveBooking[] = [];
    let seatedCovers: number = 0;
    const pendingBookings: BaseActiveBooking[] = [];
    let pendingCovers: number = 0;

    bookings.sort((a, b) => {
        return createMomentFromValue(a.startDateTime).unix() - createMomentFromValue(b.startDateTime).unix()
    }).forEach(booking => {
        if (!booking.isHeld && (!!selectedExperience ? booking.experienceId == selectedExperience : (booking.experienceId == undefined || experiences[booking.experienceId].type == ExperienceType.Experience))) {
            if (booking?.status === BookingStatus.Seated || booking?.status === BookingStatus.PartiallySeated  || booking?.status === BookingStatus.Arrived ) {
                seatedBookings.push(booking);
                seatedCovers += booking.guests;
            }
            if (booking?.status === BookingStatus.Pending) {
                pendingBookings.push(booking);
                pendingCovers += booking.guests;
            }
        }
    });

    return (
        <div>
            {/* <TabBar>
                <TabButton active={activeTab === 0} onClick={() => selectTab(0)}>Bookings</TabButton>
                <TabButton active={activeTab === 1} onClick={() => selectTab(1)}>Waitlist</TabButton>
            </TabBar> */}
            {activeTab === 0 && <>
                <SectionTitle>Seated <span style={{ float: 'right' }}><span title='Seated tables'><Icon name='dining-table' duo /> {seatedBookings.length}</span> <span title='Seated covers'><Icon name='user' duo /> {seatedCovers}</span></span></SectionTitle>
                {seatedBookings.length === 0 && <InfoBlock>No seated bookings</InfoBlock>}
                {seatedBookings.map((booking) => (
                    <BookingItem key={booking.id} areas={areas} booking={booking} bookings={bookings} setViewBooking={setViewBooking} tableMap={tableMap.current} experience={!!booking.experienceId ? experiences[booking.experienceId] : undefined} />
                ))}
                <SectionTitle>Pending <span style={{ float: 'right' }}><span title='Upcoming tables'><Icon name='dining-table' duo /> {pendingBookings.length}</span> <span title='Upcoming covers'><Icon name='user' duo /> {pendingCovers}</span></span></SectionTitle>
                {pendingBookings.length === 0 && <InfoBlock>No pending bookings</InfoBlock>}
                {pendingBookings.map((booking) => (
                    <BookingItem key={booking.id} areas={areas} booking={booking} bookings={bookings} setViewBooking={setViewBooking} tableMap={tableMap.current} experience={!!booking.experienceId ? experiences[booking.experienceId] : undefined} />
                ))}
            </>}
        </div>
    );
};

export default BookingListPanel;