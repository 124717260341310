import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { ApiService } from '../../../../../api/api-connectors';
import { BaseMenu, GenericResponse } from '../../../../../api/api-definitions';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import CoreButton from '../../../../../components/Forms/Button';
import Checkbox from '../../../../../components/Forms/Checkbox';
import { WarningMessage, InfoMessage } from '../../../../../components/Forms/Messaging';
import DataTable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import { FloatingActionBar } from '../../../../../components/Layout/FloatingActionBar';
import Loader from '../../../../../components/Layout/Loader';
import Icon from '../../../../../components/Media/Icon';
import useParentBusiness from '../../../../../hooks/useParentBusiness';
import { NotificationService } from '../../../../../services/NotificationService';
import AddNewMenu from './AddNewMenu';

const MenuList = () => {
    const [loaded, data, setData, reloadBusiness] = useParentBusiness();
    const [newMenuOpen, setNewMenuOpen] = useState<boolean>(false);
    
    if (!loaded) return <Loader />;

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        if (data.menus) data.menus.forEach((item, index) => {
            const dataItem: DataTableItem = {
                data: {
                    'Menu name': {
                        value: item.name,
                        sortable: true,
                    },
                    'Published': {
                        value: <Checkbox asToggle model={'menu-' + index} checked={item.isActive} onChange={() => setMenuActive(index)} />,
                        sortValue: item.isActive,
                        sortable: true,
                    },
                    'Manage menu': {
                        value: <CoreButton small to={`${window.location.pathname}/${item.id}`}>Manage menu</CoreButton>,
                        hideName: true
                    },
                }
            }
            items.push(dataItem);
        });
        return items;
    }

    const setMenuActive = (menuIndex: number) => {
        ApiService.menu.Publish__POST(data.menus[menuIndex]).then((response: GenericResponse) => {
            const newData = cloneDeep(data);
            newData.menus[menuIndex].isActive = !newData.menus[menuIndex].isActive;
            setData(newData)
            NotificationService.ConfirmOrError('Menu published successfully', 'Failed to publish the menu', response.success);
        }).catch(() => {
            NotificationService.Error('Failed to publish menu');
        })
    }

    const addMenuCallback = (menu: BaseMenu) => {
        const business = cloneDeep(data);
        business.menus.push(menu);
        reloadBusiness()
    }

    const hasAllUnpublishedMenues = data.menus && data.menus.length > 0 && data.menus.filter(x => x.isActive).length == 0;

    return (
        <>
            <DashboardHeader icon='book-open' title='Menu management'></DashboardHeader>
            <AddNewMenu callback={addMenuCallback} parentBusinessId={data.id} open={newMenuOpen} onClose={() => setNewMenuOpen(false)} />
            <FloatingActionBar>
                <CoreButton small onClick={() => setNewMenuOpen(true)}>New menu <Icon name='plus' /></CoreButton>
            </FloatingActionBar>
            {hasAllUnpublishedMenues &&
                <>
                    <WarningMessage>
                        You don't have a menu published yet. This means your business will not currently return in search results.
                    </WarningMessage>
                    <br />
                </>
            }
            {data.menus && data.menus.length > 0 &&
                <DataTable data={gentable()} />
            }
            {data.menus && data.menus.length == 0 &&
                <InfoMessage>
                    Welcome to your business page. Please set up a menu by clicking the "New menu" button at the bottom of the page.
                </InfoMessage>
            }
        </>
    );
};

export default MenuList;