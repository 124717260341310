import React, { useRef } from 'react';
import styled from 'styled-components';
import { useClickOutside } from '../../hooks/useClickOutside';
import { BoxShadowStyle } from '../../theme';

interface ComponentProps {
    children: React.ReactNode;
    onClose: () => void;
}

const MenuStyle = styled.div`
  ${BoxShadowStyle}
  position: absolute;
  top: 13px;
  right: 6px;
  min-width: 14rem;
  z-index: 10;
  background-color: ${props => props.theme.background};
`

const ContextMenu = ({ children, onClose }: ComponentProps) => {
    const contextRef = useRef<HTMLDivElement>();
    useClickOutside(contextRef, onClose)
    return (
        <MenuStyle ref={contextRef}>{children}</MenuStyle>
    );
};

export default ContextMenu;