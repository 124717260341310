import React from 'react';
import { BaseBusinessOpeningTimesRange, DayOfWeek } from '../../../../../api/api-definitions';
import styled from 'styled-components';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import { Column, Row } from '../../../../../components/Layout/Grid';
import Checkbox from '../../../../../components/Forms/Checkbox';
import Time from '../../../../../components/Forms/Time';
import { StyledCalendar, StyledTextInput } from '../../../../../theme/input.styles';
import { AddRemoveButton } from '../../../../../components/Forms/AddRemoveButton';
import Icon from '../../../../../components/Media/Icon';
import CoreButton from '../../../../../components/Forms/Button';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';

interface ComponentProps {
    setDay: (data: BaseBusinessOpeningTimesRange) => void;
    addOpeningTime: (data: BaseBusinessOpeningTimesRange) => void;
    removeOpeningTime: (data: BaseBusinessOpeningTimesRange) => void;
    data: BaseBusinessOpeningTimesRange;
    label: string;
    maxSortOrderForDay: number;
}

const CheckboxColumn = styled(Column)`
    padding-top: 0.8rem;
`;

const ClosedLabel = styled.p`
    text-align: center;
    margin: 20px;
`;

const OpeningTimeItem = ({ setDay, addOpeningTime, removeOpeningTime, data, label, maxSortOrderForDay }: ComponentProps) => {
    if (!data) data = { id: null, dayOfWeek: null, closed: false, parentBusinessId: null, sortOrder: 1, timeToDayOfWeek: null };

    const formUpdate = (newData: BaseBusinessOpeningTimesRange) => {
        const item: BaseBusinessOpeningTimesRange = { ...data, ...newData }

        if (!label) item.closed = data.closed;
        if (item.timeFrom == undefined) item.timeFrom = null;
        if (item.timeTo == undefined) item.timeTo = null;
        if (item.closed == undefined) item.closed = false;
        if (item.sortOrder == undefined) item.sortOrder = 1;
        setDay(item);
    }

    return (
        <>
            <Row>
                <CheckboxColumn size={2}>
                    {!isNullOrWhitespace(label) &&
                        <Checkbox
                            unlink
                            asToggle
                            checked={data.closed}
                            model='closed'
                            id={`${data.dayOfWeek}-${data.dayOfWeekSortOrder}`}
                            label={label}
                            invert
                            onChange={(e) => formUpdate({...data, closed: !data.closed})}
                        />
                    }
                </CheckboxColumn>
                {data.closed &&
                    <>
                        <Column size={1} />
                        <Column size={2}>
                            {label && <ClosedLabel>CLOSED</ClosedLabel>}
                        </Column>
                    </>
                }
                {!data.closed &&
                    <>
                        <Column size={2}>
                            <Time
                                unlink
                                value={data.timeFrom}
                                model='timeFrom'
                                onChange={(e) => formUpdate({...data, timeFrom: e.target.value})}
                            />
                        </Column>
                        <Column size={2}>
                            <Time
                                unlink
                                value={data.timeTo}
                                model='timeTo'
                                onChange={(e) => formUpdate({...data, timeTo: e.target.value})}
                            />
                        </Column>
                        <Column size={2}>
                            <StyledTextInput
                                unlink
                                value={data.shiftLabel}
                                model='shiftLabel'
                                max={50}
                                required
                                placeholder='e.g. Dinner'
                                onChange={(e) => formUpdate({...data, shiftLabel: e.target.value})}
                            />
                        </Column>
                    </>
                }
                {!data.closed && maxSortOrderForDay === data.sortOrder && data.sortOrder <= 2 &&
                    <Column size={1}>
                        <CoreButton onClick={() => addOpeningTime(data)}><Icon name='plus' /></CoreButton>
                    </Column>
                }
                {!data.closed && maxSortOrderForDay === data.sortOrder && data.sortOrder !== 1 &&
                    <Column size={1}>
                        <CoreButton type='danger' onClick={() => removeOpeningTime(data)}><Icon name='minus' /></CoreButton>
                    </Column>
                }
            </Row>
        </>
    );
};

export default OpeningTimeItem;