import React, { useEffect, useState } from 'react';
import { ApiService } from '../../../../../api/api-connectors';
import { BaseActiveBooking, BookingStatus, ClientModel, FullClientModel } from '../../../../../api/api-definitions';
import { NotificationService } from '../../../../../services/NotificationService';
import { Skeleton, Stack } from '@chakra-ui/react';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { H2, H3 } from '../../../../../components/Typography/Headings';
import DashboardPanel from '../../../../../components/Dashboard/Panel';
import Loader from '../../../../../components/Layout/Loader';
import DataTable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import { DEFAULT_DATE_FORMAT, TIMEFORMAT, createMomentFromValue, formatDate } from '../../../../../utils/date-helpers';
import useBusinessList from '../../../../../hooks/useBusinessList';
import Icon from '../../../../../components/Media/Icon';
import styled from 'styled-components';
import EditNotesModal from './EditNotesModal';
import { useBusiness } from '../../../../../hooks/useBusiness';

interface ComponentProps {
  id: number;
  parentId: number;
}

const GuestName = styled(H3)`
`

const InfoHeight = '80px';

const GuestInfo = ({ id, parentId }: ComponentProps) => {
  const [listLoaded, businessList, reload, locationDictionary] = useBusinessList();
  const [businessLoaded, business] = useBusiness();
  const [client, setClient] = useState<FullClientModel>();
  const [upcomingBookings, setUpcomingBookings] = useState<BaseActiveBooking[]>();
  const [completedBookings, setCompletedBookings] = useState<BaseActiveBooking[]>();
  const [cancelledBookings, setCancelledBookings] = useState<BaseActiveBooking[]>();
  const [noShowBookings, setNoShowBookings] = useState<BaseActiveBooking[]>();

  useEffect(() => {
    ApiService.businessClient.GetFullDetails__GET(id, parentId).then(setClient).catch(() => {
      NotificationService.Error('Sorry, we could not get the client details.')
    });
  }, [])

  if (!listLoaded) return <Loader />;

  const getBookingList = (status: BookingStatus) => {
    if (status === BookingStatus.Cancelled && cancelledBookings) {
      return;
    }
    if (status === BookingStatus.NoShow && noShowBookings) {
      return;
    }
    if (status === BookingStatus.Pending && upcomingBookings) {
      return;
    }
    if (status === BookingStatus.Completed && completedBookings) {
      return;
    }
    ApiService.businessClient.GetBookingsForStatus__GET(id, parentId, status).then(response => {
      if (status === BookingStatus.Cancelled) {
        setCancelledBookings(response)
      }
      if (status === BookingStatus.NoShow) {
        setNoShowBookings(response)
      }
      if (status === BookingStatus.Pending) {
        setUpcomingBookings(response)
      }
      if (status === BookingStatus.Completed) {
        setCompletedBookings(response)
      }
    })
  }

  const gentable = (bookings: BaseActiveBooking[]): DataTableItem[] => {
    const items: DataTableItem[] = [];
    bookings.forEach((item) => {
      const dataItem: DataTableItem = {
        data: {
          'Booking reference': {
            value: item.bookingReference,
            sortable: true,
          },
          'Location': {
            value: !!locationDictionary && locationDictionary.hasOwnProperty(item.businessId) ? locationDictionary[item.businessId]?.locationAddress.formattedName : 'N/A',
            sortable: true,
          },
          'Booking date': {
            value: formatDate(item.startDateTime, DEFAULT_DATE_FORMAT),
            sortValue: createMomentFromValue(item.startDateTime).unix(),
            sortable: true,
          },
          'Booking time': {
            value: formatDate(item.startDateTime, TIMEFORMAT),
            sortable: true,
          },
          'Guests': {
            value: item.guests,
            sortable: true,
          },
        },
      }
      items.push(dataItem);
    });
    return items;
  }

  return (
    <div>
      {!client &&
        <>
          <Skeleton height='60px' />
          <br />
          <Row>
            <Column size={6} mobile={12}>
              <Skeleton height={InfoHeight} />
            </Column>
            <Column size={6} mobile={12}>
              <Skeleton height={InfoHeight} />
            </Column>
          </Row>
          <Row>
            <Column size={6} mobile={12}>
              <Skeleton height={InfoHeight} />
            </Column>
            <Column size={6} mobile={12}>
              <Skeleton height={InfoHeight} />
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Skeleton height={InfoHeight} />
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Skeleton height={InfoHeight} />
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Skeleton height={InfoHeight} />
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Skeleton height={InfoHeight} />
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Skeleton height={InfoHeight} />
            </Column>
          </Row>
        </>
      }
      {client &&
        <>
          <GuestName hideDivider><Icon name='address-card' duo /> {client.firstName} {client.surname ? client.surname : ''}</GuestName>
          <Row>
            <Column hasBorder size={6} mobile={12}>
              <Icon name='at' duo /><strong>Email</strong>
              <br />
              {client.email ? client.email : 'N/A'}
            </Column>
            <Column hasBorder size={6} mobile={12}>
              <Icon name='phone' duo /><strong>Telephone</strong>
              <br />
              {client.telephone ? client.telephone : 'N/A'}
            </Column>
          </Row>
          <Row>
            <Column hasBorder size={6} mobile={12}>
              <Icon name='megaphone' duo /><strong>Opted for marketing</strong>
              <br />
              {client.marketing ? 'Yes' : 'No'}
            </Column>
            <Column hasBorder size={6} mobile={12}>
              <Icon name='message-text' duo /><strong>Opted for text updates</strong>
              <br />
              {client.textUpdates ? 'Yes' : 'No'}
            </Column>
          </Row>
          <Row>
            <Column hasBorder size={12}>
            <EditNotesModal client={client} parentId={parentId} businessId={business?.id} updateClient={e => setClient(e)} /> <strong>Notes</strong>
              <div style={{whiteSpace: 'pre-line'}}>
                {client.notes || 'No client notes to show'}
              </div>
            </Column>
          </Row>
          <DashboardPanel title={`Completed bookings - ${client.bookingCount}`} onToggle={() => getBookingList(BookingStatus.Completed)} defaultExpanded={false}>
            {!completedBookings && <Loader />}
            {completedBookings &&
              <DataTable defaultSort='Booking date' reverseSort data={gentable(completedBookings)} />
            }
          </DashboardPanel>
          <DashboardPanel title={`Upcoming bookings - ${client.futureBookings}`} onToggle={() => getBookingList(BookingStatus.Pending)} defaultExpanded={false}>
            {!upcomingBookings && <Loader />}
            {upcomingBookings &&
              <DataTable defaultSort='Booking date' reverseSort data={gentable(upcomingBookings)} />
            }
          </DashboardPanel>
          <DashboardPanel title={`No shows - ${client.noShowCount}`} onToggle={() => getBookingList(BookingStatus.NoShow)} defaultExpanded={false}>
            {!noShowBookings && <Loader />}
            {noShowBookings &&
              <DataTable defaultSort='Booking date' reverseSort data={gentable(noShowBookings)} />
            }
          </DashboardPanel>
          <DashboardPanel title={`Cancellations - ${client.cancelledCount}`} onToggle={() => getBookingList(BookingStatus.Cancelled)} defaultExpanded={false}>
            {!cancelledBookings && <Loader />}
            {cancelledBookings &&
              <DataTable defaultSort='Booking date' reverseSort data={gentable(cancelledBookings)} />
            }
          </DashboardPanel>
        </>
      }
    </div>
  );
};

export default GuestInfo;