import React from 'react';
import { ExtendedArea, ExtendedShape, TableDragItem, TableSetupAddButtonContainer, TableSetupBackButton, TableSetupWrapper } from '.';
import { StyledDropdown, StyledTextInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';
import Icon from '../../../../../../components/Media/Icon';
import { cloneDeep } from 'lodash';
import { ApiService } from '../../../../../../api/api-connectors';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { DEFAULTXY } from './tableLayoutUtils';
import styled from 'styled-components';

interface ComponentProps {
    shapes: ExtendedShape[];
    selectedShape: ExtendedShape;
    selectedArea: ExtendedArea;
    businessId: number;
    setDrag: (item: ExtendedShape) => void;
    selectShape: (id: number) => void;
    updateData: (shapes: ExtendedShape[]) => void;
    save: (shape: ExtendedShape) => void;
}

const defaultValue: ExtendedShape = {
    x: 10,
    y: 10,
    height: 50,
    width: 50,
    shape: 'rect',
    fill: 'black',
    text: 'Shape text',
    rotation: 0,
    id: 0,
}

const Square = styled.div`
    display: inline-block;
    width: 3rem;
    height: 3rem;
    background-color: #000;
    margin-bottom: 1rem;
`

const ShapeForm = ({ shapes, selectedShape, selectedArea, businessId, setDrag, selectShape, updateData, save }: ComponentProps) => {
    const changeShapeValue = (value, property) => {
        const newData = cloneDeep(shapes);
        const item = newData.find(x => x.id === selectedShape.id);
        item[property] = value;
        updateData(newData)
        save(item)
    }
    const removeShape = () => {
        ApiService.shapes.Delete__DELETE(selectedShape).then(() => {
            updateData(shapes.filter(x => x.id !== selectedShape.id))
            selectShape(undefined);
        })
    }

    const addShape = () => {
        const newShape = cloneDeep(defaultShape);
        newShape.businessId = businessId;
        newShape.areaId = selectedArea.id;
        ApiService.shapes.Insert__PUT(newShape).then(response => {
            const data = cloneDeep(shapes);
            newShape.id = +response.info;
            data.push(newShape);
            updateData(data);
        })
    }

    const defaultShape: ExtendedShape = {
        ...defaultValue,
        businessId: businessId,
        areaId: selectedArea.id,
    }

    if (!selectedShape) return (
        <Column size={4} tablet={12}>
            <TableDragItem
                draggable
                onDragStart={(e) => {
                    setDrag({
                        ...defaultShape
                    });
                }}
            >
                <Square />
                <br />
                Shape
            </TableDragItem>
        </Column>
    )
    return (
        <TableSetupWrapper>
            <TableSetupBackButton onClick={() => selectShape(undefined)}>
                <Icon name='arrow-left' /> Back
            </TableSetupBackButton>
            <StyledDropdown
                value={selectedShape.shape}
                label='Type'
                onChange={(item) => changeShapeValue(item.target.value, 'shape')}
                items={[{ value: 'rect', text: 'Rectangle' }, { value: 'circle', text: 'Circle' }, { value: 'text', text: 'Text' }]}
            />
            {selectedShape.shape === 'text' &&
                <>
                    <StyledTextInput label='Text:' value={selectedShape.text || ''} onChange={e => changeShapeValue(e.target.value, 'text')} />
                </>
            }
            <br />
            <br />
            <CoreButton onClick={removeShape} type='danger'>Delete shape</CoreButton>
        </TableSetupWrapper>
    );
};

export default ShapeForm;