import React, { useEffect, useState } from 'react';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { ApiService } from '../../../../../api/api-connectors';
import { BaseBusinessServer } from '../../../../../api/api-definitions';
import Loader from '../../../../../components/Layout/Loader';
import { Button } from '@chakra-ui/react';
import base from '@emotion/styled/types/base';
import DataTable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import DashboardAddButton from '../../../../../components/Dashboard/AddButton';
import CoreModal from '../../../../../components/Layout/CoreModal';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { StyledColourInput, StyledTextInput } from '../../../../../theme/input.styles';
import EasyColourInput from '../../../../../components/Forms/ColourInput';


const Servers = () => {
    const [businessLoaded, businessData] = useBusiness();
    const [servers, setServers] = useState<BaseBusinessServer[]>();
    const [modalData, setModalData] = useState<BaseBusinessServer>();

    useEffect(() => {
        if (businessData) {
            ApiService.businessServer.List__GET(businessData.id).then(setServers);
        }
    }, [businessLoaded]);

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        servers.forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'Server': {
                        value: item.name,
                        sortable: true,
                    },
                    'Colour': {
                        value: item.colour,
                        sortable: true,
                    },
                },
            }
            items.push(dataItem);
        });
        return items;
    }

    return (
        <div>
            <DashboardHeader icon='clipboard-user' title="Servers" />
            {modalData &&
                <FormWrapper onUpdate={(formDetails) => setModalData({ ...modalData, ...formDetails })}>
                    {({ id, valid }) => (
                        <CoreModal onClose={() => setModalData(null)} title={modalData.id ? 'Edit Server' : 'Add Server'}>
                            <Row>
                                <Column size={4}>
                                    <StyledTextInput label="Name" model="name" value={modalData.name}/>
                                </Column>
                                <Column size={4}>
                                    <StyledColourInput label="Colour" model="colour" value={modalData.colour}/>
                                </Column>
                            </Row>

                        </CoreModal>
                    )}
                </FormWrapper>
            }
            <DashboardAddButton onClick={() => setModalData({
                name: '',
                businessId: businessData.id,
                colour: '',
                code: '',
                id: null
            })}>Add server</DashboardAddButton>
            {!servers && <Loader />}
            {servers && <DataTable data={gentable()} />}
        </div >
    );
};

export default Servers;