import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import { ApiBookingTimesRequest, ApiMakeBookingAsBusinessRequest, BaseActiveBooking, BaseClient, BaseBookingTableSetup, BaseTableSetupArea, BookingError, BookingManagementResponse, BookingStatus, ClientModel, DateAndTimeSlots, HoldOrGetAvailableTablesResponse, TableAvailability, TableAvailabilityForDate, AreaAndTablesForTimeline, AmendBookingRequest, BaseDepositRule, DepositPaymentType, DepositRuleType, BaseBookingPayment, ExperienceType, ApiBookingTimes, ApiMakeAdminBookingResponse, BookingErrorType } from '../../../../../../api/api-definitions';
import CoreButton from '../../../../../../components/Forms/Button';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import Typeahead, { TypeaheadDataItem } from '../../../../../../components/Forms/EasyTypeahead';
import FormWrapper from '../../../../../../components/Forms/FormWrapper';
import NumberInput from '../../../../../../components/Forms/NumberInput';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../../services/NotificationService';
import { BookingInfoIcon, BookingInfoTitle, StyledCalendar, StyledDropdown, StyledNumberInput, StyledTextarea, StyledTextInput } from '../../../../../../theme/input.styles';
import { createUUID } from '../../../../../../utils/data-helpers';
import { DATABASE_TIME_FORMAT, DATEONLYFORMAT, DEFAULT_DATE_FORMAT, DISPLAY_DATE_FORMAT, RoundDownTo15, TIMEFORMAT, businessNowTime, createMomentFromValue, dateIsToday, formatDate, getDurationText, minutesToTime, secondsToTime } from '../../../../../../utils/date-helpers';
import { H3, H4, H5 } from '../../../../../../components/Typography/Headings';
import Dropdown, { DropdownItem } from '../../../../../../components/Forms/Dropdown';
import { ErrorMessage, InfoMessage, SuccessMessage, WarningMessage } from '../../../../../../components/Forms/Messaging';
import { TabBar, TabButton } from '../../../../../../components/Layout/Tabs';
import styled from 'styled-components';
import Icon from '../../../../../../components/Media/Icon';
import MainStage from '../TableLayout/MainStage';
import { ExtendedTable, ExtendedShape, ExtendedArea } from '../TableLayout';
import { ExtendedBookingManagementResponse, TIMELINE_INTERVAL } from '..';
import Loader from '../../../../../../components/Layout/Loader';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import BookingTimer from '../modules/BookingTimer';
import BREAKPOINTS from '../../../../../../config/breakpoints';
import { ModalService } from '../../../../../../services/ModalService';
import { getSelectedTableDisplay } from './bookingModalUtils';
import { BookingSpecialOccasions, EmailAddressSize } from '../../../../../../constants';
import { WaitlistItem } from '../modules/Waitlist';
import { ConvertCurrency } from '../../../../../../utils/currency-helper';
import ManualPayment from '../CreateCustomerPayment/ManualPayment';
import { Badge } from '@chakra-ui/react';
import TagContainer from '../../../../../../components/Forms/Tags';
import Tag from '../../../../../../components/Forms/Tags/Tag';

interface ComponentProps {
    bookingAmendment?: BaseActiveBooking;
    table?: BaseBookingTableSetup;
    tables: ExtendedTable[];
    areas: AreaAndTablesForTimeline;
    availability: TableAvailabilityForDate;
    dateTime?: string;
    bookingManagementData: ExtendedBookingManagementResponse;
    businessId: number;
    locationId: string;
    autoSeat?: boolean;
    waitlistItem?: WaitlistItem;
    onClose: (refresh?: boolean) => void;
}

function isAllowedToWalkin(date: Moment) {
    const now = RoundDownTo15(businessNowTime(), TIMELINE_INTERVAL);
    const from = now.clone();
    let to = now.clone();
    to = to.add(30, 'm');
    return date.isBefore(from) || date.isBetween(from, to, undefined, '[)');
}

const NewBookingModal = ({
    bookingAmendment,
    table,
    tables,
    dateTime,
    businessId,
    locationId,
    bookingManagementData,
    autoSeat,
    waitlistItem,
    onClose
}: ComponentProps) => {
    const [loading, setLoading] = useState<boolean>();
    const [loadedTables, setLoadedTables] = useState<ExtendedTable[]>(tables);
    const [step, setStep] = useState<number>(waitlistItem ? 1 : 0);
    const [availResponse, setAvailResponse] = useState<HoldOrGetAvailableTablesResponse>();
    const [existing, setExisting] = useState<boolean>();
    const [newClient, setNewClient] = useState<boolean>();
    const [businessLoaded, businessData] = useBusiness();
    const [selectedExperience, setSelectedExperience] = useState<number>();
    const [experienceTimes, setExperienceTimes] = useState<ApiBookingTimes>();
    const selectedExperienceRef = useRef<number>();
    const [clientList, setClientList] = useState<TypeaheadDataItem[]>([])
    const [errors, setErrors] = useState<BookingError[]>([]);
    const [selectedTables, setSelectedTables] = useState<number[]>([]);
    const [selectedArea, selectArea] = useState<AreaAndTablesForTimeline>(table ? bookingManagementData.areaAndTables[table.areaId] : Object.values(bookingManagementData.areaAndTables)[0]);
    const [confirmAllocationOpen, setConfirmAllocationOpen] = useState(false);
    const [shortTimeOpen, setShortTimeOpen] = useState<boolean>(false);
    const [depositRuleInfo, setDepositRuleInfo] = useState<BaseDepositRule>();
    const [depositRuleInfoOpen, setDepositRuleInfoOpen] = useState<boolean>(false);
    const [pacingWarningOpen, setPacingWarningOpen] = useState<boolean>(false);
    const [pacingWarningData, setPacingWarningData] = useState<string>();
    const [timesLoading, setTimesLoading] = useState<boolean>()
    const [sendClientEmail, setSendClientEmail] = useState<boolean>(true)
    const [shortTime, setShortTime] = useState<number>();
    const [desiredDuration, setDesiredDuration] = useState<number>();
    const [customGuests, setCustomGuests] = useState<number>();
    const [systemDefaultDuration, setSystemDefaultDuration] = useState<number>();
    const [tablesResponse, setTablesResponse] = useState<{ [key: string | number]: TableAvailability }>({});
    const [suggestedTablesSelected, setSuggestedTablesSelected] = useState<boolean>(false);
    const [experiencesForDateTime, setExperiencesForDateTime] = useState<{ [key: number | string]: boolean }>();
    const lastSlotSearch = useRef<{ date: string, guests: number, experienceId?: number }>({ date: null, guests: null });

    const [booking, setBooking] = useState<BaseActiveBooking>(bookingAmendment || {
        startDateTime: dateTime,
        endDateTime: dateTime,
        businessId: businessId,
        tables: table?.id ? [table.id] : [],
        guests: waitlistItem ? waitlistItem.guests : 1,
        bookedAtUtc: businessNowTime().format(DATABASE_TIME_FORMAT)
    });
    const bookedBooking = useRef<BaseActiveBooking>()
    const [times, setTimes] = useState<DateAndTimeSlots>();
    const rendered = useRef(false);
    const modalContentRef = useRef<HTMLDivElement>();
    const selectedIsToday = dateIsToday(booking.startDateTime);

    useEffect(() => {
        ApiService.businessClient.ListLight__GET(businessData.parentBusinessId)
            .then(response => setClientList(response.filter(x => x.email || x.telephone).map(item =>
            (
                {
                    value: `${item.firstName} ${item.surname ? item.surname : ''} (${item.telephone}) (${item.email})`,
                    text: `${item.firstName} ${item.surname ? item.surname : ''} (${item.telephone ? item.telephone : 'phone: N/A'}) (${item.email ? item.email : 'email: N/A'})`,
                    extraInfo: item
                }
            )
            ))).catch(error => NotificationService.Error('Something went wrong fetching clients'));
        setTimeout(() => {
            rendered.current = true;
        }, 500);

        if (waitlistItem) {
            getTimesForDate(moment().startOf('D'), waitlistItem.guests)
        }

        return () => {
            rendered.current = false;
        }
    }, [])

    const closeModal = () => {
        if (!rendered.current) return;
        releaseTable()
        onClose();
    }

    const getIntervals = (lastNumber: number,
        intervalDifference: number,
        systemDefaultDuration: number,
        desiredDuration: number,
        selectedTables: ExtendedTable[],
        tablesResponse: { [key: string | number]: TableAvailability }) => {
        const items: DropdownItem[] = [];
        let maxDuration = lastNumber;

        for (let i = 0; i <= selectedTables.length - 1; i++) {
            if (tablesResponse[selectedTables[i].id]) {
                const tableMaxDuration = tablesResponse[selectedTables[i].id].maximumDurationTableCanBeBookedFor;
                if (tableMaxDuration) {
                    maxDuration = Math.min(maxDuration, tableMaxDuration);
                }
            }
        }

        let foundDesired = false;
        let foundSystemDefault = false;

        for (let i = intervalDifference; i <= maxDuration; i = i + intervalDifference) {

            const minutesAndHours = minutesToTime(i);
            const dropdownTextValue = getDurationText(minutesAndHours);
            const item: DropdownItem = {
                value: `${i}`,
                text: `${i == systemDefaultDuration ? (dropdownTextValue + ' (Recommended)') : dropdownTextValue}`
            }

            if (i == desiredDuration) {
                foundDesired = true;
            }

            if (i == systemDefaultDuration) {
                foundSystemDefault = true;
            }

            items.push(item);
        }

        if (!foundDesired) {
            if (foundSystemDefault) {
                updateSearchableValueForDuration(systemDefaultDuration);
            } else {
                // if desired duration and system default (ie recommended) are not available in the dropdown,
                // default it to the last (largest) duration available
                if (!!items && items.length > 0) {
                    updateSearchableValueForDuration(items[items.length - 1].value);
                }
            }
        }

        return items;
    }

    const updateSearchableValueForDuration = (value: any) => {
        setDesiredDuration(value);
    }

    const setStepAndReleaseTable = (step: number, resetSelectedTables: boolean = false) => {
        releaseTable();
        if (resetSelectedTables) {
            setSelectedTables([]);
        }
        if (depositRuleInfo) setDepositRuleInfo(undefined)
        setStep(step);
    }

    const releaseTable = () => {
        const holdRef = availResponse?.holdReference;
        setAvailResponse(undefined);
        if (!isNullOrWhitespace(holdRef)) {
            ApiService.makebooking.ReleaseHold__POST(holdRef);
        }
    }

    const updateBooking = (item: BaseActiveBooking) => {
        const newBooking = cloneDeep(booking);
        const oldStartDate = booking.startDateTime;
        Object.assign(newBooking, item);
        newBooking.startDateTime = oldStartDate;
        setBooking(newBooking)
    }

    const updateClient = (item: BaseClient) => {
        const newBooking = cloneDeep(booking);
        newBooking.client = item;
        setBooking(newBooking)
    }

    const addBooking = () => {
        if (booking.client && !booking.client.firstName) {
            NotificationService.Error('Please enter first name')
            return;
        }
        setLoading(true)
        booking.status = BookingStatus.Pending;
        booking.isWalkIn = !booking.client;
        if (booking.isWalkIn || autoSeat) {
            booking.status = BookingStatus.Seated;
        } else {
            booking.client.businessId = businessId;
        }
        let params: ApiMakeBookingAsBusinessRequest = {
            booking: booking,
            allowBookingBeyondClosingTime: true,
            tableIdsToMerge: selectedTables,
            holdReference: availResponse.holdReference,
            sendClientEmail: sendClientEmail && !canWalkIn
        }
        ApiService.bookings.Insert__PUT(params).then((data) => {
            if (data.success && +data.bookingId > 0) {
                bookedBooking.current = data.booking;
                if (!data.bookedTableNames) {
                    NotificationService.Confirm(`Booking added.`)
                } else {
                    NotificationService.Confirm(`Booking added for ${data.bookedTableNames}.`)
                }
                if (depositRuleInfo) {
                    booking.id = data.bookingId;
                    setBooking({ ...data.booking })
                    setStep(4);
                } else {
                    onClose(true);
                }
            } else if (data.errors) {
                setErrors(data.errors);
                setTimeout(() => {
                    if (modalContentRef) modalContentRef.current.scrollTo({
                        top: 1000,
                        left: 0,
                        behavior: "smooth",
                    });
                }, 200);
            } else {
                NotificationService.Error('Sorry, we could not complete the booking. It could be that a booking has already been made for the table.')
            }
            if (!data.errors) {
                setErrors([])
            }
        }).catch(() => {
            setErrors([])
            NotificationService.Error('Sorry, there was an error saving the booking.')
        }).finally(() => {
            setLoading(false);
        })
    }

    const canWalkIn = isAllowedToWalkin(createMomentFromValue(booking.startDateTime));

    const requestTable = (guests: number, time: string, dateOverride?: string) => {
        const newDate = formatDate(dateOverride || booking.startDateTime, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + formatDate(time, 'HH:mm:ss');
        const newBooking: BaseActiveBooking = { ...booking, startDateTime: newDate, guests, experienceId: selectedExperienceRef.current };
        setBooking(newBooking)
        setLoading(true);

        let holdParams: ApiBookingTimesRequest = {
            businessId: businessData.id,
            guests: guests,
            requestedTime: newDate,
            locationId: businessData.locationId,
            specificTableId: table?.id,
            desiredDuration,
            experienceId: newBooking.experienceId || selectedExperienceRef.current
        }

        if (!!selectedTables && selectedTables.length > 0) {
            holdParams.specificTableIdsToMerge = [...selectedTables]
            holdParams.specificTableId = undefined;

            ApiService.bookings.HoldTables__POST(holdParams).then((response) => {
                setAvailResponse(response);
                setDepositRuleInfo(response.depositRule)
                if (response.holdSuccess) {
                    if (response.heldForShorterTime) {
                        setShortTime(response.heldForDurationInMinutesWithoutChangeover)
                        setShortTimeOpen(true)
                    }
                    if (response.depositRule) setDepositRuleInfoOpen(true)
                    setStep(3);
                } else {
                    NotificationService.Error('One or more tables selected are not available anymore.');
                    setSelectedTables([]);
                    //todo the below code causes infinite loop... 
                    // requestTable(guests, time, false);
                }
                setLoading(false)
            })
        } else {
            // if (!holdParams.specificTableId) {
            holdParams.suppressHold = true; // we don't want to hold just yet, 
            // we want tables view showing suggested tables and for user to confirm tables to allocate peple to.
            // }

            ApiService.bookings.HoldOrGetTablesToMerge__POST(holdParams).then((response) => {
                handleGetTablesToMergeResponse(response)
            })
        }
    }

    const handleGetTablesToMergeResponse = (response: HoldOrGetAvailableTablesResponse) => {
        setAvailResponse(response);
        if (response.goesBeyondPacingLimit && table) {
            setPacingWarningOpen(true)
        }
        setDepositRuleInfo(response.depositRule)
        if (response.holdSuccess) {
            if (response.heldForShorterTime || response.depositRule) {
                if (response.heldForShorterTime) {
                    setShortTime(response.heldForDurationInMinutesWithoutChangeover)
                    setShortTimeOpen(true)
                }
                if (response.depositRule) {
                    setDepositRuleInfoOpen(true)
                }
            } else {
                setStep(3)
            }
        } else if (response.tablesForMerging) {
            const newTables = cloneDeep(tables);
            newTables.forEach(newTable => {
                newTable.disabled = !response.tablesForMerging.tableIdAndAvailability[newTable.id]?.available;
            });

            const firstAvailiableId = response?.tablesForMerging?.tableIdAndAvailability ? Object.keys(response.tablesForMerging.tableIdAndAvailability)[0] : undefined;
            let area: AreaAndTablesForTimeline = undefined;
            if (response.suggestedTableIds && response.suggestedTableIds.length > 0) {
                const foundTable = bookingManagementData.tables.find(x => x.id == response.suggestedTableIds[0]);
                if (foundTable) {
                    area = bookingManagementData.areaAndTables[foundTable.areaId]
                }
            } else if (firstAvailiableId) {
                const foundTable = bookingManagementData.tables.find(x => x.id == +firstAvailiableId);
                if (foundTable) {
                    area = bookingManagementData.areaAndTables[foundTable.areaId]
                }
            }
            if (table) {
                if (response.tablesForMerging.tableIdAndAvailability[table?.id]?.available) {
                    setSelectedTables([table.id]);
                    selectArea(bookingManagementData.areaAndTables[table.areaId])
                } else {
                    if (area) selectArea(area)
                    setSelectedTables(response.suggestedTableIds)
                }
            } else if (!!response.suggestedTableIds && response.suggestedTableIds.length > 0) {
                setSelectedTables(response.suggestedTableIds);
                if (response.suggestedTableIds?.length > 0 && area) selectArea(area)
                setSuggestedTablesSelected(true);
            } else {
                let selectedAvail = response.tablesForMerging.tableIdAndAvailability[table?.id]?.available;
                if (selectedAvail) {
                    selectArea(bookingManagementData.areaAndTables[table.areaId])
                    setSelectedTables([table.id]);
                }
            }
            setTablesResponse(response?.tablesForMerging?.tableIdAndAvailability)
            setLoadedTables(newTables)

            setDesiredDuration(response.suggestedTablesDuration)
            setSystemDefaultDuration(response.defaultDurationExcludingChangeover);
            setStep(2)
        } else {
            response.errors.forEach(error => {
                NotificationService.Error(error.message)
            });
        }
        setLoading(false);
    }

    const selectExistingClient = (item: TypeaheadDataItem) => {
        const foundClient: ClientModel = item.extraInfo;
        if (!!foundClient) {
            const newBooking = cloneDeep(booking);
            newBooking.client = {
                firstName: foundClient.firstName,
                surname: foundClient.surname,
                telephone: foundClient.telephone,
                email: foundClient.email
            }
            setExisting(true)
            setBooking(newBooking)
        }
    }

    const addNewClient = (value: string) => {
        const newBooking = cloneDeep(booking);
        newBooking.client = {
            firstName: '',
            surname: '',
            email: '',
            telephone: ''
        }
        if (value.includes('@')) {
            newBooking.client.email = value;
        } else if (!Number.isNaN(+value)) {
            newBooking.client.telephone = value;
        } else {
            const valSplit = value.split(' ');
            if (valSplit.length > 1) {
                newBooking.client.surname = valSplit[valSplit.length - 1];
                valSplit.length = valSplit.length - 1;
            }
            newBooking.client.firstName = valSplit.join(' ');
        }
        setBooking(newBooking)
        setNewClient(true)
    }

    const updateGuests = (guests: number, checkAvail: boolean = true) => {
        const newBookingInfo = cloneDeep(booking);
        newBookingInfo.guests = guests;
        releaseTable();
        setSelectedTables([]);
        if (depositRuleInfo) setDepositRuleInfo(undefined)
        updateBooking(newBookingInfo);
        if (checkAvail) {
            if (table) {
                if (bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo && Object.keys(bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo).length > 0) {
                    const newDate = formatDate(booking.startDateTime, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + formatDate(dateTime, 'HH:mm:ss');
                    setLoading(true);
                    ApiService.bookings.GetEventAvailabilityOrTablesToMerge__POST({
                        requestedTime: newDate,
                        guests,
                        businessId: businessData.id,
                        locationId: businessData.locationId
                    }).then((response) => {
                        setLoading(false);
                        if (response.availableIds && Object.keys(response.availableIds).length > 0) {
                            setExperiencesForDateTime((response?.availableIds as any))
                            setStep(1.5)
                            setBooking({ ...booking, guests, startDateTime: dateTime })
                        } else if (response.holdResponse) {
                            handleGetTablesToMergeResponse(response.holdResponse)
                        } else {
                            requestTable(guests, dateTime);
                        }
                    })
                } else {
                    requestTable(guests, dateTime);
                }
            } else {
                getTimesForDate(createMomentFromValue(booking.startDateTime), guests)
                setStep(1);
            }
        } else {
            setStep(0);
        }
    }

    const guestButtons: React.ReactNode[] = [];

    if (step === 0) {
        for (let i = 1; i <= 15; i++) {
            const defaultType = i > table?.seats || i < table?.minimumOccupancy ? 'warning' : 'secondary';
            guestButtons.push(<Column size={4} mobile={12}>
                <CoreButton
                    type={bookingAmendment ? (i == bookingAmendment.guests ? 'success' : defaultType) : defaultType}
                    full
                    onClick={() => updateGuests(i)}>
                    {(i > table?.seats || i < table?.minimumOccupancy) && <WarningIcon name='exclamation-triangle' />}
                    {bookingAmendment && bookingAmendment.guests === i && <WarningIcon name='star' />}
                    {i} guest{i > 1 && 's'}
                </CoreButton>
            </Column>)
        }
    }

    const selectTable = (tableId: number) => {

        if (selectedTables.includes(tableId)) {
            setSelectedTables(selectedTables.filter(x => x !== tableId));
        } else {
            if (tableId) {
                setSelectedTables([...selectedTables, tableId]);
            }
        }
    }

    const getTimesForDate = (date: Moment, guests?: number) => {
        const formattedDate = date.format(DATABASE_TIME_FORMAT);
        const selectedGuests = guests || booking.guests;
        if (lastSlotSearch.current.date != formattedDate || lastSlotSearch.current.guests != selectedGuests || lastSlotSearch.current.experienceId !== selectedExperienceRef.current) {
            lastSlotSearch.current = {
                date: formattedDate,
                guests: selectedGuests,
                experienceId: selectedExperienceRef.current
            }
            setTimesLoading(true)
            ApiService.bookings.NextAvailableSlots__POST({
                requestedTime: formattedDate,
                guests: selectedGuests,
                locationId,
                bookingReference: bookingAmendment?.bookingReference,
                experienceId: selectedExperienceRef.current
            }).then((response) => {
                setTimes(response);
                setTimesLoading(false)
            })
        }
    }

    const isOpen = (date) => {
        if (bookingManagementData.closedExceptionDays && bookingManagementData.closedExceptionDays.hasOwnProperty(formatDate(date, 'YYYY-MM-DD') + 'T00:00:00')) return false;
        return bookingManagementData.openingTimes.find(x => !x.closed && x.dayOfWeek === formatDate(date, 'dddd'));
    };

    let selectedTableCoverCount = 0;
    let selectedAreas: { [key: string]: boolean } = {}

    const selectedTableItems = loadedTables ? selectedTables.map((tableId) => {
        const table = tables.find(x => x.id === tableId);
        selectedAreas[table.areaId] = true;
        selectedTableCoverCount += table.seats;
        return table;
    }) : [];

    const overallocationAmount = selectedTableCoverCount - booking.guests;

    const moreTablesThanNeeded = overallocationAmount > 0 && !!selectedTableItems.find(x => x.seats <= overallocationAmount);

    const confirmMerge = () => {
        if (moreTablesThanNeeded || selectedTableCoverCount < booking.guests || Object.keys(selectedAreas).length > 1) {
            setConfirmAllocationOpen(true)
        } else {
            requestTable(booking.guests, booking.startDateTime);
        }
    }

    const confirmAllocation = () => {
        requestTable(booking.guests, booking.startDateTime);
        setConfirmAllocationOpen(false);
    }

    const confirmPacingWarning = () => {
        if (pacingWarningData) {
            requestTable(booking.guests, pacingWarningData);
            setPacingWarningData(undefined)
        }
        setPacingWarningOpen(false);
    }

    const openPacingWarningForTimeSlot = (slot: string) => {
        setPacingWarningOpen(true);
        setPacingWarningData(slot)
    }

    const selectExperienceWhenKnownDateAndTime = (id: number) => {
        setSelectedExperience(id)
        selectedExperienceRef.current = id;
        setBooking({ ...booking, experienceId: id })
        requestTable(booking.guests, booking.startDateTime);
    }

    const onExperienceSelect = (id: number) => {
        selectedExperienceRef.current = selectedExperience == +id ? undefined : (id ? +id : undefined);
        setSelectedExperience(selectedExperience == +id ? undefined : (id ? +id : undefined))
        if (selectedExperienceRef.current && bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo[selectedExperienceRef.current].type == ExperienceType.Event) {
            ApiService.bookings.GetNextAvailable__POST({
                requestedTime: formatDate(moment(), DATABASE_TIME_FORMAT),
                guests: booking.guests,
                locationId,
                bookingReference: bookingAmendment?.bookingReference,
                experienceId: selectedExperienceRef.current,
                businessId: businessData.id
            }).then((response) => {
                setExperienceTimes(response)
            })
        } else {
            setExperienceTimes(undefined)
            getTimesForDate(createMomentFromValue(lastSlotSearch.current.date), lastSlotSearch.current.guests)
        }
    }

    const tableNameDisplay = getSelectedTableDisplay(selectedTables, tables, table);
    const seatsLeftToAllocate = booking.guests - selectedTableCoverCount;
    const invalidMerge = moreTablesThanNeeded || selectedTables?.length === 0 || selectedTableCoverCount < booking?.guests;
    const onlyExtensionTableSelected = selectedTables?.length === 1 && loadedTables.find(y => y.id === selectedTables[0])?.isTableExtension;
    const specialOccasionOptions: DropdownItem[] = [];

    if (!isNullOrWhitespace(bookingManagementData?.specialOccasions)) {
        const occasionSplit = bookingManagementData?.specialOccasions.split('|');
        Object.keys(BookingSpecialOccasions()).forEach(occasion => {
            if (occasionSplit.indexOf(occasion) > -1) specialOccasionOptions.push({ value: occasion, text: BookingSpecialOccasions()[occasion].label });
        });
    }

    let title = 'New booking';
    if (bookingAmendment) title = 'Amend booking';
    if (waitlistItem) title = 'Seat waitlist guest';

    let hasTimeSlots = false;
    let hasMutipleShifts = false;

    const experienceAndEventList = bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo;

    if (times) Object.keys(times.shiftLabelAndTimeSlots).forEach((label, index) => {
        if (times.shiftLabelAndTimeSlots[label].slots.length > 0) hasTimeSlots = true;
        if (index > 0) hasMutipleShifts = true;
    });

    console.log(customGuests)

    return (
        <FormWrapper onUpdate={updateBooking} disabled={loading}>
            {({ id, valid }) => (
                <CoreModal title={<>
                    {title}
                    <BookingInfoTitle>
                        {(booking.experienceId && step >= 2) && <BookingInfoIcon><Badge colorScheme='messenger'>{bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo[booking.experienceId]?.name}</Badge></BookingInfoIcon>}
                        {tableNameDisplay && <BookingInfoIcon><Icon name='dining-table' /> {tableNameDisplay}</BookingInfoIcon>}
                        {(bookingAmendment || table || step > 1) && <BookingInfoIcon><Icon name='calendar' /> {formatDate(booking.startDateTime, DEFAULT_DATE_FORMAT)}</BookingInfoIcon>}
                        {(bookingAmendment || table || step > 1) && <BookingInfoIcon><Icon name='clock' /> {formatDate(booking.startDateTime, TIMEFORMAT)}</BookingInfoIcon>}
                        {(bookingAmendment || step > 0) && <BookingInfoIcon><Icon name='users' /> {booking.guests}</BookingInfoIcon>}
                    </BookingInfoTitle>
                </>} onClose={closeModal} actionBar={<>
                    {step === 3 && (canWalkIn || existing || newClient) && <CoreButton requesting={loading} disabled={loading} onClick={addBooking}>{depositRuleInfo ? 'Add booking and continue to payment' : 'Add booking'}</CoreButton>}
                    {step === 2 && <CoreButton requesting={loading} disabled={onlyExtensionTableSelected || invalidMerge || loading} onClick={confirmMerge}><Icon name='check' /> Confirm selection</CoreButton>}
                </>} contentRef={modalContentRef} noTitleMarginBottom>
                    {!loading &&
                        <BookingTabs noMarginBottom={step == 2}>
                            {!booking.id && <>
                                {!waitlistItem && <TabButton active={step === 0} onClick={() => { updateGuests(1, false); if (experiencesForDateTime) setExperiencesForDateTime(undefined) }}><Icon name='users' duo /><TabText> Guests</TabText></TabButton>}
                                {!table && <TabButton disabled={step < 1} active={step === 1} onClick={() => step < 1 ? null : setStepAndReleaseTable(1, true)}><Icon name='calendar' duo /><TabText> Date and time</TabText></TabButton>}
                                {experiencesForDateTime && <TabButton disabled={step < 1.5} active={step === 1.5} onClick={() => step < 1.5 ? null : setStepAndReleaseTable(1.5, true)}><Icon name='sparkles' duo /><TabText> Experiences</TabText></TabButton>}

                                {<TabButton disabled={step < 2} active={step === 2} onClick={() => step < 2 ? null : setStepAndReleaseTable(2)}>
                                    {<><Icon name='dining-table' /><TabText> Confirm tables</TabText></>}
                                </TabButton>}

                                {!bookingAmendment && <TabButton disabled={step < 3} active={step === 3} onClick={() => step < 3 ? null : setStep(3)}><Icon name='pencil' duo /><TabText> Customer details</TabText></TabButton>}
                            </>}
                            {depositRuleInfo && <TabButton disabled={step < 4} active={step === 4} onClick={() => step < 4 ? null : setStep(4)}><Icon name='credit-card' duo /><TabText> Payment details</TabText></TabButton>}
                        </BookingTabs>
                    }
                    {confirmAllocationOpen &&
                        <CoreModal small onClose={() => setConfirmAllocationOpen(false)} title='Confirm table allocation' actionBar={<CoreButton onClick={() => confirmAllocation()}>Confirm allocation</CoreButton>}>
                            {moreTablesThanNeeded &&
                                <ErrorMessage>You are about to make a booking with more tables than you need. Are you sure you want to continue booking?</ErrorMessage>
                            }
                            {selectedTableCoverCount < booking.guests &&
                                <WarningMessage>You are about to make a booking with not enough seats selected. Are you sure you want to continue booking?</WarningMessage>
                            }
                            {Object.keys(selectedAreas).length > 1 &&
                                <WarningMessage>You are about to make a booking with tables merged over {Object.keys(selectedAreas).length} areas. Are you sure you want to continue booking?</WarningMessage>
                            }
                        </CoreModal>
                    }
                    {shortTimeOpen &&
                        <CoreModal small onClose={() => setShortTimeOpen(false)} title='Confirm duration' actionBar={<CoreButton onClick={() => { if (!depositRuleInfoOpen) { setStep(3); } setShortTimeOpen(false) }}>Confirm</CoreButton>}>
                            <WarningMessage>
                                This table is not available for the full duration. Are you sure you want to continue booking? &nbsp;
                                <br />
                                <strong> Table available for:&nbsp; </strong> {shortTime} minutes
                            </WarningMessage>
                        </CoreModal>
                    }

                    {depositRuleInfoOpen &&
                        <CoreModal small onClose={() => setDepositRuleInfoOpen(false)} title={`Payment rule found`} actionBar={<CoreButton onClick={() => { if (!shortTimeOpen) { setStep(3); } setDepositRuleInfoOpen(false) }}>Confirm</CoreButton>}>
                            <InfoMessage>
                                A payment rule has been found that matches this booking. You will be promted to take card information after entering client details.
                            </InfoMessage>
                            <br />
                            <br />
                            <strong>Amount:</strong> {ConvertCurrency({ code: businessData.currencyCode, amount: depositRuleInfo.amount })}<br />
                            <strong>Type:</strong> {depositRuleInfo.type === DepositRuleType.PerPerson ? 'Per person' : 'Per booking'}<br />
                            <strong>When:</strong> <>
                                {depositRuleInfo.paymentType == DepositPaymentType.TakeNow && 'Payment required now'}
                                {depositRuleInfo.paymentType == DepositPaymentType.TakeOnNoShow && 'Payment required if customer no-shows'}
                                {depositRuleInfo.paymentType == DepositPaymentType.TakeOnCancelAndNoShow && 'Payment required if customer cancels or no-shows'}
                            </>
                            <br />
                            <br />
                            <hr />
                            <strong>Total:</strong> {ConvertCurrency({ code: businessData.currencyCode, amount: (depositRuleInfo.type === DepositRuleType.PerPerson ? depositRuleInfo.amount * booking.guests : depositRuleInfo.amount) })}<br />
                            <hr />
                        </CoreModal>
                    }

                    {pacingWarningOpen &&
                        <CoreModal small hasCancel={!!pacingWarningData} onClose={() => { setPacingWarningOpen(false); setPacingWarningData(undefined) }} title='Selected time goes beyond pacing limit' actionBar={<CoreButton onClick={() => confirmPacingWarning()}>Confirm</CoreButton>}>
                            <WarningMessage>
                                The following action may put pressure on staff and kitchen as the number of covers at this time slot will go beyond the recommended limit. Are you sure you want to proceed?
                            </WarningMessage>
                        </CoreModal>
                    }

                    {loading && step !== 3 && <Loader />}

                    {!loading && step === 0 &&
                        <GuestContainer>
                            <Row>
                                {guestButtons}
                            </Row>
                            <Row>
                                <Column size={8} mobile={12}>
                                    <StyledNumberInput hasPlusMinus value={customGuests} min={1} placeholder='Custom number of guests' onChange={(e) => {console.log(e); setCustomGuests(isNullOrWhitespace(e.target.value) ? undefined : +e.target.value);}} />
                                </Column>
                                <Column size={4} mobile={12}>
                                    <CoreButton disabled={isNullOrWhitespace(customGuests) || customGuests < 1} full type='secondary' onClick={() => updateGuests(customGuests)}><Icon name='check' /> Confirm</CoreButton>
                                </Column>
                            </Row>
                        </GuestContainer>
                    }
                    {!loading && step === 1 &&
                        <NewBookingWrapper>
                            {experienceAndEventList && Object.keys(experienceAndEventList).length > 0 &&
                                <TagContainer>
                                    <Tag selected={!selectedExperience} onClick={() => onExperienceSelect(undefined)}>
                                        Standard booking
                                    </Tag>
                                    {Object.keys(experienceAndEventList).map((id) => (
                                        <Tag selected={selectedExperience == +id} onClick={() => onExperienceSelect(+id)}>
                                            {experienceAndEventList[id].type == ExperienceType.Event && <Icon name='calendar' />}
                                            {experienceAndEventList[id].type == ExperienceType.Experience && <Icon name='sparkles' />}
                                            {experienceAndEventList[id].name}
                                        </Tag>
                                    ))}
                                </TagContainer>
                            }
                            {experienceTimes && experienceTimes.availability.length == 0 &&
                                <InfoMessage>No availability found for event</InfoMessage>
                            }
                            {experienceTimes &&
                                <TimeRow>
                                    {experienceTimes.availability.map(date => (
                                        <>
                                            <Column size={11}>
                                                <strong>{formatDate(date.date, DISPLAY_DATE_FORMAT)}</strong>
                                            </Column>
                                            {date.times.map(time => (
                                                <Column size={2} mobile={12} key={time.time} onClick={() => time.goesBeyondPacingLimit ? openPacingWarningForTimeSlot(time.time) : requestTable(booking.guests, time.time, date.date)}>
                                                    <ButtonTime type='button'>
                                                        {time.goesBeyondPacingLimit && <><WarningIcon name='person-circle-exclamation' duo />&nbsp;</>}
                                                        {time.requiresMerging &&
                                                            <>
                                                                <Icon name='arrows-to-line' duo />
                                                                &nbsp;
                                                                &nbsp;
                                                            </>
                                                        }
                                                        {formatDate(time.time, TIMEFORMAT) + (time.eventIds?.length > 0 ? '*' : '')}
                                                    </ButtonTime>
                                                </Column>
                                            ))}
                                        </>
                                    ))}
                                </TimeRow>
                            }
                            {!experienceTimes &&
                                <Row>
                                    {!waitlistItem &&
                                        <Column size={undefined} mobile={12} flexValue='0'>
                                            <BookingCalendar>
                                                <StyledCalendar
                                                    unlink
                                                    inline
                                                    model='startDateTime'
                                                    value={booking?.startDateTime}
                                                    months={1}
                                                    onChange={e => { getTimesForDate(e); setBooking({ ...booking, startDateTime: formatDate(e, DATABASE_TIME_FORMAT) }) }}
                                                    calendarProps={{ filterDate: isOpen, peekNextMonth: false, showPreviousMonths: false }}
                                                />
                                            </BookingCalendar>
                                        </Column>
                                    }
                                    <Column size={waitlistItem ? 12 : undefined} mobile={12} flexValue='1'>
                                        {selectedIsToday &&
                                            <>
                                                <Icon name='clock' /> <strong>Time now:</strong> {businessNowTime().format(TIMEFORMAT)}
                                                <br />
                                                <br />
                                            </>
                                        }
                                        {timesLoading && <Loader />}
                                        {!timesLoading &&
                                            <>
                                                <TimeRow>
                                                    {times && Object.keys(times.shiftLabelAndTimeSlots).map(shiftLabel => (
                                                        <>
                                                            {hasMutipleShifts &&
                                                                <Column size={12}>
                                                                    <strong>{shiftLabel}</strong>
                                                                </Column>
                                                            }
                                                            {times.shiftLabelAndTimeSlots[shiftLabel].slots.map(time => (
                                                                <Column size={waitlistItem ? 2 : 4} mobile={12} key={time.slot} onClick={() => time.goesBeyondPacingLimit ? openPacingWarningForTimeSlot(time.slot) : requestTable(booking.guests, time.slot)}>
                                                                    <ButtonTime type='button'>
                                                                        {time.goesBeyondPacingLimit && <><WarningIcon name='person-circle-exclamation' duo />&nbsp;</>}
                                                                        {time.requiresMerging &&
                                                                            <>
                                                                                <Icon name='arrows-to-line' duo />
                                                                                &nbsp;
                                                                                &nbsp;
                                                                            </>
                                                                        }
                                                                        {formatDate(time.slot, TIMEFORMAT) + (time.eventIds?.length > 0 ? '*' : '')}
                                                                    </ButtonTime>
                                                                </Column>
                                                            ))}
                                                        </>
                                                    ))}
                                                </TimeRow>
                                            </>
                                        }
                                        {!timesLoading && times && !hasTimeSlots &&
                                            <WarningMessage>Sorry, there are no available time slots.</WarningMessage>
                                        }
                                        {/* <OkDateButton full type='secondary' onClick={() => requestTable(booking.guests)}><Icon name='check' /> Confirm</OkDateButton> */}
                                    </Column>
                                </Row>
                            }
                        </NewBookingWrapper>
                    }
                    {!loading && step === 1.5 &&
                        <Row style={{ marginTop: '3rem' }}>
                            <Column size={3} />
                            <Column size={6}>
                                <Tag full onClick={() => selectExperienceWhenKnownDateAndTime(undefined)}>
                                    Standard booking
                                </Tag>
                            </Column>
                            <Column size={3} />
                            {Object.keys(experiencesForDateTime).map(key => (
                                <>
                                    <Column size={3} />
                                    <Column size={6}>
                                        <Tag full onClick={() => selectExperienceWhenKnownDateAndTime(+key)}>
                                            {bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo[key]?.type == ExperienceType.Event && <Icon name='calendar' />}
                                            {bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo[key]?.type == ExperienceType.Experience && <Icon name='sparkles' />}
                                            {bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo[key]?.name}
                                        </Tag>
                                    </Column>
                                    <Column size={3} />
                                </>
                            ))}
                        </Row>
                    }
                    {!loading && step === 2 &&
                        <div>
                            <StageWrapper>
                                <div style={{ flex: 'none' }}>
                                    {Object.keys(bookingManagementData.areaAndTables).length > 1 &&
                                        <TabBar noMarginBottom>
                                            {Object.entries(bookingManagementData.areaAndTables).map(([areaId, area]) => (
                                                <TabButton key={`areaTab-${area.areaId}`} active={selectedArea.areaId === area.areaId} onClick={() => selectArea(area)}>
                                                    {area.areaName}
                                                </TabButton>
                                            ))}
                                        </TabBar>
                                    }
                                    <StageScale style={{ transformOrigin: 'top left', scale: window.innerWidth < BREAKPOINTS.mobileLarge ? '0.48' : '0.8' }}>
                                        {!bookingAmendment && suggestedTablesSelected &&
                                            <WarningMessage>Suggested tables pre-selected. Edit or proceed with proposed tables.</WarningMessage>
                                        }
                                        <MainStage
                                            tables={loadedTables}
                                            shapes={bookingManagementData.shapes}
                                            bookingManagementData={{ ...bookingManagementData, availability: null }}
                                            selectedArea={{ name: selectedArea.areaName, id: selectedArea.areaId, scale: selectedArea.areaScale }}
                                            setLayout={() => { }}
                                            setShapes={() => { }}
                                            setArea={() => { }}
                                            save={() => { }}
                                            saveShape={() => { }}
                                            selectTable={selectTable}
                                            selectShape={() => { }}
                                            selectedTables={selectedTables}
                                            readonly
                                            mergeMode
                                        />
                                    </StageScale>
                                </div>
                                <div style={{ padding: '0.5rem', flex: '1 1 auto' }}>
                                    <StyledDropdown
                                        unlink
                                        addDefault={false}
                                        label='Duration:'
                                        disabled={!selectedTables || selectedTables.length === 0}
                                        value={desiredDuration}
                                        onChange={e => updateSearchableValueForDuration(+e.target.value)}
                                        items={getIntervals(480, 15, systemDefaultDuration, desiredDuration, selectedTableItems, tablesResponse)}
                                    />
                                    <div style={{ paddingBottom: '0.5rem' }}></div>
                                    <H4 marginBottom={0}>Selected tables</H4>
                                    {selectedTables.length === 0 &&
                                        <>
                                            <Icon regular name='triangle-exclamation' />
                                            <strong>You have not selected any tables</strong>
                                        </>}
                                    {selectedTableItems.map((table) => (
                                        <>
                                            <Row>
                                                <Column marginBottom={5} marginTop={5} size={6}>
                                                    <strong>Table {table.tableName}</strong>
                                                </Column>
                                                <Column marginBottom={5} marginTop={5} size={6}>
                                                    <Icon name='user' /> {table.seats}
                                                </Column>
                                            </Row>
                                            <hr />
                                        </>
                                    ))}
                                    {selectedTableCoverCount > 0 &&
                                        <>
                                            <Row>
                                                <Column size={6} marginBottom={5} marginTop={5}>
                                                    <strong>Total selected: </strong>
                                                </Column>
                                                <Column size={6} marginBottom={5} marginTop={5}>
                                                    <Icon name='user' /> {selectedTableCoverCount}
                                                </Column>
                                            </Row>
                                            <hr />
                                        </>
                                    }
                                    {selectedTables.length > 0 && seatsLeftToAllocate > 0 &&
                                        <>
                                            <br />
                                            <Icon regular name='triangle-exclamation' /><strong>Please allocate </strong> {seatsLeftToAllocate} <strong> more seat{seatsLeftToAllocate == 1 ? '' : 's'} </strong><br />
                                        </>
                                    }
                                    {selectedTables.length > 0 && seatsLeftToAllocate <= 0 && !moreTablesThanNeeded &&
                                        <>
                                            <br />
                                            <SuccessMessage><Icon regular name='check' />All allocated ({booking.guests} guest{booking.guests == 1 ? '' : 's'}) </SuccessMessage>
                                        </>
                                    }
                                    <br />
                                    {moreTablesThanNeeded &&
                                        <>
                                            <ErrorMessage>One or more selected tables are not needed to seat the party. Please de-select tables that are not needed before continuing.</ErrorMessage>
                                            <br />
                                        </>
                                    }
                                    {onlyExtensionTableSelected &&
                                        <>
                                            <ErrorMessage>This table is only bookable in combination with other tables.</ErrorMessage>
                                            <br />
                                        </>
                                    }
                                </div>
                            </StageWrapper>
                        </div>
                    }
                    {step === 3 &&
                        <>
                            {shortTime &&
                                <>
                                    <WarningMessage>This table is not available for the full duration. <strong>Table available for:&nbsp;</strong> {shortTime} minutes</WarningMessage>
                                    <br />
                                </>
                            }
                            {(existing || newClient) && !canWalkIn &&
                                <EmailCheckbox inputName='sendEmail' label='Send confirmation email' asToggle checked={sendClientEmail} onChange={() => setSendClientEmail(!sendClientEmail)} />
                            }
                            {availResponse?.heldTableExpiryInUtc && !availResponse?.tablesForMerging &&
                                <>
                                    <BookingTimer tableNames={availResponse.heldTableNames} time={createMomentFromValue(availResponse.heldTableExpiryInUtc, true)} />
                                    <br />
                                </>
                            }
                            {!existing && !newClient &&
                                <>
                                    {canWalkIn && <InfoMessage> Enter client info in the input below or to skip client details, click "Add booking" button.</InfoMessage>}
                                    <Row>
                                        <Column size={3}></Column>
                                        <Column size={6}>
                                            <Row>
                                                <Column size={12} hidden={existing}>
                                                    <br />
                                                    <Typeahead
                                                        required={!canWalkIn}
                                                        model={createUUID()}
                                                        id={createUUID()}
                                                        label='Phone, email or name:'
                                                        value={booking?.guests}
                                                        items={clientList}
                                                        onChange={selectExistingClient}
                                                        addNew={addNewClient}
                                                        unlink />
                                                </Column>
                                            </Row>
                                        </Column>
                                    </Row>
                                </>
                            }
                            {(existing || newClient) &&
                                <>
                                    <FormWrapper onUpdate={updateClient} disabled={existing}>
                                        {({ id, valid }) => (
                                            <>
                                                <Row>
                                                    <Column size={6}>
                                                        <StyledTextInput
                                                            model='firstName'
                                                            label='First name:'
                                                            value={booking?.client?.firstName}
                                                            required />
                                                    </Column>
                                                    <Column size={6}>
                                                        <StyledTextInput
                                                            model='surname'
                                                            label='Surname:'
                                                            value={booking?.client?.surname} />
                                                    </Column>
                                                </Row>
                                                <Row>
                                                    <Column size={6}>
                                                        <StyledTextInput
                                                            model='telephone'
                                                            label='Telephone:'
                                                            value={booking?.client?.telephone} />
                                                    </Column>
                                                    <Column size={6}>
                                                        <StyledTextInput
                                                            model='email'
                                                            label='Email address:'
                                                            max={EmailAddressSize}
                                                            value={booking?.client?.email} />
                                                    </Column>
                                                </Row>
                                            </>
                                        )}
                                    </FormWrapper>
                                    <Row>
                                        <Column size={specialOccasionOptions.length > 0 ? 6 : 12}>
                                            <StyledTextarea
                                                model='notes'
                                                label='Booking notes:'
                                                value={booking?.notes}
                                            />
                                        </Column>
                                        {specialOccasionOptions.length > 0 &&
                                            <Column size={6}>
                                                <StyledDropdown label='Select an occasion' model='specialOccasion' value={booking?.specialOccasion} items={specialOccasionOptions} />
                                            </Column>
                                        }
                                    </Row>
                                </>
                            }
                        </>
                    }
                    {step === 4 &&
                        <ManualPayment
                            lockForm
                            depositRuleToApply={depositRuleInfo}
                            booking={bookedBooking.current}
                            amount={depositRuleInfo?.type == DepositRuleType.PerBooking ? depositRuleInfo?.amount : depositRuleInfo?.amount * booking.guests}
                            takeNow={depositRuleInfo?.paymentType == DepositPaymentType.TakeNow}
                            onClose={() => onClose(true)}
                        />
                    }
                    {errors.map((e, index) => (
                        <ErrorMessage key={`Error-${index}`}>{e.message}</ErrorMessage>
                    ))}
                </CoreModal>
            )}
        </FormWrapper>
    )
};

const GuestContainer = styled.div`
    width: 95%;
`

const WarningIcon = styled(Icon)`
`

const OkDateButton = styled(CoreButton)`
    height: 3rem;
    float: right;
    margin: 0;
`

const NewBookingWrapper = styled.div`
    .react-datepicker__time-list {
        height: 14.6rem !important;
    }
`

const BookingTabs = styled(TabBar)`
    .icon {
        margin-left: 0 !important;
    }
`

const BookingCalendar = styled.div`
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: 3.4rem;
        line-height: 3.4rem;
    }

    .react-datepicker__navigation-icon {
        font-size: 40px;
    }

    .react-datepicker__navigation {
        width: 80px;
        height: 80px;
    }

    .react-datepicker__day--outside-month {
        opacity: 0;
        pointer-events: none;
        touch-action: none;
    }

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        margin: 0 auto;
        display: table;

        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
            width: 2.3rem;
            line-height: 2.3rem;
        }
    
        .react-datepicker__navigation-icon {
            font-size: 40px;
        }
    
        .react-datepicker__navigation {
            width: 80px;
            height: 60px;
        }
    
        .react-datepicker__day--outside-month {
            opacity: 0;
            pointer-events: none;
            touch-action: none;
        }
    }
`

const TimeRow = styled(Row)`
    max-height: 22rem;
    // overflow: auto;
`

const ButtonTime = styled.button`
    width: 100%;
    padding: 0.5rem;
    border: 2px solid ${props => props.theme.primary};
    border-radius: 0.5rem;
    transition: all 0.4s ease;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    line-height: 2rem;

    span {
        margin: 0.5rem 0.1rem;
    }

    &:hover {
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.primaryContrast};
    }
`

const TabText = styled.span`
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: none;
    }
`

const StageScale = styled.div`
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        height: 20rem;
    }
`

const StageWrapper = styled.div`
    display: flex;
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: block;
    }
`

const EmailCheckbox = styled(Checkbox)`
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        float: right;
    }
`

export default NewBookingModal;
