import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../Media/Icon';
import { PortalProps, Tooltip } from '@chakra-ui/react';

interface ComponentProps {
    children: React.ReactNode;
    portalProps?: Pick<PortalProps, "appendToParentPortal" | "containerRef">;
}

const InfoBox = styled.div`
    position: absolute;
    border-radius: 0.2rem;
    border: 1px solid grey;
    top: 0;
    left: 2rem;
    padding: 1rem;
    color: white;
    background-color: #192733;
    width: 25rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 25px;
    visibility: hidden;
`;

const InfoContainer = styled.div`
    display: inline-block;
    cursor: pointer;
`;

const InfoButton = ({ children, portalProps }: ComponentProps) => {
    const [open, setOpen] = useState(false)
    return (
        <Tooltip label={children} isOpen={open} portalProps={portalProps}>
            <InfoContainer
                onClick={() => setOpen(!open)}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                <Icon regular name='question-circle' />
            </InfoContainer>
        </Tooltip>
    );
};

export default InfoButton;