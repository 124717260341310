import { useEffect, useRef } from "react";

export const useTimer = (callback?: () => void, timer: number = 30000): (state: boolean) => void => {
    const active = useRef(false);
    const timerRef = useRef<NodeJS.Timeout>()

    useEffect(() => {
        refresh()

        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    const refresh = () => {
        if (active) {
            timerRef.current = setTimeout(() => {
                if (callback) callback();
                refresh();
            }, timer)
        } else if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

    }

    return (state) => active.current = state;
}