import React, { useEffect } from 'react';
import { NotificationService } from '../../../services/NotificationService';
import { NotificationType } from './Notification';
import { useToast, UseToastOptions } from '@chakra-ui/react';


interface NotificationProps {
    text: string;
    type: NotificationType;
    done?: boolean;
}

function getType(type: NotificationType): "info" | "warning" | "success" | "error" | "loading" {
    if (type == NotificationType.Success) return 'success';
    if (type == NotificationType.Error) return 'error';
    if (type == NotificationType.Info) return 'info';
    if (type == NotificationType.Warning) return 'warning';
    return 'info'
}

const NotificationContainer = () => {
    const toast = useToast();
    useEffect(() => {
        NotificationService.RegisterHandler(handler);
    }, [])

    const handler = (text: string, type: NotificationType) => {
        const params: UseToastOptions = {
            description: text,
            status: getType(type),
            duration: 2000,
            isClosable: true,
            position: 'top',
        }
        if (NotificationService._toastCmd) {
            NotificationService._toastCmd(params)
        } else {
            toast(params)
        }
    }
    return <></>
}

export default NotificationContainer;