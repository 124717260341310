import React, { useEffect } from 'react';
import useParentBusiness from '../../../../../hooks/useParentBusiness';
import { ApiService } from '../../../../../api/api-connectors';
import { ApiPaymentMethod, ApiSystemProduct } from '../../../../../api/api-definitions';
import CoreButton from '../../../../../components/Forms/Button';
import { ModalService } from '../../../../../services/ModalService';
import { Column, Row } from '../../../../../components/Layout/Grid';
import styled from 'styled-components';
import { BoxShadowStyle, BoxShadowStyleWithHover } from '../../../../../theme';
import { H3, H3style, H4 } from '../../../../../components/Typography/Headings';
import Loader from '../../../../../components/Layout/Loader';
import Icon from '../../../../../components/Media/Icon';
import { HEADING_FONT } from '../../../../../constants';
import { InfoMessage } from '../../../../../components/Forms/Messaging';
import { NotificationService } from '../../../../../services/NotificationService';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';
import BusinessPermissionsService from '../../../../../services/BusinessPermissionsService';
import { useBusinessPermissions } from '../../../../../hooks/useBusinessPermissions';

const SubscriptionButton = styled.div`
    background-color: ${props => props.theme.secondary};
    color: ${props => props.theme.secondaryContrast};
    padding: 0.5rem;
`

const SubscriptionTitle = styled.div`
    font-size: 1.5rem;
    ${HEADING_FONT}
    color: ${props => props.theme.primary};
`

const SubscriptionContainer = styled.div<{ active: boolean }>`
    ${props => props.active ? BoxShadowStyle : BoxShadowStyleWithHover}
    background-color: #FFF;
    padding: 1rem;
    text-align: center;
    height: 20.1rem;
    ${props => props.active ? `
        background: linear-gradient(${props.theme.primary} 35%, ${props.theme.secondary} 100%);
        color: ${props.theme.primaryContrast};

        ${SubscriptionTitle} {
            color: ${props.theme.secondaryContrast};
        }
    ` : `
        &:hover {
            background-color: ${props.theme.secondary};
            color: ${props.theme.secondaryContrast};

            ${SubscriptionButton} {
                background-color: #FFF;
                color: ${props.theme.primary};
            }

            ${SubscriptionTitle} {
                color: ${props.theme.secondaryContrast};
            }
        }
    `};
`

const SubscriptionPrice = styled.div`
    margin: 0.5rem 0;
    font-size: 1.3rem;
`

const SubscriptionDescription = styled.div`
    margin-top: 1rem;
    height: 10rem;
    overflow: auto;
`

const BaseModuleOption = styled.div<{ active: boolean }>`
    width: 4rem;
    text-align: center;
    line-height: 1rem;
    ${props => props.active ? `
        background-color: #00958a;
        color: #fff;
    ` : `
        background-color: ${props.theme.secondary};
        color: #fff;
    `}
    
    span {
        width: 3rem;
        margin-top: calc(50% + 0.5rem);
    }
`

const ModuleOption = styled(BaseModuleOption)`
    ${props => props.active ? `
        background-color: #00958a;
        color: #fff;
    ` : `
        background-color: ${props.theme.secondary};
        color: #fff;
    `}
`

const ModuleOptionAction = styled(BaseModuleOption)`
    height: 100%;
    width: 0rem;
    transition: width 0.2s ease-in-out;
    overflow: hidden;
    ${props => props.active ? `
        background-color: ${props.theme.negative};
        color: #fff;
    ` : `
        background-color: #00958a;
        color: #fff;
    `}
`

const Module = styled.div<{ active: boolean }>`
    ${BoxShadowStyleWithHover};
    background-color: #FFF;
    display: flex;
    position: relative;

    &:hover {
        ${ModuleOptionAction} {
            width: 4rem;
        }
    }
`

const ModuleTitle = styled.div`
    font-weight: bold;
    padding: 0.5rem 0;
    font-size: 1.2rem;
    line-height: 1.2rem;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    color: ${props => props.theme.primary};
`

const ModuleContent = styled.div`
    padding: 1rem;
    flex: 1;
`

const ModuleDescription = styled.div`

`

const ModulePrice = styled.span`
    font-weight: normal;
    font-size: 1rem;
`

const ModuleAction = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
`

const Subscriptions = () => {
    const [loaded, parentBusiness] = useParentBusiness();
    const [items, setItems] = React.useState<ApiSystemProduct[]>();
    const [updating, setUpdating] = React.useState(false);
    const [permsLoaded, perms, reloadPermissions] = useBusinessPermissions();

    useEffect(() => {
        if (parentBusiness) {
            load();
        }
    }, [parentBusiness])

    const load = () => {
        ApiService.checkout.GetProductsWithSubscriptionStatus__GET(parentBusiness.id, true)
            .then(response => {
                setItems(response);
            })
            .finally(() => setUpdating(false));
    }

    const subscribe = (id: string) => {
        setUpdating(true)
        ModalService.Close();
        ApiService.checkout.Subscribe__GET(id, parentBusiness.id).then(() => {
            load();
            reloadPermissions(true);
            NotificationService.Confirm('Your subscription has been updated');
        });
    }

    const unsubscribe = (id: string) => {
        setUpdating(true);
        ModalService.Close();
        ApiService.checkout.Unsubscribe__GET(id, parentBusiness.id).then(() => {
            load();
            reloadPermissions(true);
            NotificationService.Confirm('Your subscription has been updated');
        });
    }

    if (!items || updating) return <Loader />;

    const coreItems = items?.filter(x => x.isCoreProduct);

    return (
        <>
            <H3>Core product</H3>
            <Row>
                {coreItems && coreItems.map(item => {
                    const descriptionSplit = (item.description || '').split('|');
                    const description = descriptionSplit[0];
                    let features: string[] = [];
                    if (descriptionSplit.length > 1) {
                        features = descriptionSplit[1].split(',');
                    }
                    return (
                        <Column size={12 / coreItems.length}>
                            <SubscriptionContainer active={item.iSubscribed} onClick={() => item.iSubscribed ? {} : ModalService.Open({
                                title: `Are you sure you want to switch "${item.name}"?`,
                                small: true,
                                children: <InfoMessage>All pricing changes are calculated pro rata.</InfoMessage>,
                                actionBar: <CoreButton onClick={() => subscribe(item.priceId)}>Change plan</CoreButton>
                            })}>
                                <SubscriptionTitle>{!isNullOrWhitespace(item.icon) && <Icon name={item.icon} duo />}{item.name}</SubscriptionTitle>
                                <SubscriptionDescription>
                                    {description.trim()}
                                    <br />
                                    <br />
                                    {features.map(feature => (
                                        <div>
                                            <Icon name="check" /> {feature.trim()}
                                        </div>
                                    ))}
                                </SubscriptionDescription>
                                <SubscriptionPrice>
                                    {item.price != 0 && <><strong>£{item.price}</strong> per month</>}
                                    {item.price == 0 && <strong>FREE</strong>}
                                </SubscriptionPrice>
                                {!item.iSubscribed &&
                                    <SubscriptionButton>
                                        Switch to plan
                                    </SubscriptionButton>
                                }
                                {item.iSubscribed &&
                                    <>Your plan</>
                                }
                            </SubscriptionContainer>
                        </Column>
                    )
                })}
            </Row>
            <H3>Additional extras</H3>
            <Row>
                {items && items.filter(x => !x.isCoreProduct).map(item => (
                    <Column size={12}>
                        <Module active={item.iSubscribed} onClick={() => item.iSubscribed ? ModalService.Open({
                                title: `Are you sure you want to remove the module "${item.name}"?`,
                                small: true,
                                children: <>
                                    <InfoMessage>All pricing changes are calculated pro rata.</InfoMessage>
                                    <br />
                                    <InfoMessage>You will not be able to access this module until it has been re-added.</InfoMessage>
                                </>,
                                actionBar: <CoreButton onClick={() => unsubscribe(item.priceId)}>Remove module</CoreButton>
                            }) : ModalService.Open({
                                title: `Are you sure you want to add the module "${item.name}"?`,
                                small: true,
                                children: <InfoMessage>All pricing changes are calculated pro rata.</InfoMessage>,
                                actionBar: <CoreButton onClick={() => subscribe(item.priceId)}>Add module</CoreButton>
                            })}>
                            <ModuleOption active={item.iSubscribed}>
                                <Icon name={item.iSubscribed ? 'check' : 'plus'} />
                            </ModuleOption>
                            <ModuleContent>
                                <ModuleTitle>{!isNullOrWhitespace(item.icon) && <Icon name={item.icon} duo />}{item.name} - <ModulePrice><strong>£{item.price}</strong> per month</ModulePrice></ModuleTitle>
                                <ModuleDescription>{item.description}</ModuleDescription>
                            </ModuleContent>
                            <ModuleAction>
                                <ModuleOptionAction active={item.iSubscribed}>
                                    <Icon name={item.iSubscribed ? 'trash' : 'cart-shopping'} />
                                </ModuleOptionAction>
                            </ModuleAction>
                        </Module>
                    </Column>
                ))}
            </Row>
        </>
    );
};

export default Subscriptions;