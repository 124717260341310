import React, { Component, useEffect, useState } from 'react';
import { ApiService } from '../../../api/api-connectors';
import { BaseBusiness, VerificationModel } from '../../../api/api-definitions';
import CoreButton from '../../../components/Forms/Button';
import DataTable, { DataTableItem } from '../../../components/Layout/Datatable';
import CoreModal from '../../../components/Layout/CoreModal';
import Icon from '../../../components/Media/Icon';
import { H1 } from '../../../components/Typography/Headings';
import { NotificationService } from '../../../services/NotificationService';
import Postcard from './postcard';
import DashboardHeader from '../../../components/Dashboard/Header';
interface ComponentProps {

}

interface ComponentState {
  authCodeRequest?: AuthCodeRequest;
}

export interface AuthCodeRequest {
  business: BaseBusiness;
  code: string;
}

const BusinessRequests = () => {
  const [authCodeRequest, setAuthCodeRequest] = useState<AuthCodeRequest>();
  const [data, setData] = useState<BaseBusiness[]>([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    ApiService.businessVerification.List__GET().then((result) => setData(result)).catch(() => {
      NotificationService.Error('Sorry, there was an error processing the verification request')
    })
  }

  const generateAuthCode = (business) => {
    setAuthCodeRequest({
        business,
        code: Math.random().toString(36).slice(2).toUpperCase().substring(0, 5)
      } as AuthCodeRequest
    )
  }

  const markAsSent = (businessId: number) => {
    ApiService.businessVerification.Marksent__POST({businessId}).then(() => loadData()).catch(() => {
      NotificationService.Error('Sorry, there was an error processing the verification request')
    })
  }

  const reject = (businessId: number) => {
    ApiService.businessVerification.Reject__POST({businessId}).then(() => loadData()).catch(() => {
      NotificationService.Error('Sorry, there was an error processing the verification request')
    })
  }

  const gentable = (): DataTableItem[] => {
    const items: DataTableItem[] = [];
    data.forEach((item) => {
      const dataItem: DataTableItem = {
        data: {
          'Business name': {
            value: item.name,
            sortable: true,
          },
          'Auth code': {
            value: <CoreButton small onClick={() => generateAuthCode(item)}>Generate auth code</CoreButton>,
            hideName: true
          },
          'Sent': {
            value: item.verificationDetails ? (item.verificationDetails?.sent ? <><Icon name='check' />Sent</> : <CoreButton small onClick={() => markAsSent(item.id)}>Mark as sent</CoreButton>) : 'Code not generated',
            hideName: true,
            sortable: true,
            sortValue: item.verificationDetails?.sent
          },
          'Reject': {
            // @ts-ignore
            value: item.verificationDetails?.rejected ? <><Icon name='check' />REJECTED</> : <CoreButton type='danger' small onClick={() => reject(item.id)}>Reject</CoreButton>,
            hideName: true
          },
        }
      }
      items.push(dataItem);
    });
    return items;
  }

  const sendAuthCode = () => {
    const request: VerificationModel = {
      code: authCodeRequest.code,
      businessId: authCodeRequest.business.id
    }
    setAuthCodeRequest(null)
    ApiService.businessVerification.Save__POST(request).then(() => loadData()).catch(() => {
      NotificationService.Error('Sorry, there was an error processing the verification request')
    })
  }

  return (
    <div>
        {!!authCodeRequest &&
          <CoreModal
            isOpen
            onClose={() => setAuthCodeRequest(null)}
            actionBar={<>
              <CoreButton onClick={() => window.print()}>Print</CoreButton>
              &nbsp;
              <CoreButton onClick={() => sendAuthCode()}>Save code</CoreButton>
            </>}
          >
            <Postcard data={authCodeRequest} />
          </CoreModal>
        }
        <DashboardHeader icon='bell' title='Business requests'></DashboardHeader>
        <DataTable data={gentable()} />
      </div>
  );
};

export default BusinessRequests;
