import { useEffect, useState } from "react";
import { ApiService } from "../api/api-connectors";
import { BaseBusiness, BaseParentBusiness } from "../api/api-definitions";
import BusinessListService from "../services/BusinessListService";
import { NotificationService } from "../services/NotificationService";
import BusinessListCacheService from "../services/BusinessListCacheService";
import useOffline from "./useOffline";

export interface ExtendedBaseParentBusiness extends BaseParentBusiness {
    locations: BaseBusiness[];
}

const useBusinessList = (parentId?: number | string): [boolean, ExtendedBaseParentBusiness[], () => void, {[key: number]: BaseBusiness}] => {
    const offline = useOffline();
    const [data, setData] = useState<ExtendedBaseParentBusiness[]>();
    const [locationDataDictionary, setLocationDataDictionary] = useState<{[key: number]: BaseBusiness}>();

    useEffect(() => {
        load(false)
    }, [])

    useEffect(() => {
        if (data && !data.find(x => x.id === parentId)) {
            load(true)
        }
    }, [parentId])

    const load = (force?: boolean) => {
        // (offline ? BusinessListCacheService.GetBusinessList(parentId ? parentId.toString() : 'null') : BusinessListService.GetBusinessList(force, +parentId)).then((data) => {
        BusinessListService.GetBusinessList(force, +parentId).then((data) => {
            const parentBusinessesList: ExtendedBaseParentBusiness[] = [];
            const locationDictionary: {[key: number]: BaseBusiness} = {};
            data.forEach(location => {
                locationDictionary[location.id] = location;
                const parent = parentBusinessesList.find(x => x.id === location.parentBusinessId);
                if (!parent) {
                    const parentObject = {
                        ...location.parentBusiness,
                        locations: [location]
                    }
                    parentBusinessesList.push(parentObject);
                } else {
                    parent.locations.push(location)
                }
            });
            setData(parentBusinessesList);
            setLocationDataDictionary(locationDictionary);
        }).catch((e) => {
            NotificationService.Error('Sorry, there was an error loading your dashboard.')
        })
    }

    const reload = () => {
        setData(undefined)
        load(true)
    }

    return [!!data, data && parentId ? data.filter(x => x.id === +parentId) : data, reload, locationDataDictionary]
}

export default useBusinessList;