export interface ModalServiceParams {
    children?: React.ReactNode;
    title?: React.ReactNode;
    actionBar?: React.ReactNode;
    small?: boolean;
    medium?: boolean;
    slim?: boolean;
    hideCancelButton?: boolean;
}

export class ModalService {
    private static _handler: (params: ModalServiceParams) => void = null;

    static Open(params: ModalServiceParams) {
        if (this._handler) {
            this._handler(params)
        }
    }

    static Close() {
        if (this._handler) {
            this._handler(undefined)
        }
    }

    static RegisterHandler(handler: (params: ModalServiceParams) => void) {
        this._handler = handler;
    }
}