import { ApiService } from '../../../../../api/api-connectors';
import { VerificationModel, VerificationStatus } from '../../../../../api/api-definitions';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import CoreButton from '../../../../../components/Forms/Button';
import Loader from '../../../../../components/Layout/Loader';
import { useBusiness } from '../../../../../hooks/useBusiness';
import ActionBox from '../../../../../components/Forms/Messaging';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { StyledNumberInput, StyledTextInput } from '../../../../../theme/input.styles';
import { useState } from 'react';
import { NotificationService } from '../../../../../services/NotificationService';

const VerifyBusiness = () => {
    const [businessLoaded, business] = useBusiness();
    const [code, setCode] = useState<string>();

    const confirmCode = () => {
        const request: VerificationModel = {
            businessId: business.id,
            code: code
        }
        ApiService.businessVerification.Verify__POST(request).then((response) => {
            if (response.success) {
                NotificationService.Confirm('Business has been verified!')
            } else {
                NotificationService.Error('Sorry, your business could not be verified at this time.')
            }
        })
    }

    return (
        <>
            <DashboardHeader icon='badge-check' title='Business verification' />
            {!business && <Loader />}
            {business &&
                <>
                    {business.verificationStatus !== VerificationStatus.Verified &&
                        <>
                            <Row>
                                <Column size={6}>
                                    <ActionBox icon='server' title='Verify via DNS record'>
                                        You can verify your business by adding a DNS record to your domain name.
                                        <br />
                                        <br />
                                        <Row style={{textAlign: 'left'}}>
                                            <Column size={3}>
                                                <StyledTextInput disabled value='@' label='Hostname' />
                                            </Column>
                                            <Column size={9}>
                                                <StyledTextInput disabled value={`dishforager.verify=${business.placeId}`} label='Destination TXT' />
                                            </Column>
                                        </Row>
                                        Place the above as a TXT record againt your domains DNS.
                                        <br />
                                        <br />
                                        <CoreButton>Verify DNS record</CoreButton>
                                    </ActionBox>
                                </Column>
                                <Column size={6}>
                                    <ActionBox icon='envelope' title='Verify via post card'>
                                        You can verify your business by entering the postcard we sent you.
                                        <br />
                                        <br />
                                        <Row style={{textAlign: 'left'}}>
                                            <Column size={12}>
                                                <StyledTextInput max={5} min={5} label='Code' onChange={(e) => setCode(e.target.value)} />
                                            </Column>
                                        </Row>
                                        <br />
                                        <br />
                                        <CoreButton disabled={!code || code.length !== 5} onClick={confirmCode}>Verify code</CoreButton>
                                    </ActionBox>
                                </Column>
                            </Row>
                        </>
                    }
                    {business.verificationStatus === VerificationStatus.Verified &&
                        <ActionBox icon='check'>
                            Your business is verified, no further action needed.
                        </ActionBox>
                    }
                </>
            }
        </>
    );
};

export default VerifyBusiness;