import { BaseClient } from '../../../../../api/api-definitions';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { H3 } from '../../../../../components/Typography/Headings';
import Icon from '../../../../../components/Media/Icon';
import styled from 'styled-components';

interface ComponentProps {
  client: BaseClient;
}

const GuestName = styled(H3)`
`

const GuestInfoOffline = ({ client }: ComponentProps) => {

  return (
    <div>
      {client &&
        <>
          <GuestName hideDivider><Icon name='address-card' duo /> {client.firstName} {client.surname ? client.surname : ''}</GuestName>
          <Row>
            <Column hasBorder size={6} mobile={12}>
              <Icon name='at' duo /><strong>Email</strong>
              <br />
              {client.email ? client.email : 'N/A'}
            </Column>
            <Column hasBorder size={6} mobile={12}>
              <Icon name='phone' duo /><strong>Telephone</strong>
              <br />
              {client.telephone ? client.telephone : 'N/A'}
            </Column>
          </Row>
          <Row>
            <Column hasBorder size={6} mobile={12}>
              <Icon name='megaphone' duo /><strong>Opted for marketing</strong>
              <br />
              {client.marketingUpdates ? 'Yes' : 'No'}
            </Column>
          </Row>
        </>
      }
    </div>
  );
};

export default GuestInfoOffline;