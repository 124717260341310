import React from 'react';
import { IonicDarkTheme, IonicLightTheme } from '../../theme';
import { createGlobalStyle } from 'styled-components';
import { BODY_FONT } from '../../constants';

const Global = createGlobalStyle<{excludeScrollbar?: boolean}>`
    ${props => {
        return props.theme.name === 'Light' ? IonicLightTheme : IonicDarkTheme
    }}

    * {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    ${props => !props.excludeScrollbar && `
        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        * {
            scrollbar-width: 5px;
            scrollbar-color: ${props.theme.secondary} #ffffff;
            background: rgba(0,0,0,0);
        }

        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            width: 5px;
            background: rgba(0,0,0,0);
        }

        *::-webkit-scrollbar-track {
            background: rgba(0,0,0,0);
        }

        *::-webkit-scrollbar-thumb {
            background-color: ${props.theme.secondary};
            border-radius: 5px;
        }
    `}

    body, html {
        ${BODY_FONT}
        font-size: 18px;
        line-height: 30px;
    }

    :root {
        --ion-${BODY_FONT}
    }

    .chakra-input__left-addon {
        border-color: #aeaeae !important;
    }

    ion-button {
        --border-radius: 0rem !important;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        font-size: 0.8rem;
        ${BODY_FONT}

        &.button-small {
            --padding-top: 0.5rem;
            --padding-bottom: 0.5rem;
        }

        &.button-outline-inverse {
            --ion-color-base: #FFF !important;
        }
    }

    @media print {

        @page {
          size: A4 landscape;
        }
    
        html, body {
          height: 200px; 
          overflow: hidden;   
        }
    
        body * {
            visibility: hidden;
        }
        #section-to-print, #section-to-print * {
            visibility: visible;
        }
        #section-to-print {
            -webkit-print-color-adjust: exact !important;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
`;

interface ComponentProps {
    children: React.ReactNode;
    excludeScrollbar?: boolean;
}

const ThemeWrapper = ({ children, excludeScrollbar }: ComponentProps) => {
    return <>
        <Global excludeScrollbar={excludeScrollbar} />
        {children}
    </>;
};

export default ThemeWrapper;