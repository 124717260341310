import { Moment } from "moment";
import { BaseActiveBooking, BaseBookingTimeBlock, BaseBusinessOpeningTimeException, BaseBusinessOpeningTimesRange, BaseExceptionTime, BookingSourceToBlock, BookingStatus, EventAreaSelection, EventSlot } from "../../../../../api/api-definitions";
import { defaultColors } from "../../../../../theme";
import { DATABASE_TIME_FORMAT, TIMEFORMAT, createMomentFromValue, formatDate, resetDateOnTime } from "../../../../../utils/date-helpers";
import { ExtendedBookingManagementResponse, TIMELINE_INTERVAL } from ".";
import { BlockedId, TimelineTimeRange } from "./Timeline";
import { isNullOrWhitespace } from "../../../../../utils/text-helpers";
import { cloneDeep } from "lodash";

interface TimeBlock {
    timeFrom: Moment;
    timeTo: Moment;
    rowIds: number[];
    sectionIds: number[];
    hardBlocked: boolean;
}

export function GetStatusColour(booking: BaseActiveBooking) {
    if (booking.isHeld) return BookingStatusColours.held.background;
    if (booking.status === BookingStatus.Seated) return BookingStatusColours.seated.background;
    if (booking.status === BookingStatus.PartiallySeated) return BookingStatusColours.partiallySeated.background;
    if (booking.status === BookingStatus.Cancelled || booking.status === BookingStatus.NoShow) return BookingStatusColours.cancelled.background;
    if (booking.status === BookingStatus.Pending) return BookingStatusColours.pending.background;
    if (booking.status === BookingStatus.Completed) return BookingStatusColours.completed.background;
    if (booking.status === BookingStatus.Arrived) return BookingStatusColours.arrived.background;
    return '#FFF'
}

export function GetStatusTextColour(booking: BaseActiveBooking) {
    if (booking.status === BookingStatus.Seated) return BookingStatusColours.seated.text;
    if (booking.status === BookingStatus.PartiallySeated) return BookingStatusColours.partiallySeated.text;
    if (booking.status === BookingStatus.Cancelled || booking.status === BookingStatus.NoShow) return BookingStatusColours.cancelled.text;
    if (booking.status === BookingStatus.Pending) return BookingStatusColours.pending.text;
    if (booking.status === BookingStatus.Completed) return BookingStatusColours.completed.text;
    if (booking.status === BookingStatus.Arrived) return BookingStatusColours.arrived.text;
    return '#000'
}

export function GetBookingColour(booking: BaseActiveBooking) {
    if (booking.isHeld) return BookingStatusColours.held;
    if (booking.status === BookingStatus.Seated) return BookingStatusColours.seated;
    if (booking.status === BookingStatus.Cancelled || booking.status === BookingStatus.NoShow) return BookingStatusColours.cancelled;
    if (booking.status === BookingStatus.Pending) return BookingStatusColours.pending;
    if (booking.status === BookingStatus.Completed) return BookingStatusColours.completed;
    if (booking.status === BookingStatus.Arrived) return BookingStatusColours.arrived;
    return {
        background: '#FFF',
        text: '#000'
    }
}

export const BookingStatusColours = {
    held: {
        background: '#dfdfdf',
        text: '#000'
    },
    seated: {
        background: 'rgb(46 149 46)',
        text: '#FFF'
    },
    partiallySeated: {
        background: '#C1E1C1',
        text: '#000'
    },
    cancelled: {
        background: defaultColors.negative,
        text: '#FFF'
    },
    pending: {
        background: '#ffa609',
        text: '#000'
    },
    completed: {
        background: defaultColors.primary,
        text: '#FFF'
    },
    arrived: {
        background: '#227ea3',
        text: '#FFF'
    },
    endingSoon: {
        background: 'rgb(232 133 1)',
        text: '#000'
    }
}

export function isOpenAtSomePoint(x: BaseBusinessOpeningTimesRange, resetTime: moment.Moment, timeFromAfterTimeTo: boolean) {
    if (x.closed) return false;
    return isOpen(x, resetTime, timeFromAfterTimeTo);
}

export function isOpen(x: BaseBusinessOpeningTimesRange | BaseExceptionTime, resetTime: moment.Moment, timeFromAfterTimeTo: boolean) {
    let timeFrom = resetDateOnTime(x.timeFrom);
    let timeTo = resetDateOnTime(x.timeTo, timeFromAfterTimeTo);
    if (timeTo.isBefore(timeFrom)) {
        timeTo = timeTo.add(1, 'day');
    }
    const between = resetTime.isBetween(timeFrom, timeTo, undefined, '[)');
    const openAllDay = x.timeFrom === x.timeTo;
    return openAllDay || between;
}

export function isOpenAtSomePoint_Exceptions(x: BaseBusinessOpeningTimeException, resetTime: moment.Moment, timeFromAfterTimeTo: boolean) {
    if (x.closed) return false;
    for (let index = 0; index <= x.exceptionTimes.length - 1; index++) {
        const open = isOpen(x.exceptionTimes[index], resetTime, timeFromAfterTimeTo);
        if (open) {
            return open;
        }
    }

    return false;
}

function exceptionTimeValidForDay(date: Date, exceptionTime: BaseBusinessOpeningTimeException) {
    const dayString = formatDate(date, 'dddd').toLowerCase();
    return exceptionTime[dayString];
}

export const calculateTimes = (
    start: Moment,
    end: Moment,
    date: Date,
    bookingManagementData: ExtendedBookingManagementResponse,
    openingTimes: BaseBusinessOpeningTimesRange[],
    eventTimes?: EventSlot[],
): TimelineTimeRange[] => {
    var times: TimelineTimeRange[] = [];
    let startTime = start;
    let endTime = end;
    let exceptionTime = cloneDeep(bookingManagementData.exceptionTimeForDate)
    const dayOfWeek = createMomentFromValue(date).format('dddd').toLowerCase();
    const openingTimesForDay = openingTimes.filter(x => x.dayOfWeek.toLowerCase() == dayOfWeek.toLowerCase());
    if (exceptionTime && !exceptionTimeValidForDay(date, exceptionTime)) {
        exceptionTime = undefined;
    }
    if (openingTimesForDay.length > 0 && !openingTimesForDay[0].closed) {
        let earlistStartTime = openingTimesForDay[0].timeFrom.split('T')[1];
        let latestEndTime = openingTimesForDay[openingTimesForDay.length - 1].timeTo.split('T')[1];
        startTime = createMomentFromValue(formatDate(start, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + earlistStartTime).subtract(TIMELINE_INTERVAL * 8, 'minutes');
        endTime = openingTimesForDay[openingTimesForDay.length - 1].closed ? end : createMomentFromValue(formatDate(start, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + latestEndTime).add(TIMELINE_INTERVAL * 8, 'minutes');
    }
    if (exceptionTime && !exceptionTime.closed) {
        let earlistStartTime = exceptionTime.exceptionTimes[0].timeFrom.split('T')[1];
        let latestEndTime = exceptionTime.exceptionTimes[exceptionTime.exceptionTimes.length - 1].timeTo.split('T')[1];
        startTime = createMomentFromValue(formatDate(start, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + earlistStartTime).subtract(TIMELINE_INTERVAL * 8, 'minutes');
        endTime = exceptionTime.closed ? end : createMomentFromValue(formatDate(start, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + latestEndTime).add(TIMELINE_INTERVAL * 8, 'minutes');
    }
    if (endTime.isBefore(startTime)) {
        endTime = endTime.add(1, 'day');
    }
    let resetTime = resetDateOnTime(startTime.clone());

    while (startTime <= endTime) {
        let blockedRowIds: BlockedId[] = [];
        let blockedSectionIds: BlockedId[] = [];
        let blocked: boolean = false;
        let hardBlocked: boolean = false;

        const hasNoExperience = (eventTimes && !eventTimes.find(x =>
            resetTime.isSameOrAfter(resetDateOnTime(x.timeFrom)) &&
            resetTime.isSameOrBefore(resetDateOnTime(x.timeTo).isBefore(resetDateOnTime(x.timeFrom)) ? resetDateOnTime(x.timeTo).add(1, 'day') : resetDateOnTime(x.timeTo))
        ))

        if (!eventTimes || hasNoExperience) Object.keys(bookingManagementData.tableIdsAndTheirBlockIds).forEach(tableId => {
            const tableBlocks = bookingManagementData.tableIdsAndTheirBlockIds[tableId];
            tableBlocks.forEach(blockId => {
                const block = bookingManagementData.blockIdsAndBlockInfoForDay[blockId];
                let momentStart = block.timeFrom ? resetDateOnTime(block.timeFrom) : undefined;
                let momentEnd = block.timeTo ? resetDateOnTime(block.timeTo) : undefined;
                if (momentStart && momentEnd && momentEnd.isSameOrBefore(momentStart)) {
                    momentEnd = momentEnd.add(1, 'day');
                }
                if ((!momentStart || resetTime.isSameOrAfter(momentStart)) && (!momentEnd || resetTime.isSameOrBefore(momentEnd))) {
                    blockedRowIds.push({
                        id: +tableId,
                        hardBlocked: !hasNoExperience && block.bookingSourceToBlock == BookingSourceToBlock.OnlineAndInHouse
                    })
                }
            });
        })

        times.push({
            formatted: startTime.format(TIMEFORMAT),
            formattedMinutes: startTime.format('mm'),
            raw: startTime.toDate(),
            formattedHoursMinutes: startTime.format('HH:mm'),
            next: startTime.clone().add(TIMELINE_INTERVAL, 'm'),
            closed: (!eventTimes || hasNoExperience) && ((exceptionTime && !isOpenAtSomePoint_Exceptions(exceptionTime, resetTime, false)) ||
                (!exceptionTime && openingTimes.filter(x => {
                    return isOpenAtSomePoint(x, resetTime, x.timeFromAfterTimeTo)
                }).length === 0)),
            blocked: blocked || hasNoExperience,
            hardBlocked,
            blockedRows: blockedRowIds,
            blockedSections: blockedSectionIds
        });
        startTime = startTime.add(TIMELINE_INTERVAL, 'm');
        resetTime = resetTime.add(TIMELINE_INTERVAL, 'm');
    }

    return times;
}

export const getShiftName = (time: BaseBusinessOpeningTimesRange | BaseExceptionTime) => {
    if (createMomentFromValue(time.timeFrom).hours() >= 16) {
        return 'Dinner';
    }
    if (createMomentFromValue(time.timeTo).hours() <= 12) {
        return 'Breakfast';
    }
    return 'Lunch';
}

export const shouldShowArea = (id: number, showOnlySectionsAndTables: { [key: string | number]: EventAreaSelection }, hideSectionsAndTables: { [key: string | number]: EventAreaSelection }) => {
    let returnValue = true;
    if (showOnlySectionsAndTables) {
        returnValue = !!showOnlySectionsAndTables[id]
    }
    if (hideSectionsAndTables) {
        returnValue = !!!hideSectionsAndTables[id]
    }
    return returnValue;
}

export const shouldShowTable = (id: number, tableId: number, showOnlySectionsAndTables: { [key: string | number]: EventAreaSelection }, hideSectionsAndTables: { [key: string | number]: EventAreaSelection }) => {
    if (showOnlySectionsAndTables) {
        const area = showOnlySectionsAndTables[id];
        if (!area) {
            return false;
        } else if (area.wholeAreaSelected) {
            return true;
        } else {
            return !!area.selectedTableIds[tableId]
        }
    }
    if (hideSectionsAndTables) {
        const area = hideSectionsAndTables[id];
        if (!area) {
            return true;
        } if (area.wholeAreaSelected) {
            return false;
        } else {
            return !!!area.selectedTableIds[tableId]
        }
    }
    return true;
}