import React from 'react';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { PanelLink } from '../../../../../components/Dashboard/PanelLink';
import Icon from '../../../../../components/Media/Icon';
import { DashboardPage } from '../../../DashboardLayout';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import { useRecoilState } from 'recoil';
import { LoginState } from '../../../../../components/Authentication/AuthenticationWrapper';
import useParentBusiness from '../../../../../hooks/useParentBusiness';

const CmsLanding = () => {
  const [loginValue, setLoginValue] = useRecoilState(LoginState);
  const [parentLoaded, parentBusiness] = useParentBusiness();
  
  return (
    <>
      <DashboardHeader title='Content management system' icon='globe'>
        Easily manage your website from the links below.
      </DashboardHeader>
      <Row>
        <Column size={4} tablet={6} mobile={12}>
          <PanelLink to={`/dashboard/${parentBusiness?.id}/site-builder/pages`}><Icon name='grid-2-plus' /> Website pages</PanelLink>
        </Column>
        <Column size={4} tablet={6} mobile={12}>
          <PanelLink to={`/dashboard/${parentBusiness?.id}/site-builder/menu-builder`}><Icon name='sitemap' /> Menu builder</PanelLink>
        </Column>
        {loginValue?.data?.isSuperAdmin &&
          <Column size={4} tablet={6} mobile={12}>
            <PanelLink to={`/dashboard/${parentBusiness?.id}/site-builder/component-builder`}><Icon name='cog' /> Component builder</PanelLink>
          </Column>
        }
      </Row>
    </>
  );
};

export default CmsLanding;