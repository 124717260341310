import React, { useContext, useEffect, useRef } from 'react';
import { FormAttibuteContext, FormContext } from '../FormWrapper';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BaseErrorMessage, BaseLabelStyle } from '../../../theme/input.core.styles';
import { useRecoilState } from 'recoil';
import { FormControlState } from '../../../controllers/easyFormConsumer';
import { createUUID } from '../../../utils/data-helpers';
import { useFormUpdate } from '../../../hooks/formState';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { isValidValue, formatNumberToText } from '../../../utils/number-helper';
import i18n from '../../../utils/i18n';
import styled, { createGlobalStyle } from 'styled-components';

interface ComponentProps {
    value: string;
    onChange?: (value: string, valid: boolean) => void;
    onBlur?: (value: string, valid: boolean) => void;
    className?: string;
    label?: string;
    required?: boolean;
    id?: string;
    inputName?: string;
    model?: string;
    unlink?: boolean;
    maxLength?: number;
    minLength?: number;
}

const RichTextEdior = ({ value, onChange, onBlur, className, label, maxLength, minLength, unlink, required, id, inputName, model }: ComponentProps) => {
    const context = useContext(FormContext);
    const uuid = useRef(createUUID());
    const formId = unlink || !context ? null : context;
    const [componentState, setComponentState] = useRecoilState(FormControlState(formId || uuid.current, model || inputName));
    const setComponentData = useFormUpdate(formId, model);
    const mounted = useRef<boolean>(false);
    const { error, internalValue } = componentState;
    const valueToUse = isNullOrWhitespace(internalValue) ? '' : internalValue;
    const nameToUse = inputName ? inputName : model;

    useEffect(() => {
        const [valid, newError] = validate(value, mounted.current);
        if (model && formId) setComponentData(value, valid);
        setComponentState({ internalValue: value, error });
        if (!mounted) mounted.current = true;
    }, [value])

    const setValue = (e: string) => {
        const [valid, newError] = validate(e, false);

        if (model && formId) setComponentData(e, valid);

        if (onChange) onChange(e, valid);

        setComponentState({ internalValue: e, error: newError });
    }

    const validate = (value, setState: boolean = true): [boolean, string] => {
        if (!value && required) {
            let error = i18n('Required');
            if (setState) setComponentState({ error, internalValue });
            return [false, error];
        } else if (value && minLength && value.length < minLength) {
            let error = i18n('Minimum number of characters is ') + minLength.toString();
            if (setState) setComponentState({ error, internalValue });
            return [false, error];
        } else if (value && maxLength && value.length > maxLength) {
            let error = i18n('Maximum number of characters is ') + maxLength.toString();
            if (setState) setComponentState({ error, internalValue });
            return [false, error];
        } else {
            if (setState) setComponentState({ error: null, internalValue });
            return [true, null];
        }
    }

    const blur = (e: string) => {
        const [valid] = validate(e);
        if (onBlur) onBlur(e, valid)
    }

    return (
        <div className={className}>
            <EditorWrapper />
            <FormAttibuteContext.Consumer>
                {attr => (
                    <>
                        {label &&
                            <BaseLabelStyle className={required ? 'required' : ''} htmlFor={id ? id : nameToUse}>
                                {label}
                            </BaseLabelStyle>
                        }
                        <div>
                            <CKEditor
                                editor={ClassicEditor}
                                data={value}
                                id={id ? id : nameToUse}
                                onChange={(event, editor) => {
                                    setValue(editor.getData());
                                }}
                                config={ {
                                    toolbar: {
                                        items: [
                                            'undo', 'redo',
                                            '|', 'heading',
                                            '|', 'bold', 'italic',
                                            '|', 'link',
                                            '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                                        ]
                                    }
                                } }
                                onBlur={(event, editor) => {
                                    blur(editor.getData());
                                }}
                                
                            />
                        </div>

                        {error &&
                            <BaseErrorMessage>
                                {error}
                            </BaseErrorMessage>
                        }
                    </>
                )}
            </FormAttibuteContext.Consumer>
        </div>
    );
};

const EditorWrapper = createGlobalStyle`
    .ck-editor__editable {
        min-height: 7rem;
    }

    .ck-powered-by-balloon {
        display: none !important;
    }

    ul {
        padding: revert !important;
    }
`

// DEFAULT TOOLBAR
// [
//     'undo', 'redo',
//     '|', 'heading',
//     '|', 'bold', 'italic',
//     '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'mediaEmbed',
//     '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
// ]

export default RichTextEdior;