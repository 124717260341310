import styled from "styled-components";
import { BoxShadowStyle } from "../../../theme";

export const FloatingActionBar = styled.div<{flex?: boolean}>`
  position: fixed;
  bottom: 0;
  width: calc(100vw + -4.3rem);
  padding: 1rem;
  right: 0;
  background-color: white;
  z-index: 9;
  ${props => props.flex && 'display: flex;'}
  ${BoxShadowStyle}

  .chakra-alert {
    width: auto !important;
    padding: 0.3rem 0.5rem !important;
    flex: 1;
    float: left;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;