import React, { useEffect, useState } from 'react';
import { BaseActiveBooking, BaseBookingPayment } from '../../../../../../../api/api-definitions';
import { ApiService } from '../../../../../../../api/api-connectors';
import CreateCustomerPayment from '../../CreateCustomerPayment';
import CoreButton from '../../../../../../../components/Forms/Button';
import Icon from '../../../../../../../components/Media/Icon';
import DataTable, { DataTableItem } from '../../../../../../../components/Layout/Datatable';
import { ConvertCurrency } from '../../../../../../../utils/currency-helper';
import { Badge } from '@chakra-ui/react';
import Loader from '../../../../../../../components/Layout/Loader';
import DashboardPanel from '../../../../../../../components/Dashboard/Panel';
import { isNullOrWhitespace } from '../../../../../../../utils/text-helpers';
import { NotificationService } from '../../../../../../../services/NotificationService';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import { InfoMessage } from '../../../../../../../components/Forms/Messaging';

interface ComponentProps {
  booking: BaseActiveBooking;
}

const BookingPayments = ({ booking }: ComponentProps) => {
  const [payments, setPayments] = useState<BaseBookingPayment[]>();
  const [paymentWindowOpen, setPaymentWindowOpen] = useState(false);
  const [deletePaymentItem, setDeletePaymentItem] = useState<BaseBookingPayment>();
  const [issueRefundItem, setIssueRefundItem] = useState<BaseBookingPayment>();
  const [chargeCardItem, setChargeCardItem] = useState<BaseBookingPayment>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadPayments = () => {
    ApiService.bookingPayment.List__GET(booking.id, booking.businessId).then((response) => {
      setPayments(response);
    })
  }

  const gentable = (): DataTableItem[] => {
    const items: DataTableItem[] = [];
    payments.forEach((item) => { // Please change 'any' to be the type of your object
      const dataItem: DataTableItem = {
        data: {
          'Payment amount': {
            value: ConvertCurrency({ code: item.currency, amount: item.amount }),
            sortable: true,
          },
          'Method': {
            value: item.terminalPayment ? <Badge colorScheme='blue'>Terminal</Badge> : <Badge colorScheme='teal'>Online</Badge>,
            sortable: true,
          },
          'Paid': {
            value: !isNullOrWhitespace(item.refundId) ? <Badge colorScheme='red'>Refunded</Badge> : <>
              {item.fufilled ? <Badge colorScheme='green'>Paid</Badge> : <Badge colorScheme='yellow'>Pending</Badge>}
            </>,
            sortable: true,
          },
          'Action': {
            value: !isNullOrWhitespace(item.refundId) ? <></> : <>
            {item.fufilled ? <CoreButton type='tertiary' onClick={() => setIssueRefundItem(item)}>Refund</CoreButton> : <CoreButton onClick={() => setChargeCardItem(item)}>Charge card</CoreButton>}
          </>,
          },
          'Remove': {
            value: item.fufilled ? <></> : <CoreButton type='danger' onClick={() => setDeletePaymentItem(item)}>Remove</CoreButton>,
          }
        },
      }
      items.push(dataItem);
    });
    return items;
  }

  const chargeCard = (payment: BaseBookingPayment) => {
    ApiService.checkout.ChargePreAuthedPayment__POST(payment).then((response) => {
      if (response.success) {
        setChargeCardItem(undefined)
        NotificationService.Confirm('Card charged')
        loadPayments();
      }
    })
  }

  const issueRefund = (payment: BaseBookingPayment) => {
    ApiService.checkout.IssueRefund__POST(payment).then((response) => {
      if (response.success) {
        setIssueRefundItem(undefined)
        NotificationService.Confirm('Refund issued')
        loadPayments();
      }
    })
  }

  const removePayment = (payment: BaseBookingPayment) => {
    ApiService.bookingPayment.Delete__DELETE(payment).then((response) => {
      if (response.success) {
        setDeletePaymentItem(undefined)
        NotificationService.Confirm('Payment removed')
        loadPayments();
      }
    })
  }

  const closePaymentWindow = () => {
    loadPayments()
    setPaymentWindowOpen(false)
  }

  return (
    <div>
      {paymentWindowOpen && <CreateCustomerPayment booking={booking} onClose={closePaymentWindow} />}
      {deletePaymentItem &&
        <CoreModal
          small
          title={`Are you sure you want to delete this payment?`}
          onClose={() => setDeletePaymentItem(undefined)}
          actionBar={<CoreButton disabled={loading} onClick={() => removePayment(deletePaymentItem)}>Confirm</CoreButton>}
        >
          {loading && <Loader />}
          <InfoMessage>{ConvertCurrency({ code: deletePaymentItem.currency, amount: deletePaymentItem.amount })}</InfoMessage>
        </CoreModal>
      }
      {issueRefundItem &&
        <CoreModal
          small
          title={`Are you sure you want to issue refund for this payment?`}
          onClose={() => setIssueRefundItem(undefined)}
          actionBar={<CoreButton disabled={loading} onClick={() => issueRefund(issueRefundItem)}>Confirm</CoreButton>}
        >
          {loading && <Loader />}
          <InfoMessage>{ConvertCurrency({ code: issueRefundItem.currency, amount: issueRefundItem.amount })}</InfoMessage>
        </CoreModal>
      }
      {chargeCardItem &&
        <CoreModal
          small
          title={`Are you sure you want to make a charge for this payment?`}
          onClose={() => setChargeCardItem(undefined)}
          actionBar={<CoreButton disabled={loading} onClick={() => chargeCard(chargeCardItem)}>Confirm</CoreButton>}
        >
          {loading && <Loader />}
          <InfoMessage>{ConvertCurrency({ code: chargeCardItem.currency, amount: chargeCardItem.amount })}</InfoMessage>
        </CoreModal>
      }
      <CoreButton onClick={() => setPaymentWindowOpen(true)}><Icon name='credit-card-front' /> Take payment</CoreButton>
      <br />
      <br />
      <DashboardPanel title={`Payments`} onToggle={() => !payments ? loadPayments() : {}} defaultExpanded={false}>
        {!payments && <Loader />}
        {payments &&
          <DataTable data={gentable()} />
        }
      </DashboardPanel>
    </div>
  );
};

export default BookingPayments;