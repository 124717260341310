import styled from 'styled-components';
import { ApiService } from '../../../../../api/api-connectors';
import { UserAccountDetails } from '../../../../../api/api-definitions';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import CoreButton from '../../../../../components/Forms/Button';
import Datatable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import Loader from '../../../../../components/Layout/Loader';
import { H1, H3 } from '../../../../../components/Typography/Headings';
import { useApi } from '../../../../../hooks/useApi';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../services/NotificationService';

interface ProfileImageProps {
    image: string;
}

const ProfileImage = styled.div`
    width: 3rem;
    height: 3rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${(props: ProfileImageProps) => props.image});
    border-radius: 100%;
`;

const BusinessUsers = () => {
    const [businessLoaded, business] = useBusiness();
    const [loaded, data] = useApi<UserAccountDetails[]>(ApiService.appUser.GetBusinessUsers__GET, business.id)

    const revokePermission = (id: string) => {
        // ApiService.
        //.then(() => {
        NotificationService.Confirm('Permssion removed')
        //}).catch(() => NotificationService.Error('Could not remove permission. Please contact support.'))
    }

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        data.forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'ProfileImage': {
                        value: <ProfileImage image={!!item.imageUrl ? item.imageUrl : 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/434px-Unknown_person.jpg'} />,
                        hideName: true,
                    },
                    'Username': {
                        value: <>{item.userName}{item.isMe ? <strong> (me)</strong> : '' }</>,
                        sortable: true,
                    },
                    'Revoke': {
                        value: 
                        <>
                            {
                                item.isMe ?
                                <>N/A</>
                                :
                                <CoreButton type='danger' onClick={() => revokePermission(item.userName)}>Revoke permissions</CoreButton>
                            }
                        </>
                    }
                },
            }
            items.push(dataItem);
        });
        return items;
    }
    return (
        <>
            <DashboardHeader title='Users'>Manage users who have access to your business and what permissions they have.</DashboardHeader>
            {!loaded && <Loader />}
            {loaded && <Datatable data={gentable()} />}
        </>
    );
};

export default BusinessUsers;