import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BaseDish, BaseDishTagLink } from '../../api/api-definitions';
import { Constants } from '../../constants';
import { isNullOrWhitespace } from '../../utils/text-helpers';
import { Column } from '../Layout/Grid';
import Icon from '../Media/Icon';
import TagList from './TagList';
import { ConvertCurrency } from '../../utils/currency-helper';

interface ComponentProps {
    dish: BaseDish;
    place: string;
    menuName: string;
    sectionName: string;
    actionBar?: React.ReactNode;
    currencyCode?: string;
    showPlace?: boolean;
    onClick?: () => void;
    id?: number;
    size?: number;
    city?: string;
    miles?: number | string;
}

type MenuStyle = 'gridTopImage' | 'gridLeftImage' | 'listLeftImage' | 'listNoImage';

const LeftImage = styled(LazyLoadImage)`
    width: 100%;
    height: 11rem;
    margin-bottom: 1rem;
    border-radius: 0.2rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${props => props.theme.primary};
`;

const Content = styled.div`
    flex: 1 1 auto;
    padding: 1rem;
    line-height: 1.5rem;
`;

const Container = styled.div`
    margin-bottom: 1rem;
    background-color: ${props => props.theme.background};
    border: 1px solid #fafafa;
    border-radius: 0.2rem;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const NameItem = styled.div`
    font-size: 1.1rem;
    padding-right: 0.7rem;
    padding-left: 0;
    font-weight: bold;
    letter-spacing: 0.5px;
`;
const PlaceItem = styled.span`
    font-style: italic;
    opactity: 0.9;
    margin-left: 1rem;
    font-size: 0.9rem;
`;
const DescriptionItem = styled.div`
    border-bottom: ${props => props.theme.borderSize} solid ${props => props.theme.primary};
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
`;

const BottomContent = styled.div`
    background-color: #FFFFFF;
    border: 2px solid #e5e5e5;
    border-radius: 0.2rem;
    padding: 1rem;
    color: black;
    margin: 1rem;
    width: 30rem;
`;

const MenuTypes = styled.strong`
    font-style: italic;
    display: block;
`;

const City = styled.div`
    margin-top: 0.5rem;
    .icon {
        color: ${props => props.theme.primary};
    }
`;

const RatingContainer = styled.div`
    color: ${props => props.theme.primary};
`;

const RatingList = styled(RatingContainer)`
`;

const FoodResultContainer = styled(Column)`
    box-shadow: 0px 4px 7px 0px rgb(217 217 217 / 75%);
    background-color: white;
`;

const InfoBox = styled.div`
    display: flex;
    font-size: 0.9rem;
    font-weight: bold;
    font-style: italic;
`

const FlexTextRight = styled.div`
    flex: 1 1 auto;
    text-align: right;
`

const FlexTextLeft = styled.div`
    flex: 1 1 auto;
    text-align: left;
`

const FlexTextCenter = styled.div`
    flex: 1 1 auto;
    text-align: center;
`

// const noImageSrc = 'https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg';

const FoodResult = ({
    dish,
    place,
    city,
    miles,
    showPlace,
    menuName,
    onClick,
    sectionName,
    actionBar,
    currencyCode,
    id,
    size = 4
}: ComponentProps) => {
    const noImageSrc = 'https://source.unsplash.com/random/600x600/?food&' + dish.name;
    const imageName = isNullOrWhitespace(dish.imageUrl) ? `url('${noImageSrc}')` : `url('${Constants.baseImageUrl}${dish.imageUrl}')`;
    return (
        <FoodResultContainer size={size} onClick={onClick}>
            {!isNullOrWhitespace(dish.imageUrl) &&
                <LeftImage
                    style={{ backgroundImage: imageName }}
                    placeholder={<div></div>}
                    threshold={200}
                />
            }
            {/* {!isNullOrWhitespace(dish.imageUrl) && <LeftImage style={{ backgroundImage: isNullOrWhitespace(dish.imageUrl) ? `url('${noImageSrc}')` : `url('${window.location.origin}/api/image/${dish.imageUrl}?maxWidth=800&maxHeight=1200')` }} />} */}
            <Content>
                <NameItem>
                    {dish.name}
                </NameItem>
                <DescriptionItem>{isNullOrWhitespace(dish.description) ? 'This item has no description.' : dish.description}</DescriptionItem>
                {/* <MenuTypes>
                    {menuName} - {sectionName}
                </MenuTypes> */}
                {!isNullOrWhitespace(currencyCode) && !isNullOrWhitespace(dish.price) && dish.price > 0 &&
                    <InfoBox>
                        <FlexTextLeft>
                            {ConvertCurrency({
                                amount: dish.price,
                                code: currencyCode
                            })}
                        </FlexTextLeft>
                        {!isNullOrWhitespace(dish.calorieCount) &&
                            <FlexTextRight>
                                {dish.calorieCount} kcal
                            </FlexTextRight>
                        }
                    </InfoBox>
                }
                {dish.dishTags && <TagList tags={dish.dishTags.map((tag) => tag.tagDescription)} />}
                {city && <City><Icon name='city' />{city} - {miles} miles away</City>}
                {actionBar}
            </Content>
        </FoodResultContainer>
    );
};

export default FoodResult;