import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { HEADING_FONT } from '../../constants';
import { BoxShadowStyle } from '../../theme';
import Icon from '../Media/Icon';
import DashboardContextMenu from './ContextMenu';
import BREAKPOINTS from '../../config/breakpoints';

export const DashboardPanelStyle = styled.div`
  background-color: ${props => props.theme.background};
  ${BoxShadowStyle}
  margin-bottom: 1rem;
  position: relative;
`;

const DashboardPanelHeader = styled.div<{hasPointer?: boolean}>`
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.2rem;
  ${props => props.hasPointer && `
    border-bottom: 1px solid ${props.theme.borderColor};
    cursor: pointer;
  `}
  position: relative;
  ${HEADING_FONT}
`;

const DashboardPanelBody = styled.div<{ expanded: boolean; bodyHeight: number; }>`
  overflow-y: hidden;
  transition: max-height ease 0.5s;
  ${props => props.bodyHeight && props.bodyHeight > 0 && (props.expanded ? `max-height: calc(${props.bodyHeight}px + 4rem);` : 'max-height: 0;')}
`;

const InnerBody = styled.div`
  padding: 2rem;
  
  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    padding: 0.5rem;
  }
`

const ExpandIcon = styled(Icon)`
  position: absolute;
  click-events: none;
  user-select: none;
  right: 1rem;
  top: calc(50% - 0.6rem);
`

interface ComponentProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  options?: React.ReactNode;
  disableCollapse?: boolean;
  defaultExpanded?: boolean;
  onToggle?: () => void;
}

const DashboardPanel = ({ title, children, options, disableCollapse, onToggle, defaultExpanded = true }: ComponentProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [previousSize, setPreviousSize] = useState<number>(0);
  const body = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!title && !expanded) toggleExpand()
    if (!defaultExpanded) {
      const height = body?.current?.getBoundingClientRect()?.height || 10000000;
      setPreviousSize(height);
    }
  }, [title])

  const toggleExpand = () => {
    if (expanded) {
      const height = body?.current?.getBoundingClientRect()?.height || 10000000;
      setPreviousSize(height);
    }
    setTimeout(() => {
      setExpanded(!expanded)
      if (!expanded) {
        setTimeout(() => {
          setPreviousSize(0);
        }, 510);
      }
    }, 100)
  }

  return (
    <DashboardPanelStyle>
      {title && <DashboardPanelHeader hasPointer={!!children} onClick={!disableCollapse && children ? () => {toggleExpand(); if (onToggle) onToggle();} : null}>{title} {!disableCollapse && children && <ExpandIcon name={expanded ? 'chevron-down' : 'chevron-right'} />}</DashboardPanelHeader>}
      {options &&
        <DashboardContextMenu>
          {options}
        </DashboardContextMenu>
      }
      {children &&
        <DashboardPanelBody expanded={expanded} bodyHeight={previousSize}>
          <InnerBody ref={body}>
            {children}
          </InnerBody>
        </DashboardPanelBody>
      }
    </DashboardPanelStyle>
  );
};

export default DashboardPanel;