import DashboardHeader from '../../../../../components/Dashboard/Header';
import { useBusiness } from '../../../../../hooks/useBusiness';
import styled from 'styled-components';
import { BoxShadowStyle, BoxShadowStyleWithHover } from '../../../../../theme';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { H3 } from '../../../../../components/Typography/Headings';
import Loader from '../../../../../components/Layout/Loader';
import { NotificationService } from '../../../../../services/NotificationService';
import StripeIntegration from './StripeIntegration';
import SendGridIntegration from './SendGridIntegration';
import TwilioIntegration from './TwilioIntegration';

export const IntegrationBox = styled.div<{ hasDetails?: boolean }>`
    ${props => props.hasDetails ? `
        ${BoxShadowStyle}

        .icon {
            margin-top: 0.5rem;
        }
    ` : `
        ${BoxShadowStyleWithHover}

        span {
            margin: 1rem 0;
        }

        img {
            margin: 1rem 0;
        }

        &:hover {
            span {
                color: ${props => props.theme.primary};
            }
        }
    `}

    .chakra-alert {
        padding: 0.2rem 1rem;
    }
    
    background-color: ${props => props.theme.background};
    text-align: center;
    padding: 1rem;
    height: 200px;
    overflow: hidden;

    span {
        font-size: 5rem;
        color: ${props => props.theme.secondary};
    }

    img {
        height: 5rem;
        display: inline;
    }
`

export const IntegrationTitle = styled.div`
    font-weight: bold;
    text-alight: center;
    font-size: 1rem;
`

const PaymentIntegrations = () => {
    const [businessLoaded, businessData, setBusinessData] = useBusiness();

    const error = () => NotificationService.Error('Sorry, we could not update the integration');

    const updateBusiness = (property: string, value: boolean) => {
        const newData = { ...businessData };
        newData[property] = value;
        setBusinessData(newData)
    }

    return (
        <div>
            <DashboardHeader title='Integrations' icon='grid-2-plus' />
            {!businessData && <Loader />}
            {businessData &&
                <>
                    <H3>Payments</H3>
                    <Row>
                        <Column size={4} tablet={6} mobile={12}>
                            <StripeIntegration
                                enabled={businessData.stripeEnabled}
                                locationId={businessData.locationId}
                                updateBusiness={updateBusiness}
                                error={error}
                            />
                        </Column>
                    </Row>
                    <H3>Communication</H3>
                    <Row>
                        <Column size={4} tablet={6} mobile={12}>
                            <SendGridIntegration
                                enabled={businessData.sendGridEnabled}
                                locationId={businessData.locationId}
                                updateBusiness={updateBusiness}
                                error={error}
                            />
                        </Column>
                        <Column size={4} tablet={6} mobile={12}>
                            <TwilioIntegration
                                enabled={businessData.twilioEnabled}
                                locationId={businessData.locationId}
                                updateBusiness={updateBusiness}
                                error={error}
                            />
                        </Column>
                    </Row>
                </>
            }
        </div>
    );
};

export default PaymentIntegrations;