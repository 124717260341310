import React from 'react';
import styled from 'styled-components';

interface ComponentProps extends TagListContainerProps {
    tags: string[];
}

const TagContainer = styled.div`
    ${(props: TagListContainerProps) => props.center ? 'text-align: center;' : ''}
`;

interface TagListContainerProps {
    center?: boolean;
}

const TagText = styled.div`
    text-align: center;
    background-color: ${props => props.theme.primary};
    color: white;
    border-radius: 4px;
    padding: 0.2rem 1rem;
    margin-right: 0.2rem;
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
`;

const TagList = ({ tags, center }: ComponentProps) => {
    return (
        <TagContainer center={center}>
            {tags.map((item, index) => (
                <TagText key={index}>
                    {item}
                </TagText>
            ))}
        </TagContainer>
    );
};

export default TagList;