import { Route, Switch } from 'react-router-dom';
import NotFound from '../NotFound';
import DashboardLanding from './modules/DashboardLanding';
import ParentDashboard from './modules/ParentBusinessModules/ParentDashboard';
import Locations from './modules/ParentBusinessModules/Locations';
import DashboardLayout, { CenterContainer, DashboardPage } from './DashboardLayout';
import LocationDashboard from './modules/LocationModules/LocationDashboard';
import BusinessUsers from './modules/ParentBusinessModules/BusinessDetails/BusinessUsers';
import BusinessStats from './modules/ParentBusinessModules/BusinessDetails/BusinessStats';
import Users from './modules/LocationModules/BusinessDetails/Users';
import Stats from './modules/LocationModules/BusinessDetails/Stats';
import BookingTimeline from './modules/LocationModules/Bookings';
import BookingTableLayout from './modules/LocationModules/Bookings/TableLayout';
import BookingSetup from './modules/LocationModules/Bookings/BookingSetup';
import Guestbook from './modules/ParentBusinessModules/Guestbook';
import OpeningTimes from './modules/LocationModules/BusinessDetails/OpeningTimes';
import BusinessDetails from './modules/LocationModules/BusinessDetails/BusinessDetails';
import ViewMenu from './modules/ParentBusinessModules/MenuManagement/ViewMenu';
import MenuList from './modules/ParentBusinessModules/MenuManagement/MenuList';
import ParentBusinessDetails from './modules/ParentBusinessModules/BusinessDetails/ParentBusinessDetails';
import DocumentBuilder from './modules/ParentBusinessModules/DocumentBuilder';
// import { Spotlight, SpotlightManager, SpotlightTransition } from '@atlaskit/onboarding';
import { useEffect, useState } from 'react';
import { introductionItems } from './modules/Shared/introductionTour';
import Icon from '../../components/Media/Icon';
import styled from 'styled-components';
import SupportTickets from './modules/Shared/SupportTickets';
import { useRecoilState } from 'recoil';
import { LoginState } from '../../components/Authentication/AuthenticationWrapper';
import Loader from '../../components/Layout/Loader';
import ActionBox from '../../components/Forms/Messaging';
import DashboardLeftNav from './modules/LeftNav';
import VerifyBusiness from './modules/LocationModules/BusinessDetails/VerifyBusiness';
import Servers from './modules/LocationModules/Servers';
import DepositRules from './modules/LocationModules/Bookings/BookingRules/DepositRules';
import Integrations from './modules/LocationModules/Integrations';
import useOffline from '../../hooks/useOffline';
import PaymentCenter from './modules/ParentBusinessModules/Payments';
import LocationReports from './modules/LocationModules/Reporting';
import AllBusinesses from '../Admin/AllBusinesses';
import BusinessRequests from '../Admin/BusinessRequests';
import OverlappingBookings from '../Admin/OverlappingBookings';
import SupportDesk from '../Admin/SupportDesk';
import Memberships from '../Admin/Memberships';
import BookingsAdmin from '../Admin/BookingsAdmin';
import 'shepherd.js/dist/css/shepherd.css'
import CmsPages from './modules/ParentBusinessModules/CMS/Pages';
import CmsLanding from './modules/ParentBusinessModules/CMS';
import CmsEditPage from './modules/ParentBusinessModules/CMS/EditPage';
import MenuBuilder from './modules/ParentBusinessModules/CMS/MenuBuilder';
import ComponentBuilder from './modules/ParentBusinessModules/CMS/ComponentBuilder';
import Experiences from './modules/LocationModules/Bookings/Experiences';
import MakePayment from './MakePayment';
import TerminalSetup from './modules/LocationModules/TerminalSetup';

interface ComponentProps {
    match: any;
}

const TourButton = styled(Icon)`
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: ${props => props.theme.primary};

    &:hover {
        color: ${props => props.theme.secondary};
    }
`

type Appearance = 'default' | 'danger' | 'link' | 'primary' | 'subtle' | 'subtle-link' | 'warning';

const BusinessDashboard = ({ match }: ComponentProps) => {
    const [activeSpotlight, setActiveSpotlight] = useState<null | number>(null);
    const [loginValue, setLoginValue] = useRecoilState(LoginState);

    const start = () => setActiveSpotlight(0);
    const next = () => setActiveSpotlight((activeSpotlight || 0) + 1);
    const back = () => setActiveSpotlight((activeSpotlight || 1) - 1);
    const end = () => setActiveSpotlight(null);

    // const renderActiveSpotlight = () => {
    //     const spotlights = introductionItems.map((item, index) => (
    //         <Spotlight
    //             actions={[
    //                 ...(introductionItems.length > 1 && index !== introductionItems.length - 1 ? [{ onClick: () => next(), text: 'Next' }] : []),
    //                 { onClick: () => end(), text: 'Dismiss', appearance: 'subtle' },
    //                 ...(index > 0 ? [{ onClick: () => back(), text: 'Go back', appearance: 'subtle' as Appearance }] : []),
    //             ]}
    //             heading={item.heading}
    //             target={item.target}
    //             key={item.target}
    //             targetRadius={3}
    //             targetBgColor='rgba(9, 30, 66, 0.54)'
    //         >{item.text}</Spotlight>
    //     ));

    //     if (activeSpotlight === null) {
    //         return null;
    //     }

    //     return spotlights[activeSpotlight];
    // };

    // if (offline) return <DashboardPage>
    //     <DashboardLeftNav />
    //     <CenterContainer>
    //         <br />
    //         <ActionBox title='Offline'>
    //             You seem to be offline. The app will resume automatically when a connection is established.
    //         </ActionBox>
    //     </CenterContainer>
    // </DashboardPage>

    if (!loginValue.data) return <Loader />;

    if (loginValue.hasErrored) return <DashboardPage>
        <DashboardLeftNav />
        <CenterContainer>
            <br />
            <ActionBox title='Unable to contact server'>
                Sorry, were unable to establish a connection to our servers. Please try again later.
            </ActionBox>
        </CenterContainer>
    </DashboardPage>

    if (!loginValue.data.isVerified) return <DashboardPage>
        <DashboardLeftNav />
        <CenterContainer>
            <br />
            <ActionBox icon='badge-check'>
                Your account is not verified. Please verify your account before accessing dashboard.
                <br />
                <br />
                Please check your emails for next steps.
                <br />
                <br />
                Thank your for registering your interest, we will contact you shortly.
            </ActionBox>
        </CenterContainer>
    </DashboardPage>

    if (loginValue.data.needsOnboarding) return <DashboardPage>
        <DashboardLeftNav />
        <CenterContainer>
            <br />
            <ActionBox icon='badge-check'>
                Thank your for registering your interest, we will contact you shortly.
            </ActionBox>
        </CenterContainer>
    </DashboardPage>

    return (
        <>
            {/* <SpotlightManager> */}
            {/* <TourButton onClick={start} duo name='circle-info' /> */}
            <Switch>
                <Route path={`${match.path}/`} exact component={(props) => <DashboardLayout title='Dashboard' route={props}><DashboardLanding {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/make-payment`} exact component={(props) => <MakePayment {...props} />} />
                <Route path={`${match.path}/all-businesses`} exact component={(props) => <DashboardLayout requiresAdmin title='All Businesses' route={props}><AllBusinesses {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/business-requests`} exact component={(props) => <DashboardLayout requiresAdmin title='Business Requests' route={props}><BusinessRequests {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/overlapping-bookings`} exact component={(props) => <DashboardLayout requiresAdmin title='Overlapping Bookings' route={props}><OverlappingBookings {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/bookings-admin`} exact component={(props) => <DashboardLayout requiresAdmin title='Bookings Admin' route={props}><BookingsAdmin {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/support-desk`} exact component={(props) => <DashboardLayout requiresAdmin title='Support Desk' route={props}><SupportTickets {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/support`} exact component={(props) => <DashboardLayout title='Support' route={props}><SupportTickets {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId`} exact component={(props) => <DashboardLayout title='Group' route={props}><ParentDashboard {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/menus`} exact component={(props) => <DashboardLayout title='Menus' route={props}><MenuList {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/site-builder`} exact component={(props) => <DashboardLayout title='Site builder' route={props}><CmsLanding {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/site-builder/pages`} exact component={(props) => <DashboardLayout title='Site builder' route={props}><CmsPages {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/site-builder/menu-builder`} exact component={(props) => <DashboardLayout title='Menu builder' route={props}><MenuBuilder {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/site-builder/component-builder`} exact component={(props) => <DashboardLayout title='Component builder' route={props}><ComponentBuilder {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/site-builder/:page/edit-page`} exact component={(props) => <DashboardLayout title='Site builder' route={props}><CmsEditPage {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/menus/:menuid`} exact component={(props) => <DashboardLayout title='View menu' route={props}><ViewMenu {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/guestbook`} exact component={(props) => <DashboardLayout title='Guestbook' route={props}><Guestbook {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations`} exact component={(props) => <DashboardLayout title='Group Locations' route={props}><Locations {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/payment-center`} exact component={(props) => <DashboardLayout title='Payments' route={props}><PaymentCenter {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/users`} exact component={(props) => <DashboardLayout title='Users' route={props}><BusinessUsers {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/stats`} exact component={(props) => <DashboardLayout title='Statistics' route={props}><BusinessStats {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/document-builder`} exact component={(props) => <DashboardLayout title='Document Builder' route={props}><DocumentBuilder {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/details`} exact component={(props) => <DashboardLayout title='Group Details' route={props}><ParentBusinessDetails {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id`} exact component={(props) => <DashboardLayout title='Location' route={props}><LocationDashboard {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/edit-details`} exact component={(props) => <DashboardLayout title='Edit Location' route={props}><BusinessDetails {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/card-readers`} exact component={(props) => <DashboardLayout title='Card Readers' route={props}><TerminalSetup {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/guestbook`} exact component={(props) => <DashboardLayout title='Guestbook' route={props}><Guestbook {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/verify-business`} exact component={(props) => <DashboardLayout title='Verify Location' route={props}><VerifyBusiness {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/opening-times`} exact component={(props) => <DashboardLayout title='Opening Times' route={props}><OpeningTimes {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/integrations`} exact component={(props) => <DashboardLayout title='Integrations' route={props}><Integrations {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/menus`} exact component={(props) => <DashboardLayout title='Location Menus' route={props}><MenuList {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/experiences`} component={(props) => <Experiences {...props} />} />
                <Route path={`${match.path}/:parentId/locations/:id/menus/:menuid`} exact component={(props) => <DashboardLayout title='View Location menu' route={props}><ViewMenu {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/users`} exact component={(props) => <DashboardLayout title='Location Users' route={props}><Users {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/stats`} exact component={(props) => <DashboardLayout title='Location Statistics' route={props}><Stats {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/bookings`} exact component={(props) => <DashboardLayout title='Bookings' permission='bookings' route={props}><BookingTimeline {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/servers`} exact component={(props) => <DashboardLayout title='Servers' route={props}><Servers {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/deposit-rules`} exact component={(props) => <DashboardLayout title='Payment Rules' route={props}><DepositRules {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/table-setup`} exact component={(props) => <DashboardLayout title='Table Setup' route={props}><BookingTableLayout {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/booking-configuration`} exact component={(props) => <DashboardLayout title='Booking Configuration' route={props}><BookingSetup {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/:parentId/locations/:id/reports`} exact component={(props) => <DashboardLayout title='Location Reports' route={props}><LocationReports {...props} /></DashboardLayout>} />
                <Route path={`${match.path}/*`}><NotFound /></Route>
            </Switch>
            {/* {activeSpotlight !== null && <SpotlightTransition>{renderActiveSpotlight()}</SpotlightTransition>}
        </SpotlightManager> */}
        </>
    );
};

export default BusinessDashboard;