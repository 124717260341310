import React from 'react';
import Icon from '../../../../components/Media/Icon';
import moment from 'moment';
import { DATE_WITH_DAY_OF_WEEK_FORMAT, TIMEFORMAT } from '../../../../utils/date-helpers';
import { Checkmark, ThankYou, ManageMyBookingLink } from '../../../booking.styles';
import { ApiBusinessBookingDetails, ApiMakeBookingResponse } from '../../../../api/api-definitions';
import { BookingFormData } from '..';
import { useParams } from 'react-router';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    bookingResponse: ApiMakeBookingResponse;
    formData: BookingFormData;
    selectedTime: string;
    selectedExperience: number;
    experienceTypeShouldShow: (business: ApiBusinessBookingDetails) => boolean;
    getSelectedExperienceName: (selectedExperience: number, business: ApiBusinessBookingDetails) => string;
}

const BookingConfirmation = ({
    business,
    bookingResponse,
    formData,
    selectedTime,
    selectedExperience,
    experienceTypeShouldShow,
    getSelectedExperienceName,
}: ComponentProps) => {
    const routeParams: any = useParams();
    
    return (
        <div style={{ textAlign: 'center' }}>
            <Checkmark widgetTheme={business?.theme}><Icon name='check' /></Checkmark>
            <ThankYou>
                Thank you for booking with {business.name}!
                <br />
            </ThankYou>
            <p style={{ marginBottom: '0.5rem' }}>
                Booking reference: <strong>{bookingResponse.bookingReference}</strong>
            </p>
            <div>
                <Icon name='calendar' /> {moment(formData.date).format(DATE_WITH_DAY_OF_WEEK_FORMAT)}<br />
                <Icon name='clock' /> {moment(selectedTime).format(TIMEFORMAT)}<br />
                <Icon name='user-friends' /> {formData.guests} guest{formData.guests > 1 && 's'}<br />
                {experienceTypeShouldShow(business) &&
                    <>
                        <Icon name='utensils' />
                        {getSelectedExperienceName(selectedExperience, business)}
                    </>
                }
            </div>
            <br />
            <strong>Please note, you can manage this booking via the link in your confirmation email.</strong>
            <br />
            <br />
            <strong>Incorrect details?</strong>
            <br />
            <br />
            <ManageMyBookingLink widgetTheme={business?.theme} href={`https://dishforager.com/manage-my-booking/${routeParams.location}/${bookingResponse.bookingReference}/${bookingResponse.bookingUniqueId}`}>Amend your booking</ManageMyBookingLink>
            {business.bookingConfirmationEmailText &&
                <p style={{ whiteSpace: 'pre-line' }}>
                    <br />
                    <br />
                    {business.bookingConfirmationEmailText}
                </p>
            }
            <br />
            <br />
        </div>
    );
};

export default BookingConfirmation;