
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { VictoryBar, VictoryChart, VictoryPie, VictoryTheme } from 'victory';
import { ApiService } from '../../../../../api/api-connectors';
import { ApiPageInteraction } from '../../../../../api/api-definitions';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../components/Dashboard/Panel';
import { Column, Row } from '../../../../../components/Layout/Grid';
import Loader from '../../../../../components/Layout/Loader';
import { H1, H3 } from '../../../../../components/Typography/Headings';
import { useBusiness } from '../../../../../hooks/useBusiness';

interface PieChartItem {
  x: string;
  y: number;
}

const BusinessStats = () => {
  const [loaded, business] = useBusiness();
  const [stats, setStats] = useState<ApiPageInteraction[]>();

  useEffect(() => {
    if (loaded && business) ApiService.pageInteraction.List__GET(business.locationId).then(interactions => setStats(interactions))
  }, [loaded])

  if (!loaded || !stats) return <Loader />;

  const getCountryData = () => {
    const items: PieChartItem[] = [];
    stats.forEach(stat => {
      const foundItem = items.find(x => x.x == stat.country);
      if (foundItem) {
        foundItem.y++;
      } else {
        items.push({
          x: stat.country,
          y: 1
        })
      }
    });
    return items;
  }

  const getCityData = () => {
    const items: PieChartItem[] = [];
    stats.forEach(stat => {
      const foundItem = items.find(x => x.x == stat.city);
      if (foundItem) {
        foundItem.y++;
      } else {
        items.push({
          x: stat.city,
          y: 1
        })
      }
    });
    return items;
  }

  const getBrowserData = () => {
    const items: PieChartItem[] = [];
    stats.forEach(stat => {
      const browser = getBrowser(stat.device);
      const foundItem = items.find(x => x.x == browser);
      if (foundItem) {
        foundItem.y++;
      } else {
        items.push({
          x: browser,
          y: 1
        })
      }
    });
    return items;
  }

  const getBrowser = (agent: string) => {
    switch (true) {
      case agent.toLowerCase().indexOf("edge") > -1: return "Edge";
      case agent.toLowerCase().indexOf("edg") > -1: return "Chromium Edge";
      case agent.toLowerCase().indexOf("opr") > -1: return "Opera";
      case agent.toLowerCase().indexOf("chrome") > -1: return "Chrome";
      case agent.toLowerCase().indexOf("trident") > -1: return "Internet Explorer";
      case agent.toLowerCase().indexOf("firefox") > -1: return "Firefox";
      case agent.toLowerCase().indexOf("safari") > -1: return "Safari";
      default: return "other";
    }
  }

  return (
    <div>
      <DashboardHeader title='Statistics'>View your location statistics such as vistors to your public business page.</DashboardHeader>
      <DashboardPanel>
        <Row>
          <Column size={4} tablet={6} mobile={12}>
            <H3>Visitors by country</H3>
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={{ x: 20 }}
              title='Visitors by country'
            >
              <VictoryBar
                barWidth={({ index }) => +index * 2 + 8}
                style={{
                  data: { fill: "#00403D" }
                }}
                data={getCountryData()}
              />
            </VictoryChart>
          </Column>
          <Column size={4} tablet={6} mobile={12}>
            <H3>Visitors by city</H3>
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={{ x: 20 }}
              title='Visitors by city'
            >
              <VictoryBar
                barWidth={({ index }) => +index * 2 + 8}
                style={{
                  data: { fill: "#00403D" }
                }}
                data={getCityData()}
              />
            </VictoryChart>
          </Column>
          <Column size={4} tablet={6} mobile={12}>
            <H3>Visitors by browser</H3>
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={{ x: 20 }}
              title='Visitors by browser'
            >
              <VictoryBar
                barWidth={({ index }) => +index * 2 + 8}
                style={{
                  data: { fill: "#00403D" }
                }}
                data={getBrowserData()}
              />
            </VictoryChart>
          </Column>
        </Row>
      </DashboardPanel>
    </div>
  );
};

export default BusinessStats;