import React from 'react';
import CoreButton from '../../../../components/Forms/Button';
import Icon from '../../../../components/Media/Icon';
import styled from 'styled-components';
import BREAKPOINTS from '../../../../config/breakpoints';

interface ComponentProps {
    onClick: () => void;
    className?: string;
}

const Button = styled(CoreButton)`
    position: absolute !important;
    top: 5rem !important;
    right: 6rem !important;

    @media (max-width: 1023px) {
      top: 12rem !important;
    }
`

const BugButton = ({ onClick, className }: ComponentProps) => {
  return (
    <Button small onClick={onClick} className={className}><Icon name='bug' /></Button>
  );
};

export default BugButton;