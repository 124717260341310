import React, { Component, useEffect, useState } from 'react';
import { ApiService } from '../../../api/api-connectors';
import { BaseActiveBooking, BaseBusiness, OverlappingBooking, VerificationModel } from '../../../api/api-definitions';
import DataTable, { DataTableItem } from '../../../components/Layout/Datatable';
import { H1 } from '../../../components/Typography/Headings';
import { NotificationService } from '../../../services/NotificationService';
import { TIMEFORMAT, formatDate } from '../../../utils/date-helpers';
import DashboardHeader from '../../../components/Dashboard/Header';
import Loader from '../../../components/Layout/Loader';

interface ComponentProps {

}

interface ComponentState {

}

const OverlappingBookings = () => {
  const [data, setData] = useState<OverlappingBooking[]>([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setLoading(true);

    ApiService.admin.List__GET().
    then((result) => {
      setData(result);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
      NotificationService.Error('Sorry, there was an error fetching data.')
    })
  }

  const gentable = (): DataTableItem[] => {
    const items: DataTableItem[] = [];
    data.forEach((item) => {
      const dataItem: DataTableItem = {
        data: {
          'Business name': {
            value: item.businessName,
            sortable: true,
          },
          // 'Location Id': {
          //   value: item.locationId,
          //   sortable: true,
          // },
          'Booking ref': {
            value: item.bookingReference,
            sortable: true,
          },
          'Table name': {
            value: item.tableName,
            sortable: true,
          },
          'Date': {
            value: formatDate(item.slotDate),
            sortable: true,
          },
          'From time': {
            value: formatDate(item.startDateTime, TIMEFORMAT),
            sortable: true,
          },
          'To time': {
            value: formatDate(item.endDateTime, TIMEFORMAT),
            sortable: true,
          },
        }
      }
      items.push(dataItem);
    });
    return items;
  }

  return (
    <div>
        <DashboardHeader icon='diagram-venn' title='Overlapping bookings'></DashboardHeader>
        {loading && <Loader />}
        <DataTable data={gentable()} />
      </div>
  );
};

export default OverlappingBookings;
