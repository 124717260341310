import React, { useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { NodeOptions } from './node-options';
import DynamicDocument, { DocumentContentNode, ElementTypes } from '../../../../../DocumentTemplates/Dynamic';
import { createUUID } from '../../../../../utils/data-helpers';
import Icon from '../../../../../components/Media/Icon';
import DocumentService from '../../../../../DocumentTemplates/shared/DocumentService';
import { StyledColourInput, StyledDropdown, StyledNumberInput, StyledTextInput, StyledTextarea } from '../../../../../theme/input.styles';
import { Column, Row } from '../../../../../components/Layout/Grid';

export const BACKGROUND_COLOR = '#fbfbfc';

interface DocumentNode {
    label: string;
    path: string;
    index?: number;
}

interface ComponentProps {

}

const Viewer = styled.div`
    display: flex;
    margin-top: 1rem;
`

const Left = styled.div`
    width: 25rem;
    min-width: 25rem;
    max-width: 25rem;
    flex: 1 auto;
    min-height: 100vh;
    border-right: 1px solid ${props => props.theme.borderColor};
    background-color: ${BACKGROUND_COLOR};

    input, textarea {
        width: 100%;
        padding: 0.3rem;
        margin-top: 0.5rem;
    }
`;

const Right = styled.div`
    flex: 1 auto;
`;

const Tabs = styled.div`
    display: flex;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    background-color: #fff;
`

const Tab = styled.div<{ active?: boolean }>`
    flex: 1 auto;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    ${props => props.active ? `
        color: ${props.theme.primary};
        border-bottom: 2px solid ${props.theme.primary};
    ` : `
        border-bottom: 2px solid transparent;
    `}

    &:hover {
        color: ${props => props.theme.primary};
    }
`

const ComponentButton = styled(Column)`
    flex: 1 auto;
    padding: 1rem 1rem;
    text-align: center;
    background-color: #FFF;
    border: 1px solid ${props => props.theme.borderColor};
    cursor: move;
    transition: all 0.3s ease;
    font-size: 0.7rem;

    &:hover {
        border-color: #d4dadf;
    }

    .icon {
        font-size: 1rem;
    }
`

const Heading = styled.div`
    text-align: center;
    font-weight: bold;
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid ${props => props.theme.borderColor};
`

const OptionGroup = styled.div`
    margin: 1rem 1rem;

    label {
        font-weight: bold;
    }
`

const ColumnDisplay = styled.div`
    display: inline-block;
    border: 1.5px solid black;
    margin-bottom: 1rem;
    height: 1rem;
    overflow: hidden;

    div {
        display: inline-block;
        height: 1rem;
        width: 0.5rem;
        border-right: 1.5px solid black;

        &:last-child {
            border: none;
        }
    }
`

export interface DragAndDropLocation {
    id?: string;
    columnIndex?: number;
    index?: number;
}

const DocumentBuilder = ({ }: ComponentProps) => {
    const [storedData, setStoredData] = useState<DocumentContentNode[]>([]);
    const [tab, setTab] = useState(0);
    const [dragItemIndex, setDragItemIndex] = useState<DragAndDropLocation>();
    const [hoverItemIndex, setHoverItemIndex] = useState<DragAndDropLocation>();
    const [dragElement, setDragElement] = useState<ElementTypes>();
    const [selectedElement, setSelectedElement] = useState<DocumentContentNode>();
    const dragItem = useRef<DragAndDropLocation>();

    const addContentNode = (dragOntoItem: DragAndDropLocation) => {
        if (!dragOntoItem) return;
        dragOntoItem.index += 1;
        let edited = cloneDeep(storedData);
        if (!edited) edited = [];
        const newNode: DocumentContentNode = {
            Element: dragElement,
            Values: {},
            Id: createUUID()
        };
        NodeOptions[dragElement].options.forEach(option => {
            if (option.property === 'columns') {
                newNode.Values[option.property] = [{ value: 6 }, { value: 6 }];
            } else if (option.property === 'columnContent') {
                newNode.Values.columnContent = {
                    0: [],
                    1: [],
                    2: [],
                    3: [],
                    4: [],
                    5: [],
                    6: [],
                    7: [],
                    8: [],
                    9: [],
                    10: [],
                    11: [],
                };
            } else if (option.type === 'size') {
                newNode.Values[option.property] = {
                    size: 100,
                    unit: '%'
                }
            } else if (option.type === 'style') {
                newNode.Values[option.property] = {
                    fontSize: {
                        value: 1,
                        unit: 'REM'
                    },
                    textAlign: 'left',
                    display: 'block'
                }
            } else {
                newNode.Values[option.property] = '';
            }
        })
        if (!dragOntoItem.id) {
            edited.splice(dragOntoItem.index, 0, newNode)
        } else {
            const node = findNodeRecursive(edited, dragOntoItem.id);
            node.Values.columnContent[dragOntoItem.columnIndex].splice(dragOntoItem.index, 0, newNode)
        }
        setSelectedElement(newNode)
        setDragItemIndex(undefined)
        dragItem.current = undefined;
        setDragElement(undefined)
        setStoredData(edited)
    }

    const findNodeRecursive = (nodes: DocumentContentNode[], id: string) => {
        if (nodes) {
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].Id === id) return nodes[i];
                if (nodes[i].Element === 'row') {
                    for (let x = 0; x < 12; x++) {
                        const item = findNodeRecursive(nodes[i].Values.columnContent[x], id);
                        if (item) return item;
                    }
                }
            }
        }
    }

    const onContentNodeChange = (value: any, property: string, id: string) => {
        const edited = cloneDeep(storedData);
        const nodes = edited;
        const node = findNodeRecursive(nodes, id);
        if (node) {
            node.Values[property] = value;
            setSelectedElement(node);
        }
        setStoredData(edited)
    }

    const onDrop = (item: DragAndDropLocation) => {
        if (dragItemIndex !== null && dragItemIndex !== undefined) {
            addContentNode(cloneDeep(item));
        } else {
            setDragElement(undefined);
            setDragItemIndex(undefined);
        }
    }

    const updateDragIndex = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: DragAndDropLocation) => {
        setTimeout(() => {
            event.stopPropagation();
            dragItem.current = index;
            setDragItemIndex(index)
        }, 10);
    }

    const updateHoverIndex = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: DragAndDropLocation) => {
        event.stopPropagation();
        setHoverItemIndex(index)
    }

    return (
        <Viewer>
            <Left>
                <Tabs>
                    <Tab onClick={() => setTab(0)} active={tab === 0}>Page builder</Tab>
                    <Tab onClick={() => setTab(1)} active={tab === 1}>Page settings</Tab>
                </Tabs>
                {tab === 0 && !selectedElement &&
                    <>
                        <Heading>
                            Add components
                        </Heading>
                        <OptionGroup>
                            <Row>
                                {Object.keys(NodeOptions).map((key, index) => (
                                    <ComponentButton size={4} key={key} draggable onDragStart={() => setDragElement(NodeOptions[key].type)} onDragEnd={() => onDrop(dragItem.current)}>
                                        <Icon name={NodeOptions[key].icon || 'circle-question'} /><br />{NodeOptions[key].title}
                                    </ComponentButton>
                                ))}
                            </Row>
                        </OptionGroup>
                    </>
                }
                {tab === 0 && selectedElement &&
                    <>
                        <Heading onClick={() => setSelectedElement(undefined)}>
                            Edit {NodeOptions[selectedElement.Element].title}
                        </Heading>
                        {NodeOptions[selectedElement.Element].options.map((option) => (
                            <OptionGroup>
                                {option.type === 'text' &&
                                    <StyledTextInput
                                        value={selectedElement.Values[option.property]}
                                        label={option.label}
                                        onChange={(e) => onContentNodeChange(e.target.value, option.property, selectedElement.Id)} />
                                }
                                {option.type === 'number' &&
                                    <StyledNumberInput
                                        value={selectedElement.Values[option.property]}
                                        label={option.label}
                                        onChange={(e) => onContentNodeChange(e.target.value, option.property, selectedElement.Id)} />
                                }
                                {option.type === 'size' &&
                                    <Row>
                                        <Column size={9}>
                                            <StyledNumberInput
                                                value={selectedElement.Values[option.property]?.size}
                                                label={option.label}
                                                onChange={(e) => onContentNodeChange({ size: e.target.value, unit: selectedElement.Values[option.property]?.unit }, option.property, selectedElement.Id)} />
                                        </Column>
                                        <Column size={3}>
                                            <StyledDropdown
                                                value={selectedElement.Values[option.property]?.unit}
                                                addDefault={false}
                                                items={[{ value: '%' }, { value: 'px' }, { value: 'pt' }]}
                                                label='&nbsp;'
                                                onChange={(e) => onContentNodeChange({ size: selectedElement.Values[option.property]?.size, unit: e.target.value }, option.property, selectedElement.Id)} />
                                        </Column>
                                    </Row>
                                }
                                {option.type === 'textarea' &&
                                    <StyledTextarea
                                        rows={6}
                                        label={option.label}
                                        onChange={(e) => onContentNodeChange(e.target.value, option.property, selectedElement.Id)} />
                                }
                                {option.type === 'columnSelector' &&
                                    <Row>
                                        <ComponentButton size={4} onClick={() => onContentNodeChange([{ value: 12 }], option.property, selectedElement.Id)}>
                                            <ColumnDisplay><div /></ColumnDisplay><br />
                                            1 Column
                                        </ComponentButton>
                                        <ComponentButton size={4} onClick={() => onContentNodeChange([{ value: 6 }, { value: 6 }], option.property, selectedElement.Id)}>
                                            <ColumnDisplay><div /><div /></ColumnDisplay><br />
                                            2 Columns
                                        </ComponentButton>
                                        <ComponentButton size={4} onClick={() => onContentNodeChange([{ value: 4 }, { value: 4 }, { value: 4 }], option.property, selectedElement.Id)}>
                                            <ColumnDisplay><div /><div /><div /></ColumnDisplay><br />
                                            3 Columns
                                        </ComponentButton>
                                        <ComponentButton size={4} onClick={() => onContentNodeChange([{ value: 3 }, { value: 3 }, { value: 3 }, { value: 3 }], option.property, selectedElement.Id)}>
                                            <ColumnDisplay><div /><div /><div /><div /></ColumnDisplay><br />
                                            4 Columns
                                        </ComponentButton>
                                        <ComponentButton size={4} onClick={() => onContentNodeChange([{ value: 2 }, { value: 2 }, { value: 2 }, { value: 2 }, { value: 2 }, { value: 2 }], option.property, selectedElement.Id)}>
                                            <ColumnDisplay><div /><div /><div /><div /><div /><div /></ColumnDisplay><br />
                                            6 Columns
                                        </ComponentButton>
                                    </Row>
                                }
                                {option.type === 'style' &&
                                    <>
                                        <Heading>
                                            Component style options
                                        </Heading>
                                        {selectedElement.Element === 'text' &&
                                            <>
                                                <Row>
                                                    <Column size={9}>
                                                        <StyledNumberInput
                                                            value={selectedElement.Values[option.property]?.fontSize?.value}
                                                            label='Font size'
                                                            onChange={(e) => onContentNodeChange({ ...selectedElement.Values[option.property], fontSize: { ...selectedElement.Values[option.property].fontSize, value: e.target.value } }, option.property, selectedElement.Id)} />
                                                    </Column>
                                                    <Column size={3}>
                                                        <StyledDropdown
                                                            value={selectedElement.Values[option.property]?.fontSize?.unit}
                                                            addDefault={false}
                                                            items={[{ value: 'rem' }, { value: 'em' }, { value: 'px' }, { value: 'pt' }]}
                                                            label='&nbsp;'
                                                            onChange={(e) => onContentNodeChange({ ...selectedElement.Values[option.property], fontSize: { ...selectedElement.Values[option.property].fontSize, unit: e.target.value } }, option.property, selectedElement.Id)} />
                                                    </Column>
                                                </Row>
                                                <Row>
                                                    <Column size={12}>
                                                        <StyledDropdown
                                                            value={selectedElement.Values[option.property]?.textAlign}
                                                            addDefault={false}
                                                            items={[{ value: 'left' }, { value: 'center' }, { value: 'right' }]}
                                                            label='Text align'
                                                            onChange={(e) => onContentNodeChange({ ...selectedElement.Values[option.property], textAlign: e.target.value }, option.property, selectedElement.Id)} />
                                                    </Column>
                                                </Row>
                                                <Row>
                                                    <Column size={12}>
                                                        <StyledColourInput
                                                            value={selectedElement.Values[option.property]?.color}
                                                            label='Text colour'
                                                            onChange={(e) => onContentNodeChange({ ...selectedElement.Values[option.property], color: e.target.value }, option.property, selectedElement.Id)} />
                                                    </Column>
                                                </Row>
                                            </>
                                        }
                                        <Row>
                                            <Column size={12}>
                                                <StyledColourInput
                                                    value={selectedElement.Values[option.property]?.backgroundColor}
                                                    label='Background colour'
                                                    onChange={(e) => onContentNodeChange({ ...selectedElement.Values[option.property], backgroundColor: e.target.value }, option.property, selectedElement.Id)} />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column size={12}>
                                                <StyledTextInput
                                                    value={selectedElement.Values[option.property]?.padding}
                                                    label='Padding'
                                                    onChange={(e) => onContentNodeChange({ ...selectedElement.Values[option.property], padding: e.target.value }, option.property, selectedElement.Id)} />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column size={12}>
                                                <StyledTextInput
                                                    value={selectedElement.Values[option.property]?.margin}
                                                    label='Margin'
                                                    onChange={(e) => onContentNodeChange({ ...selectedElement.Values[option.property], margin: e.target.value }, option.property, selectedElement.Id)} />
                                            </Column>
                                        </Row>
                                    </>
                                }
                            </OptionGroup>
                        ))}
                    </>
                }
            </Left>
            <Right>
                {/* {visible && DocumentStaticService.ViewMode === 0 &&
                    <PDFViewer style={{ height: 'calc(100vh - 110px)', width: '99.8vw' }}>
                        <CustomDoc data={storedData} basePath={'/assets/'} onHover={updateHoverIndex} />
                    </PDFViewer>
                } */}
                <DynamicDocument
                    data={storedData}
                    basePath={'/assets/'}
                    onHover={updateHoverIndex}
                    onDragOver={updateDragIndex}
                    hoverActive={!!dragElement}
                    onSelect={setSelectedElement}
                    setDrag={() => { }}
                    selectedId={selectedElement?.Id}
                    hoverItem={hoverItemIndex}
                />
            </Right>
        </Viewer>
    );
};

export default DocumentBuilder;