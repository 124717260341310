import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ApiService } from '../../../../../api/api-connectors';
import { BaseBusinessOpeningTimesRange, DayOfWeek, GenericResponse, UpdateOpeningTimes } from '../../../../../api/api-definitions';
import CoreButton from '../../../../../components/Forms/Button';
import Time from '../../../../../components/Forms/Time';
import { FloatingActionBar } from '../../../../../components/Layout/FloatingActionBar';
import { Column, Row } from '../../../../../components/Layout/Grid';
import Loader from '../../../../../components/Layout/Loader';
import Icon from '../../../../../components/Media/Icon';
import { H1, H3 } from '../../../../../components/Typography/Headings';
import { Constants } from '../../../../../constants';
import { useApi } from '../../../../../hooks/useApi';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { useMergeState } from '../../../../../hooks/useMergeState';
import { NotificationService } from '../../../../../services/NotificationService';
import { BaseErrorMessage } from '../../../../../theme/input.core.styles';
import { firstBy } from "thenby";

import OpeningTimeItem from './OpeningTimeItem';
import { response } from 'express';
import { ErrorMessage, WarningMessage as BaseMessage, InfoMessage } from '../../../../../components/Forms/Messaging';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../components/Dashboard/Panel';
import { TabBar, TabButton } from '../../../../../components/Layout/Tabs';
import SpecialOpeningTimes from './SpecialOpeningTimes';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';
import { StyledTextInput } from '../../../../../theme/input.styles';
import InfoButton from '../../../../../components/Cta/InfoButton';

const DaysOfWeek = {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
}

const WarningMessage = styled(BaseMessage)`
    padding: 0.5rem;
    margin-right: 11rem;
    flex: 1;
`;

const Divider = styled.div`
    border-bottom: ${props => props.theme.borderSize} solid ${props => props.theme.primary};
    margin-bottom: 1rem;
`

const OpeningTimes = () => {
    const [businessLoaded, data] = useBusiness();
    const businessId = data?.id;
    const parentBusinessId = data?.parentBusinessId;
    const [openingTimes, setOpeningTimes] = useMergeState({ timeFrom: null, timeTo: null, shiftLabel: '' });
    const [deletedIds, setDeletedIds] = useState<number[]>([]);
    const [tab, setTab] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string>('');
    if (!data) {
        let [] = useApi<BaseBusinessOpeningTimesRange[]>(null);
    }
    if (!data) return <Loader />;
    const [loaded, days, setDays, updateDay, loadApi, hasChanges] = useApi<BaseBusinessOpeningTimesRange[]>(
        ApiService.businessOpeningTimesRange.List__GET,
        { businessId, parentBusinessId },
        'Failed to load opening times'
    );
    if (!loaded || !businessLoaded) return <Loader />;

    const saveChanges = () => {
        let updateParams: UpdateOpeningTimes = {
            openingTimeRanges: days,
            deletedIds: deletedIds,
            businessId: businessId,
            parentBusinessId: parentBusinessId
        };

        ApiService.businessOpeningTimesRange.Save__POST(updateParams).then((response: GenericResponse) => {
            if (response.success) {
                NotificationService.Confirm('Opening times have been updated.');
                setDays([])
                loadApi()
            } else {
                if (response.info) {
                    setErrorMessage(response.info);
                }
                NotificationService.Error('Failed to save some opening times');
            }
        }).catch(() => {
            NotificationService.Error('Failed to save opening times');
        });
    }

    const applyTimes = () => {
        if (errorMessage) setErrorMessage('');

        const newDays = cloneDeep(days);
        newDays.forEach(day => {
            if (!day.closed) {
                if (openingTimes.timeFrom) day.timeFrom = openingTimes.timeFrom;
                if (openingTimes.timeTo) day.timeTo = openingTimes.timeTo;
                if (!isNullOrWhitespace(openingTimes.shiftLabel)) day.shiftLabel = openingTimes.shiftLabel;
            }
        });
        setDays(newDays);
        setOpeningTimes({ timeFrom: null, timeTo: null });
    }

    const removeOpeningTime = (day: BaseBusinessOpeningTimesRange): void => {
        if (errorMessage) setErrorMessage('');

        if (!!day.id) {
            const newDays = cloneDeep(days);
            const item = newDays.find(x => x.dayOfWeek === day.dayOfWeek && x.sortOrder === day.sortOrder);

            if (!!item.id && item.id !== 0) {
                const clonedDeletedIds = cloneDeep(deletedIds);
                clonedDeletedIds.push(item.id);
                setDeletedIds(clonedDeletedIds);
            }
        }

        const daysAfterRemoval = days.filter(d => !(d.dayOfWeek === day.dayOfWeek && d.sortOrder === day.sortOrder));
        setDays(daysAfterRemoval);
    }

    const addOpeningTime = (day: BaseBusinessOpeningTimesRange): void => {
        if (errorMessage) setErrorMessage('');

        const newData = cloneDeep(days);
        const currentDay: BaseBusinessOpeningTimesRange = {
            id: undefined,
            dayOfWeek: day.dayOfWeek,
            timeToDayOfWeek: day.dayOfWeek,
            closed: false,
            sortOrder: day.sortOrder + 1,
            parentBusinessId: day.parentBusinessId,
            businessId: day.businessId,
            dayOfWeekSortOrder: day.dayOfWeekSortOrder
        };

        newData.push(currentDay);

        newData.sort(firstBy(function (v: BaseBusinessOpeningTimesRange) { return v.dayOfWeekSortOrder })
            .thenBy(function (v: BaseBusinessOpeningTimesRange) { return v.sortOrder }));

        setDays(newData);
    }

    const setDay = (data: BaseBusinessOpeningTimesRange, day: DayOfWeek) => {
        if (errorMessage) setErrorMessage('');

        const newDays = cloneDeep(days);
        // @ts-ignore
        data.dayOfWeek = day;
        const item = newDays.find(x => x.dayOfWeek === day && x.sortOrder === data.sortOrder);

        if (data.closed !== item.closed) {
            const otherDays = newDays.filter(x => x.dayOfWeek === day && x.sortOrder !== data.sortOrder);

            if (otherDays && otherDays.length > 0) {
                for (let i = 0; i <= otherDays.length - 1; i++) {
                    otherDays[i].closed = data.closed;
                }
            }
        }

        if (item) {
            Object.assign(item, data);
            setDays(newDays)
        } else {
            newDays.push(data);
            setDays(newDays)
        }
    }

    if (!Array.isArray(days)) {
        return (
            <>
                <DashboardHeader icon='clock' title='Opening times'>Set up opening times for each weekday and up to 3 slots per day. Set up exceptions like Christmas break.</DashboardHeader>
                <BaseErrorMessage>
                    Sorry, there has been a problem loading opening times.
                </BaseErrorMessage>
            </>
        )
    }

    const formValid = !days.find(x => !x.closed &&
        (isNullOrWhitespace(x.shiftLabel) ||
            isNullOrWhitespace(x.timeFrom) ||
            isNullOrWhitespace(x.timeFrom) ||
            x.shiftLabel.length > 50)
    );
    const closingTimeLabel = 'Closing time'
    return (
        <>
            <DashboardHeader icon='clock' docsLink='business-setup/opening-times' title='Opening times'>
                <br/>
                Set up opening times for each weekday with up to 3 opening slots per day. <br/>
                Set up altered opening times, for example Bank holiday opening hours under the Exceptions tab.</DashboardHeader>
            <TabBar>
                <TabButton onClick={() => setTab(0)} active={tab === 0}>Standard times</TabButton>
                <TabButton onClick={() => setTab(1)} active={tab === 1}>Exceptions</TabButton>
            </TabBar>
            <InfoMessage>
                Please note, shift labels will be visible on booking widget when multiple shifts are configured.
            </InfoMessage>
            <br />
            {tab == 0 &&
                <>
                    <DashboardPanel>
                        <Row>
                            <Column size={2} style={{ paddingTop: '0.8rem' }} noMarginBottom>
                                Set all to:
                            </Column>
                            <Column size={2} noMarginBottom>
                                <Time value={openingTimes.timeFrom} onChange={(e) => setOpeningTimes({ timeFrom: e.target.value })} />
                            </Column>
                            <Column size={2} noMarginBottom>
                                <Time value={openingTimes.timeTo} onChange={(e) => setOpeningTimes({ timeTo: e.target.value })} />
                            </Column>
                            <Column size={2} noMarginBottom>
                                <StyledTextInput value={openingTimes.shiftLabel} placeholder='e.g. Dinner' onChange={(e) => setOpeningTimes({ shiftLabel: e.target.value })} />
                            </Column>
                            <Column size={2} noMarginBottom>
                                <CoreButton onClick={applyTimes}>Apply</CoreButton>
                            </Column>
                        </Row>
                    </DashboardPanel>
                    <DashboardPanel>
                        <Row>
                            <Column size={2}>
                            </Column>
                            <Column size={2} style={{ fontWeight: 'bold' }}>
                                Opening time
                            </Column>
                            <Column size={2} style={{ fontWeight: 'bold' }}>
                               {closingTimeLabel} <InfoButton>The last slot will not be bookable. e.g. If '{closingTimeLabel}' is set to 6pm, then bookable slots will be until 5:45pm, as 6pm would be considered as closed time.</InfoButton>
                            </Column>
                            <Column size={2} style={{ fontWeight: 'bold' }}>
                                Shift label <InfoButton>When multiple shifts per day are configured, these labels will be visible both externally (on booking widget) and internally (on table bookings view). This will help your clients and your staff to identify which shift the bookings are made for e.g. Breakfast, Lunch, Brunch, Dinner etc. </InfoButton>
                            </Column>
                        </Row>
                        {days.map((day, dayIndex) => {
                            const currentDayMaxSortOrder = Math.max(...days.filter(x => x.dayOfWeek === day.dayOfWeek).map(x => x.sortOrder));
                            if (!(day.closed && day.sortOrder > 1)) return (
                                <OpeningTimeItem
                                    addOpeningTime={() => addOpeningTime(day)}
                                    removeOpeningTime={() => removeOpeningTime(day)}
                                    setDay={(data) => setDay(data, day.dayOfWeek)}
                                    data={day}
                                    maxSortOrderForDay={currentDayMaxSortOrder}
                                    label={`${day.sortOrder > 1 ? '' : day.dayOfWeek}`}
                                />
                            )
                        })}
                    </DashboardPanel>
                </>
            }
            {tab == 1 &&
                <SpecialOpeningTimes />
            }
            {hasChanges &&
                <FloatingActionBar flex>
                    {errorMessage &&
                        <ErrorMessage>
                            {errorMessage}
                        </ErrorMessage>
                    }
                    <WarningMessage>{Constants.messaging.makeSureToSave}</WarningMessage>
                    <CoreButton small disabled={!formValid} onClick={() => saveChanges()} floatRight>Save changes <Icon name='save' /></CoreButton>
                </FloatingActionBar>
            }
        </>
    );
};

export default OpeningTimes;
