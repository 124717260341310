import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    // Your Firebase configuration goes here
    apiKey: "AIzaSyCfBQCFCNdogVQyES4pFpmX2FgpKo3tHxU",
    authDomain: "dishforager.firebaseapp.com",
    projectId: "dishforager",
    storageBucket: "dishforager.appspot.com",
    messagingSenderId: "838994993337",
    appId: "1:838994993337:web:9eb7c16e2800e7316dfb68",
    measurementId: "G-KQER4LS0N4"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

interface ComponentProps {
    children: any;
}

export const requestForToken = () => {
    // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
    // when sending message requests to different push services
    return getToken(messaging, { vapidKey: `BMqaC3ZzddU2bHc5mGN1O3HJL5OWCXafaPLAbh5jxpMmsC0_RsRhMpY7XQOVFLEPIKfnL17Ryoe6XNJmKYq-hWk` }) //to authorize send requests to supported web push services
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);

                if(localStorage.getItem('fcmToken') && currentToken !==localStorage.getItem('fcmToken')){
                    localStorage.setItem('fcmToken', currentToken);

                }

                else if(!localStorage.getItem('fcmToken')){
                    localStorage.setItem('fcmToken', currentToken);

                }


            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

const PushNotificationWrapper = ({ children }: ComponentProps) => {
    const [token, setToken] = useState(null);
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        // Request permission to receive notifications
        requestForToken();

        // Listen for incoming messages
        onMessage(messaging, (payload) => {
            setNotification(payload.notification);
            console.log('Received notification:', payload);
        });
    }, []);
    
    return children;
};

export default PushNotificationWrapper;