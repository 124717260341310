import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { ApiService } from '../../../../api/api-connectors';
import { RegisterBusinessModel } from '../../../../api/api-definitions';
import DashboardAddButton from '../../../../components/Dashboard/AddButton';
import DashboardHeader from '../../../../components/Dashboard/Header';
import LocationLink from '../../../../components/Dashboard/LocationLink';
import CoreButton from '../../../../components/Forms/Button';
import { WarningMessage } from '../../../../components/Forms/Messaging';
import { Column, Row } from '../../../../components/Layout/Grid';
import Loader from '../../../../components/Layout/Loader';
import CoreModal from '../../../../components/Layout/CoreModal';
import Icon from '../../../../components/Media/Icon';
import useBusinessList from '../../../../hooks/useBusinessList';
import { NotificationService } from '../../../../services/NotificationService';
import { StyledTextInput } from '../../../../theme/input.styles';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import NewBusinessForm from '../../../NewBusiness/modules/NewBusinessForm';

interface ComponentProps {
    match: any;
}

const WarningIcon = styled(Icon)`
    color: ${props => props.theme.warning};
    float: none !important;
    margin-right: 1rem !important;
`

const OtherIcon = styled(Icon)`
    color: ${props => props.theme.primary};
    float: none !important;
    margin-right: 1rem !important;
`

const Spacer = styled.div`
    display: inline-block;
    width: 2.7rem;
    height: 1rem;
`

const Locations = ({ match }: ComponentProps) => {
    const [loaded, data, reload] = useBusinessList(match.params.parentId);
    const [newLocation, setNewLocation] = useState<RegisterBusinessModel>()
    const [newLocationOpen, setNewLocationOpen] = useState<boolean>(false)
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>();

    const closeModal = () => {
        setNewLocationOpen(false);
        setNewLocation(undefined);
    }

    const openModal = () => {
        setNewLocation({ currency: 'GBP' });
        setNewLocationOpen(true);
    }

    const addLocation = () => {
        setModalLoading(true)
        newLocation.parentBusinessId = +match.params.parentId;
        newLocation.isChain = true;
        ApiService.parentBusiness.Add__PUT(newLocation).then((response) => {
            if (response.success) {
                setModalLoading(false)
                closeModal();
                reload();
                setFilter(newLocation.branchName)
                NotificationService.Confirm('Location added');
            } else {
                NotificationService.Error(response.info)
                setModalLoading(false)
            }
        })
    }

    if (!loaded) return <Loader />;

    const business = data && data.length > 0 ? data[0] : null;

    const filteredData = isNullOrWhitespace(filter) ? business.locations : business.locations.filter(x => x.name.toLowerCase().indexOf(filter.toLowerCase()) > -1)

    return (
        <>
            {newLocationOpen &&
                <CoreModal title='Add new location' small onClose={closeModal} actionBar={<>
                    <CoreButton onClick={addLocation} disabled={modalLoading || isNullOrWhitespace(newLocation.name) || (newLocation.isChain && isNullOrWhitespace(newLocation.branchName))}>Add</CoreButton>
                </>}>
                    {!modalLoading &&
                        <Row>
                            <Column size={8} tablet={12} mobile={12}>
                                <NewBusinessForm hideBranchName data={newLocation} setData={setNewLocation} errors={[]} />
                            </Column>
                        </Row>
                    }
                    {modalLoading && <Loader />}
                </CoreModal>
            }
            <DashboardHeader icon='store' title={`Locations for ${business.name}`}></DashboardHeader>
            {business.locations.find(x => x.verificationStatus.toString() != 'Verified') &&
                <>
                    <WarningMessage>
                        One or more businesses have not been verified. This means the unverified businesses will not be public until verification is complete.<br />
                        A verification code has been sent via post to the address provided.<br />
                        You can still continue to set up your business in the meantime.
                    </WarningMessage>
                    <br />
                </>
            }
            {!!business.locations && business.locations.length > 1 && 
                <Row>
                    <Column size={4} tablet={6} mobile={12}>
                        <StyledTextInput model='filter' value={filter} onChange={(e) => setFilter(e.target.value)} label='Filter by name' />
                    </Column>
                    <Column size={4} tablet={6} mobile={12}>
                        <br />
                        {!isNullOrWhitespace(filter) && <CoreButton outline onClick={() => setFilter(undefined)}>Clear filter</CoreButton> }
                    </Column>
                </Row>
            }
            <DashboardAddButton onClick={openModal}>Add location</DashboardAddButton>
            <br />
            {filteredData.map(item => (
                <LocationLink
                    name={<>
                        {item.verificationStatus.toString() != 'Verified' && <WarningIcon name='exclamation-triangle' />}
                        {item.verificationStatus.toString() == 'Verified' && <OtherIcon name='location-arrow' />}
                        {item.locationAddress.formattedName}
                    </>}
                    url={`/dashboard/${business.id}/locations/${item.locationId}`}
                    description={<><Spacer />{item.locationAddress.formattedAddress}</>}
                />
            ))}
        </>
    );
};

export default Locations;