import React, { useState } from 'react';
import { BaseSupportTicket } from '../../../../../api/api-definitions';
import CoreModal from '../../../../../components/Layout/CoreModal';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { StyledTextInput, StyledTextarea } from '../../../../../theme/input.styles';
import { ApiService } from '../../../../../api/api-connectors';
import { NotificationService } from '../../../../../services/NotificationService';
import CoreButton from '../../../../../components/Forms/Button';
import RichTextEdior from '../../../../../components/Forms/RichTextEditor';

interface ComponentProps {
    ticket?: BaseSupportTicket;
    onClose: (refreshData?: boolean) => void;
    full?: boolean;
}

const SupportTicketForm = ({ ticket, onClose }: ComponentProps) => {
    const [data, setData] = useState(ticket);
    const [saving, setSaving] = useState(false);

    const submit = () => {
        setSaving(true);
        ApiService.supportTicket.Add__PUT(data).then(() => {
            NotificationService.Confirm('Support issue logged');
            onClose(true)
        }).catch(() => {
            setSaving(false)
            NotificationService.Error('Sorry, ironically there was an error submitting the support issue. Please contact us directly.')
        })
    }

    return (
        <FormWrapper onUpdate={(formData) => setData({...data, ...formData})} disabled={saving}>
            {({ id, valid }) => (
                <CoreModal actionBar={<CoreButton requesting={saving} disabled={!valid || saving} onClick={submit}>Submit</CoreButton>} onClose={() => onClose(false)} title={ticket.id ? 'Edit reported issue' : 'Report an issue'}>
                    <Row>
                        <Column size={12}>
                            <StyledTextInput required value={data.summary} max={50} label='Summary' model='summary' />
                        </Column>
                        <Column size={12}>
                            <RichTextEdior required value={data.description} label='Please describe the issue as detailed as possible' model='description' />
                        </Column>
                    </Row>
                </CoreModal>
            )}
        </FormWrapper>
    );
};

export default SupportTicketForm;