import React from 'react';
import styled from 'styled-components';
import { BaseDish } from '../../../../api/api-definitions';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { BasketState, FoodItemState } from '..';
import { ConvertCurrency } from '../../../../utils/currency-helper';
import { cloneDeep } from 'lodash';
import { StyledNumberInput } from '../../../../theme/input.styles';
import BREAKPOINTS from '../../../../config/breakpoints';

interface ComponentProps {
    dish: BaseDish;
}

const BasketItem = ({ dish }: ComponentProps) => {
    const setBasketState = useSetRecoilState(BasketState);
    const [value, setValue] = useRecoilState(FoodItemState([dish.id, 0]));

    const setQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const amount = +e.target.value;
        if (amount < 0) return;
        setBasketState(basket => {
            const current = cloneDeep(basket);
            if (current[dish.id]) {
                current[dish.id].quantity = amount;
            } else {
                current[dish.id] = {
                    quantity: amount
                }
            }
            if (current[dish.id].quantity == 0) {
                delete current[dish.id];
            }
            return current;
        })
        setValue(amount)
    }

    return (
        <BasketItemContainer>
            <BasketQty><StyledNumberInput value={value} hasPlusMinus min={0} onChange={setQuantity} /></BasketQty>
            <BasketName>{dish.name}</BasketName>
            <BasketPrice>{ConvertCurrency({ code: 'GBP', amount: dish.price * value })}</BasketPrice>
        </BasketItemContainer>
    );
};

const BasketItemContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding: 0.5rem 0;
  line-height: 2rem;
  flex-wrap: wrap;

  &:last-child {
    border: none;
  }
`

const BasketQty = styled.div`
  width: 7rem;

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    order: 2;
    width: 50%;
  }
`

const BasketPrice = styled.div`
  width: 5rem;
  text-align: right;

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    order: 3;
    width: 50%;
  }
`

const BasketName = styled.div`
  padding: 0 0.5rem;
  flex: 1;
  font-size: 0.9rem;

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    order: 1;
    width: 100%;
    flex: none;
    padding: 0;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
  }
`

export default BasketItem;