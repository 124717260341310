import React from 'react';
import { ButtonBack, ButtonTime, FormRow, InfoMessage, Message, SectionTitle, TimeRow } from '../../../booking.styles';
import { StyledDropdown } from '../../../../theme/input.styles';
import { ApiBookingTimesWidget, ApiBusinessBookingDetails, BookingAvailabilityTimesWidget, BookingAvailabilityWidget } from '../../../../api/api-definitions';
import { DropdownItem } from '../../../../components/Forms/Dropdown';
import BookingExperienceOption from './BookingExperienceOption';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import Loader from '../../../../components/Layout/Loader';
import Icon from '../../../../components/Media/Icon';
import { Column } from '../../../../components/Layout/Grid';
import moment, { Moment } from 'moment';
import { formatDate, TIMEFORMAT } from '../../../../utils/date-helpers';
import { BookingFormData, desktopSize, mobileSize, tabletSize } from '..';

interface ComponentProps {
    nextAvail: ApiBookingTimesWidget;
    nextAvailLoading: boolean;
    business: ApiBusinessBookingDetails;
    eventGuests: number;
    previewLocation: string;
    loading: boolean;
    searchableEvent: number;
    eventBreaksGuestRules: boolean;
    nextAvailHasMultipleShifts: BookingAvailabilityWidget;
    formData: BookingFormData;
    generatePeopleList: (limit: number) => DropdownItem[];
    setEventGuests: (value: React.SetStateAction<number>) => void;
    nextAvailSearch: (experienceId?: number, guestsOverride?: number, isEventSearch?: boolean) => void;
    setFormData: (value: React.SetStateAction<BookingFormData>) => void;
    setSelectedTimeDropdown: (value: React.SetStateAction<string>) => void;
    hold: (date?: Moment, time?: string, slot?: BookingAvailabilityTimesWidget) => void;
    setNextAvail: (value: React.SetStateAction<ApiBookingTimesWidget>) => void;
    getMaximumPartyForEvent: (eventId: number) => number;
}

const ExperienceTab = ({
    nextAvail,
    nextAvailLoading,
    business,
    eventGuests,
    previewLocation,
    loading,
    searchableEvent,
    eventBreaksGuestRules,
    nextAvailHasMultipleShifts,
    formData,
    generatePeopleList,
    setEventGuests,
    nextAvailSearch,
    setFormData,
    setSelectedTimeDropdown,
    hold,
    setNextAvail,
    getMaximumPartyForEvent
}: ComponentProps) => {
    const eventOrExperienceDetails = searchableEvent ? business?.specialEvents[searchableEvent] || business?.experiences[searchableEvent] : undefined;
    const defaultMaximumParty = 30;
    
    const maxPeopleForEvents = () => {
        let max = 0;
        [...Object.keys(business.specialEvents || {}), ...Object.keys(business.experiences || {})].forEach(key => {
            const eventMax = getMaximumPartyForEvent(+key)
            if (!eventMax && defaultMaximumParty > max) max = defaultMaximumParty; 
            if (eventMax > max) max = eventMax;
        });
        if (max == 0) return defaultMaximumParty;
        return max;
    }

    return (
        <>
            {!nextAvail && !nextAvailLoading &&
                <>
                    <FormRow widgetTheme={business?.theme}>
                        <StyledDropdown
                            icon='users'
                            model='guests'
                            value={eventGuests}
                            addDefault={false}
                            items={generatePeopleList(maxPeopleForEvents())}
                            onChange={(e) => setEventGuests(+e.target.value)}
                            unlink
                        />
                    </FormRow>
                    {Object.keys(business?.specialEvents).length > 0 &&
                        <>
                            <SectionTitle widgetTheme={business?.theme}>Our events</SectionTitle>
                        </>
                    }
                    {business?.specialEvents && Object.keys(business?.specialEvents).map(key => (
                        <BookingExperienceOption
                            id={+key}
                            key={'event-' + key}
                            disabled={!isNullOrWhitespace(previewLocation)}
                            layout={business?.specialEvents[key].layout}
                            business={business}
                            location={business?.specialEvents[key].location}
                            title={business?.specialEvents[key].name}
                            description={business?.specialEvents[key].description}
                            image={business?.specialEvents[key].imageUrl}
                            price={business?.specialEvents[key].price}
                            onSearch={() => nextAvailSearch(+key, eventGuests, true)}
                        />
                    ))}
                    {business?.experiences && Object.keys(business?.experiences).length > 0 &&
                        <SectionTitle widgetTheme={business?.theme}>Our experiences</SectionTitle>
                    }
                    {business?.experiences && Object.keys(business?.experiences).map(key => (
                        <BookingExperienceOption
                            id={+key}
                            key={'event-' + key}
                            business={business}
                            layout={business?.experiences[key].layout}
                            location={business?.experiences[key].location}
                            title={business?.experiences[key].name}
                            image={business?.experiences[key].imageUrl}
                            description={business?.experiences[key].description}
                            price={business?.experiences[key].price}
                            onSearch={() => nextAvailSearch(+key, eventGuests, true)}
                        />
                    ))}
                </>
            }
            {nextAvailLoading && <Loader />}

            {nextAvail && !loading && !nextAvailLoading &&
                <>
                    {nextAvail && searchableEvent &&
                        <div style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                            <br />
                            <strong>Next availability for {eventOrExperienceDetails?.name}</strong>
                        </div>
                    }
                    <FormRow widgetTheme={business?.theme}>
                        <StyledDropdown
                            icon='users'
                            model='guests'
                            value={eventGuests}
                            addDefault={false}
                            items={generatePeopleList(maxPeopleForEvents())}
                            onChange={(e) => {
                                if (+e.target.value != eventGuests) nextAvailSearch(searchableEvent, +e.target.value)
                            }}
                            unlink
                        />
                    </FormRow>
                    <ButtonBack style={{ marginTop: '1rem', marginBottom: '1rem' }} type='button' widgetTheme={business?.theme} onClick={() => setNextAvail(undefined)}>
                        <Icon name='arrow-left' duo doNotStyleDuo /> Back
                    </ButtonBack>
                    {eventBreaksGuestRules &&
                        <>
                            <InfoMessage marginTop widgetTheme={business?.theme}>
                                For a party of this size please contact us {isNullOrWhitespace(business.phoneNumber) ? 'directly' : `on ${business.phoneNumber}`} to make this booking.
                            </InfoMessage>
                            <br />
                        </>
                    }
                    {!eventBreaksGuestRules && nextAvail.availability?.length === 0 &&
                        <>
                            <InfoMessage widgetTheme={business?.theme}>
                                There is no availability{searchableEvent && <> for <strong>{eventOrExperienceDetails?.name}</strong></>} in the near future. Please check again later.
                            </InfoMessage>
                            <br />
                        </>
                    }
                    {!eventBreaksGuestRules && nextAvail.availability.map((slot, index) => (
                        <>
                            <Message widgetTheme={business?.theme}>
                                <strong>{formatDate(moment(slot.date), 'dddd, Do MMMM YYYY')}</strong>
                            </Message>
                            <br />
                            <TimeRow key={'next-avil-' + index}>
                                {slot && Object.keys(slot.shiftLabelAndTimes).map(shiftLabel => (
                                    <>
                                        {nextAvailHasMultipleShifts &&
                                            <Column size={12}>
                                                <strong>{shiftLabel}</strong>
                                            </Column>
                                        }
                                        {slot.shiftLabelAndTimes[shiftLabel].slots.map(time => (
                                            <Column size={desktopSize} tablet={tabletSize} mobile={mobileSize} key={`next-avail-${index}-${time.time}`}>
                                                <ButtonTime
                                                    onClick={() => {
                                                        setFormData({ ...formData, date: moment(slot.date), guests: eventGuests });
                                                        setSelectedTimeDropdown(time.time)
                                                        hold(moment(slot.date), time.time, time)
                                                    }}
                                                    type='button'
                                                    widgetTheme={business?.theme}>
                                                    {moment(time.time).format(TIMEFORMAT)}
                                                </ButtonTime>
                                            </Column>
                                        ))}
                                    </>
                                ))}
                            </TimeRow>
                        </>
                    ))}
                </>
            }
        </>
    );
};

export default ExperienceTab;