import React from 'react';
import DashboardLayout, { DashboardPage } from '../../../../DashboardLayout';
import { Route, Switch } from 'react-router';
import ExperienceList from './list';
import EditExperience from './edit';

interface ComponentProps {
    match: any;
}

const Experiences = ({ match }: ComponentProps) => {
    return (
        <Switch>
            <Route path={`${match.path}/`} exact component={(props) => <DashboardLayout requiresAdmin title='Experiences and events' route={props}><ExperienceList {...props} /></DashboardLayout>} />
            <Route path={`${match.path}/:experienceId`} component={(props) => <DashboardLayout requiresAdmin title='Edit experience or event' route={props}><EditExperience {...props} /></DashboardLayout>} />
        </Switch>
    );
};

export default Experiences;