import React from 'react';
import styled from 'styled-components';
import { ConvertHexToRGBA } from '../../theme';
import Icon from '../Media/Icon';

interface ComponentProps {
    children: React.ReactNode;
    inline?: boolean;
    icon?: string;
    onClick: () => void;
    className?: string;
}

const Container = styled.div<{inline?: boolean}>`
    background-color: rgba(${props => ConvertHexToRGBA(props.theme.primary, 0.1)});
    border: 1px dashed ${props => props.theme.primary};
    cursor: pointer;
    text-align: center;
    color: ${props => props.theme.primary};
    font-weight: bold;
    line-height: 3rem;
    ${props => props.inline ? 'display: inline-block;' : ''}
    padding: 0 2rem;

    &:hover {
        border: 1px solid ${props => props.theme.primary};
    }
`

const DashboardAddButton = ({ children, onClick, inline, icon, className }: ComponentProps) => {
  return (
    <Container className={className} inline={inline} onClick={onClick}><Icon name={icon || 'plus'} /> {children}</Container>
  );
};

export default DashboardAddButton;