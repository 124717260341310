import { DiscoverResult, ErrorResponse, ISdkManagedPaymentIntent, Reader, Terminal, loadStripeTerminal } from '@stripe/terminal-js';
import React, { useEffect, useRef, useState } from 'react';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { ApiService } from '../../../../../api/api-connectors';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { Badge } from '@chakra-ui/react';
import styled from 'styled-components';
import { BoxShadowStyle, BoxShadowStyleWithHover } from '../../../../../theme';
import { DashboardPage } from '../../../DashboardLayout';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import ActionBox from '../../../../../components/Forms/Messaging';
import CoreButton from '../../../../../components/Forms/Button';
import Loader from '../../../../../components/Layout/Loader';
import { NotificationService } from '../../../../../services/NotificationService';
import { Constants } from '../../../../../constants';

interface ComponentProps {
}

const TerminalSetup = ({ }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const terminal = useRef<Terminal>();
    const [readers, setReaders] = useState<Reader[]>();
    const [loading, setLoading] = useState(true);
    const loaded = useRef(false);

    useEffect(() => {
        if (!loaded.current && business) {
            if (business.stripeReaderLocationId) setupStripeTerminal();
            setLoading(false)
            loaded.current = true;
        }
    }, [businessLoaded])

    const fetchConnectionToken = async (): Promise<string> => {
        let token = '';
        await ApiService.checkout.CreateConnectionToken__GET(business.id).then((response) => {
            token = response.info;
        });
        return token;
    }

    const setupStripeTerminal = async () => {
        const StripeTerminal = await loadStripeTerminal();
        const response = StripeTerminal.create({
            onFetchConnectionToken: fetchConnectionToken,
            onUnexpectedReaderDisconnect: unexpectedDisconnect,
        });
        terminal.current = response;
        const config = { simulated: true };
        // const config = { location: business.stripeReaderLocationId };
        // const config = {  };
        terminal.current.discoverReaders(config).then(discoverResult => {
            if ((discoverResult as ErrorResponse).error) {
                console.log('Failed to discover: ', (discoverResult as ErrorResponse).error);
            } else if ((discoverResult as DiscoverResult).discoveredReaders.length === 0) {
                console.log('No available readers.');
            } else {
                let discoveredReaders = (discoverResult as DiscoverResult).discoveredReaders;
                console.log(discoveredReaders)
                setReaders(discoveredReaders)
            }
        });
    }

    const unexpectedDisconnect = () => {

    }

    const registerLocation = () => {
        ApiService.checkout.CreateReaderLocation__GET(business.id).then((response) => {
            if (response.success) {
                NotificationService.Confirm('Location registered')
                setupStripeTerminal()
            } else {
                NotificationService.Error('Could not register location')
            }
        })
    }

    return (
        <>
            <DashboardHeader title='Card readers' icon='credit-card' />
            {loading && <Loader />}
            {!loading &&
                <>
                    {!business?.stripeReaderLocationId &&
                        <ActionBox title='Welcome to card reader setup'>
                            To start accepting card payments in person with a card reader supplied by {Constants.businessName}, click the button below.
                            <br />
                            <br />
                            <CoreButton onClick={registerLocation}>Register your location</CoreButton>
                        </ActionBox>
                    }
                    {business?.stripeReaderLocationId &&
                        <Row>
                            {readers && readers.map((reader) => (
                                <Column size={4}>
                                    <CardSelectionContainer>
                                        <img src='/assets/graphics/WisePOS-E.png' />
                                        <div>
                                            <MachineName>{reader.label}</MachineName>

                                            <CardReaderStatus>
                                                {reader.status == 'online' ? <Badge colorScheme='green'>Online</Badge> : <Badge colorScheme='red'>Offline</Badge>}
                                            </CardReaderStatus>
                                        </div>
                                    </CardSelectionContainer>
                                </Column>
                            ))}
                        </Row>
                    }
                </>
            }
        </>
    );
};

const MachineName = styled.div`
    padding: 0 1rem;
    line-height: 3rem;
`;

const CardSelectionContainer = styled.div`
    ${BoxShadowStyle}
    padding: 1rem;
    background-color: white;
    text-align: center;

    img {
        display: block;
        max-height: 10rem;
        margin: 1rem auto;
    }
`

const CardReaderStatus = styled.div`
    padding-top: 0.6rem;
`

const CardReaderIcon = styled.div`
    width: 3rem;
    line-height: 3rem;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primaryContrast};
    border-radius: 0.2rem;
    text-align: center;
    font-size: 1.5rem;
`

export default TerminalSetup;