import React, { useEffect, useRef, useState } from 'react';
import { Canvas, ExtendedArea, ExtendedShape, ExtendedTable, SidePanel } from '..';
import MainStage from '../MainStage';
import styled from 'styled-components';
import CoreButton from '../../../../../../../components/Forms/Button';
import Icon from '../../../../../../../components/Media/Icon';
import { ModalService } from '../../../../../../../services/ModalService';
import { NotificationService } from '../../../../../../../services/NotificationService';
import { ApiService } from '../../../../../../../api/api-connectors';
import { AddCombinationRequest, DeleteRequest, LightCombinationGroup } from '../../../../../../../api/api-definitions';
import { useBusiness } from '../../../../../../../hooks/useBusiness';
import Loader from '../../../../../../../components/Layout/Loader';
import { StyledDropdown, StyledNumberInput } from '../../../../../../../theme/input.styles';
import { priorityOptions } from '../../bookingConstants';
import Checkbox from '../../../../../../../components/Forms/Checkbox';
import { cloneDeep, isEqual } from 'lodash';
import { isNullOrWhitespace } from '../../../../../../../utils/text-helpers';
import { Column, Row } from '../../../../../../../components/Layout/Grid';
import { getNumberArray } from '../../../../../../../utils/number-helper';
import { DropdownItem } from '../../../../../../../components/Forms/Dropdown';
import InfoButton from '../../../../../../../components/Cta/InfoButton';

interface ComponentProps {
    tables: ExtendedTable[];
    areas: ExtendedArea[];
    shapes: ExtendedShape[];
    selectedArea: ExtendedArea;
    experienceId?: number;
}

const ActionBar = styled.div`
    border-bottom: 1px solid ${props => props.theme.borderColor};
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    height: 3.5rem;

    span {
        padding: 0.5rem;
        line-height: 1.7rem;
        cursor: pointer;
    }
`

const CombinationContainer = styled.div`
    padding: 0.5rem;
`

const Combination = styled.div<{ selected?: boolean }>`
    border: 1px solid ${props => props.theme.borderColor};
    margin-bottom: 0.5rem;
    font-weight: bold;
`

const CombinationTitle = styled.div<{ selected?: boolean }>`
    padding: 0.5rem;
    cursor: pointer;
    ${props => props.selected ? `
        background-color: ${props.theme.primary};
        color: ${props.theme.primaryContrast};
    ` : `
        &:hover {
            background-color: ${props.theme.tertiary};
            color: ${props.theme.tertiaryContrast};
        }
    `}    
`

const CombinationOptions = styled.div`
    padding: 0.5rem;
    display: flex;

    div {
        flex: 1;
    }
`

function getTableMap(tables: ExtendedTable[]) {
    const value: { [key: number]: ExtendedTable } = {};
    tables.forEach(table => {
        value[table.id.toString()] = table;
    });
    return value;
}

function getAreaMap(areas: ExtendedArea[]) {
    const value: { [key: number]: ExtendedArea } = {};
    areas.forEach(area => {
        value[area.id.toString()] = area;
    });
    return value;
}

const Combinability = ({ tables, shapes, selectedArea, areas, experienceId }: ComponentProps) => {
    const [businessLoaded, businessData] = useBusiness();
    const [selectedTables, setSelectedTables] = useState<number[]>([]);
    const [combinations, setCombinations] = useState<LightCombinationGroup[]>();
    const combinationsRef = useRef<LightCombinationGroup[]>([]);
    const [selectedCombination, setSelectedCombination] = useState<number>();
    const selectedCombinationRef = useRef<number>();
    const tableMap = useRef<{ [key: string]: ExtendedTable }>(getTableMap(tables));
    const areaMap = useRef<{ [key: string]: ExtendedArea }>(getAreaMap(areas));

    const moveSelection = (e: KeyboardEvent) => {
        if (selectedCombinationRef.current === undefined) return;
        switch (e.key) {
            case "ArrowUp":
                if (selectedCombinationRef.current === 0) break;
                updateSelectedCombination(selectedCombinationRef.current - 1)
                e.preventDefault();
                break;
            case "ArrowDown":
                if (selectedCombinationRef.current === combinationsRef.current.length - 1) break;
                updateSelectedCombination(selectedCombinationRef.current + 1)
                e.preventDefault();
                break;
        }
    }

    useEffect(() => {
        if (businessData) {
            document.addEventListener('keydown', (e) => moveSelection(e));
            ApiService.combinationGroup.GetAllForBusiness__GET(businessData.id).then(response => {
                setCombinations(!!experienceId ? response.filter(x => x.tableIds.find(x => areaMap.current[tableMap.current[x].areaId].experienceId == experienceId)) : response)
            })
        }
    }, [businessLoaded])

    useEffect(() => {
        setSelectedTables([]);
    }, [selectedArea])

    if (!combinations) return <Loader />;

    const confirmDeleteCombination = () => {
        const request: DeleteRequest = {
            id: combinations[selectedCombination].combinationId
        }
        ApiService.combinationGroup.Delete__DELETE(request).then(response => {
            setCombinations([...combinations.filter(x => x.combinationId !== combinations[selectedCombination].combinationId)])
            updateSelectedCombination(undefined)
            ModalService.Close();
            NotificationService.Confirm('Combination deleted')
        })
    }

    const updateSelectedCombination = (index: number) => {
        setSelectedCombination(index);
        selectedCombinationRef.current = index;
    }

    const updateCombinations = (value: LightCombinationGroup[]) => {
        setCombinations(value);
        combinationsRef.current = value;
    }

    const selectTable = (tableId: number) => {
        if (selectedTables.includes(tableId)) {
            setSelectedTables(selectedTables.filter(x => x !== tableId));
        } else {
            if (tableId) {
                setSelectedTables([...selectedTables, tableId]);
            }
        }
    }

    const saveCombination = (item: LightCombinationGroup) => {
        const newData = cloneDeep(combinations);
        const index = newData.findIndex(x => x.combinationId == item.combinationId);
        if (index > -1) {
            newData[index] = item;
            updateCombinations(newData);
            ApiService.combinationGroup.Update__POST(item).catch(() => {
                NotificationService.Error('Unable to update combination')
            })
        }
    }

    const addCombination = () => {
        const tables = selectedTables.map(x => tableMap.current[x.toString()])
        const tableMax = tables.reduce((a, b) => a + b.seats, 0)
        const tableMin = tables.reduce((a, b) => a + b.minimumOccupancy, 0)
        const request: AddCombinationRequest = {
            businessId: businessData.id,
            tableMergeLinks: selectedTables,
            minimumSeats: tableMin,
            maximumSeats: tableMax
        }
        ApiService.combinationGroup.Add__PUT(request).then((response) => {
            const newGroup: LightCombinationGroup = {
                businessId: businessData.id,
                combinationId: +response.info,
                tableIds: [...selectedTables],
                minimumSeats: tableMin,
                maximumSeats: tableMax
            }
            updateCombinations([...combinations, ...[newGroup]])
            setSelectedTables([])
            NotificationService.Confirm('Combination added')
        })
    }

    const matchingSelection: boolean = combinations.some(item => isEqual(item.tableIds.sort(), selectedTables.sort()));

    return (
        <>
            <Canvas>
                <MainStage
                    tables={tables}
                    shapes={shapes}
                    selectedArea={selectedArea}
                    setLayout={() => { }}
                    setShapes={() => { }}
                    setArea={() => { }}
                    save={() => { }}
                    saveShape={() => { }}
                    selectTable={(id) => selectedCombination === undefined ? selectTable(id) : undefined}
                    selectMultipleTables={setSelectedTables}
                    selectShape={() => { }}
                    selectedTables={selectedCombination === undefined ? selectedTables : combinations[selectedCombination].tableIds}
                    readonly
                    mergeMode
                />
            </Canvas>
            <SidePanel>
                <ActionBar>
                    {selectedCombination === undefined && <CoreButton full disabled={selectedTables.length < 2 || matchingSelection} onClick={() => addCombination()}>Add combination</CoreButton>}
                    {selectedCombination !== undefined &&
                        <>
                            <Icon name='arrow-left' onClick={() => updateSelectedCombination(undefined)} />
                            <CoreButton floatRight type='danger' onClick={() => ModalService.Open({
                                small: true,
                                title: 'Are you sure you want to delete this combination?',
                                children: <>{combinations[selectedCombination].tableIds.map(x => tableMap.current[x.toString()].tableName).join(', ')}</>,
                                actionBar: <CoreButton type='danger' onClick={() => confirmDeleteCombination()}>Confirm deletion</CoreButton>
                            })}>Delete combination</CoreButton>
                        </>
                    }
                </ActionBar>
                <CombinationContainer>
                    {combinations.map((item, index) => {
                        if (tableMap.current[item.tableIds[0]].areaId !== selectedArea.id) return <></>;
                        const selectedTables = item.tableIds.map(x => tableMap.current[x.toString()])
                        const smallestSeater = Math.min(...selectedTables.map(x => x.seats));

                        const tableMax = selectedTables.reduce((a, b) => a + b.seats, 0);
                        const tableMin = tableMax - smallestSeater + 1; // set minimum to min number of people needed for this combo to make sense
                        // eg join 4 seater, 6 seater and another 6 seater. 
                        // It make sense have boths 6 seaters full and 4 seater to be filled up by at least 1
                        // so accept 6x2 + 1 person on 4 seater as a minimum = 12 + 1 = 13 people. 
                        //Any less than this, and we can get away by just merging two 6 seaters, without needing 4 seater at all, so this should be minimum
                        return (
                            <Combination key={index}>
                                <CombinationTitle onClick={() => updateSelectedCombination(selectedCombination === index ? undefined : index)} selected={selectedCombination === index}>
                                    {selectedTables.map(x => x.tableName).join(', ')}
                                </CombinationTitle>
                                {index === selectedCombination &&
                                    <>
                                        <CombinationOptions>
                                            <StyledDropdown unlink defaultText='No priority' items={priorityOptions} value={item.priority} label='Priority' onChange={(e) => saveCombination({ ...item, priority: isNullOrWhitespace(e.target.value) ? 0 : +e.target.value })} />
                                        </CombinationOptions>
                                        {/* <CombinationOptions> */}
                                            { /* @ts-ignore */}
                                            {/* <Checkbox unlink inputName='ticketing' asToggle label={<>Enable ticketing <InfoButton>Enabling this will allow seats to be sold seperately. This can be useful for communal seating and bar seating.</InfoButton></>} checked={item.ticketing} onChange={() => saveCombination({ ...item, ticketing: !item.ticketing })} /> */}
                                        {/* </CombinationOptions> */}
                                        { /* @ts-ignore */}
                                        {!item.ticketing && <>
                                            <CombinationOptions>
                                                <Checkbox unlink inputName='joinedByDefault' asToggle label='Joined by default' checked={item.joinedByDefault} onChange={() => saveCombination({ ...item, joinedByDefault: !item.joinedByDefault })} />
                                            </CombinationOptions>
                                            <CombinationOptions>
                                                <Row>
                                                    <Column size={6}>
                                                        <StyledDropdown addDefault={false} unlink label='Min covers' items={getNumberArray(item.maximumSeats && item.maximumSeats < tableMax ? item.maximumSeats : tableMax, tableMin).map(x => { return { value: x.toString() } as DropdownItem })} value={item.minimumSeats || tableMin} onChange={(e) => saveCombination({ ...item, minimumSeats: isNullOrWhitespace(e.target.value) ? 0 : +e.target.value })} />
                                                    </Column>
                                                    <Column size={6}>
                                                        <StyledDropdown addDefault={false} unlink label='Max occupancy' items={getNumberArray(tableMax, item.minimumSeats && item.minimumSeats > tableMin ? item.minimumSeats : tableMin).map(x => { return { value: x.toString() } as DropdownItem })} value={item.maximumSeats || tableMax} onChange={(e) => saveCombination({ ...item, maximumSeats: isNullOrWhitespace(e.target.value) ? 0 : +e.target.value })} />
                                                    </Column>
                                                </Row>
                                            </CombinationOptions>
                                        </>}
                                        { /* @ts-ignore */}
                                        {item.ticketing && <>
                                            <CombinationOptions>
                                                { /* @ts-ignore */}
                                                <Checkbox unlink inputName='preventLeavingOneEmptySeat' asToggle label={<>Prevent leaving one empty seat <InfoButton>Enabling this will not allow a booking if it leaves only 1 seat free.</InfoButton></>} checked={item.preventLeavingOneEmptySeat} onChange={() => saveCombination({ ...item, preventLeavingOneEmptySeat: !item.preventLeavingOneEmptySeat })} />
                                            </CombinationOptions>
                                        </>}
                                    </>
                                }
                            </Combination>
                        )
                    })}
                </CombinationContainer>
            </SidePanel>
        </>
    );
};

export default Combinability;