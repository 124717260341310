import React, { useRef } from 'react';
import { BaseMenu } from '../../../../../../api/api-definitions';
import DashboardPanel from '../../../../../../components/Dashboard/Panel';
import CoreButton from '../../../../../../components/Forms/Button';
import FormWrapper from '../../../../../../components/Forms/FormWrapper';
import { Row, Column } from '../../../../../../components/Layout/Grid';
import Icon from '../../../../../../components/Media/Icon';
import { Constants, MenuDescriptionSize, MenuNameSize, menuTypes } from '../../../../../../constants';
import { FloatingActionBar } from '../../../../../../components/Layout/FloatingActionBar';
import { WarningMessage } from '../../../../../../components/Forms/Messaging';
import { cloneDeep } from 'lodash';
import { StyledCalendar, StyledDropdown, StyledTextInput, StyledTextarea } from '../../../../../../theme/input.styles';

interface ComponentProps {
    menu: BaseMenu;
    hasChanges: boolean;
    saving: boolean;
    updateMenu: (data: BaseMenu) => void;
    saveMenu: () => void;
    deleteMenu: () => void;
}

const MenuDetailsForm = ({ menu, updateMenu, saveMenu, deleteMenu, hasChanges, saving }: ComponentProps) => {
    return (
        <FormWrapper onUpdate={updateMenu} disabled={saving}>
            {({ id, valid }) => (
                <>
                    <DashboardPanel title='Menu details' options={<>
                        <CoreButton type='danger' full onClick={deleteMenu}>
                            Delete menu <Icon name='trash-alt' />
                        </CoreButton>
                    </>}>
                        <Row>
                            <Column size={4}>
                                <StyledTextInput value={menu.name} max={MenuNameSize} required label='Menu name' model='name' />
                            </Column>
                            <Column size={4}>
                                <StyledDropdown label='Menu type' model='type' value={menu.type} required items={menuTypes} />
                            </Column>
                        </Row>
                        <Row>
                            <Column size={8}>
                                <StyledTextarea model='description' rows={6} maxLength={MenuDescriptionSize} label='Description' value={menu.description} inputName='menuDescription' />
                            </Column>
                        </Row>
                        <Row>
                            <Column size={4}>
                                <StyledCalendar value={menu.validFrom} label='Valid from' model='validFrom' />
                            </Column>
                            <Column size={4}>
                                <StyledCalendar value={menu.validTo} label='Valid to' model='validTo' />
                            </Column>
                        </Row>
                    </DashboardPanel>
                    {hasChanges &&
                        <FloatingActionBar>
                            <WarningMessage>{Constants.messaging.makeSureToSave}</WarningMessage>
                            <CoreButton requesting={saving} small disabled={!valid || saving} onClick={() => saveMenu()} floatRight>Save changes <Icon name='save' /></CoreButton>
                        </FloatingActionBar>
                    }
                </>
            )}
        </FormWrapper>
    );
};

export default MenuDetailsForm;