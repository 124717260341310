import React from 'react';
import { ComponentDropLine, PlaceHolderTitle } from './componentItem';
import { ApiComponentProperty, ApiPageComponent } from '../../../../../../api/api-definitions';

interface ComponentProps {
    onComponentDrop: (
        draggedComponent: ApiPageComponent,
        targetComponent: ApiPageComponent,
        position: 'top' | 'bottom' | 'empty',
        oldPlaceholderId?: string,
        newPlaceholderId?: string
    ) => void;
    component: ApiPageComponent;
    placeholderItem: ApiComponentProperty;
    children: React.ReactNode;
}

const DragPlaceholder = ({ onComponentDrop, component, placeholderItem, children }: ComponentProps) => {
    const [dropLine, setDropLine] = React.useState<boolean>(false);

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const containerTop = rect.y;

        setDropLine(true)
    }

    const onDrop = (event: React.DragEvent<HTMLDivElement>, alternativePlaceholder?: string) => {
        setDropLine(undefined)
        event.preventDefault();
        const draggedComponent: ApiPageComponent = JSON.parse(event.dataTransfer.getData('component'));
        onComponentDrop(draggedComponent, component, 'empty', event.dataTransfer.getData('placeholderId'), alternativePlaceholder);
    }
    
    return (
        <div
            onDragOver={onDragOver}
            onDragLeave={() => setDropLine(undefined)}
            onDrop={(e) => onDrop(e, placeholderItem.rowKey)}
        >
            <PlaceHolderTitle>{placeholderItem.name}</PlaceHolderTitle>
            <ComponentDropLine active={dropLine} />
            {children}
        </div>
    );
};

export default DragPlaceholder;