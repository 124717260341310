import React, { useEffect, useRef, useState } from 'react';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../../components/Dashboard/Panel';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledCurrencyInput, StyledDropdown, StyledNumberInput, StyledTextInput } from '../../../../../../theme/input.styles';
import RichTextEdior from '../../../../../../components/Forms/RichTextEditor';
import PaymentRules from '../BookingRules/DepositRules';
import DurationRules from '../BookingRules/DurationRules';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import PacingRules from '../BookingRules/PacingRules';
import { ApiService } from '../../../../../../api/api-connectors';
import { useHistory, useParams } from 'react-router';
import { BaseBusinessOpeningTimeException, BaseExperience, ExperienceType, ExtendedBaseExperience, WidgetImageLayout } from '../../../../../../api/api-definitions';
import FormWrapper from '../../../../../../components/Forms/FormWrapper';
import Loader from '../../../../../../components/Layout/Loader';
import SpecialOpeningTimes from '../../BusinessDetails/SpecialOpeningTimes';
import { FloatingActionBar } from '../../../../../../components/Layout/FloatingActionBar';
import { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../../components/Forms/Messaging';
import { BookingTypeDescriptionSize, BookingTypeNameSize, Constants } from '../../../../../../constants';
import CoreButton from '../../../../../../components/Forms/Button';
import Icon from '../../../../../../components/Media/Icon';
import { NotificationService } from '../../../../../../services/NotificationService';
import { cloneDeep } from 'lodash';
import BookingTableLayout from '../TableLayout';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import styled from 'styled-components';
import InfoButton from '../../../../../../components/Cta/InfoButton';
import ImageSelector from '../../../../../../components/Forms/ImageSelector';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import BookingModule from '../../../../../../bookingModule/pages/Bookings';
import WebisteIntegration from '../BookingSetup/websiteIntegration';
import BlockOutTimesSetup from '../BookingRules/BlockOutTimeRules';
import { DateBandValidityItem } from '../../../../../../components/Forms/Calendar';
import { createMomentFromValue } from '../../../../../../utils/date-helpers'; import { ExperienceService, ExperienceWarningType } from './experienceService';
import { H4style } from '../../../../../../components/Typography/Headings';
import { ModalService } from '../../../../../../services/ModalService';
import { Badge } from '@chakra-ui/react';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import ExperienceLocation from './experienceLocation';

const EditExperience = () => {
    const [businessLoaded, businessData] = useBusiness();
    const [data, setData] = useState<ExtendedBaseExperience>();
    const [dates, setDates] = useState<DateBandValidityItem[]>();
    const [saving, setSaving] = useState<boolean>(false);
    const initialData = useRef<ExtendedBaseExperience>();
    const [preview, setPreview] = useState(false);
    const params = useParams<{ experienceId: string }>();
    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        ApiService.experience.Get__GET(+params.experienceId).then((resp) => {
            initialData.current = cloneDeep(resp);
            setData(resp)
        })
    }, [])

    const saveChanges = () => {
        setSaving(true)
        if (isNullOrWhitespace(data.minGuests)) data.minGuests = null;
        if (isNullOrWhitespace(data.maxGuests)) data.maxGuests = null;
        ApiService.experience.Save__POST(data).then(() => {
            initialData.current = cloneDeep(data);
            NotificationService.Confirm('Changes saved');
        }).catch(() => {
            NotificationService.Error('Unable to save changes')
        }).finally(() => {
            setSaving(false)
        })
    }

    const confirmPublishAction = (published: boolean) => {
        const newData: BaseExperience = {
            ...initialData.current,
            published
        }
        setSaving(true)
        ApiService.experience.Save__POST(newData).then(() => {
            initialData.current = cloneDeep(newData);
            setData({
                ...data,
                published
            })
            setPublishModalOpen(false)
            NotificationService.Confirm(published ? 'Published!' : 'Unpublished');
        }).catch(() => {
            NotificationService.Error('Unable to save changes')
        }).finally(() => {
            setSaving(false)
        })
    }

    const updateDates = (response: BaseBusinessOpeningTimeException[]) => {
        ExperienceService._dates = response;
        setDates(response.map(x => ({
            dateFrom: x.dateFrom ? createMomentFromValue(x.dateFrom) : undefined,
            dateTo: x.dateTo ? createMomentFromValue(x.dateTo) : undefined,
        } as DateBandValidityItem)))
    }

    const hasChanges = JSON.stringify(data) != JSON.stringify(initialData.current);
    const typeName = initialData.current?.type == ExperienceType.Event ? 'event' : 'experience';
    const warningList = publishModalOpen ? ExperienceService.warningList : undefined;
    const eventOrExperience = data && data.type == ExperienceType.Event ? 'Event' : 'Experience';
    const eventOrExperienceLowerCase = eventOrExperience.toLowerCase();

    const confirmDelete = () => {
        ModalService.Close()
        setSaving(true)
        ApiService.experience.Delete__DELETE(data.id).then(() => {
            NotificationService.Confirm(`${eventOrExperience} deleted`)
            history.push(`/dashboard/${businessData.parentBusinessId}/locations/${businessData.locationId}/experiences`)
        }).catch(() => NotificationService.Error('An error has occured.'))
            .finally(() => setSaving(false))
    }

    return (
        <>
            <DashboardHeader title={!initialData.current ? '' : <>Edit {eventOrExperienceLowerCase} {initialData.current?.published ? <Badge colorScheme='green'>Published</Badge> : <Badge colorScheme='orange'>Unpublished</Badge>}</>} />
            {publishModalOpen &&
                <CoreModal
                    title={`Are you sure you want to ${initialData.current.published ? 'unpublish' : 'publish'} this ${typeName}?`}
                    onClose={() => setPublishModalOpen(false)}
                    small={initialData.current.published}
                    slimPanel={initialData.current.published}
                    actionBar={initialData.current.published ?
                        <CoreButton requesting={saving} disabled={saving} type='warning' onClick={() => confirmPublishAction(false)}><Icon name='download' /> Unpublish</CoreButton> :
                        <CoreButton requesting={saving} disabled={saving || warningList.hasErrors} type='success' onClick={() => confirmPublishAction(true)}><Icon name='upload' /> Publish</CoreButton>
                    }
                >
                    {!initialData.current.published &&
                        <>
                            {warningList.hasErrors && <H4style>Required actions</H4style>}
                            {warningList.dates == ExperienceWarningType.Error &&
                                <MessageContainer><ErrorMessage>There are no&nbsp;<strong>dates and time slots</strong>&nbsp;set up for your {typeName}.</ErrorMessage></MessageContainer>
                            }
                            {warningList.publishedDates == ExperienceWarningType.Error &&
                                <MessageContainer><ErrorMessage>There are no&nbsp;<strong>active dates</strong>&nbsp;set up for your {typeName}.</ErrorMessage></MessageContainer>
                            }
                            {warningList.selectedTables == ExperienceWarningType.Error &&
                                <MessageContainer><ErrorMessage>There are no&nbsp;<strong>areas or tables</strong>&nbsp;set up for your {typeName}.</ErrorMessage></MessageContainer>
                            }
                            {warningList.hasWarnings && <H4style>Optional settings to review</H4style>}
                            {warningList.timeBlockRules == ExperienceWarningType.Info &&
                                <MessageContainer><InfoMessage>There are no&nbsp;<strong>block out rules</strong>&nbsp;set up for your {typeName}.</InfoMessage></MessageContainer>
                            }
                            {warningList.pacingRules == ExperienceWarningType.Info &&
                                <MessageContainer><InfoMessage>There are no&nbsp;<strong>pacing rules</strong>&nbsp;set up for your {typeName}.</InfoMessage></MessageContainer>
                            }
                            {warningList.durationRules == ExperienceWarningType.Info &&
                                <MessageContainer><InfoMessage>There are no&nbsp;<strong>duration rules</strong>&nbsp;set up for your {typeName}.</InfoMessage></MessageContainer>
                            }
                            {warningList.depositRules == ExperienceWarningType.Info &&
                                <MessageContainer><InfoMessage>There are no&nbsp;<strong>payment rules</strong>&nbsp;set up for your {typeName}.</InfoMessage></MessageContainer>
                            }
                        </>
                    }
                </CoreModal>
            }
            {preview &&
                <CoreModal isOpen onClose={() => setPreview(false)}>
                    <BookingModule
                        previewLocation={businessData.locationId}
                        previewBusinessData={{
                            name: businessData.name,
                            theme: {
                                primaryColour: businessData.bookingConfigurationSettings.primaryColour,
                                primaryContrastColour: businessData.bookingConfigurationSettings.primaryContrastColour,
                                backgroundColour: businessData.bookingConfigurationSettings.backgroundColour,
                                textColour: businessData.bookingConfigurationSettings.textColour,
                                buttonColour: businessData.bookingConfigurationSettings.buttonColour,
                                buttonTextColour: businessData.bookingConfigurationSettings.buttonTextColour,
                            },
                            specialEvents: {
                                [data.id]: {
                                    name: data.displayName,
                                    description: data.description,
                                    imageUrl: data.imageUrl,
                                    type: data.type,
                                    minimumPeople: data.minGuests,
                                    maximumPeople: data.maxGuests,
                                    price: data.pricePerGuest,
                                    layout: data.widgetLayout
                                }
                            }
                        }}
                    />
                </CoreModal>
            }
            {(!businessData || !data) && <Loader />}
            {businessData && data &&
                <PageWrap>
                    <TopActionBar>
                        {!initialData.current.published &&
                            <TopActionBarButton>
                                <CoreButton onClick={() => setPublishModalOpen(true)} type='success'><Icon name='upload' /> Publish</CoreButton>
                            </TopActionBarButton>
                        }
                        {initialData.current.published &&
                            <TopActionBarButton>
                                <CoreButton onClick={() => setPublishModalOpen(true)} type='warning'><Icon name='download' /> Unpublish</CoreButton>
                            </TopActionBarButton>
                        }
                        <TopActionBarButton>
                            <CoreButton type='secondary' outline onClick={() => setPreview(true)}><Icon name='magnifying-glass' /> Preview on booking widget</CoreButton>
                        </TopActionBarButton>
                        {/* <TopActionBarButton>
                            <CoreButton><Icon name='list' /> Summary</CoreButton>
                            </TopActionBarButton> */}
                        <Spacer />
                        <TopActionBarButton>
                            <CoreButton noOutline type='secondary' to={`/dashboard/${businessData.parentBusinessId}/locations/${businessData.locationId}/experiences`}><Icon name='arrow-left' /> Back to all</CoreButton>
                        </TopActionBarButton>
                    </TopActionBar>
                    <FormWrapper<BaseExperience> disabled={saving} onUpdate={(formDetails) => setData({ ...data, ...formDetails })}>
                        {({ id, valid }) => (
                            <DashboardPanel title='Details'>
                                <Row>
                                    <Column size={4}>
                                        <StyledTextInput max={BookingTypeNameSize} model='name' label={<>Internal short name <InfoButton>This should be a short name to allow staff to quickly identify the event/experience. For example, an experience called "Bottomless afternoon tea for two" could be set to "Afternoon tea" in the internal short name field. This name will be visible to your staff when making bookings internally.</InfoButton></>} required value={data?.name} />
                                    </Column>
                                    <Column size={4}>
                                        <StyledTextInput max={BookingTypeNameSize} model='displayName' label={<>Public name <InfoButton>This will be the event/experience name visible to your clients online.</InfoButton></>} required value={data?.displayName} />
                                    </Column>
                                    <Column size={4}>
                                        <StyledTextInput max={500} model='location' label={<>Location name and address <InfoButton>This will be the event/experience location visible to your clients online. Please provide the location name and address if the location of the event is different to your restaurant address. </InfoButton></>} value={data?.location} />
                                    </Column>
                                    <Column size={12}>
                                        <RichTextEdior maxLength={BookingTypeDescriptionSize} model='description' label='Description' value={data?.description} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column size={3}>
                                        <StyledCurrencyInput model='pricePerGuest' value={data?.pricePerGuest} label='Price per guest' currencySymbol='£' />
                                    </Column>
                                    <Column size={3}>
                                        <StyledNumberInput model='minGuests' value={data?.minGuests} min={1} label='Min guests per booking' placeholder='No min' />
                                    </Column>
                                    <Column size={3}>
                                        <StyledNumberInput model='maxGuests' value={data?.maxGuests} min={1} label='Max guests per booking' placeholder='No max' />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column size={3}>
                                        <ImageSelector value={data.imageUrl} label={`${eventOrExperience} image`} onChange={(imageUrl) => setData({ ...data, imageUrl })} />
                                    </Column>
                                    <Column size={3}>
                                        <StyledDropdown addDefault={false} model='widgetLayout' label='Image position on widget' value={data.widgetLayout} items={[{ value: WidgetImageLayout.Top, text: 'Top' }, { value: WidgetImageLayout.Left, text: 'Left' }]} />
                                    </Column>
                                </Row>
                                {hasChanges &&
                                    <FloatingActionBar flex>
                                        <WarningMessage>{Constants.messaging.makeSureToSave}</WarningMessage>
                                        <CoreButton small disabled={!valid || saving} onClick={() => saveChanges()} floatRight>Save changes <Icon name='save' /></CoreButton>
                                    </FloatingActionBar>
                                }
                            </DashboardPanel>
                        )}
                    </FormWrapper>
                    <DashboardPanel title='Location'>
                        <ExperienceLocation typeName={typeName} data={data} />
                    </DashboardPanel>
                    <DashboardPanel title='Dates and bookable slots'>
                        {data.type === ExperienceType.Event && <>
                            <InfoMessage>Any dates/times entered here will make the event bookable during those times, even if the business is closed on that day.</InfoMessage><br />
                        </>}
                        <SpecialOpeningTimes experienceId={data.id} experienceType={data.type} onDatesUpdate={updateDates} />
                    </DashboardPanel>
                    <DashboardPanel title='Duration rules'>
                        <DurationRules experienceId={data.id} validDateBands={dates} />
                    </DashboardPanel>
                    <DashboardPanel title='Pacing rules'>
                        <PacingRules experienceId={data.id} validDateBands={dates} />
                    </DashboardPanel>
                    <DashboardPanel title='Floor plans'>
                        <BookingTableLayout experienceId={data.id} experienceType={data.type} />
                    </DashboardPanel>
                    <DashboardPanel title='Block out times'>
                        <BlockOutTimesSetup experienceId={data.id} experienceType={data.type} validDateBands={dates} />
                    </DashboardPanel>
                    <DashboardPanel title='Payments'>
                        <PaymentRules experienceId={data.id} maxPrice={isNullOrWhitespace(data?.pricePerGuest) ? undefined : data?.pricePerGuest} validDateBands={dates} />
                    </DashboardPanel>
                    {data.type == ExperienceType.Event && <WebisteIntegration experienceId={data.id} />}
                    {!data.hasBookings &&
                        <CoreButton type='danger' onClick={() => ModalService.Open({
                            title: `Are you sure you want to ${data.hasBookings ? 'archive' : 'delete'} "${data?.name}"?`,
                            medium: true,
                            children: data.hasBookings ? <InfoMessage>Archiving this {data.type == ExperienceType.Event ? 'event' : 'experience'} will make it unavailable from widget and in-house views.</InfoMessage> : undefined,
                            actionBar: <CoreButton type='danger' onClick={confirmDelete}>Confirm</CoreButton>
                        })}>{data.hasBookings ? 'Archive' : 'Delete'} {data.type == ExperienceType.Event ? 'event' : 'experience'}</CoreButton>
                    }
                </PageWrap>
            }
        </>
    );
};

const MessageContainer = styled.div`
    margin-bottom: 0.5rem;
`

const PageWrap = styled.div`
    position: relative;
    overflow: clip;
`

const TopActionBar = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0.5rem;
    background-color: ${props => props.theme.background};
    border: 1px solid ${props => props.theme.borderColor};
    padding: 0.5rem;
    position: sticky;
    align-self: flex-start;
    top: 0;
    z-index: 2;
`

const TopActionBarButton = styled.div`
    background-color: ${props => props.theme.background};
    color: ${props => props.theme.primary};
    cursor: pointer;
    text-align: center;
    transition: all 0.4s ease;
    margin-left: 1rem;
    display: flex;
    line-height: 1.5rem;
`

const Spacer = styled.div`
    flex: 1;
`

export default EditExperience;