import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import { ApiBookingTimesRequest, BaseActiveBooking, BaseBookingTableSetup, BookingError, DateAndTimeSlots, HoldOrGetAvailableTablesResponse, TableAvailability, AreaAndTablesForTimeline, AmendBookingRequest, ExperienceType, ApiBookingTimes, ExperienceData } from '../../../../../../api/api-definitions';
import CoreButton from '../../../../../../components/Forms/Button';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../../services/NotificationService';
import { BookingInfoIcon, BookingInfoTitle, StyledCalendar, StyledDropdown, StyledNumberInput, AmendmentSummary } from '../../../../../../theme/input.styles';
import { DATABASE_TIME_FORMAT, DATEONLYFORMAT, DEFAULT_DATE_FORMAT, DISPLAY_DATE_FORMAT, TIMEFORMAT, createMomentFromValue, formatDate, getDurationText, minutesToTime } from '../../../../../../utils/date-helpers';
import { H4 } from '../../../../../../components/Typography/Headings';
import { DropdownItem } from '../../../../../../components/Forms/Dropdown';
import { ErrorMessage, InfoMessage, SuccessMessage, WarningMessage } from '../../../../../../components/Forms/Messaging';
import { TabBar, TabButton } from '../../../../../../components/Layout/Tabs';
import styled from 'styled-components';
import Icon from '../../../../../../components/Media/Icon';
import MainStage from '../TableLayout/MainStage';
import { ExtendedTable } from '../TableLayout';
import { ExtendedBookingManagementResponse } from '..';
import Loader from '../../../../../../components/Layout/Loader';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import BREAKPOINTS from '../../../../../../config/breakpoints';
import { getSelectedTableDisplay } from './bookingModalUtils';
import TagContainer from '../../../../../../components/Forms/Tags';
import Tag from '../../../../../../components/Forms/Tags/Tag';
import { Badge } from '@chakra-ui/react';

interface ComponentProps {
    bookingAmendment?: BaseActiveBooking;
    onAmendSuccess?: (booking: BaseActiveBooking) => void;
    table?: BaseBookingTableSetup;
    tables: ExtendedTable[];
    bookingManagementData: ExtendedBookingManagementResponse;
    locationId: string;
    onClose: (refresh?: boolean) => void;
    amendDuration?: boolean;
}

const MAX_DURATION = 480;

const GuestContainer = styled.div`
    width: 95%;
`

const OkDateButton = styled(CoreButton)`
    height: 3rem;
    float: right;
    margin: 0;
`

const BookingTabs = styled(TabBar)`
    width: 100%;
    .icon {
        margin-left: 0 !important;
    }
`

const TimeRow = styled(Row)`
    // max-height: 22rem;
    // overflow: auto;
`

const ButtonTime = styled.button<{ active?: boolean; }>`
    width: 100%;
    padding: 0.5rem;
    border: 2px solid ${props => props.theme.primary};
    border-radius: 0.5rem;
    transition: all 0.4s ease;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    line-height: 2rem;

    span {
        margin: 0.5rem 0.1rem;
    }

    &:hover {
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.primaryContrast};
    }

    ${props => props.active && `
        background-color: ${props.theme.primary};
        color: ${props.theme.primaryContrast};
    `}
`

const AmendmentIcon = styled(Icon)`
    color: ${props => props.theme.positive};
`

const StageWrapper = styled.div`
    display: flex;
    margin-top: 1rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: block;
    }
`

const StageScale = styled.div`
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        height: 20rem;
    }
`

const AmendBookingModal = ({
    bookingAmendment,
    onAmendSuccess,
    table,
    tables,
    locationId,
    bookingManagementData,
    amendDuration,
    onClose
}: ComponentProps) => {
    const [loading, setLoading] = useState<boolean>(amendDuration);
    const [loadedTables, setLoadedTables] = useState<ExtendedTable[]>(tables);
    const [step, setStep] = useState<number>(0);
    const [availResponse, setAvailResponse] = useState<HoldOrGetAvailableTablesResponse>();
    const [businessLoaded, businessData] = useBusiness();
    const [errors, setErrors] = useState<BookingError[]>([]);
    const [selectedTables, setSelectedTables] = useState<number[]>([]);
    const [tablesResponse, setTablesResponse] = useState<{ [key: string | number]: TableAvailability }>({});
    const [selectedArea, selectArea] = useState<AreaAndTablesForTimeline>(table ? bookingManagementData.areaAndTables[table.areaId] : Object.values(bookingManagementData.areaAndTables)[0]);
    const [confirmAllocationOpen, setConfirmAllocationOpen] = useState(false);
    const [timesLoading, setTimesLoading] = useState<boolean>();
    const [experienceTimes, setExperienceTimes] = useState<ApiBookingTimes>();
    const [selectedExperience, setSelectedExperience] = useState<number>(bookingAmendment.experienceId);
    const selectedExperienceRef = useRef<number>(bookingAmendment.experienceId);
    const [suggestedTablesSelected, setSuggestedTablesSelected] = useState<boolean>(false);
    const [systemDefaultDuration, setSystemDefaultDuration] = useState<number>();
    const [desiredDuration, setDesiredDuration] = useState<number>();
    const [pacingWarningOpen, setPacingWarningOpen] = useState<boolean>(false);
    const [shouldShowPacingWarning, setShouldShowPacingWarning] = useState<boolean>(false);
    const [booking, setBooking] = useState<BaseActiveBooking>(bookingAmendment);
    const [times, setTimes] = useState<DateAndTimeSlots>();
    const previousTableDisplay = useRef<string>(getSelectedTableDisplay(booking.tables, tables, table))
    const [tableLabels, setTableLabels] = useState<{ [key: number]: string }>();
    const previousNextAvailRequestRef = useRef<string>();
    const rendered = useRef(false);
    const modalContentRef = useRef<HTMLDivElement>()

    useEffect(() => {
        setTimeout(() => {
            rendered.current = true;
        }, 500);

        if (amendDuration) requestTable();
        return () => {
            rendered.current = false;
        }
    }, [])

    const closeModal = () => {
        if (!rendered.current) return;
        releaseTable()
        onClose();
    }

    const getIntervals = (lastNumber: number,
        intervalDifference: number,
        systemDefaultDuration: number,
        desiredDuration: number,
        selectedTables: ExtendedTable[],
        tablesResponse: { [key: string | number]: TableAvailability }) => {
        const items: DropdownItem[] = [];
        let maxDuration = lastNumber;
        for (let i = 0; i <= selectedTables.length - 1; i++) {
            if (tablesResponse && tablesResponse[selectedTables[i].id]) {
                const tableMaxDuration = tablesResponse[selectedTables[i].id].maximumDurationTableCanBeBookedFor;
                if (tableMaxDuration) {
                    maxDuration = Math.min(maxDuration, tableMaxDuration);
                }
            }
        }

        let foundDesired = false;

        for (let i = intervalDifference; i <= maxDuration; i = i + intervalDifference) {

            const minutesAndHours = minutesToTime(i);
            const dropdownTextValue = getDurationText(minutesAndHours);
            const item: DropdownItem = {
                value: `${i}`,
                text: `${i == systemDefaultDuration ? (dropdownTextValue + ' (Recommended)') : dropdownTextValue}`
            }

            if (i == desiredDuration) {
                foundDesired = true;
            }

            items.push(item);
        }

        if (!foundDesired) {
            updateSearchableValueForDuration(systemDefaultDuration);
        }

        return items;
    }

    const releaseTable = () => {
        const holdRef = availResponse?.holdReference;
        setAvailResponse(undefined);
        if (!isNullOrWhitespace(holdRef)) {
            ApiService.makebooking.ReleaseHold__POST(holdRef);
        }
    }

    const requestTable = () => {
        setLoading(true);

        let holdParams: ApiBookingTimesRequest = {
            businessId: businessData.id,
            guests: booking.guests,
            requestedTime: formatDate(booking.startDateTime, DATABASE_TIME_FORMAT),
            locationId: businessData.locationId,
            specificTableId: table?.id,
            experienceId: selectedExperienceRef.current,
            bookingReference: bookingAmendment.bookingReference,
            desiredDuration: desiredDuration ? desiredDuration : bookingAmendment.bookingDurationInMinutes
        }

        ApiService.bookings.AmendBookingSearch__POST(holdParams).then((response) => {
            const newTables = cloneDeep(tables);
            const labels: { [key: number]: string } = {};
            newTables.forEach(newTable => {
                newTable.disabled = !response.availableTables?.tableIdAndAvailability[newTable.id]?.available;
                if (amendDuration && !newTable.disabled) {
                    labels[newTable.id] = 'Free for ' + getDurationText(minutesToTime(response.availableTables?.tableIdAndAvailability[newTable.id]?.maximumDurationTableCanBeBookedFor || MAX_DURATION))
                }
            });

            const firstAvailiableId = response?.suggestedTableIds ? response.suggestedTableIds[0] : undefined;
            let area: AreaAndTablesForTimeline = undefined;
            debugger
            if (response.suggestedTableIds && response.suggestedTableIds.length > 0) {
                const foundTable = bookingManagementData.tables.find(x => x.id == response.suggestedTableIds[0]);
                if (foundTable) {
                    area = bookingManagementData.areaAndTables[foundTable.areaId]
                }
            } else if (firstAvailiableId) {
                const foundTable = bookingManagementData.tables.find(x => x.id == +firstAvailiableId);
                if (foundTable) {
                    area = bookingManagementData.areaAndTables[foundTable.areaId]
                }
            }

            if (amendDuration) {
                setTableLabels(labels)
            }

            setTablesResponse(response.availableTables?.tableIdAndAvailability)

            if (amendDuration) {
                if (!!response.suggestedTableIds && response.suggestedTableIds.length > 0 ) {
                    setSelectedTables(response.suggestedTableIds);
                } else {
                    setSelectedTables([])
                }
                setSuggestedTablesSelected(true);
                if (area) selectArea(area)
            }
            else if (!!response.suggestedTableIds && response.suggestedTableIds.length > 0) {
                setSelectedTables(response.suggestedTableIds);
                setSuggestedTablesSelected(true);
                if (area) selectArea(area)
            } else {
                let selectedAvail = response.availableTables?.tableIdAndAvailability[table?.id]?.available;
                if (selectedAvail) {
                    setSelectedTables([table.id]);
                    if (area) selectArea(area)
                }
            }

            setSystemDefaultDuration(response.defaultDurationExcludingChangeover);
            setDesiredDuration(booking.bookingDurationInMinutes);
            setLoadedTables(newTables)
            setStep(1)
            setLoading(false)
        })
    }

    const updateSearchableValueForDuration = (value: any) => {
        setDesiredDuration(value);
    }

    const updateSearchableValue = (property?: string, value?: any, showPacingWarning: boolean = false) => {
        if (showPacingWarning) {
            setShouldShowPacingWarning(true);
        }
        const newBookingInfo = cloneDeep(booking);
        if (property) {
            newBookingInfo[property] = value;
            setBooking(newBookingInfo);
        }
        if (selectedExperienceRef.current && bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo[selectedExperienceRef.current].type == ExperienceType.Event) {
            const request: ApiBookingTimesRequest = {
                requestedTime: formatDate(moment(), DATABASE_TIME_FORMAT),
                guests: newBookingInfo.guests,
                locationId,
                bookingReference: bookingAmendment?.bookingReference,
                experienceId: selectedExperienceRef.current,
                businessId: businessData.id
            }
            if (previousNextAvailRequestRef.current != JSON.stringify(request)) {
                previousNextAvailRequestRef.current = JSON.stringify(request);
                ApiService.bookings.GetNextAvailable__POST(request).then((response) => {
                    setExperienceTimes(response)
                })
            }
        } else if (!property) {
            setExperienceTimes(undefined)
            getTimesForDate(createMomentFromValue(newBookingInfo.startDateTime), newBookingInfo.guests, newBookingInfo.bookingDurationInMinutes)
        }
    }

    const selectTable = (tableId: number) => {
        if (selectedTables.includes(tableId)) {
            setSelectedTables(selectedTables.filter(x => x !== tableId));
        } else {
            if (tableId) {
                setSelectedTables([...selectedTables, tableId]);
            }
        }
    }

    const confirmPacingWarning = () => {
        requestTable();
        setPacingWarningOpen(false);
    }

    const getTimesForDate = (date: Moment, guests: number, duration: number) => {
        setTimesLoading(true)
        ApiService.bookings.NextAvailableSlots__POST({
            requestedTime: date.format(DATABASE_TIME_FORMAT),
            guests: guests || booking.guests,
            locationId,
            bookingReference: bookingAmendment?.bookingReference,
            desiredDuration: duration,
            experienceId: selectedExperienceRef.current
        }).then((response) => {
            let showPacingWarning = false;
            if (response.shiftLabelAndTimeSlots) Object.keys(response.shiftLabelAndTimeSlots).forEach(label => {
                if (response.shiftLabelAndTimeSlots[label].slots.find(value => value.goesBeyondPacingLimit && formatDate(value.slot, TIMEFORMAT) == formatDate(booking.startDateTime, TIMEFORMAT))) {
                    showPacingWarning = true;
                }
            })
            setShouldShowPacingWarning(showPacingWarning);
            setTimes(response);
            setTimesLoading(false)
        });
    }

    const confirmAmendment = () => {
        setLoading(true)
        const newDate = formatDate(booking.startDateTime, DATABASE_TIME_FORMAT);
        const selectedDuration = desiredDuration ? desiredDuration : booking.bookingDurationInMinutes;
        const amendmentRequest: AmendBookingRequest = {
            bookingReference: bookingAmendment.bookingReference,
            bookingId: bookingAmendment.id,
            guests: booking.guests,
            startDateTime: newDate,
            desiredDurationInMinutesWithoutChangeover: selectedDuration,
            tableIds: selectedTables,
            experienceId: selectedExperienceRef.current
        }
        ApiService.bookings.AmendSave__POST(amendmentRequest).then((data) => {
            setLoading(false)
            if (data.success) {
                NotificationService.Confirm(`Booking has been amended.`)
                onAmendSuccess({
                    ...bookingAmendment,
                    startDateTime: newDate,
                    guests: booking.guests,
                    bookingDurationInMinutes: selectedDuration,
                    tables: selectedTables,
                    experienceId: selectedExperienceRef.current
                });
            } else {
                NotificationService.Error('Sorry, we could not amend the booking. It could be that a booking has already been made for the table.')
            }
        }).catch(() => {
            setErrors([])
            NotificationService.Error('Sorry, there was an error saving the booking.')
        })
    }

    const isOpen = (date) => {
        if (bookingManagementData.closedExceptionDays && bookingManagementData.closedExceptionDays.hasOwnProperty(formatDate(date, 'YYYY-MM-DD') + 'T00:00:00')) return false;
        return bookingManagementData.openingTimes.find(x => !x.closed && x.dayOfWeek === formatDate(date, 'dddd'));
    };

    let selectedTableCoverCount = 0;
    let selectedAreas: { [key: string]: boolean } = {}
    const selectedTableItems = tables ? selectedTables.map((tableId) => {
        const table = tables.find(x => x.id === tableId);
        selectedAreas[table.areaId] = true;
        selectedTableCoverCount += table.seats;
        return table;
    }) : [];

    const overallocationAmount = selectedTableCoverCount - booking.guests;

    const moreTablesThanNeeded = overallocationAmount > 0 && !!selectedTableItems.find(x => x.seats <= overallocationAmount);

    const confirmMerge = () => {
        if (moreTablesThanNeeded || selectedTableCoverCount < booking.guests || Object.keys(selectedAreas).length > 1) {
            setConfirmAllocationOpen(true)
        } else {
            requestTable();
        }
    }

    const confirmAllocation = () => {
        requestTable();
        setConfirmAllocationOpen(false);
    }

    const onExperienceSelect = (id: number) => {
        selectedExperienceRef.current = selectedExperience == +id ? undefined : (id ? +id : undefined);
        setSelectedExperience(selectedExperience == +id ? undefined : (id ? +id : undefined))
        updateSearchableValue()
    }

    const amendmentDateTime = formatDate(bookingAmendment.startDateTime, 'HH:mm')

    const tableNameDisplay = getSelectedTableDisplay(selectedTables, tables, table);
    const seatsLeftToAllocate = booking.guests - selectedTableCoverCount;
    const invalidMerge = moreTablesThanNeeded || selectedTables?.length === 0 || selectedTableCoverCount < booking?.guests;
    const durationText = getDurationText(minutesToTime(bookingAmendment.bookingDurationInMinutes));
    const desiredDurationText = desiredDuration ? getDurationText(minutesToTime(desiredDuration)) : '';
    const durationChanged = desiredDurationText && durationText != desiredDurationText;
    const experienceAndEventList = bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo;

    let noTimeSelected = true;
    let hasTimeSlots = false;
    let hasMutipleShifts = false;

    if (selectedExperience && experienceTimes) {
        if (experienceTimes) experienceTimes.availability.forEach((date, index) => {
            if (date.times.length > 0) hasTimeSlots = true;
            if (date.times.find(time => formatDate(booking.startDateTime, 'HH:mm') === formatDate(time.time, 'HH:mm'))) {
                noTimeSelected = false;
            }
        });
    } else {
        if (times) Object.keys(times.shiftLabelAndTimeSlots).forEach((label, index) => {
            if (times.shiftLabelAndTimeSlots[label].slots.length > 0) hasTimeSlots = true;
            if (index > 0) hasMutipleShifts = true;
            if (times.shiftLabelAndTimeSlots[label].slots.find(time => formatDate(booking.startDateTime, 'HH:mm') === formatDate(time.slot, 'HH:mm'))) {
                noTimeSelected = false;
            }
        });
    }

    const eventAmendmentSummary = () => {
        if (bookingManagementData?.eventsForDay?.eventsAndExperiences && bookingManagementData.eventsForDay.eventsAndExperiences[bookingAmendment.experienceId]) {
            return <>{selectedExperienceRef.current != bookingAmendment.experienceId ? <AmendmentSummary>{bookingAmendment.experienceId ? bookingManagementData.eventsForDay.eventsAndExperiences[bookingAmendment.experienceId].name : 'Standard booking'}</AmendmentSummary> : <></>} &nbsp;{selectedExperienceRef.current ? bookingManagementData.eventsForDay.eventsAndExperiences[selectedExperienceRef.current].name : 'Standard booking'}</>
        }
        if (bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo && bookingManagementData.upcomingExperiencesAndEvents.eventIdAndInfo[bookingAmendment.experienceId]) {
            return <>{selectedExperienceRef.current != bookingAmendment.experienceId ? <AmendmentSummary>{bookingAmendment.experienceId ? bookingManagementData.upcomingExperiencesAndEvents.eventIdAndInfo[bookingAmendment.experienceId].name : 'Standard booking'}</AmendmentSummary> : <></>} &nbsp;{selectedExperienceRef.current ? bookingManagementData.upcomingExperiencesAndEvents.eventIdAndInfo[selectedExperienceRef.current].name : 'Standard booking'}</>
        }
        return <>{selectedExperienceRef.current != bookingAmendment.experienceId ? <AmendmentSummary>{bookingAmendment.experienceId ? 'Unknown event' : 'Standard booking'}</AmendmentSummary> : <></>} &nbsp;{selectedExperienceRef.current ? 'Unknown event' : 'Standard booking'}</>
    }

    const originalDateNotAvailable = experienceTimes && !!!experienceTimes.availability.find(x => formatDate(x.date, DATEONLYFORMAT) === formatDate(bookingAmendment.startDateTime, DATEONLYFORMAT))?.times.find(x => formatDate(x.time, 'HH:mm') && formatDate(bookingAmendment.startDateTime, DATEONLYFORMAT));
    const invalidEventDate = experienceTimes && originalDateNotAvailable && booking.startDateTime == bookingAmendment.startDateTime;

    const showNoTimesWarning = !experienceTimes && (!times || (times && !hasTimeSlots));
    console.log(showNoTimesWarning, experienceTimes, times)

    return (
        <CoreModal title={<>
            {amendDuration ? 'Amend duration / table allocation' : 'Amend booking'}
            <BookingInfoTitle>
                {(step >= 1) && tableNameDisplay && <BookingInfoIcon><Icon name='dining-table' /> {tableNameDisplay}</BookingInfoIcon>}
                {(step >= 1) && <BookingInfoIcon><Icon name='users' /> {booking.guests}</BookingInfoIcon>}
                {(step >= 1) && <BookingInfoIcon><Icon name='calendar' /> {formatDate(booking.startDateTime, DEFAULT_DATE_FORMAT)}</BookingInfoIcon>}
                {(step >= 1) && <BookingInfoIcon><Icon name='clock' /> {formatDate(booking.startDateTime, TIMEFORMAT)}&nbsp; ({desiredDurationText})&nbsp;</BookingInfoIcon>}
            </BookingInfoTitle>
        </>} onClose={closeModal}
            warning={step === 0 && noTimeSelected && 'Please select a time slot to continue'}
            actionBar={<>
                {step === 0 && <CoreButton disabled={invalidEventDate || noTimeSelected} onClick={() => shouldShowPacingWarning ? setPacingWarningOpen(true) : requestTable()}>Next</CoreButton>}
                {step === 1 && <CoreButton requesting={loading} disabled={invalidMerge || loading} onClick={confirmAmendment}>Confirm amendment</CoreButton>}
            </>} contentRef={modalContentRef}>
            <Row hiddenDesktop>
                <Column noMarginBottom size={6} tablet={12} mobile={12}><strong>Amendment summary: </strong> </Column>
                <Column noMarginBottom size={6} tablet={12} mobile={12}>
                    <Icon name='address-card' duo /> {bookingAmendment.client.firstName} {bookingAmendment.client.surname}
                </Column>
                <Column noMarginBottom size={6} tablet={12} mobile={12}>
                    <Icon name='users' />{bookingAmendment.guests != booking.guests ? <AmendmentSummary>{bookingAmendment.guests}</AmendmentSummary> : <></>} {booking.guests}
                </Column>
                <Column noMarginBottom size={6} tablet={12} mobile={12}>
                    <Icon name='calendar' />{formatDate(bookingAmendment.startDateTime, DEFAULT_DATE_FORMAT) != formatDate(booking.startDateTime, DEFAULT_DATE_FORMAT) ? <AmendmentSummary>{formatDate(bookingAmendment.startDateTime, DEFAULT_DATE_FORMAT)}</AmendmentSummary> : <></>} {formatDate(booking.startDateTime, DEFAULT_DATE_FORMAT)}
                </Column>
                <Column noMarginBottom size={6} tablet={12} mobile={12}>
                    <Icon name='clock' />{formatDate(bookingAmendment.startDateTime, TIMEFORMAT) != formatDate(booking.startDateTime, TIMEFORMAT) ? <AmendmentSummary>{formatDate(bookingAmendment.startDateTime, TIMEFORMAT)}</AmendmentSummary> : <></>} {formatDate(booking.startDateTime, TIMEFORMAT)}
                    &nbsp;({durationChanged ? <AmendmentSummary>{durationText}</AmendmentSummary> : <></>}{durationChanged ? ' ' : ''}{desiredDurationText ? desiredDurationText : durationText})
                </Column>
                <Column noMarginBottom size={6} tablet={12} mobile={12}>
                    <Icon name='dining-table' />{previousTableDisplay.current != (isNullOrWhitespace(tableNameDisplay) ? previousTableDisplay.current : tableNameDisplay) ? <AmendmentSummary>{previousTableDisplay.current}</AmendmentSummary> : <></>} &nbsp;{isNullOrWhitespace(tableNameDisplay) ? previousTableDisplay.current : tableNameDisplay}
                </Column>
                {(!!selectedExperienceRef.current || selectedExperienceRef.current != bookingAmendment.experienceId) &&
                    <Column noMarginBottom size={6} tablet={12} mobile={12}>
                        <>
                            &nbsp;
                            &nbsp;
                            <Badge colorScheme='messenger'>
                                {eventAmendmentSummary()}
                            </Badge>
                        </>
                    </Column>
                }
            </Row>
            <Row hiddenMobile hiddenTablet>
                <Column noMarginBottom size={12} tablet={12} mobile={12}><strong>Amendment summary: </strong>
                    <Icon name='address-card' duo /> {bookingAmendment.client.firstName} {bookingAmendment.client.surname}
                    <Icon name='users' />{bookingAmendment.guests != booking.guests ? <AmendmentSummary>{bookingAmendment.guests}</AmendmentSummary> : <></>} {booking.guests}
                    <Icon name='calendar' />{formatDate(bookingAmendment.startDateTime, DEFAULT_DATE_FORMAT) != formatDate(booking.startDateTime, DEFAULT_DATE_FORMAT) ? <AmendmentSummary>{formatDate(bookingAmendment.startDateTime, DEFAULT_DATE_FORMAT)}</AmendmentSummary> : <></>} {formatDate(booking.startDateTime, DEFAULT_DATE_FORMAT)}
                    <Icon name='clock' /> {formatDate(bookingAmendment.startDateTime, TIMEFORMAT) != formatDate(booking.startDateTime, TIMEFORMAT) ? <AmendmentSummary>{formatDate(bookingAmendment.startDateTime, TIMEFORMAT)}</AmendmentSummary> : <></>} {formatDate(booking.startDateTime, TIMEFORMAT)}
                    &nbsp;({durationChanged ? <AmendmentSummary>{durationText}</AmendmentSummary> : <></>}{durationChanged ? ' ' : ''}{desiredDurationText ? desiredDurationText : durationText})
                    <Icon name='dining-table' />{previousTableDisplay.current != (isNullOrWhitespace(tableNameDisplay) ? previousTableDisplay.current : tableNameDisplay) ? <AmendmentSummary>{previousTableDisplay.current}</AmendmentSummary> : <></>} &nbsp;{isNullOrWhitespace(tableNameDisplay) ? previousTableDisplay.current : tableNameDisplay}
                    {(!!selectedExperienceRef.current || selectedExperienceRef.current != bookingAmendment.experienceId) &&
                        <>
                            &nbsp;
                            &nbsp;
                            <Badge colorScheme='messenger'>
                                {eventAmendmentSummary()}
                            </Badge>
                        </>
                    }
                </Column>
            </Row>
            {!loading && !amendDuration &&
                <BookingTabs noMarginBottom={step == 2}>
                    <TabButton active={step === 0} onClick={() => setStep(0)}><Icon name='users' duo /><Icon name='calendar' duo /> Booking details</TabButton>
                    <TabButton disabled={step < 1} active={step === 1}>
                        <Icon name='dining-table' /> Confirm tables
                    </TabButton>
                </BookingTabs>
            }

            {confirmAllocationOpen &&
                <CoreModal small onClose={() => setConfirmAllocationOpen(false)} title='Confirm table allocation' actionBar={<CoreButton onClick={() => confirmAllocation()}>Confirm allocation</CoreButton>}>
                    {moreTablesThanNeeded &&
                        <ErrorMessage>You are about to make a booking with more tables than you need. Are you sure you want to continue booking?</ErrorMessage>
                    }
                    {selectedTableCoverCount < booking.guests &&
                        <WarningMessage>You are about to make a booking with not enough seats selected. Are you sure you want to continue booking?</WarningMessage>
                    }
                    {Object.keys(selectedAreas).length > 1 &&
                        <WarningMessage>You are about to make a booking with tables merged over {Object.keys(selectedAreas).length} areas. Are you sure you want to continue booking?</WarningMessage>
                    }
                </CoreModal>
            }

            {pacingWarningOpen &&
                <CoreModal small hasCancel onClose={() => setPacingWarningOpen(false)} title='Selected time goes beyond pacing limit' actionBar={<CoreButton onClick={() => confirmPacingWarning()}>Confirm</CoreButton>}>
                    <WarningMessage>
                        The following action may put pressure on staff and kitchen as the number of covers at this time slot will go beyond the recommended limit.
                    </WarningMessage>
                </CoreModal>
            }

            {loading && <Loader />}

            {!loading && step === 0 &&
                <GuestContainer>
                    {experienceAndEventList && Object.keys(experienceAndEventList).length > 0 &&
                        <TagContainer>
                            <Tag selected={!selectedExperience} onClick={() => onExperienceSelect(undefined)}>
                                Standard booking
                            </Tag>
                            {Object.keys(experienceAndEventList).map((id) => (
                                <Tag selected={selectedExperience == +id} onClick={() => onExperienceSelect(+id)}>
                                    {experienceAndEventList[id].type == ExperienceType.Event && <Icon name='calendar' />}
                                    {experienceAndEventList[id].type == ExperienceType.Experience && <Icon name='sparkles' />}
                                    {experienceAndEventList[id].name}
                                </Tag>
                            ))}
                        </TagContainer>
                    }
                    <Row>
                        <Column size={12} mobile={12} noMarginBottom>
                            <Row>
                                <Column size={6} mobile={12} hidden={experienceAndEventList[selectedExperience]?.type == ExperienceType.Event}>
                                    <StyledCalendar
                                        unlink
                                        label='Date:'
                                        model='startDateTime'
                                        value={booking?.startDateTime}
                                        keepTime
                                        months={1}
                                        onChange={e => updateSearchableValue('startDateTime', formatDate(e, DATABASE_TIME_FORMAT))}
                                        calendarProps={{ filterDate: isOpen, peekNextMonth: false, showPreviousMonths: false }}
                                    />
                                </Column>
                                <Column size={6} mobile={12}>
                                    <StyledNumberInput
                                        hasPlusMinus
                                        unlink
                                        value={booking?.guests}
                                        min={1}
                                        label='Guests:'
                                        onChange={e => updateSearchableValue('guests', +e.target.value)}
                                    />
                                </Column>
                            </Row>
                        </Column>
                        <Column size={12} mobile={12}>
                            {experienceTimes && experienceTimes.availability.length == 0 &&
                                <InfoMessage>No availability found for event</InfoMessage>
                            }
                            {experienceTimes && !!!experienceTimes.availability.find(x => formatDate(x.date, DATEONLYFORMAT) === formatDate(bookingAmendment.startDateTime, DATEONLYFORMAT))?.times.find(x => formatDate(x.time, 'HH:mm') && formatDate(bookingAmendment.startDateTime, DATEONLYFORMAT)) &&
                                <>
                                    <strong>{formatDate(bookingAmendment.startDateTime, DISPLAY_DATE_FORMAT)}</strong><br />
                                    <WarningMessage inline>The original booked date is no longer available</WarningMessage>
                                    <br />
                                    <br />
                                </>
                            }
                            {experienceTimes &&
                                <TimeRow>
                                    {experienceTimes.availability.map(date => (
                                        <>
                                            <Column size={11}>
                                                <strong>{formatDate(date.date, DISPLAY_DATE_FORMAT)}</strong>
                                            </Column>
                                            {date.times.map(time => (
                                                <Column size={2} mobile={12} key={time.time} onClick={() => updateSearchableValue('startDateTime', formatDate(date.date, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + formatDate(time.time, 'HH:mm:ss'))}>
                                                    <ButtonTime type='button' active={formatDate(booking.startDateTime, 'HH:mm') === formatDate(time.time, 'HH:mm') && formatDate(booking.startDateTime, DATEONLYFORMAT) === formatDate(date.date, DATEONLYFORMAT)}>
                                                        {amendmentDateTime === formatDate(time.time, 'HH:mm') &&
                                                            <>
                                                                <AmendmentIcon name='star' />
                                                                &nbsp;
                                                            </>
                                                        }
                                                        {time.goesBeyondPacingLimit && <><Icon name='person-circle-exclamation' duo />&nbsp;</>}
                                                        {time.requiresMerging &&
                                                            <>
                                                                <Icon name='arrows-to-line' duo />
                                                                &nbsp;
                                                                &nbsp;
                                                            </>
                                                        }
                                                        {formatDate(time.time, TIMEFORMAT) + (time.eventIds?.length > 0 ? '*' : '')}
                                                    </ButtonTime>
                                                </Column>
                                            ))}
                                        </>
                                    ))}
                                </TimeRow>
                            }
                            {!experienceTimes &&
                                <>
                                    <TimeRow>
                                        {timesLoading && <Loader />}
                                        {!timesLoading && times && Object.keys(times.shiftLabelAndTimeSlots).map(shiftLabel => (
                                            <>
                                                {hasMutipleShifts &&
                                                    <Column size={12}>
                                                        <strong>{shiftLabel}</strong>
                                                    </Column>
                                                }
                                                {times.shiftLabelAndTimeSlots[shiftLabel].slots.map(time => (
                                                    <Column size={3} tablet={4} mobile={12} key={time.slot} onClick={() => updateSearchableValue('startDateTime', formatDate(booking.startDateTime, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + formatDate(time.slot, 'HH:mm:ss'))}>
                                                        <ButtonTime type='button' active={formatDate(booking.startDateTime, 'HH:mm') === formatDate(time.slot, 'HH:mm')}>
                                                            {amendmentDateTime === formatDate(time.slot, 'HH:mm') &&
                                                                <>
                                                                    <AmendmentIcon name='star' />
                                                                    &nbsp;
                                                                </>
                                                            }
                                                            {time.goesBeyondPacingLimit && <><Icon name='person-circle-exclamation' duo />&nbsp;</>}
                                                            {time.requiresMerging &&
                                                                <>
                                                                    <Icon name='arrows-to-line' duo />
                                                                    &nbsp;
                                                                    &nbsp;
                                                                </>
                                                            }
                                                            {formatDate(time.slot, TIMEFORMAT)}
                                                        </ButtonTime>
                                                    </Column>
                                                ))}
                                            </>
                                        ))}
                                    </TimeRow>
                                    {showNoTimesWarning &&
                                        <WarningMessage>Sorry, there are no available time slots.</WarningMessage>
                                    }
                                </>
                            }

                        </Column>
                    </Row>
                </GuestContainer>
            }
            {!loading && step === 1 &&
                <StageWrapper>
                    <div style={{ flex: 'none' }}>
                        {Object.keys(bookingManagementData.areaAndTables).length > 1 &&
                            <TabBar noMarginBottom>
                                {Object.entries(bookingManagementData.areaAndTables).map(([areaId, area]) => (
                                    <TabButton key={`areaTab-${area.areaId}`} active={selectedArea.areaId === area.areaId} onClick={() => selectArea(area)}>
                                        {area.areaName}
                                    </TabButton>
                                ))}
                            </TabBar>
                        }
                        <StageScale style={{ transformOrigin: 'top left', scale: window.innerWidth < BREAKPOINTS.mobileLarge ? '0.48' : '0.8' }}>
                            <MainStage
                                tables={loadedTables}
                                shapes={bookingManagementData.shapes}
                                bookingManagementData={{ ...bookingManagementData, availability: null }}
                                selectedArea={{ name: selectedArea.areaName, id: selectedArea.areaId, scale: selectedArea.areaScale }}
                                setLayout={() => { }}
                                setShapes={() => { }}
                                setArea={() => { }}
                                save={() => { }}
                                saveShape={() => { }}
                                selectTable={selectTable}
                                selectShape={() => { }}
                                selectedTables={selectedTables}
                                readonly
                                mergeMode
                                tableLabels={tableLabels}
                            />
                        </StageScale>
                    </div>
                    <div style={{ padding: '0.5rem', flex: '1 1 auto' }}>
                        <StyledDropdown
                            unlink
                            addDefault={false}
                            label='Duration:'
                            disabled={!selectedTables || selectedTables.length === 0}
                            value={desiredDuration}
                            onChange={e => updateSearchableValueForDuration(+e.target.value)}
                            items={getIntervals(MAX_DURATION, 15, systemDefaultDuration, desiredDuration, selectedTableItems, tablesResponse)}
                        />
                        <br />
                        <H4 hideDivider={false} textCenter={false} marginBottom={0}>Selected tables</H4>
                        {selectedTables.length === 0 &&
                            <>
                                <Icon regular name='triangle-exclamation' />
                                <strong>You have not selected any tables</strong>
                            </>}
                        {selectedTableItems.map((table) => (
                            <>
                                <Row>
                                    <Column marginBottom={10} marginTop={10} size={6}>
                                        <strong>Table {table.tableName}</strong>
                                    </Column>
                                    <Column marginBottom={10} marginTop={10} size={6}>
                                        <Icon name='user' /> {table.seats}
                                    </Column>
                                </Row>
                                <hr />
                            </>
                        ))}
                        {selectedTableCoverCount > 0 &&
                            <>
                                <br />
                                <Row>
                                    <Column size={6}>
                                        <strong>Total selected: </strong>
                                    </Column>
                                    <Column size={6}>
                                        <Icon name='user' /> {selectedTableCoverCount}
                                    </Column>
                                </Row>
                                <hr />
                            </>
                        }
                        {selectedTables.length > 0 && seatsLeftToAllocate > 0 &&
                            <>
                                <br />
                                <Icon regular name='triangle-exclamation' /><strong>Please allocate </strong> {seatsLeftToAllocate} <strong> more seat{seatsLeftToAllocate == 1 ? '' : 's'} </strong><br />
                            </>
                        }
                        {selectedTables.length > 0 && seatsLeftToAllocate <= 0 && !moreTablesThanNeeded &&
                            <>
                                <br />
                                <SuccessMessage><Icon regular name='check' />All allocated ({booking.guests} guest{booking.guests == 1 ? '' : 's'}) </SuccessMessage>
                            </>
                        }
                        <br />
                        {moreTablesThanNeeded &&
                            <>
                                <ErrorMessage>One or more selected tables are not needed to seat the party. Please de-select tables that are not needed before continuing.</ErrorMessage>
                                <br />
                            </>
                        }
                        {!bookingAmendment && <OkDateButton full disabled={invalidMerge} type='secondary' onClick={() => confirmMerge()}><Icon name='check' /> Confirm</OkDateButton>}
                    </div>
                </StageWrapper>
            }
            {errors.map((e, index) => (
                <ErrorMessage key={`Error-${index}`}>{e.message}</ErrorMessage>
            ))}
        </CoreModal>
    )
};

export default AmendBookingModal;