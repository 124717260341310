import { BaseBookingTableSetup } from "../../../../../../api/api-definitions";
import { ExtendedTable } from "../TableLayout";

export const getSelectedTableDisplay = (selectedTables: number[], tables: ExtendedTable[], table: BaseBookingTableSetup) => {
    if (selectedTables?.length > 0) {
        return selectedTables.map(x => tables.find(y => y.id === x).tableName).join(', ');
    } else if (table) {
        return table.tableName;
    }
    return null;
}

export const moreTablesThanNeeded = (selectedTableItems: ExtendedTable[], selectedTableCoverCount: number, guests: number) => {
    const overallocationAmount = selectedTableCoverCount - guests;
    return overallocationAmount > 0 && !!selectedTableItems.find(x => x.seats <= overallocationAmount);
}