import React from 'react';
import Icon from '../../../../components/Media/Icon';
import { ApiBusinessBookingDetails, ApiMakeBookingRequest, BookingError, EnumWidgetFieldType, HeldBookingResponse } from '../../../../api/api-definitions';
import FormWrapper from '../../../../components/Forms/FormWrapper';
import { Row, Column } from '../../../../components/Layout/Grid';
import { TELEPHONE_REGEX, EmailAddressSize, EMAIL_REGEX } from '../../../../constants';
import { StyledTextInput, StyledTextarea, StyledDropdown, StyledNumberInput } from '../../../../theme/input.styles';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { Button, InfoMessage } from '../../../booking.styles';
import Checkbox from '../../../../components/Forms/Checkbox';
import { DropdownItem } from '../../../../components/Forms/Dropdown';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    bookingErrors: BookingError[];
    bookingHash: string;
    selectedTime: string;
    specialOccasionOptions: DropdownItem[];
    details: ApiMakeBookingRequest;
    holdDetails: HeldBookingResponse;
    bookingError: string;
    setDetails: (details: ApiMakeBookingRequest) => void;
    takeClientDetails: () => void;
}

const CustomerDetails = ({
    business,
    bookingErrors,
    bookingHash,
    selectedTime,
    specialOccasionOptions,
    details,
    holdDetails,
    bookingError,
    setDetails,
    takeClientDetails,
 }: ComponentProps) => {
    return (
        <FormWrapper onUpdate={setDetails}>
            {({ id, valid }) => (
                <>
                    {(!bookingErrors || bookingErrors.length === 0) &&
                        <>

                            {!bookingHash &&
                                <>
                                    <Row hidden={!!!selectedTime}>
                                        <Column size={6} mobile={12}>
                                            <StyledTextInput required label='First name' model='firstName' max={100} value={details.firstName} />
                                        </Column>
                                        <Column size={6} mobile={12}>
                                            <StyledTextInput required label='Last name' model='lastName' max={100} value={details.lastName} />
                                        </Column>
                                    </Row>
                                    <Row hidden={!!!selectedTime}>
                                        <Column size={6} mobile={12}>
                                            <StyledTextInput required label='Telephone' model='telephone' max={20} value={details.telephone} regexMatch={TELEPHONE_REGEX} regexError='Value must be a telephone number' />
                                        </Column>
                                        <Column size={6} mobile={12}>
                                            <StyledTextInput required label='Email' max={EmailAddressSize} model='email' value={details.email} regexMatch={EMAIL_REGEX} regexError='Value must be a valid email address' />
                                        </Column>
                                    </Row>
                                </>
                            }
                            <Row hidden={!!!selectedTime}>
                                <Column size={12} mobile={12}>
                                    <StyledTextarea label='Special requests' maxLength={600} model='specialRequests' value={details.specialRequests} />
                                </Column>
                                {specialOccasionOptions.length > 0 &&
                                    <Column size={12} mobile={12}>
                                        <StyledDropdown label='Select an occasion' model='specialOccasion' value={details.specialOccasion} items={specialOccasionOptions} />
                                    </Column>
                                }
                                {!bookingHash && business?.customFields?.map((field, index) => (
                                    <Column size={12} mobile={12} key={`Custom-${index}`}>
                                        {field.type == EnumWidgetFieldType.Text && <StyledTextInput required={field.required} label={field.label} model={`Custom-${index}`} value={details[`Custom-${index}`]} />}
                                        {field.type == EnumWidgetFieldType.Number && <StyledNumberInput required={field.required} label={field.label} model={`Custom-${index}`} value={details[`Custom-${index}`]} />}
                                        {field.type == EnumWidgetFieldType.Dropdown && <StyledDropdown required={field.required} label={field.label} value={details[`Custom-${index}`]} items={field.values.split('|').map(x => ({ value: x }))} model={`Custom-${index}`} />}
                                    </Column>
                                ))}
                                {!bookingHash &&
                                    <Column size={12} mobile={12} noMarginBottom>
                                        <Checkbox model='updates' checked={details.updates} label={`Yes, I would like to receive updates and promotions from ${business.name}.`} />
                                    </Column>
                                }
                            </Row>
                            {holdDetails?.depositRequired && !holdDetails?.takeDepositNow &&
                                <>
                                    <InfoMessage marginTop widgetTheme={business?.theme}>No show charges apply for this booking. You will be asked on the next screen for your card details. The no show charge for this booking is: £{holdDetails.depositAmount.toFixed(2)}</InfoMessage>
                                </>
                            }
                            {holdDetails?.depositRequired && holdDetails?.takeDepositNow &&
                                <>
                                    <InfoMessage marginTop widgetTheme={business?.theme}>A deposit is required for this booking. You will be asked on the next screen for your card details. The deposit for this booking is: £{holdDetails.depositAmount.toFixed(2)}</InfoMessage>
                                </>
                            }
                        </>
                    }
                    {bookingErrors && bookingErrors.length > 0 &&
                        <>
                            <br />
                            {bookingErrors.map((e, index) => (
                                <InfoMessage widgetTheme={business?.theme} key={`Error-${index}`}>
                                    <Icon name='triangle-exclamation' /> {e.message}
                                </InfoMessage>
                            ))}
                        </>
                    }
                    {bookingError && !bookingHash && (!bookingErrors || bookingErrors.length == 0) &&
                        <>
                            <br />
                            <InfoMessage widgetTheme={business?.theme} key={`Error`}>{bookingError}</InfoMessage>
                        </>
                    }
                    <Button disabled={!valid || !isNullOrWhitespace(bookingError) || (bookingErrors && bookingErrors.length > 0)} style={{ marginTop: '1.5rem' }} widgetTheme={business?.theme} type='button' onClick={() => takeClientDetails()}>
                        {bookingHash ? 'Confirm amendment' : isNullOrWhitespace(business.reserveButtonTextStepTwo) ? 'Complete booking' : business.reserveButtonTextStepTwo}
                    </Button>
                    {bookingError && bookingHash &&
                        <>
                            <InfoMessage marginTop widgetTheme={business?.theme}>{bookingError}</InfoMessage>
                        </>
                    }
                </>
            )}
        </FormWrapper>
    );
};

export default CustomerDetails;