import React, { useEffect } from 'react';
import useParentBusiness from '../../../../../hooks/useParentBusiness';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import { TabBar, TabButton } from '../../../../../components/Layout/Tabs';
import PaymentMethods from './PaymentMethods';
import Loader from '../../../../../components/Layout/Loader';
import Subscriptions from './Subscriptions';
import Invoices from './Invoices';
import { getQueryParams } from '../../../../../utils/data-helpers';
import { NotificationService } from '../../../../../services/NotificationService';


const PaymentCenter = () => {
    const [loaded, parentBusiness] = useParentBusiness();
    const [tab, setTab] = React.useState(0);
    
    useEffect(() => {
        const queryParams = getQueryParams();
        if (queryParams.redirect_status) {
            setTab(1);
            window.history.replaceState(null, '', window.location.pathname)
            if (queryParams.redirect_status === 'succeeded') {
                NotificationService.Confirm('Your card has been added')
            } else {
                NotificationService.Error('Your card could not be added')
            }
        }
    })

    return (
        <>
            <DashboardHeader title='Payment center' icon='file-invoice' />
            {!parentBusiness && <Loader />}
            {parentBusiness &&
                <>
                    <TabBar>
                        <TabButton active={tab === 0} onClick={() => setTab(0)}>Subscriptions</TabButton>
                        <TabButton active={tab === 1} onClick={() => setTab(1)}>Payment methods</TabButton>
                        <TabButton active={tab === 2} onClick={() => setTab(2)}>Invoices</TabButton>
                    </TabBar>
                    {tab === 0 && <Subscriptions />}
                    {tab === 1 && <PaymentMethods />}
                    {tab === 2 && <Invoices />}
                </>
            }
        </>
    );
};

export default PaymentCenter;