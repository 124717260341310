export default class DocumentService {
    private static _mode: number = 1;
    public static RenderedHeader: boolean = false;

    static SetViewHTML() {
        this._mode = 1;
    }

    static SetViewPDF() {
        this._mode = 0;
    }

    static get ViewMode() {
        return this._mode;
    }
}