import { BaseBookingDurationRule, BaseBookingPacingRule, BaseBookingTimeBlock, BaseBusinessOpeningTimeException, BaseDepositRule, BaseExperienceAreaLink } from "../../../../../../api/api-definitions";

export enum ExperienceWarningType {
    None,
    Error,
    Warning,
    Info
}

interface ExperienceWarnings {
    dates: ExperienceWarningType;
    publishedDates: ExperienceWarningType;
    selectedTables: ExperienceWarningType;
    durationRules: ExperienceWarningType;
    depositRules: ExperienceWarningType;
    pacingRules: ExperienceWarningType;
    timeBlockRules: ExperienceWarningType;
    hasErrors: boolean;
    hasWarnings: boolean;
}

export class ExperienceService {
    public static _dates: BaseBusinessOpeningTimeException[] = [];
    public static _selectedTables: BaseExperienceAreaLink[] = [];
    public static _durationRules: BaseBookingDurationRule[] = [];
    public static _depositRules: BaseDepositRule[] = [];
    public static _pacingRules: BaseBookingPacingRule[] = [];
    public static _timeBlockRules: BaseBookingTimeBlock[] = [];

    public static get warningList(): ExperienceWarnings {
        const returnValue: ExperienceWarnings = {
            dates: ExperienceWarningType.None,
            publishedDates: ExperienceWarningType.None,
            selectedTables: ExperienceWarningType.None,
            durationRules: ExperienceWarningType.None,
            depositRules: ExperienceWarningType.None,
            pacingRules: ExperienceWarningType.None,
            timeBlockRules: ExperienceWarningType.None,
            hasErrors: false,
            hasWarnings: false
        }

        if (this._dates.length == 0) {
            returnValue.dates = ExperienceWarningType.Error;
            returnValue.hasErrors = true;
        }
        if (this._dates.length > 0 && !!!this._dates.find(x => x.bookableForEvent)) {
            returnValue.publishedDates = ExperienceWarningType.Error;
            returnValue.hasErrors = true;
        }
        if (this._durationRules.length == 0) {
            returnValue.durationRules = ExperienceWarningType.Info;
            returnValue.hasWarnings = true;
        }
        if (this._depositRules.length == 0) {
            returnValue.depositRules = ExperienceWarningType.Info;
            returnValue.hasWarnings = true;
        }
        if (this._pacingRules.length == 0) {
            returnValue.pacingRules = ExperienceWarningType.Info;
            returnValue.hasWarnings = true;
        }
        if (this._timeBlockRules.length == 0) {
            returnValue.timeBlockRules = ExperienceWarningType.Info;
            returnValue.hasWarnings = true;
        }

        if (this._selectedTables.length == 0 || !!!this._selectedTables.find(x => x.wholeAreaSelected || x.experienceTableSelections.length > 0)) {
            returnValue.selectedTables = ExperienceWarningType.Error;
            returnValue.hasErrors = true;
        }

        return returnValue;
    }
}