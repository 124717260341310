import React, { useState } from 'react';
import styled from 'styled-components';
import CoreButton from '../../../../../../components/Forms/Button';
import BREAKPOINTS from '../../../../../../config/breakpoints';
import Icon from '../../../../../../components/Media/Icon';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { BookingStatusColours } from '../bookingUtils';
import { bookingTables } from '../../../../../../svgs/bookingTables';
import { svgToURL } from '../../../../../../utils/data-helpers';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { TIMEFORMAT, formatDate } from '../../../../../../utils/date-helpers';
import moment from 'moment';

const TimelineKey = styled.div<{
    now?: boolean;
    held?: boolean;
    blocked?: boolean;
    closed?: boolean;
    arrived?: boolean;
    partiallySeated?: boolean;
    seated?: boolean;
    completed?: boolean;
    upcoming?: boolean;
}>`
    ${props => props.completed && `background-color: ${BookingStatusColours.completed.background}; color: ${BookingStatusColours.completed.text};`}
    ${props => props.seated && `background-color: ${BookingStatusColours.seated.background}; color: ${BookingStatusColours.seated.text};`}
    ${props => props.partiallySeated && `background-color: ${BookingStatusColours.partiallySeated.background}; color: ${BookingStatusColours.partiallySeated.text};`}
    ${props => props.upcoming && `background-color: ${BookingStatusColours.pending.background}; color: ${BookingStatusColours.pending.text};`}
    ${props => props.arrived && `background-color: ${BookingStatusColours.arrived.background}; color: ${BookingStatusColours.arrived.text};`}
    ${props => props.now && `background-color: #ddeaf7;`}
    ${props => props.held && `background-color: #dfdfdf;`}
    ${props => props.closed && `background-color: #ffe2e2;`}
    ${props => props.closed && `background-color: #ffe2e2;`}
    ${props => props.blocked && `
        background-color: rgba(0, 0, 0, 0.3);
        background: repeating-linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3) 5px,
            rgba(0, 0, 0, 0) 5px,
            rgba(0, 0, 0, 0) 10px
        );
    `}
    text-align: center;
    flex: 1 1 auto;
    
    @media (max-width: ${BREAKPOINTS.desktop}px) {
        min-width: 25%;
    }

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        min-width: 50%;
    }

`

const Button = styled(CoreButton)`
    position: absolute !important;
    top: 5rem !important;
    right: 2rem !important;

    @media (max-width: 1023px) {
      top: 12rem !important;
    }
`

const TableImage = styled.img`
    width: 100%;
    max-width: 6rem;
    margin: 0 auto;
`;

const InfoModule = () => {
    const [open, setOpen] = useState<boolean>(false);
    const pendingTable = svgToURL(bookingTables[`4-rectangle`](BookingStatusColours.pending.background, undefined, {1: BookingStatusColours.seated.background, 2: BookingStatusColours.seated.background, 3: BookingStatusColours.seated.background, 4: BookingStatusColours.seated.background}))
    const pendingThreeGuestsTable = svgToURL(bookingTables[`4-rectangle`](BookingStatusColours.pending.background, undefined, {1: BookingStatusColours.seated.background, 2: BookingStatusColours.seated.background, 3: BookingStatusColours.seated.background}))
    const seatedTable = svgToURL(bookingTables[`4-rectangle`](BookingStatusColours.seated.background))
    const partiallySeatedTable = svgToURL(bookingTables[`4-rectangle`](BookingStatusColours.partiallySeated.background))
    const seatedTableEndingSoon = svgToURL(bookingTables[`4-rectangle`](BookingStatusColours.seated.background, BookingStatusColours.endingSoon.background))
    const overrunningTable = svgToURL(bookingTables[`4-rectangle`](BookingStatusColours.seated.background, 'red'))
    const freeTable = svgToURL(bookingTables[`4-rectangle`]('white'))

    return (
        <>
            {open &&
                <CoreModal isOpen onClose={() => setOpen(false)} slimPanel cancelText='Close'>
                    <div><strong>Status indicators:</strong></div>
                    <Row>
                        <Column size={6} mobile={12}>
                            <TimelineKey closed>Closed</TimelineKey>
                        </Column>
                        <Column size={6} mobile={12}>
                            <TimelineKey held>Held</TimelineKey>
                        </Column>
                        <Column size={6} mobile={12}>
                            <TimelineKey blocked><span style={{backgroundColor: 'white', padding: '0 0.5rem'}}>Blocked</span></TimelineKey>
                        </Column>
                        <Column size={6} mobile={12}>
                            <TimelineKey now>Current</TimelineKey>
                        </Column>
                        <Column size={6} mobile={12}>
                            <TimelineKey partiallySeated>Partially seated</TimelineKey>
                        </Column>
                        <Column size={6} mobile={12}>
                            <TimelineKey arrived>Arrived</TimelineKey>
                        </Column>
                        <Column size={6} mobile={12}>
                            <TimelineKey seated>Seated</TimelineKey>
                        </Column>
                        <Column size={6} mobile={12}>
                            <TimelineKey completed>Completed</TimelineKey>
                        </Column>
                        <Column size={6} mobile={12}>
                            <TimelineKey upcoming>Upcoming</TimelineKey>
                        </Column>
                    </Row>
                    <Row style={{ backgroundColor: 'lightgray', textAlign: 'center', paddingTop: '0.5rem' }}>
                        <Column size={6} mobile={12}>
                            <div><strong>Unavailable with pending booking (table full):</strong></div>
                            <div><TableImage src={pendingTable} /></div>
                        </Column>
                        <Column size={6} mobile={12}>
                            <div><strong>Unavailable with pending booking (3 guests):</strong></div>
                            <div><TableImage src={pendingThreeGuestsTable} /></div>
                        </Column>
                        <Column size={6} mobile={12}>
                            <div><strong>Partially seated table:</strong></div>
                            <div><TableImage src={partiallySeatedTable} /></div>
                        </Column>
                        <Column size={6} mobile={12}>
                            <div><strong>Seated table:</strong></div>
                            <div><TableImage src={seatedTable} /></div>
                        </Column>
                        <Column size={6} mobile={12}>
                            <div><strong>Table ending soon:</strong></div>
                            <div><TableImage src={seatedTableEndingSoon} /></div>
                        </Column>
                        <Column size={6} mobile={12}>
                            <div><strong>Overunning table:</strong></div>
                            <div><TableImage src={overrunningTable} /></div>
                        </Column>
                        <Column size={6} mobile={12}>
                            <div><strong>Available table:</strong></div>
                            <div><TableImage src={freeTable} /></div>
                        </Column>
                        <Column size={6} mobile={12}>
                            <div><strong>Available, booked later today:</strong></div>
                            <div style={{ position: 'relative' }}>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: BookingStatusColours.pending.background,
                                    color: BookingStatusColours.pending.text,
                                    padding: '0 10px',
                                    lineHeight: '1rem',
                                    top: '1.8rem',
                                    left: 'calc(50% - 3.2rem)'
                                }}>S: {formatDate(moment(), TIMEFORMAT)}</div>
                                <TableImage src={freeTable} />
                            </div>
                        </Column>
                    </Row>
                </CoreModal>
            }
            <Button small onClick={() => setOpen(true)}><Icon name='info-circle' /></Button>
        </>
    );
};

export default InfoModule;