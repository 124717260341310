import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ProfileImage from '../Media/ProfileImage';
import Icon from '../Media/Icon';
import { useRecoilValue } from 'recoil';
import CoreButton from '../Forms/Button';
import { LoginState } from '../Authentication/AuthenticationWrapper';
import { isNullOrWhitespace } from '../../utils/text-helpers';
import BREAKPOINTS from '../../config/breakpoints';
import { Row, Column } from '../Layout/Grid';

interface ComponentProps extends StyledAppBarProps {
}

const MenuLink = styled(Link)`
    margin: 1rem 1.5rem;
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    &:hover {
        border-bottom: 1px solid white;
    }
`;

const LogoLink = styled(MenuLink)`
    margin: 0.5rem 1.5rem;
    height: 3rem;
`;

const StyledToolbar = styled.div`
    flex-wrap: wrap;
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 64px;
`;

const ToolbarTitle = styled(LogoLink)`
    flex-grow: 1;
    background-image: url('/assets/logo3.svg');
    background-position: left center;
    background-size: 16rem auto;
    background-repeat: no-repeat;

    @media (max-width: 530px) {
        background-size: 100% auto;
        margin: 0.7rem;
    }

    &:hover {
        border-bottom: none;
    }
`;

const ToolbarTitleMobileNav = styled(LogoLink)`
    background-image: url('/assets/logo3.svg');
    background-position: left center;
    background-size: 12rem auto;
    background-repeat: no-repeat;
    width: 17rem;
    display: inline-block;
    float: left;
    margin: 0 1rem;
    height: 2rem;
    position: absolute;
    left: 0;
`


interface StyledAppBarProps {
    hasSplashImage?: boolean;
}

const StyledFiller = styled.div`
    height: 4rem;
    position: relative;
`;

const LoginButton = styled(CoreButton)`
    margin-right: 1rem;
`;

const DropdownContent = styled.div`
    position: absolute;
    width: 15rem;
    border: none;
    box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
    border-radius: .5rem;
    z-index: 1;
    right: -0.5rem;
    line-height: 1rem;

    display: block;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease-in;
`;

const Dropdown = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 1rem;
    line-height: 4rem;

    &:hover ${DropdownContent} {
        transform: scaleY(1);
                    opacity: 1;
                    visibility: visible;
    }
`;

const sharedLinkStyle = `
    padding: 1rem;
    background-color: white;
    color: black;
    display: block;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-decoration: none;

    &:hover {
        background-color: #f8f9fa;
    }

    &::last-child {
        border: none;
    }
`

const ProfileOption = styled(Link)`
    ${sharedLinkStyle}
    border-bottom: 1px solid ${props => props.theme.borderColor};
`;

const LogoutButton = styled.div`
${sharedLinkStyle}
`;

const RegisterPopup = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(10, 10, 10, 0.4);
    z-index: 1000;
`;

const RegisterPopupModal = styled.div`
    position: fixed;
    width: 40rem;
    height: 10rem;
    top: calc(50vh - 5rem);
    left: calc(50vw - 20rem);
    background-color: white;
    z-index: 1000;
    display: flex;
    padding: 1rem;
`;

const RegisterPopupSlide = styled(Link)`
    flex: 1 0 auto;
    background-color: ${props => props.theme.primary};
    line-height: 8.5rem;
    font-size: 1.3em;
    width: calc(50% - 2rem);
    text-align: center;
    margin-right: 0.5rem;
    box-shadow: 4px 4px 2px 0 rgb(0 0 0 / 41%);
    cursor: pointer;
    text-decoration: none;
    color: white;

    &:hover {
        box-shadow: none;
    }
`;

const RegisterPopupSlideRight = styled(RegisterPopupSlide)`
    background-color: ${props => props.theme.secondary};
    margin-left: 0.5rem;
    margin-right: 0;
`;


const NavButtonContainer = styled.div`
    margin-top: -0.3rem;
`;

const HamburgerMenu = styled.div`
    display: none;
    font-size: 1.5rem;
`

const SlideOutMenu = styled.div<{ active?: boolean; }>`
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    right: ${props => props.active ? '0' : '-100vw'};
    transition: right ease 0.5s;
    background-color: ${props => props.theme.secondary};
    color: ${props => props.theme.secondaryContrast};
    width: 100vw;
    z-index: 99999;
    max-width: 30rem;

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        display: block;
    }
`

const SlideOutMenuBehind = styled.div<{ active?: boolean; }>`
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: ${props => props.active ? '1' : '0'};
    transition: opacity ease 0.5s;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    z-index: 99998;
    pointer-events: ${props => props.active ? 'all' : 'none'};

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        display: block;
    }
`

const SlideOutCloseBar = styled.div`
    line-height: 2rem;
    font-size: 1.5rem;
    background-color: ${props => props.theme.primary};
    text-align: right;
    padding: 0.5rem;
    height: 3rem;
`

const SlideOutNavbar = styled.div`
    height: calc(100vh - 3rem);
    overflow-y: auto;
    padding: 0.5rem;
`

const SlideOutNavbarUsername = styled(Column)`
    line-height: 3rem;
    font-size: 1.3rem;
`

const StyledAppBar = styled.div<StyledAppBarProps>`
    width: 100%;
    display: flex;
    z-index: 100;
    box-sizing: border-box;
    position: static;
    flex-shrink: 0;
    flex-direction: column;
    top: 0;
    ${(props) => `background-color: ${props.theme.primary};`}
    position: absolute;
    color: white;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 12%);

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        nav, #profileImage, ${Dropdown}, ${NavButtonContainer} {
            display: none;
        }

        ${HamburgerMenu} {
            display: inline-block;
        }
    }
`;

const TopNavigation = ({ hideOptions }: { hideOptions?: boolean }) => {
    const [modalOpen, setOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const loginValue = useRecoilValue(LoginState);

    function logout() {
        // @ts-ignore
        window.logout();
    }
    function openModal(e) {
        e.preventDefault();
        setOpen(!modalOpen);
    }
    const location = useLocation();

    if (location.pathname.startsWith('/standalone/') || location.pathname.startsWith('/dashboard')) return null;

    return (
        <React.Fragment>
            <SlideOutMenuBehind active={mobileMenuOpen} onClick={() => setMobileMenuOpen(false)} />
            <SlideOutMenu active={mobileMenuOpen}>
                <SlideOutCloseBar>
                    <ToolbarTitleMobileNav title='Home page link' to='/' onClick={() => setMobileMenuOpen(false)} />
                    <Icon name='xmark' onClick={() => setMobileMenuOpen(false)} />
                </SlideOutCloseBar>
                <SlideOutNavbar>
                    {loginValue.isLoggedIn &&
                        <>
                            <Row>
                                <Column size={2}>
                                    <ProfileImage small src={!isNullOrWhitespace(loginValue.data.imageUrl) ? loginValue.data.imageUrl : 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/434px-Unknown_person.jpg'} />
                                </Column>
                                <SlideOutNavbarUsername size={10}>
                                    Hello {loginValue.data.firstName} {loginValue.data.surname}!
                                </SlideOutNavbarUsername>
                            </Row>
                            {/* <ProfileOption to='/profile/' onClick={() => setMobileMenuOpen(false)}>
                                <Icon name='user' /> Profile
                            </ProfileOption> */}
                            {loginValue.data.isBusinessUser &&
                                <ProfileOption to='/dashboard/' onClick={() => setMobileMenuOpen(false)}>
                                    <Icon name='globe-europe' /> Dashboard
                                </ProfileOption>
                            }
                            {loginValue.data.isSuperAdmin &&
                                <>
                                    {!hideOptions &&
                                        <ProfileOption to='/showcase/' onClick={() => setMobileMenuOpen(false)}>
                                            <Icon name='dragon' /> Showcase
                                        </ProfileOption>
                                    }
                                </>
                            }
                        </>
                    }
                    {!hideOptions &&
                        <ProfileOption to='/business/' onClick={() => setMobileMenuOpen(false)}>
                            <Icon name='briefcase' /> For Businesses
                        </ProfileOption>
                    }
                    <ProfileOption to='/contact/' onClick={() => setMobileMenuOpen(false)}>
                        <Icon name='envelope' /> Contact us
                    </ProfileOption>
                    <br />
                    {!loginValue.isLoggedIn &&
                        <LoginButton small full outline inverse type='secondary' to='/login/' onClick={() => setMobileMenuOpen(false)}>
                            <Icon name='user' /> Log in
                        </LoginButton>
                    }
                    {loginValue.isLoggedIn &&
                        <LogoutButton onClick={() => { logout(); setMobileMenuOpen(false) }}>
                            <Icon name='sign-out-alt' /> Logout
                        </LogoutButton>
                    }
                    {!hideOptions &&
                        <CoreButton small outline full inverse type='secondary' onClick={(e) => { openModal(e); setMobileMenuOpen(false) }}>
                            <Icon name='edit' /> Register
                        </CoreButton>
                    }
                    <CoreButton outline small full inverse type='secondary' to='/register-business/' onClick={() => setMobileMenuOpen(false)}>
                        <Icon name='edit' /> Register your interest
                    </CoreButton>
                </SlideOutNavbar>
            </SlideOutMenu>
            <StyledAppBar hasSplashImage>
                <StyledToolbar>
                    <ToolbarTitle title='Home page link' to='/' />
                    <HamburgerMenu onClick={() => setMobileMenuOpen(true)}>
                        <Icon name='bars' />
                    </HamburgerMenu>
                    <nav>
                        {!hideOptions &&
                            <MenuLink to='/business/'>
                                For Businesses
                            </MenuLink>
                        }
                        <MenuLink to='/contact/'>
                            Contact us
                        </MenuLink>
                    </nav>
                    {!loginValue.isLoggedIn &&
                        <>
                            <NavButtonContainer>
                                <LoginButton small outline inverse type='secondary' to='/login/'>
                                    <Icon name='user' /> Log in
                                </LoginButton>
                                {!hideOptions &&
                                    <CoreButton small outline inverse type='secondary' onClick={(e) => openModal(e)}>
                                        <Icon name='edit' /> Register
                                    </CoreButton>
                                }
                                <CoreButton small outline inverse type='secondary' to='/register-business/'>
                                    <Icon name='edit' /> Register your interest
                                </CoreButton>
                            </NavButtonContainer>
                            {modalOpen &&
                                <RegisterPopup onClick={() => setOpen(!modalOpen)}>
                                    <RegisterPopupModal>
                                        {/* <RegisterPopupSlide to='/register/'>
                                            <Icon name='user' /> Register as a user
                                        </RegisterPopupSlide> */}
                                        <RegisterPopupSlideRight to='/register-business/'>
                                            <Icon name='briefcase' /> Register as a business
                                        </RegisterPopupSlideRight>
                                    </RegisterPopupModal>
                                </RegisterPopup>
                            }
                        </>
                    }
                    {loginValue.isLoggedIn &&
                        <>
                            <ProfileImage small src={!isNullOrWhitespace(loginValue.data.imageUrl) ? loginValue.data.imageUrl : 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/434px-Unknown_person.jpg'} />
                            <Dropdown>
                                {loginValue.data.firstName} {loginValue.data.surname} <Icon name='angle-down' />
                                <DropdownContent>
                                    {/* <ProfileOption to='/profile/'>
                                        <Icon name='user' /> Profile
                                    </ProfileOption> */}
                                    {loginValue.data.isBusinessUser &&
                                        <ProfileOption to='/dashboard/'>
                                            <Icon name='globe-europe' /> Dashboard
                                        </ProfileOption>
                                    }
                                    {/* <ProfileOption to='/my-recipies/'>
                                        <Icon name='book' /> My Recipes
                                    </ProfileOption> */}
                                    {loginValue.data.isSuperAdmin &&
                                        <>
                                            <ProfileOption to='/showcase/'>
                                                <Icon name='dragon' /> Showcase
                                            </ProfileOption>
                                        </>
                                    }
                                    <LogoutButton onClick={() => logout()}>
                                        <Icon name='sign-out-alt' /> Log out
                                    </LogoutButton>
                                </DropdownContent>
                            </Dropdown>
                        </>
                    }
                </StyledToolbar>
            </StyledAppBar>
            <StyledFiller />
        </React.Fragment>
    );
};

export default TopNavigation;
