import React, { useState } from 'react';
import styled from 'styled-components';
import ContextMenu from '../Cta/ContextMenu';
import Icon from '../Media/Icon';

interface ComponentProps {
    children: React.ReactNode;
}

const ContextButton = styled.div`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.primaryContrast};
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
  border-radius: 0.4rem;

  &:hover {
    background-color: ${props => props.theme.secondary};
  }
`

const DashboardContextMenu = ({ children }: ComponentProps) => {
    const [contextOpen, setContextOpen] = useState(false);
    const toggleContextMenu = () => {
        setContextOpen(!contextOpen);
    }
    return (
        <>
            {contextOpen &&
                <ContextMenu onClose={() => setContextOpen(false)}>
                    {children}
                </ContextMenu>
            }
            <ContextButton onClick={toggleContextMenu}><Icon name='ellipsis-v' /></ContextButton>
        </>
    );
};

export default DashboardContextMenu;