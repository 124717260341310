import { clone } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../components/Media/Icon';
import useBusinessList, { ExtendedBaseParentBusiness } from '../../hooks/useBusinessList';
import { BoxShadowStyle } from '../../theme';
import { capitalizeFirst } from '../../utils/string-helpers';
import { isNullOrWhitespace } from '../../utils/text-helpers';
import { BusinessSidebar, LocationSidebar } from './sideBars';
import { useBusiness } from '../../hooks/useBusiness';
import useParentBusiness from '../../hooks/useParentBusiness';

interface ComponentProps {
    route: any;
}

const Container = styled.div`
    display: flex;
    ${BoxShadowStyle}
    background-color: ${props => props.theme.background};
    border-radius: 1rem;
    margin-top: 1rem;
    padding: 0.5rem;
    flex-wrap: wrap;
`

const BreadcrumbLink = styled(Link)`
    flex: 0 0 auto;
    display: inline-block;
    color: ${props => props.theme.primary};
    font-weight: 600;
    max-width: 100%;

    &:hover {
        text-decoration: underline;
    }
`

const BreadcrumbIcon = styled.div`
    flex: 0 0 auto;
    display: inline-block;
    color: ${props => props.theme.primary};
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 1rem;

    span {
        line-height: 1.5rem;
    }
`

interface Breadcrumb {
    name: string;
    url: string;
}

const getUrl = (paths: string[], index: number): string => {
    const clonedPaths = clone(paths)
    clonedPaths.length = index + 1;
    return clonedPaths.join('/');
}

const getName = (name: string): string => capitalizeFirst(name.replace('-', ' '))

const Breadcrumbs = ({ route }: ComponentProps) => {
    const [loaded, data] = useBusinessList();
    const [businessLoaded, businessData] = useBusiness();
    const [parentLoaded, parentBusiness] = useParentBusiness();

    if (!loaded) return;

    const pathSplit = route.location.pathname.split('/')
    const paths: Breadcrumb[] = [];
    let parent: ExtendedBaseParentBusiness;

    pathSplit.forEach((path, index) => {
        if (!isNullOrWhitespace(path) && path.toLowerCase() !== 'dashboard') {
            switch (index) {
                case 2:
                    parent = data.find(x => x.id == +path)
                    if (parent) {
                        paths.push({
                            name: parent.name,
                            url: getUrl(pathSplit, index)
                        });
                    } else if (businessData && businessData.parentBusiness) {
                        paths.push({
                            name: businessData.parentBusiness.name,
                            url: getUrl(pathSplit, index)
                        });
                    } else {
                        paths.push({
                            name: getName(path),
                            url: getUrl(pathSplit, index)
                        });
                    }
                    break;
                case 3:
                    const item = BusinessSidebar.find(x => x.link.toLowerCase() == path.toLowerCase())
                    if (item) {
                        paths.push({
                            name: item.name,
                            url: getUrl(pathSplit, index)
                        });
                    } else {
                        paths.push({
                            name: getName(path),
                            url: getUrl(pathSplit, index)
                        });
                    }
                    break;
                case 4:
                    if (businessData) {
                        paths.push({
                            name: businessData?.locationAddress?.formattedName,
                            url: getUrl(pathSplit, index)
                        });
                    } else if (pathSplit[index - 1].toLowerCase() == 'site-builder' && pathSplit[index + 1]?.toLowerCase() == 'edit-page') {
                        paths.push({
                            name: 'Pages',
                            url: `/dashboard/${parentBusiness?.id}/site-builder/pages`
                        });
                    } else {
                        paths.push({
                            name: getName(path),
                            url: getUrl(pathSplit, index)
                        });
                    }
                    break;
                case 5:
                    const subitem = LocationSidebar.find(x => x.link.toLowerCase() == path.toLowerCase())
                    if (subitem) {
                        paths.push({
                            name: subitem.name,
                            url: getUrl(pathSplit, index)
                        });
                    } else {
                        paths.push({
                            name: getName(path),
                            url: getUrl(pathSplit, index)
                        });
                    }
                    break;
                case 6:
                    if (pathSplit[pathSplit.length - 2].toLowerCase() == 'experiences') {
                        paths.push({
                            name: 'Edit',
                            url: getUrl(pathSplit, index)
                        });
                    } else {
                        paths.push({
                            name: getName(path),
                            url: getUrl(pathSplit, index)
                        });
                    }
                    break;
                default:
                    paths.push({
                        name: getName(path),
                        url: getUrl(pathSplit, index)
                    });
                    break;
            }
        }
    });
    if (paths.length === 0) return null;

    return (
        <Container>
            <BreadcrumbLink to='/dashboard'><Icon name='home' /></BreadcrumbLink>
            <BreadcrumbIcon><Icon name='chevron-right' /></BreadcrumbIcon>
            {paths.map((path, index) => (
                <React.Fragment key={`breadcrumb-${index}`}>
                    <BreadcrumbLink to={path.url}>{path.name}</BreadcrumbLink>
                    {index !== paths.length - 1 && <BreadcrumbIcon><Icon name='chevron-right' /></BreadcrumbIcon>}
                </React.Fragment>
            ))}
        </Container>
    );
};

export default Breadcrumbs;