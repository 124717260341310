import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../../../../api/api-connectors';
import { ClientModel } from '../../../../../api/api-definitions';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import CoreButton from '../../../../../components/Forms/Button';
import DataTable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import Loader from '../../../../../components/Layout/Loader';
import { Constants, EmailAddressSize } from '../../../../../constants';
import { LockScreen } from '../../LocationModules/Bookings';
import IdleJs from 'idle-js';
import useParentBusiness from '../../../../../hooks/useParentBusiness';
import CoreModal from '../../../../../components/Layout/CoreModal';
import GuestInfo from './GuestInfo';
import useBusinessList from '../../../../../hooks/useBusinessList';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import SectionGroup from '../../../../../components/Forms/SectionGroup';
import { Row, Column } from '../../../../../components/Layout/Grid';
import { StyledTextInput } from '../../../../../theme/input.styles';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';

interface Filter {
    name?: string;
    telephone?: string;
    email?: string;
}

function isValidFilterItem(item: string, filterValue: string): boolean {
    return isNullOrWhitespace(filterValue) || item?.toLowerCase().indexOf(filterValue?.toLowerCase()) > -1
}

const BookingUsers = () => {
    const [businessLoaded, parentBusiness] = useParentBusiness();
    const [listLoaded, businessList, reload, locationDictionary] = useBusinessList();
    const loaded = useRef(false)
    const [viewClient, setViewClient] = useState<ClientModel>();
    const [filter, setFilter] = useState<Filter>({});
    const [data, setData] = useState<ClientModel[]>();
    const [showLock, setShowLock] = useState<boolean>(false);
    const lockRef = useRef<boolean>(false);
    const timer = useRef<IdleJs>(new IdleJs({
        idle: 30000, // idle time in ms
        events: ['mousemove', 'keydown', 'scroll'], // events that will trigger the idle resetter
        onIdle: () => { setShowLock(true); lockRef.current = true; }, // callback function to be executed after idle time
        onActive: () => {
            if (lockRef.current) {
                setShowLock(false);
                lockRef.current = false;
            }
        }, // callback function to be executed after back form idleness
        onHide: function () { }, // callback function to be executed when window become hidden
        onShow: function () { }, // callback function to be executed when window become visible
        keepTracking: true, // set it to false if you want to be notified only on the first idleness change
        startAtIdle: false // set it to true if you want to start in the idle state
    }));

    useEffect(() => {
        timer.current.start();

        return () => {
            timer.current.stop();
        }
    }, [])

    useEffect(() => {
        if (parentBusiness && !loaded.current) {
            loaded.current = true;
            ApiService.businessClient.List__GET(parentBusiness.id)
                .then(setData)
                .catch(error => alert(error));
        }
    }, [businessLoaded])

    const tableFilter = (item: ClientModel): boolean => {
        return isValidFilterItem(item.telephone, filter.telephone) &&
            isValidFilterItem(`${item.firstName} ${item.surname ? item.surname : ''}`, filter.name) &&
            isValidFilterItem(item.email, filter.email)
    }

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        data.filter(tableFilter).forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'Name': {
                        value: `${item.firstName} ${item.surname ? item.surname : ''}`,
                        sortable: true,
                    },
                    'Email': {
                        value: item.email || <>&nbsp;</>,
                        sortable: true,
                    },
                    'Telephone': {
                        value: item.telephone || <>&nbsp;</>,
                        sortable: true,
                    },
                    'Completed': {
                        value: item.bookingCount || 0,
                        sortable: true,
                    },
                    'Upcoming': {
                        value: item.futureBookings || 0,
                        sortable: true,
                    },
                    'No showed': {
                        value: item.noShowCount || 0,
                        sortable: true,
                    },
                    'Cancelled': {
                        value: item.cancelledCount || 0,
                        sortable: true,
                    },
                    'View': {
                        value: <CoreButton onClick={() => setViewClient(item)}>View</CoreButton>,
                        hideName: true
                    }
                }
            }
            items.push(dataItem);
        });
        return items;
    }
    return (
        <div>
            {showLock && <LockScreen>{parentBusiness?.name}</LockScreen>}
            {viewClient &&
                <CoreModal isOpen onClose={() => setViewClient(undefined)} hasCancel cancelText='Close'>
                    <GuestInfo id={viewClient.clientId} parentId={parentBusiness.id} />
                </CoreModal>
            }
            <DashboardHeader icon='address-book' title='Guestbook'>
                Manage all clients that have booked via {Constants.businessName} or manually added by you.
            </DashboardHeader>
            {!data && <Loader />}
            {data &&
                <>
                    <SectionGroup name='Filter'>
                        <FormWrapper onUpdate={setFilter}>
                            {({ id, valid }) => (
                                <Row>
                                    <Column size={3} tablet={6} mobile={12} noMarginBottom>
                                        <StyledTextInput model='name' label='Name' value={filter.name} />
                                    </Column>
                                    <Column size={3} tablet={6} mobile={12} noMarginBottom>
                                        <StyledTextInput max={EmailAddressSize} model='email' label='Email' value={filter.email} />
                                    </Column>
                                    <Column size={3} tablet={6} mobile={12} noMarginBottom>
                                        <StyledTextInput model='telephone' label='Telephone' value={filter.telephone} />
                                    </Column>
                                    <Column size={3} tablet={6} mobile={12} noMarginBottom checkboxInput>
                                        <CoreButton onClick={() => setFilter({})} type='secondary' full>Clear filter</CoreButton>
                                    </Column>
                                </Row>
                            )}
                        </FormWrapper>
                    </SectionGroup>
                    <DataTable data={gentable()} />
                </>
            }
        </div>
    );
};

export default BookingUsers;