import React, { useEffect } from 'react';
import useParentBusiness from '../../../../../hooks/useParentBusiness';
import { ApiService } from '../../../../../api/api-connectors';
import { ApiInvoice, ApiPaymentMethod, ApiSystemProduct } from '../../../../../api/api-definitions';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import DataTable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import { InfoMessage, WarningMessage } from '../../../../../components/Forms/Messaging';
import DashboardPanel from '../../../../../components/Dashboard/Panel';
import DashboardAddButton from '../../../../../components/Dashboard/AddButton';
import CoreModal from '../../../../../components/Layout/CoreModal';
import Checkout from '../../../../../components/Payments/Checkout';
import CoreButton from '../../../../../components/Forms/Button';
import { ModalService } from '../../../../../services/ModalService';
import Loader from '../../../../../components/Layout/Loader';


const Invoices = () => {
    const [loaded, parentBusiness] = useParentBusiness();
    const [items, setItems] = React.useState<ApiInvoice[]>();

    useEffect(() => {
        if (parentBusiness) {
            load();
        }
    }, [parentBusiness])

    const load = () => {
        ApiService.checkout.GetInvoices__GET(parentBusiness.id).then(setItems)
    }

    const gentable = (): DataTableItem[] => {
        const dataItems: DataTableItem[] = [];
        items.forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'Invoice number': {
                        value: item.invoiceNumber,
                    },
                    'Date issued': {
                        value: item.dateIssued,
                    },
                    'Date due': {
                        value: item.dateDue,
                    },
                    'Amount': {
                        value: item.totalPrice + ' GBP',
                    },
                    'Paid': {
                        value: item.paid ? 'PAID' : '',
                        hideName: true
                    },
                    'Download': {
                        value: <CoreButton onClick={() => downloadInvoice(item.url, item.invoiceNumber)}>Download</CoreButton>
                    }
                }
            }
            dataItems.push(dataItem);
        });
        return dataItems;
    }

    const downloadInvoice = (url: string, name: string) => {
        window.open(url, '_blank');
    }

    if (!items) return <Loader />;

    return (
        <>
            <DashboardPanel>
                {items.length == 0 &&
                    <>
                        <InfoMessage>No invoices to display</InfoMessage>
                    </>
                }
                {items.length > 0 &&
                    <DataTable data={gentable()} />
                }
            </DashboardPanel>
        </>
    );
};

export default Invoices;