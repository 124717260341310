import React, { useState } from 'react';
import styled from 'styled-components';
import CoreButton from '../../../../../../../components/Forms/Button';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import { StyledNumberInput } from '../../../../../../../theme/input.styles';

interface ComponentProps {
    onClose: () => void;
    waitlistId: number;
}

const NotifyClient = ({ onClose, waitlistId }: ComponentProps) => {
    const [waitTime, setWaitTime] = useState(15)
    return (
        <CoreModal small slimPanel onClose={onClose} title='Message customer' cancelText='Close'>
            <CoreButton full>Notify table is ready</CoreButton>
            <Divider />
            <CoreButton full disabled={!waitTime}>Notify table is ready in (mins)</CoreButton><StyledNumberInput min={5} value={waitTime} onChange={e => setWaitTime(+e.target.value)} hasPlusMinus incrementOf={5} />
            <Divider />
            <CoreButton full type='warning'>Cannot seat</CoreButton>
        </CoreModal>
    );
};

const Divider = styled.div`
    margin-top: 1rem;
`

export default NotifyClient;