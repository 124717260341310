import { Link } from "react-router-dom";
import styled from "styled-components";
import { BoxShadowStyleWithHover } from "../../theme";

export const PanelLink = styled(Link)`
  ${BoxShadowStyleWithHover};
  background-color: ${props => props.theme.background};
  display: block;
  padding: 1rem;
  text-align: center;
`