import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../api/api-connectors";
import { BaseBusiness, BaseParentBusiness } from "../api/api-definitions";
import BusinessListService from "../services/BusinessListService";
import { NotificationService } from "../services/NotificationService";
import BusinessListCacheService from "../services/BusinessListCacheService";
import useOffline from "./useOffline";

export interface ExtendedBaseParentBusiness extends BaseParentBusiness {
    locations: BaseBusiness[];
}

const useParentBusiness = (): [boolean, ExtendedBaseParentBusiness, (data: ExtendedBaseParentBusiness) => void, () => void] => {
    const params: any = useParams();
    const offline = useOffline();
    const [data, setData] = useState<ExtendedBaseParentBusiness>();

    useEffect(() => {
        if (params.parentId) load(false)
    }, [])

    const load = (force?: boolean) => {
        (offline ? BusinessListCacheService.GetBusinessList('null') : BusinessListService.GetBusinessList(force)).then((data) => {
            const parentBusinessesList: ExtendedBaseParentBusiness[] = [];
            data.forEach(location => {
                const parent = parentBusinessesList.find(x => x.id === location.parentBusinessId);
                if (!parent) {
                    parentBusinessesList.push({
                        ...location.parentBusiness,
                        locations: [location]
                    });
                } else {
                    parent.locations.push(location)
                }
            });
            const foundBusiness = parentBusinessesList && parentBusinessesList.length > 0 ? parentBusinessesList.find(x => x.id === +params.parentId) : null;
            setData(foundBusiness);
        }).catch((e) => {
            NotificationService.Error('Sorry, there was an error loading business information.')
        })
    }

    const reload = () => {
        setData(undefined)
        load(true)
    }

    return [!!data, data, setData, reload]
}

export default useParentBusiness;