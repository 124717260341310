import { useParams } from 'react-router';
import { Column, Row } from '../../../components/Layout/Grid';
import { StyledCalendar, StyledDropdown, StyledNumberInput, StyledTextInput, StyledTextarea } from '../../../theme/input.styles';
import Icon from '../../../components/Media/Icon';
import { useEffect, useRef, useState } from 'react';
import Loader from '../../../components/Layout/Loader';
import { DATABASE_TIME_FORMAT, DATEONLYFORMAT, DATE_WITH_DAY_OF_WEEK_FORMAT, DEFAULT_DATE_FORMAT, businessNowTime, createMomentFromValue, formatDate, secondsToTime } from '../../../utils/date-helpers';
import moment, { Moment } from 'moment';
import { ApiService } from '../../../api/api-connectors';
import { ApiBookingTimesWidget, ApiBookingTimesRequest, ApiBusinessBookingDetails, ApiMakeBookingRequest, ApiPaymentRequest, BookingError, BookingTimeSlots, DateAndTimeSlots, HeldBookingResponse, PublicAmendBookingRequest, EnumWidgetFieldType, SpecialOccasion, TimeSlot, ExperienceType, EventsAvailabilityResponse, WidgetImageLayout, BookingAvailabilityTimesWidget, ThemeOptions, ApiMakeBookingResponse } from '../../../api/api-definitions';
import { BookingSpecialOccasions, Constants, EMAIL_REGEX, EmailAddressSize, TELEPHONE_REGEX } from '../../../constants';
import Checkbox from '../../../components/Forms/Checkbox';
import { getQueryParams, pushEvent } from '../../../utils/data-helpers';
import FormWrapper from '../../../components/Forms/FormWrapper';
import { DropdownItem } from '../../../components/Forms/Dropdown';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import Checkout from '../../../components/Payments/Checkout';
import { NotificationService } from '../../../services/NotificationService';
import { captureException } from '@sentry/react';
import { BookingModuleContainer, InfoMessage, Title, ButtonBack, TransitionWrapper, YourDetails, InfoForm, FormRow, TimeRow, ButtonTime, ButtonOutline, BookingQuickInfo, Checkmark, ThankYou, Logo, Transition, Button, SubTitle, BookingTabs, BookingTab, GlobalWidgetStyle, SectionTitle, ExperienceOptionContainer, ExperienceOption, BookingQuickInfoTwo, BookingQuickInfoItem, ManageMyBookingLink, BackgroundImage, MainContentContainer, BackgroundImageMobile } from '../../booking.styles';
import BookingExperienceOption from './modules/BookingExperienceOption';
import ExperienceTab from './modules/ExperienceTab';
import ExperienceSelection from './modules/ExperienceSelection';
import BookingSummary from './modules/BookingSummary';
import PoweredBy from './modules/PoweredBy';
import BookingConfirmation from './modules/BookingConfirmation';
import PaymentForm from './modules/PaymentForm';
import CustomerDetails from './modules/CustomerDetails';
import SearchForm from './modules/SearchForm';

export const mobileSize = 6;
export const tabletSize = 3;
export const desktopSize = 3;
const TIMEFORMAT = 'h:mm A';
const ERROR = 'Something went wrong, please try again later.';
const ERRORMESSAGE = 'Sorry, we were unable to complete your booking, please try again.';
const AMENDERRORMESSAGE = 'Sorry, we were unable to amend your booking, please try again.';
const HOLDERRORMESSAGE = 'Sorry, we were unable to hold the selected time slot for your booking.';


export interface BookingFormData {
    guests: number;
    date: Moment;
    time: string;
    experienceId?: number;
}

function getClosestFifteenMinutes(): string {
    const start = businessNowTime();
    const remainder = 15 - (start.minute() % 15);
    const time = moment(start).add(remainder, "minutes");
    if (time.isBefore(businessNowTime())) {
        time.add(15, 'minutes')
    }
    return time.format("HH:mm");
}

function generatePeopleList(limit: number): DropdownItem[] {
    const list: DropdownItem[] = [];
    for (let i = 1; i <= limit; i++) {
        list.push({
            value: i.toString(),
            text: i === 1 ? '1 person' : `${i} people`
        })
    }
    list.push({
        value: 'MAX',
        text: `${(limit + 1)}+ people`
    })
    return list;
}

interface Props {
    previewLocation?: string;
    previewBusinessData?: ApiBusinessBookingDetails;
    defaultDateTime?: Moment;
    bookingHash?: string;
    bookingReference?: string;
    previousSpecialRequests?: string;
    previousOccasion?: SpecialOccasion;
    previousGuests?: number;
    previousExperience?: number;
    onUpdateBooking?: () => void;
}

const BookingModule = ({
    previewLocation,
    previewBusinessData,
    defaultDateTime,
    bookingHash,
    bookingReference,
    previousGuests,
    previousSpecialRequests,
    previousOccasion,
    previousExperience,
    onUpdateBooking
}: Props) => {
    const routeParams: any = useParams();
    const params = getQueryParams();
    const [loading, setLoading] = useState(true)
    const [nextAvailLoading, setNextAvailLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [booked, setBooked] = useState(false)
    const [appError, setAppError] = useState(false)
    const [noBusiness, setNoBusiness] = useState(false)
    const [details, setDetails] = useState<ApiMakeBookingRequest>({ specialRequests: previousSpecialRequests, specialOccasion: previousOccasion })
    const [selectedTimeDropdown, setSelectedTimeDropdown] = useState<string>(defaultDateTime ? '0001-01-01T' + defaultDateTime.format('HH:mm') + ':00' : undefined);
    const [selectedTime, setSelectedTime] = useState<string>();
    const [selectedTimeForExperienceSelection, setSelectedTimeForExperienceSelection] = useState<string>();
    const [bookingError, setBookingError] = useState<string>();
    const [bookingRef, setBookingRef] = useState<string>();
    const [bookingResponse, setBookingResponse] = useState<ApiMakeBookingResponse>();
    const [selectedTab, selectTab] = useState<number>(params.event ? 1 : 0);
    const [selectedExperience, setSelectedExperience] = useState<number>();
    const [bookingErrors, setBookingErrors] = useState<BookingError[]>([])
    const [business, setBusiness] = useState<ApiBusinessBookingDetails>();
    const businessRef = useRef<ApiBusinessBookingDetails>();
    const [holdDetails, setHoldDetails] = useState<HeldBookingResponse>();
    const [eventsAvailabilityResponse, setEventsAvailabilityResponse] = useState<EventsAvailabilityResponse>()
    const [searchableExperience, setSearchableExperience] = useState<number>();
    const [searchableEvent, setSearchableEvent] = useState<number>();
    const specialEventDates = useRef<Date[]>([])
    const [eventGuests, setEventGuests] = useState<number>(2);
    const [timer, setTimer] = useState<Moment>();
    const [times, setTimes] = useState<DateAndTimeSlots>();
    const [nextAvail, setNextAvail] = useState<ApiBookingTimesWidget>()
    const [formData, setFormData] = useState<BookingFormData>({
        guests: 2,
        date: defaultDateTime || businessNowTime(),
        time: defaultDateTime ? defaultDateTime.format(TIMEFORMAT) : getClosestFifteenMinutes()
    })
    const lastDateSearch = useRef<string>();
    const [paymentIntent, setPaymentIntent] = useState<string>();
    const selectedTimeRef = useRef<string>();
    const nextAlternative = useRef<boolean>();
    const [hasLoadedFirstData, setHasLoadedFirstData] = useState<boolean>(false);
    const maxPeople = useRef<number>();
    const availableTimes = useRef<BookingTimeSlots>();
    const [showExperienceSelection, setShowExperienceSelection] = useState<number[]>();
    const partyTooBig = formData.guests?.toString() == 'MAX' || (maxPeople.current ? +formData.guests > maxPeople.current : false);

    useEffect(() => {
        if (previewLocation) {
            availableTimes.current = {
                timeSlots: {
                    Monday: true,
                    Tuesday: true,
                    Wednesday: true,
                    Thursday: true,
                    Friday: true,
                    Saturday: true,
                    Sunday: true,
                }
            }
            maxPeople.current = 2;
            setBusiness(previewBusinessData)
            businessRef.current = previewBusinessData;
            setLoading(false)
            setLoaded(true)
            setTimes({
                date: formatDate(businessNowTime(), DATABASE_TIME_FORMAT),
                shiftLabelAndTimeSlots: {
                    'Lunch': {
                        slots: [
                            {
                                slot: '0001-01-01T14:00:00'
                            },
                            {
                                slot: '0001-01-01T14:15:00'
                            },
                            {
                                slot: '0001-01-01T14:30:00'
                            },
                            {
                                slot: '0001-01-01T14:45:00'
                            },
                            {
                                slot: '0001-01-01T15:00:00'
                            }
                        ]
                    },
                    'Dinner': {
                        slots: [
                            {
                                slot: '0001-01-01T18:00:00'
                            },
                            {
                                slot: '0001-01-01T18:15:00'
                            },
                            {
                                slot: '0001-01-01T18:30:00'
                            },
                            {
                                slot: '0001-01-01T18:45:00'
                            },
                            {
                                slot: '0001-01-01T19:00:00'
                            }
                        ]
                    }
                }
            })
            setHasLoadedFirstData(true)
        } else {
            if (isNullOrWhitespace(routeParams.location)) {
                pushEvent('bookingAppLoadError', { 'locationId': 'Not specified' })
                setAppError(true)
                return;
            }
            ApiService.weblocation.Getlocation__GET(routeParams.location, !!routeParams.ref).then(data => {
                if (isNullOrWhitespace(data?.name)) {
                    setNoBusiness(true)
                } else {
                    if (data.specialEventDates) {
                        specialEventDates.current = Object.keys(data.specialEventDates).map(x => createMomentFromValue(x).toDate())
                    }
                    moment.tz.setDefault(data.timeZone);
                    if (data.timeSlotsPerDay?.timeSlots) {
                        availableTimes.current = data.timeSlotsPerDay;
                        maxPeople.current = data.maximumBookableParty;
                        const todaysSlots = data.timeSlotsPerDay.timeSlots[createMomentFromValue(formData.date).format('dddd')];
                        if (!defaultDateTime && !todaysSlots) {
                            setFormData({
                                ...formData,
                                date: createMomentFromValue(data.firstAvailableDate) || null
                            })
                        } else if (!defaultDateTime && !!data.firstAvailableDate) {
                            setFormData({
                                ...formData,
                                date: createMomentFromValue(data.firstAvailableDate) || formData.date
                            })
                        }
                        setBusiness(data)
                        businessRef.current = data;
                        document.body.style.backgroundColor = data.theme.backgroundColour;
                        document.body.style.color = data.theme.textColour;
                        var css = `#pageContent { --ion-background-color: ${data.theme.backgroundColour} !important; }`,
                            head = document.head || document.getElementsByTagName('head')[0],
                            style = document.createElement('style');

                        head.appendChild(style);
                        // @ts-ignore
                        style.type = 'text/css';
                        // @ts-ignore
                        if (style.styleSheet) {
                            // This is required for IE8 and below.
                            // @ts-ignore
                            style.styleSheet.cssText = css;
                        } else {
                            style.appendChild(document.createTextNode(css));
                        }
                        if (isNullOrWhitespace(params.setup_intent) && isNullOrWhitespace(params.payment_intent)) {
                            if (data.specialEvents) {
                                if (params.event) {
                                    let peopleToSearchFor = 2;
                                    if (data.specialEvents[params.event].minimumPeople && data.specialEvents[params.event].minimumPeople > 2) {
                                        peopleToSearchFor = data.specialEvents[params.event].minimumPeople;
                                    }
                                    if (data.specialEvents[params.event].maximumPeople && data.specialEvents[params.event].maximumPeople < 2) {
                                        peopleToSearchFor = 1;
                                    }
                                    nextAvailSearch(+params.event, peopleToSearchFor)
                                }
                            }
                            if (defaultDateTime) {
                                dateChange(defaultDateTime, true, previousGuests);
                            } else {
                                dateChange(createMomentFromValue(data.firstAvailableDate) || formData.date, true);
                            }
                            setLoading(false)
                        } else {
                            const { previousFormData, previousDetails } = JSON.parse(sessionStorage.getItem('cd'));
                            setDetails(previousDetails);
                            setFormData(previousFormData);
                            const request: ApiMakeBookingRequest = {
                                ...previousDetails,
                                chargedCard: params.takeNow == 'true',
                                cardIntent: params.setup_intent || params.payment_intent,
                                selectedSlotInformation: {
                                    locationId: routeParams.location,
                                    holdReference: params.holdRef,
                                },
                            }
                            ApiService.makebooking.Book__POST(request).then(bookingResponse => {
                                window.history.replaceState(null, '', '/' + routeParams.location)
                                if (bookingResponse.success) {
                                    setBookingRef(bookingResponse.bookingReference);
                                    setBookingError(undefined);
                                    setBookingResponse(bookingResponse);
                                    setBooked(true);
                                    setSelectedTime(bookingResponse.bookingDateAndTime);
                                    setFormData({
                                        guests: bookingResponse.guests,
                                        date: createMomentFromValue(bookingResponse.bookingDateAndTime),
                                        time: ''
                                    })
                                    setLoading(false)
                                } else {
                                    setLoading(false)
                                    setBookingError(ERRORMESSAGE)
                                }

                                if (bookingResponse.errors) {
                                    setBookingErrors(bookingResponse.errors)
                                } else {
                                    setBookingErrors([])
                                }
                            }).catch(() => {
                                setLoading(false)
                                setBookingError(ERRORMESSAGE)
                            })
                        }
                    } else {
                        pushEvent('bookingAppLoadError', { 'locationId': routeParams.location })
                        setAppError(true)
                    }
                }
            }).catch((e) => {
                captureException(e);
                NotificationService.Error('Sorry, there has been an error loading the application.')
            })
        }
    }, [])

    useEffect(() => {
        if (hasLoadedFirstData) dateChange(formData.date)
    }, [formData.guests])

    const getApiBookingTimeRequest = (date: string | Moment | Date, guests?: number, experienceId?: number): ApiBookingTimesRequest => {
        return {
            requestedTime: formatDate(createMomentFromValue(date).startOf('day'), DATABASE_TIME_FORMAT),
            guests: guests || formData.guests,
            locationId: routeParams.location,
            bookingReference: isNullOrWhitespace(bookingReference) ? null : bookingReference,
            experienceId
        }
    }

    const nextAvailSearch = (experienceId: number = null, guestsOverride: number = null, isEventSearch: boolean = false) => {
        if (isNullOrWhitespace(previewLocation)) {
            if (partyTooBig) {
                setTimes({
                    date: '',
                    shiftLabelAndTimeSlots: {}
                })
            } else {
                let preventSearch = false;
                if (experienceId && (guestsOverride || eventGuests)) {
                    preventSearch = ((guestsOverride || eventGuests) > getMaximumPartyForEvent(experienceId) || (guestsOverride || eventGuests) < (businessRef.current?.specialEvents[experienceId]?.minimumPeople || 1));
                }
                if (guestsOverride) setEventGuests(guestsOverride);
                if (experienceId && selectedTab == 1) setSearchableEvent(experienceId);
                setBookingRef(undefined)
                setBookingError(undefined)
                releaseHold()
                if (preventSearch) {
                    setNextAvail({ availability: [] })
                    setLoaded(true)
                } else {
                    setNextAvailLoading(true)
                    ApiService.makebooking.GetNextAvailable__POST(getApiBookingTimeRequest(isEventSearch ? moment() : formData.date, guestsOverride, experienceId)).then((data) => {
                        setNextAvail(data)
                        setLoaded(true)
                        setNextAvailLoading(false)
                    }).catch((e) => {
                        captureException(e);
                        setLoaded(false);
                        setNextAvailLoading(false);
                        setBookingError(ERROR)
                    })
                }
            }
        }
    }

    const releaseHold = () => {
        const holdRef = sessionStorage.getItem('fhr');
        if (!isNullOrWhitespace(holdRef)) {
            ApiService.makebooking.ReleaseHold__POST(holdRef).finally(() => {
                sessionStorage.removeItem('fhr')
                setHoldDetails(undefined)
            })
        }
    }

    const amendBooking = () => {
        setLoading(true);

        if (!details.specialOccasion) {
            details.specialOccasion = SpecialOccasion.NotSet;
        }

        const request: PublicAmendBookingRequest = {
            newSpecialRequests: details.specialRequests,
            newSpecialOccasion: details.specialOccasion,
            locationId: routeParams.location,
            holdReference: holdDetails.holdReference,
            bookingReference: bookingReference,
            hash: bookingHash
        }
        ApiService.makebooking.AmendBooking__POST(request).then(bookingResponse => {
            if (bookingResponse.success) {
                setLoading(false);
                onUpdateBooking();
            } else {
                setLoading(false)
                if (isNullOrWhitespace(bookingResponse.info)) {
                    setBookingError(AMENDERRORMESSAGE)
                } else {
                    setBookingError(bookingResponse.info)
                }
            }
        }).catch(() => {
            setLoading(false)
            setBookingError(AMENDERRORMESSAGE)
        })
    }

    const confirmHold = (date: Moment = null, time: string = null) => {
        setBookingError(undefined);
        setBookingErrors(undefined);
        if (isNullOrWhitespace(previewLocation)) {
            setLoading(true)
            setBookingError(undefined)
            setBookingRef(undefined)
            releaseHold()
            const experienceToUse = selectedExperience && selectedExperience > 0 ? selectedExperience : searchableEvent;
            const request: ApiBookingTimesRequest = {
                requestedTime: formatDate(date || formData.date, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + (time || selectedTimeDropdown).split('T')[1],
                guests: selectedTab == 1 ? eventGuests : formData.guests,
                locationId: routeParams.location,
                bookingReference: bookingReference,
                experienceId: experienceToUse
            }
            if (searchableEvent) setSelectedExperience(searchableEvent)
            ApiService.makebooking.Hold__POST(request).then((holdResponse) => {
                if (holdResponse.success) {
                    setSelectedTime((time || selectedTimeDropdown))
                    selectedTimeRef.current = (time || selectedTimeDropdown);
                    sessionStorage.setItem('fhr', holdResponse.holdReference);
                    setTimer(moment().add(5, 'minutes'))
                    setHoldDetails(holdResponse)
                } else {
                    if (isNullOrWhitespace(holdResponse.error?.message)) {
                        setBookingError('Sorry, we were not able to hold the requested time. Please try again.')
                    } else {
                        setBookingError(holdResponse.error.message)
                    }
                    dateChange(date || formData.date)
                }
                setLoading(false)
                setLoaded(true)
            }).catch(() => {
                setLoading(false)
                setLoaded(true)
                setBookingError(HOLDERRORMESSAGE)
            })
        }
    }

    const hold = (date: Moment = null, time: string = null, slot?: BookingAvailabilityTimesWidget) => {
        setBookingError(undefined);
        let fullSelectedTime: TimeSlot = null;
        if (!slot) {
            Object.keys(times.shiftLabelAndTimeSlots).forEach(x => {
                const searchTime = times.shiftLabelAndTimeSlots[x].slots.find(x => x.slot === (time || selectedTimeDropdown))
                if (searchTime) fullSelectedTime = searchTime;
            })
        }
        if ((business?.specialEvents || business?.experiences) && !searchableEvent) {
            setLoading(true)
            const request: ApiBookingTimesRequest = {
                requestedTime: formatDate(date || formData.date, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + (time || selectedTimeDropdown).split('T')[1],
                guests: selectedTab == 1 ? eventGuests : formData.guests,
                locationId: routeParams.location,
                bookingReference: bookingReference,
            }
            ApiService.makebooking.GetEventAvailability__POST(request).then((response) => {
                if (!response.availableIds || Object.keys(response.availableIds).length == 0) {
                    confirmHold(date, time)
                } else {
                    if (response.availableIds[previousExperience]) {
                        setSelectedExperience(previousExperience)
                    }
                    setEventsAvailabilityResponse(response)
                    setLoading(false)
                    setShowExperienceSelection(slot ? [] : fullSelectedTime.eventIds)
                    if (searchableExperience && response.availableIds[searchableExperience]) {
                        setSelectedExperience(searchableExperience)
                    }
                    if (searchableEvent && response.availableIds[searchableEvent]) {
                        setSelectedExperience(searchableEvent)
                    }
                    setSelectedTimeForExperienceSelection((time || selectedTimeDropdown))
                }
            }).catch(() => {
                setLoading(false)
                setBookingError(ERROR)
            })
        } else {
            confirmHold(date, time)
        }
    }

    const takeClientDetails = () => {
        if (holdDetails?.depositRequired) {
            processPaymentIntent()
        } else {
            if (!!bookingHash) {
                amendBooking();
            } else {
                book()
            }
        }
    }

    const processPaymentIntent = () => {
        sessionStorage.setItem('cd', JSON.stringify({ previousFormData: formData, previousDetails: details }))
        setLoading(true);
        const request: ApiPaymentRequest = {
            holdClientInfoRequest: {
                ...details,
                holdReference: sessionStorage.getItem('fhr'),
            },
            apiBookingTimesRequest: {
                requestedTime: moment(formData.date).format('YYYY-MM-DD') + 'T' + selectedTime.split('T')[1],
                guests: formData.guests,
                locationId: routeParams.location
            }
        }
        const apiCall = holdDetails?.takeDepositNow ? ApiService.makebooking.GetClientPaymentIntent__POST(request) : ApiService.makebooking.GetClientSetupIntent__POST(request);
        apiCall.then((holdResponse) => {
            if (holdResponse.success) {
                setPaymentIntent(holdResponse.info)
            } else {
                // handle error
            }
        })
    }

    const book = () => {
        setLoading(true);
        const customFields: { [key: string]: any } = details;
        let customFieldList: string[] = [];
        Object.keys(customFields).forEach(key => {
            if (key.indexOf('Custom-') > -1) {
                const field = business.customFields[+key.split('-')[1]]
                if (field) {
                    customFieldList.push(`${field.id}~~~${customFields[key]}`)
                }
            }
        })
        const request: ApiMakeBookingRequest = {
            ...details,
            customFields: customFieldList.join('|||'),
            selectedSlotInformation: {
                requestedTime: moment(formData.date).format('YYYY-MM-DD') + 'T' + selectedTime.split('T')[1],
                guests: formData.guests,
                locationId: routeParams.location,
                holdReference: sessionStorage.getItem('fhr'),
            },
        }
        ApiService.makebooking.Book__POST(request).then(bookingResponse => {
            if (bookingResponse.success) {
                setBookingRef(bookingResponse.bookingReference);
                setBookingResponse(bookingResponse);
                setBookingError(undefined);
                setBooked(true);
            } else {
                setLoading(false)
                setBookingError(ERRORMESSAGE)
            }

            if (bookingResponse.errors) {
                setBookingErrors(bookingResponse.errors)
            } else {
                setBookingErrors([])
            }
        }).catch(() => {
            setLoading(false)
            setBookingError(ERRORMESSAGE)
        })
    }

    const experienceTypeShouldShow = (business: ApiBusinessBookingDetails): boolean => {
        return (!!business?.specialEvents && Object.keys(business?.specialEvents)?.length > 0) ||
            (!!business?.experiences && Object.keys(business?.experiences)?.length > 0);
    }

    const getSelectedExperienceName = (selectedExperience: number, business: ApiBusinessBookingDetails): string => {
        if (!!selectedExperience && business.experiences[selectedExperience]) {
            return business.experiences[selectedExperience].name
        } else if (!!selectedExperience && business.specialEvents[selectedExperience]) {
            return business.specialEvents[selectedExperience].name;
        } else {
            return isNullOrWhitespace(business?.standardBookingName) ? 'Standard booking' : business?.standardBookingName;
        }
    }

    const dateChange = (date: Date | Moment, firstLoad?: boolean, guests?: number, experienceId?: number, noExperience?: boolean) => {
        const formattedTime = '0001-01-01T' + formatDate(date, 'HH:mm') + ':00';
        if (!firstLoad && formatDate(date, DATEONLYFORMAT) == formatDate(formData.date, DATEONLYFORMAT) && guests == formData.guests && searchableExperience === experienceId) return;
        const formattedLastSearch = formatDate(date, DATEONLYFORMAT) + '-' + (guests || formData.guests) + '-' + (noExperience ? '0' : (experienceId || searchableExperience) ? (experienceId || searchableExperience) : '0');
        if (!previewLocation && (guests || formData.guests) && (guests || formData.guests)?.toString() !== 'MAX' && lastDateSearch.current != formattedLastSearch) {
            const request = getApiBookingTimeRequest(date, (guests || formData.guests), noExperience ? undefined : (experienceId || searchableExperience))
            lastDateSearch.current = formattedLastSearch;
            ApiService.makebooking.GetForDate__POST(request).then((response) => {
                setTimes(response);
                setFormData({ ...formData, date: createMomentFromValue(date), guests: guests || formData.guests })
                if (!hasLoadedFirstData) setHasLoadedFirstData(true);
                if (!firstLoad && !bookingReference) {
                    setSelectedTimeDropdown('');
                } else if (guests) {
                    setSelectedTimeDropdown(formattedTime);
                }
            })
        }
    }

    const onUpdateSearchableExperience = (experienceId?: number) => {
        if (nextAvail) {
            nextAvailSearch(experienceId)
        } else {
            dateChange(formData.date, false, formData.guests, experienceId, !experienceId);
        }
        setSearchableExperience(experienceId);
    }

    const updateSelectedExperience = (experienceId?: number) => {
        setBookingError(undefined);
        setSelectedExperience(experienceId)
    }

    const changeTab = (index: number) => {
        if (searchableEvent) setSearchableEvent(undefined)
        if (nextAvail) setNextAvail(undefined)
        selectTab(index)
    }

    const getMaximumPartyForEvent = (eventId: number) => {
        const event = businessRef.current.specialEvents[eventId] || businessRef.current.experiences[eventId];

        if (businessRef.current.maximumBookableParty) {
            if (event) {
                return event.maximumPeople > 99 ? 99 : event.maximumPeople;
            } else {
                return businessRef.current.maximumBookableParty;
            }
        } else if (event && event.maximumPeople > 99) {
            return 99;
        }
        if (businessRef.current.maximumBookableParty) return businessRef.current.maximumBookableParty;
        return 20;
    }

    if (appError) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <InfoMessage widgetTheme={business?.theme}>
                Sorry, we encountered an error and are unable to load the booking application. Please reload and try again.
            </InfoMessage>
        </BookingModuleContainer>
    )

    if (noBusiness) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <InfoMessage widgetTheme={business?.theme}>
                Sorry, we could not find the specified business.
            </InfoMessage>
        </BookingModuleContainer>
    )

    if (!hasLoadedFirstData) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <Loader />
        </BookingModuleContainer>
    )

    const specialOccasionOptions: DropdownItem[] = [];

    if (!isNullOrWhitespace(business?.specialOccasions)) {
        const occasionSplit = business?.specialOccasions.split('|');
        Object.keys(BookingSpecialOccasions()).forEach(occasion => {
            if (occasionSplit.indexOf(occasion) > -1) specialOccasionOptions.push({ value: occasion, text: BookingSpecialOccasions()[occasion].label });
        });
    }

    let hasTimeSlots = false;
    let nextAvailHasMultipleShifts = nextAvail?.availability?.find(date => Object.keys(date.shiftLabelAndTimes).length > 1)
    let timeOptions: DropdownItem[] = [];
    const eventBreaksGuestRules = searchableEvent && (eventGuests > getMaximumPartyForEvent(searchableEvent) || eventGuests < (business.specialEvents[searchableEvent]?.minimumPeople || 1));

    if (times) Object.keys(times.shiftLabelAndTimeSlots).forEach((label, index) => {
        if (times.shiftLabelAndTimeSlots[label].slots.length > 0) hasTimeSlots = true;
        times.shiftLabelAndTimeSlots[label].slots.forEach(time => {
            timeOptions.push({
                value: time.slot,
                text: moment(time.slot).format(TIMEFORMAT) + (time.eventIds?.length > 0 ? '*' : '') + (time.eventOnly ? ' (event only)' : ''),
                group: Object.keys(times.shiftLabelAndTimeSlots).length > 1 ? label : undefined
            })
        });
    });

    return (
        <>
            <GlobalWidgetStyle widgetTheme={business?.theme} />
            {isNullOrWhitespace(bookingHash) && <BackgroundImage imageUrl={business?.imageUrl} />}
            <MainContentContainer>
                {/* <BackgroundImageMobile imageUrl={business?.imageUrl} /> */}
                <BookingModuleContainer widgetTheme={business?.theme} hideBorder={!isNullOrWhitespace(bookingHash)}>
                    {!business && <Loader />}
                    {business &&
                        <>
                            {isNullOrWhitespace(bookingHash) && <>
                                <Title widgetTheme={business?.theme}>{business.name}</Title>
                                <hr />
                            </>}
                            {!booked && !loading && !!times && ((!!selectedTime && loaded) || showExperienceSelection) && !!!bookingRef &&
                                <ButtonBack
                                    style={{ marginTop: '0.5rem' }}
                                    widgetTheme={business?.theme}
                                    type='button'
                                    onClick={() => { setSelectedTime(undefined); setSelectedExperience(undefined); setShowExperienceSelection(undefined); selectedTimeRef.current = undefined; setBookingError(undefined); setBookingErrors([]); releaseHold(); }}>
                                    <Icon name='arrow-left' duo doNotStyleDuo /> Back</ButtonBack>
                            }
                            {!bookingError && !loading && loaded && times && selectedTime && !bookingRef && timer &&
                                <Timer time={timer} business={business} />
                            }
                            {!booked && isNullOrWhitespace(paymentIntent) &&
                                <TransitionWrapper>
                                    <Transition active={!!selectedTime}>
                                        <YourDetails style={selectedTime && !showExperienceSelection ? { maxHeight: '2rem', overflowY: 'hidden' } : undefined}>
                                            {!showExperienceSelection &&
                                                <InfoForm>
                                                    {((business?.specialEvents && Object.keys(business?.specialEvents).length > 0) || (business?.experiences && Object.keys(business?.experiences).length > 0)) &&
                                                        <BookingTabs>
                                                            <BookingTab widgetTheme={business?.theme} selected={selectedTab == 0} onClick={() => changeTab(0)}><Icon name='calendar' duo doNotStyleDuo /> Book</BookingTab>
                                                            <BookingTab widgetTheme={business?.theme} selected={selectedTab == 1} onClick={() => changeTab(1)}><Icon name='sparkles' duo doNotStyleDuo /> Explore</BookingTab>
                                                        </BookingTabs>
                                                    }
                                                    {selectedTab == 1 &&
                                                        <ExperienceTab
                                                            nextAvail={nextAvail}
                                                            nextAvailLoading={nextAvailLoading}
                                                            business={business}
                                                            eventGuests={eventGuests}
                                                            previewLocation={previewLocation}
                                                            loading={loading}
                                                            searchableEvent={searchableEvent}
                                                            eventBreaksGuestRules={eventBreaksGuestRules}
                                                            nextAvailHasMultipleShifts={nextAvailHasMultipleShifts}
                                                            formData={formData}
                                                            generatePeopleList={generatePeopleList}
                                                            setEventGuests={setEventGuests}
                                                            nextAvailSearch={nextAvailSearch}
                                                            setFormData={setFormData}
                                                            setSelectedTimeDropdown={setSelectedTimeDropdown}
                                                            hold={hold}
                                                            setNextAvail={setNextAvail}
                                                            getMaximumPartyForEvent={getMaximumPartyForEvent}
                                                        />
                                                    }
                                                    <div style={selectedTab == 1 ? { opacity: 0, height: 0, overflow: 'hidden' } : undefined}>
                                                        <SearchForm
                                                            business={business}
                                                            loading={loading}
                                                            formData={formData}
                                                            nextAvail={nextAvail}
                                                            partyTooBig={partyTooBig}
                                                            searchableExperience={searchableExperience}
                                                            nextAvailLoading={nextAvailLoading}
                                                            bookingReference={bookingReference}
                                                            specialEventDates={specialEventDates}
                                                            nextAvailHasMultipleShifts={nextAvailHasMultipleShifts}
                                                            hasTimeSlots={hasTimeSlots}
                                                            selectedTimeDropdown={selectedTimeDropdown}
                                                            bookingError={bookingError}
                                                            bookingRef={bookingRef}
                                                            timeOptions={timeOptions}
                                                            previewLocation={previewLocation}
                                                            availableTimes={availableTimes}
                                                            setFormData={setFormData}
                                                            generatePeopleList={generatePeopleList}
                                                            onUpdateSearchableExperience={onUpdateSearchableExperience}
                                                            setNextAvail={setNextAvail}
                                                            setSelectedTimeDropdown={setSelectedTimeDropdown}
                                                            hold={hold}
                                                            nextAvailSearch={nextAvailSearch}
                                                            dateChange={dateChange}
                                                        />
                                                    </div>
                                                </InfoForm>
                                            }
                                            {showExperienceSelection &&
                                                <ExperienceSelection
                                                    business={business}
                                                    formData={formData}
                                                    loading={loading}
                                                    selectedTimeForExperienceSelection={selectedTimeForExperienceSelection}
                                                    eventsAvailabilityResponse={eventsAvailabilityResponse}
                                                    selectedExperience={selectedExperience}
                                                    bookingError={bookingError}
                                                    updateSelectedExperience={updateSelectedExperience}
                                                    confirmHold={confirmHold}
                                                />
                                            }
                                        </YourDetails>
                                        {loading && selectedTime &&
                                            <YourDetails>
                                                <Loader />
                                            </YourDetails>
                                        }

                                        {!loading && loaded && times && !false &&
                                            <YourDetails>
                                                <BookingSummary
                                                    business={business}
                                                    formData={formData}
                                                    selectedTime={selectedTime}
                                                    selectedExperience={selectedExperience}
                                                    experienceTypeShouldShow={experienceTypeShouldShow}
                                                    getSelectedExperienceName={getSelectedExperienceName}
                                                />
                                                <CustomerDetails
                                                    business={business}
                                                    bookingErrors={bookingErrors}
                                                    bookingHash={bookingHash}
                                                    selectedTime={selectedTime}
                                                    specialOccasionOptions={specialOccasionOptions}
                                                    details={details}
                                                    holdDetails={holdDetails}
                                                    bookingError={bookingError}
                                                    setDetails={setDetails}
                                                    takeClientDetails={takeClientDetails}
                                                />
                                                <div style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
                                                    **By selecting “{isNullOrWhitespace(business.reserveButtonTextStepTwo) ? 'Complete booking' : business.reserveButtonTextStepTwo}” you are agreeing to the terms and conditions of the Dish Forager User Agreement and Privacy Policy.
                                                </div>
                                            </YourDetails>
                                        }
                                    </Transition>
                                </TransitionWrapper>
                            }
                            {!isNullOrWhitespace(paymentIntent) &&
                                <PaymentForm
                                    business={business}
                                    holdDetails={holdDetails}
                                    paymentIntent={paymentIntent}
                                />
                            }
                            {(booked) &&
                                <BookingConfirmation
                                    business={business}
                                    bookingResponse={bookingResponse}
                                    formData={formData}
                                    selectedTime={selectedTime}
                                    selectedExperience={selectedExperience}
                                    experienceTypeShouldShow={experienceTypeShouldShow}
                                    getSelectedExperienceName={getSelectedExperienceName}
                                />
                            }
                            {!bookingHash &&
                                <PoweredBy business={business} />
                            }
                        </>
                    }
                </BookingModuleContainer>
            </MainContentContainer>
        </>
    );
};


const Timer = ({ time, business }: { time: Moment, business: any }) => {
    const timeRef = useRef(time)
    const [secondsRemaining, setSecondsRemaining] = useState(timeRef.current.diff(moment(), 'seconds'))
    const timeLeft = secondsToTime(secondsRemaining);

    if (secondsRemaining > 0) setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
    }, 1000);

    if (secondsRemaining < 1) return (
        <>
            <InfoMessage widgetTheme={business?.theme}>
                Your held slot has timed out. You can still try and book but we cannot guarantee your booking.
            </InfoMessage>
            <br />
        </>
    )

    return (
        <>
            <InfoMessage widgetTheme={business?.theme}>
                Booking is held for <strong>{timeLeft.m}:{(timeLeft.s < 10 ? '0' : '') + timeLeft.s} minutes</strong>
            </InfoMessage>
            <br />
        </>
    );
};

export default BookingModule;
