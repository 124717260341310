import React from 'react';
import { CoreView } from '../shared/CoreModules/coreComponents';

interface ComponentProps {
    children: React.ReactNode;
    marginTop?: boolean;
}

const Row = ({ children, marginTop }: ComponentProps) => {
    return (
        <CoreView style={[{ margin: "auto", flexDirection: "row", justifyContent: "flex-start", display: 'flex' }, marginTop ? { marginTop: 8 } : {}]}>
            {children}
        </CoreView>
    );
};

export default Row;