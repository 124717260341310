import React, { useRef, useState } from 'react';
import { ApiMenu, ApiPageComponent, GetPageResponse } from '../../../../../../api/api-definitions';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { StyledDropdown, StyledNumberInput, StyledTextInput, StyledTextarea } from '../../../../../../theme/input.styles';
import styled from 'styled-components';
import RichTextEdior from '../../../../../../components/Forms/RichTextEditor';
import { cloneDeep } from 'lodash';
import CoreButton from '../../../../../../components/Forms/Button';
import { BoxShadowStyleWithHover } from '../../../../../../theme';
import { BaseLabelStyle } from '../../../../../../theme/input.core.styles';
import { Constants } from '../../../../../../constants';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import NoImage from '../../../../../../components/Media/NoImage';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import ImageSelector from '../../../../../../components/Forms/ImageSelector';

interface ComponentProps {
  component: ApiPageComponent;
  pageInfo: GetPageResponse;
  menus: ApiMenu[];
  onClose: () => void;
  updateData: (component: ApiPageComponent[]) => void;
}

const ComponentPropertyContainer = styled.div`
  margin-bottom: 1rem;
`

const ImagePreview = styled.div<{ image: string }>`
  background-image: url('${props => props.image}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 200px;
  height: 200px;
  ${BoxShadowStyleWithHover}
`

const ComponentPropertiesModal = ({ component, pageInfo, menus, onClose, updateData }: ComponentProps) => {
  const [componentToEdit, setComponentToEdit] = useState<ApiPageComponent>(component);
  const compoentName = useRef<string>(pageInfo.availableComponents.find(x => x.rowKey === component.componentId).name);
  const editableProperties = component.properties.filter(property => property.type !== 'placeholder');

  const onConfirm = () => {
    const newComponents = cloneDeep(pageInfo.activeComponents);
    newComponents.find(x => x.rowKey === component.rowKey).values = cloneDeep(componentToEdit.values);
    updateData(newComponents)
    closeModal();
  }

  const updateValue = (valueId: string, value: string | boolean) => {
    const newComponent = cloneDeep(componentToEdit);
    newComponent.values.find(x => x.rowKey === valueId).value = value.toString();
    setComponentToEdit(newComponent);
  }

  const closeModal = () => {
    onClose();
  }

  return (
    <>
      <CoreModal onClose={closeModal} title={compoentName.current} actionBar={<CoreButton onClick={onConfirm}>Confirm</CoreButton>}>
        {editableProperties.map((property) => {
          const value = componentToEdit.values.find(x => x.propertyId === property.rowKey);
          return (
            <ComponentPropertyContainer>
              {property.type == 'text' &&
                <StyledTextInput value={value.value} unlink label={property.name} onChange={(e) => updateValue(value.rowKey, e.target.value)} />
              }
              {property.type == 'checkbox' &&
                <Checkbox asToggle checked={value.value} unlink label={property.name} inputName={property.rowKey} onChange={(e) => updateValue(value.rowKey, e.target.checked)} />
              }
              {property.type == 'number' &&
                <StyledNumberInput value={value.value} unlink label={property.name} hasPlusMinus onChange={(e) => updateValue(value.rowKey, e.target.value)} />
              }
              {property.type == 'textarea' &&
                <StyledTextarea value={value.value} unlink label={property.name} onChange={(e) => updateValue(value.rowKey, e.target.value)} />
              }
              {property.type == 'menu' &&
                <StyledDropdown value={value.value} unlink label={property.name} items={menus.map(x => ({ value: x.rowKey, text: x.name }))} onChange={(e) => updateValue(value.rowKey, e.target.value)} />
              }
              {property.type == 'richtext' &&
                <RichTextEdior value={value.value} unlink label={property.name} onChange={(e) => updateValue(value.rowKey, e)} />
              }
              {property.type == 'image' &&
                <ImageSelector label={property.name} value={value.value} onChange={(image) => updateValue(value.rowKey, image)} />
              }
              {property.type == 'multiImage' &&
                <>
                  TODO
                </>
              }
            </ComponentPropertyContainer>
          )
        })}
      </CoreModal>
    </>
  );
};

export default ComponentPropertiesModal;