import { Link, useParams } from 'react-router-dom';
import Icon from '../../../../components/Media/Icon';
import useBusinessList from '../../../../hooks/useBusinessList';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { BusinessSidebar } from '../../sideBars';
import { NavBarTitle } from './';
import useOffline from '../../../../hooks/useOffline';

interface ComponentProps {
    toggleOpen: () => void;
    parentId: string;
}

const ParentNav = ({ toggleOpen, parentId }: ComponentProps) => {
    const offline = useOffline();
    const [loaded, data] = useBusinessList(parentId);

    if (!loaded) return null;
  
    const business = data && data.length > 0 ? data[0] : null;

    if (!business) return null;

    return (

        <>
            <NavBarTitle>{business.name}</NavBarTitle>
            {BusinessSidebar.filter(x => isNullOrWhitespace(x.category) && (offline ? x.offline : true)).map((link, index) => (
                <Link key={`parentNav-${index}`} to={`/dashboard/${parentId}/${link.link}`} onClick={toggleOpen}><Icon name={link.icon} /> {link.name}</Link>
            ))}
        </>
    );
};

export default ParentNav;
