import React from 'react';
import { ApiPage } from '../../../../../../api/api-definitions';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledTextInput, StyledTextarea } from '../../../../../../theme/input.styles';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import DashboardPanel from '../../../../../../components/Dashboard/Panel';
import { DATABASE_TIME_FORMAT, DISPLAY_DATE_TIME_FORMAT, formatDate } from '../../../../../../utils/date-helpers';

interface ComponentProps {
    page: ApiPage;
    updatePage: (page: ApiPage) => void;
}

const PageSettings = ({ page, updatePage }: ComponentProps) => {
    return (
        <div>
            <DashboardPanel title='General settings'>
                <Row>
                    <Column size={4}>
                        <StyledTextInput label='Page name' value={page.name} onChange={(e) => updatePage({ ...page, name: e.target.value })} />
                    </Column>
                    <Column size={4}>
                        <StyledTextInput label='Page url' value={page.url} onChange={(e) => updatePage({ ...page, url: e.target.value })} />
                    </Column>
                    <Column size={4} checkboxInput>
                        <Checkbox asToggle label='Published' inputName='published' checked={page.published} onChange={(e) => updatePage({ ...page, published: e.target.checked })} />
                    </Column>
                </Row>
            </DashboardPanel>
            <DashboardPanel title='Metadata'>
                <Row>
                    <Column size={4}>
                        <StyledTextInput label='Meta title' value={page.metaTitle} onChange={(e) => updatePage({ ...page, metaTitle: e.target.value })} />
                    </Column>
                    <Column size={4}>
                        <StyledTextarea label='Meta description' value={page.metaDescription} onChange={(e) => updatePage({ ...page, metaDescription: e.target.value })} />
                    </Column>
                </Row>
            </DashboardPanel>
            <DashboardPanel title='Page information'>
                <Row>
                    <Column size={4}>
                        <StyledTextInput label='Created' disabled value={formatDate(page.created, DISPLAY_DATE_TIME_FORMAT)} />
                    </Column>
                    <Column size={4}>
                        <StyledTextInput label='Updated' disabled value={formatDate(page.updated, DISPLAY_DATE_TIME_FORMAT)} />
                    </Column>
                </Row>
            </DashboardPanel>
        </div>
    );
};

export default PageSettings;