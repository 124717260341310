import React, { useEffect, useRef } from 'react';
import { DashboardPage } from '../../../../DashboardLayout';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import { useParams } from 'react-router';
import { ApiService } from '../../../../../../api/api-connectors';
import { ApiComponent, ApiComponentProperty, ApiMenu, ApiMenuListResponse, ApiPage, ApiPageComponent, ApiTag, GetPageResponse } from '../../../../../../api/api-definitions';
import Loader from '../../../../../../components/Layout/Loader';
import Page from './page';
import { PageEditorGlobalStyle } from './page/lagacy-page.style';
import { TabBar, TabButton } from '../../../../../../components/Layout/Tabs';
import ComponentItem from './componentItem';
import ComponentPropertiesModal from './componentPropertiesModal';
import DeleteComponentModal from './deleteComponentModal';
import { cloneDeep } from 'lodash';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import AddComponentModal from './addComponentModal';
import { MENUBUILDER, SAMPLE } from './sampleJson';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import { NotificationService } from '../../../../../../services/NotificationService';
import { createUUID, moveInArray } from '../../../../../../utils/data-helpers';
import { FloatingActionBar } from '../../../../../../components/Layout/FloatingActionBar';
import CoreButton from '../../../../../../components/Forms/Button';
import styled from 'styled-components';
import PageSettings from './pageSettings';
import moment from 'moment';
import { DATABASE_TIME_FORMAT } from '../../../../../../utils/date-helpers';

function generateCategories(data: ApiComponent[]) {
    let categories = [];
    data.map(component => {
        if (!categories.includes(component.category)) {
            categories.push(component.category);
        }
    });
    return categories;
}

interface AddComponentInfo {
    addAtIndex: number;
    placeholderId?: string;
    parentId?: string;
}

export function getPlaceHolderArray(component: ApiPageComponent, placeholderItem: ApiComponentProperty, pageInfo: GetPageResponse): ApiPageComponent[] {
    const placeholders = component.values.find(x => x.propertyId === placeholderItem.rowKey).value.split('|');
    const componentsToRender: ApiPageComponent[] = [];
    placeholders.forEach(item => {
        const foundItem = pageInfo.activeComponents.find(x => x.rowKey === item);
        if (foundItem) componentsToRender.push(pageInfo.activeComponents.find(x => x.rowKey === item));
    });
    return componentsToRender;
}

const CmsEditPage = () => {
    const params: any = useParams();
    const [pageInfo, setPageInfo] = React.useState<GetPageResponse>();
    const oldPageInfo = useRef<ApiPage>();
    const originalComponents = useRef<ApiPageComponent[]>();
    const [categoryList, setCategoryList] = React.useState<string[]>();
    const [componentToEdit, setComponentToEdit] = React.useState<ApiPageComponent>();
    const [componentToDelete, setComponentToDelete] = React.useState<ApiPageComponent>();
    const [addComponentInfo, setAddComponentInfo] = React.useState<AddComponentInfo>();
    const [menus, setMenus] = React.useState<ApiMenuListResponse>({ menus: [] });
    const [expandedItems, setExpandedItems] = React.useState<{ [rowKey: string]: boolean }>({})
    const [tab, setTab] = React.useState<0 | 1>(0);
    const hasChanges = pageInfo && (JSON.stringify(pageInfo.activeComponents) != JSON.stringify(originalComponents.current) || JSON.stringify(pageInfo.pageInfo) != JSON.stringify(oldPageInfo.current));

    useEffect(() => {
        load()
        setMenus(MENUBUILDER)
        // ApiService.menuBuilderApi.List__GET(params.parentId).then(setMenus);
    }, [])

    const cancelChanges = () => {
        const newPageInfo = cloneDeep(pageInfo);
        newPageInfo.activeComponents = cloneDeep(originalComponents.current);
        setPageInfo(newPageInfo)
    }

    const load = () => {
        setPageInfo(SAMPLE)
        setCategoryList(generateCategories(SAMPLE.availableComponents))
        // ApiService.page.Get__GET(params.page, params.parentId).then((response) => {
        //     oldPageInfo.current = response.pageInfo;
        //     setPageInfo(response);
        //     originalComponents.current = cloneDeep(response.activeComponents)
        //     setCategoryList(generateCategories(response.availableComponents))
        // })
    }

    const onComponentMove = (item: ApiPageComponent, increment: boolean, placeholderId?: string) => {
        const newPageInfo = cloneDeep(pageInfo);

        if (isNullOrWhitespace(item.parent) || !placeholderId) {
            const baseComponents = newPageInfo.activeComponents.filter(x => isNullOrWhitespace(x.parent));
            const childComponents = newPageInfo.activeComponents.filter(x => !isNullOrWhitespace(x.parent));
            const currentIndex = baseComponents.findIndex(component => component.rowKey === item.rowKey);
            if (currentIndex === -1) return;
            const newIndex = increment ? currentIndex - 1 : currentIndex + 1;
            moveInArray(baseComponents, currentIndex, newIndex);
            baseComponents.forEach((x, index) => x.order = index);
            newPageInfo.activeComponents = baseComponents.concat(childComponents);
        } else {
            const parentIndex = newPageInfo.activeComponents.findIndex(component => component.rowKey === item.parent);
            const placeholder = newPageInfo.activeComponents[parentIndex].values.find(x => x.propertyId === placeholderId);
            const placeholderArray = placeholder.value.split('|');
            const currentIndex = placeholderArray.indexOf(item.rowKey);
            if (currentIndex === -1) return;
            const newIndex = increment ? currentIndex - 1 : currentIndex + 1;
            moveInArray(placeholderArray, currentIndex, newIndex);
            placeholder.value = placeholderArray.join('|');
        }
        setPageInfo(newPageInfo);
    }

    const onComponentDrop = (
        draggedComponent: ApiPageComponent,
        targetComponent: ApiPageComponent,
        position: 'top' | 'bottom' | 'empty',
        oldPlaceholderId?: string,
        newPlaceholderId?: string
    ) => {
        if (draggedComponent.rowKey === targetComponent.rowKey) return;
        const newPageInfo = cloneDeep(pageInfo);
        newPageInfo.activeComponents = newPageInfo.activeComponents.filter(x => x.rowKey !== draggedComponent.rowKey);

        if (!isNullOrWhitespace(oldPlaceholderId)) {
            const oldParent = newPageInfo.activeComponents.find(x => x.rowKey === draggedComponent.parent);
            const oldPlaceholderItem = oldParent.values.find(x => x.propertyId === oldPlaceholderId);
            const oldPlaceholderArray = oldPlaceholderItem.value.split('|');
            oldPlaceholderItem.value = oldPlaceholderArray.filter(x => x !== draggedComponent.rowKey).join('|');
            draggedComponent.parent = '';
        }

        if (isNullOrWhitespace(newPlaceholderId)) {
            const baseComponents = newPageInfo.activeComponents.filter(x => isNullOrWhitespace(x.parent));
            const childComponents = newPageInfo.activeComponents.filter(x => !isNullOrWhitespace(x.parent));
            const targetIndex = baseComponents.findIndex(x => x.rowKey === targetComponent.rowKey);
            baseComponents.splice(position === 'top' ? targetIndex : targetIndex + 1, 0, draggedComponent);
            baseComponents.forEach((x, index) => x.order = index);
            newPageInfo.activeComponents = baseComponents.concat(childComponents);
        } else {
            const newParent = newPageInfo.activeComponents.find(x => x.rowKey === (position == 'empty' ? targetComponent.rowKey : targetComponent.parent));
            const newPlaceholderItem = newParent.values.find(x => x.propertyId === newPlaceholderId);
            const newPlaceholderArray = newPlaceholderItem.value.split('|');
            const targetIndex = newPlaceholderArray.findIndex(x => x == targetComponent.rowKey);
            newPlaceholderArray.splice(position === 'top' ? targetIndex : targetIndex + 1, 0, draggedComponent.rowKey);
            newPlaceholderItem.value = newPlaceholderArray.join('|');
            draggedComponent.parent = position == 'empty' ? targetComponent.rowKey : targetComponent.parent;
            newPageInfo.activeComponents.push(draggedComponent)
        }

        setPageInfo(newPageInfo)
    }

    const onComponentAdd = (component: ApiPageComponent) => {
        const newPageInfo = cloneDeep(pageInfo);
        component.rowKey = createUUID();
        if (isNullOrWhitespace(addComponentInfo.parentId)) {
            component.order = pageInfo.activeComponents.filter(x => isNullOrWhitespace(x.parent)).length;
            newPageInfo.activeComponents.splice(addComponentInfo.addAtIndex + 1, 0, component);
        } else {
            const parentComponent = newPageInfo.activeComponents.find(x => x.rowKey == addComponentInfo.parentId);
            const valueItem = parentComponent.values.find(x => x.propertyId === addComponentInfo.placeholderId);
            const values = valueItem.value.split('|');
            component.order = values.length;
            component.parent = addComponentInfo.parentId;
            values.splice(addComponentInfo.addAtIndex + 1, 0, component.rowKey);
            valueItem.value = values.join('|');
            newPageInfo.activeComponents.push(component);
        }
        if (component.properties.filter(x => x.type !== 'placeholder').length > 0) {
            setComponentToEdit(component);
        }
        setPageInfo(newPageInfo)
        setAddComponentInfo(undefined)
    }

    const updateActiveAcomponents = (components: ApiPageComponent[]) => {
        const newPageInfo = cloneDeep(pageInfo);
        newPageInfo.activeComponents = components;
        setPageInfo(newPageInfo)
    }

    const onSave = () => {
        const request = cloneDeep(pageInfo.pageInfo);
        request.components = cloneDeep(pageInfo.activeComponents);
        ApiService.page.Save__POST(request).then((response) => {
            request.updated = moment().format(DATABASE_TIME_FORMAT);
            setPageInfo({ ...pageInfo, pageInfo: request })
            oldPageInfo.current = request;
            originalComponents.current = request.components;
            NotificationService.Confirm('Page saved')
        })
    }

    return (
        <>
            <PageEditorGlobalStyle />
            <DashboardHeader title='Edit page' icon='browser' />
            {componentToEdit &&
                <ComponentPropertiesModal
                    menus={menus.menus}
                    component={componentToEdit}
                    pageInfo={pageInfo}
                    onClose={() => setComponentToEdit(undefined)}
                    updateData={updateActiveAcomponents}
                />
            }
            {componentToDelete &&
                <DeleteComponentModal
                    component={componentToDelete}
                    componentName={pageInfo.availableComponents.find(x => x.rowKey === componentToDelete.componentId).name}
                    componentList={pageInfo.activeComponents}
                    closeModal={() => setComponentToDelete(undefined)}
                    updateData={updateActiveAcomponents}
                />
            }
            {addComponentInfo &&
                <AddComponentModal
                    categoryList={categoryList}
                    components={pageInfo.availableComponents}
                    addComponent={onComponentAdd}
                    closeModal={() => setAddComponentInfo(undefined)}
                />
            }
            <TabBar>
                <TabButton active={tab == 0} onClick={() => setTab(0)}>
                    Active components
                </TabButton>
                <TabButton active={tab == 1} onClick={() => setTab(1)}>
                    Page settings
                </TabButton>
            </TabBar>
            {pageInfo && tab == 0 &&
                <>
                    {pageInfo.activeComponents.map((component, index) => component.parent ? null : (
                        <ComponentItem
                            key={component.rowKey}
                            pageInfo={pageInfo}
                            component={component}
                            editComponent={setComponentToEdit}
                            deleteComponent={setComponentToDelete}
                            onComponentMove={onComponentMove}
                            onComponentDrop={onComponentDrop}
                            setExpandedItems={setExpandedItems}
                            expandedItems={expandedItems}
                            onAddClick={(addAtIndex, placeholderId, parentId) => setAddComponentInfo({ addAtIndex, placeholderId, parentId })}
                            isFirst={index === 0}
                            isLast={index === pageInfo.activeComponents.length - 1}
                        />
                    ))}
                    <AddButton onClick={() => setAddComponentInfo({ addAtIndex: pageInfo.activeComponents.length - 1 })}>Add component</AddButton>
                </>
            }
            {pageInfo && tab == 1 &&
                <PageSettings page={pageInfo.pageInfo} updatePage={(e) => setPageInfo({ ...pageInfo, pageInfo: e })} />
            }
            {!pageInfo && <Loader />}
            {hasChanges &&
                <FloatingActionBar>
                    <CoreButton floatLeft onClick={cancelChanges} type='secondary' outline>Cancel changes</CoreButton>
                    <CoreButton floatRight onClick={onSave}>Publish changes</CoreButton>
                </FloatingActionBar>
            }
        </>
    );
};

const AddButton = styled(DashboardAddButton)`
    margin-top: 0.5rem;
`

export default CmsEditPage;

