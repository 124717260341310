import React, { useState } from 'react';
import { TwilioUpdate } from '../../../../../api/api-definitions';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import CoreModal from '../../../../../components/Layout/CoreModal';
import { IntegrationBox, IntegrationTitle } from '.';
import CoreButton from '../../../../../components/Forms/Button';
import { Row, Column } from '../../../../../components/Layout/Grid';
import { StyledTextInput } from '../../../../../theme/input.styles';
import { ApiService } from '../../../../../api/api-connectors';
import { NotificationService } from '../../../../../services/NotificationService';
import Icon from '../../../../../components/Media/Icon';

interface ComponentProps {
    enabled: boolean;
    locationId: string;
    updateBusiness: (property: string, value: boolean) => void;
    error: () => void;
}

const TwilioIntegration = ({ enabled, locationId, updateBusiness, error }: ComponentProps) => {
    const [data, setData] = useState<TwilioUpdate>({});
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [updating, setUpdating] = useState(false);

    const update = (clear: boolean = false) => {
        setUpdating(true)
        ApiService.business.UpdateTwilio__POST(clear ? { locationId } : { ...data, locationId }).then((response) => {
            if (response.success) {
                setOpen(false);
                setDeleteOpen(false);
                setData({})
                updateBusiness('twilioEnabled', !clear)
                NotificationService.Confirm(`Twilio integration ${clear ? 'deleted' : 'added'} successfully`);
            } else {
                error()
            }
        }).catch(error).finally(() => setUpdating(false))
    }

    return (
        <>
            <IntegrationBox hasDetails={enabled}
                onClick={enabled ? undefined : () => setOpen(true)}>
                <IntegrationTitle>Twilio SMS</IntegrationTitle>
                <img src='/assets/brands/twilio.png' />
                {enabled && <CoreButton full type='danger' onClick={() => setDeleteOpen(true)}>Delete integration</CoreButton>}
            </IntegrationBox>
            {deleteOpen &&
                <CoreModal
                    onClose={() => setDeleteOpen(false)}
                    small
                    title={`Are you sure you want to delete your Twilio integration?`}
                    actionBar={<CoreButton type='danger' onClick={() => update(true)}>Confirm deletion</CoreButton>}
                />
            }
            <FormWrapper onUpdate={setData} disabled={updating}>
                {({ id, valid }) => open ? (
                    <CoreModal
                        title='Twilio details'
                        small
                        onClose={() => setOpen(false)}
                        actionBar={<CoreButton
                            onClick={() => update()}
                            disabled={!valid || updating}>Confirm</CoreButton>
                        }>
                        <Row>
                            <Column size={6}>
                                <StyledTextInput required label='Username' model='username' value={data.username} />
                            </Column>
                            <Column size={6}>
                                <StyledTextInput required label='Auth token' model='authToken' value={data.authToken} />
                            </Column>
                        </Row>
                    </CoreModal>
                ) : null}
            </FormWrapper>
        </>
    );
};

export default TwilioIntegration;