import React from 'react';
import { DayBox } from '.';
import { BaseBusiness } from '../../../../../../api/api-definitions';
import Icon from '../../../../../../components/Media/Icon';

interface ComponentProps {
    data: string[];
    bookingSetup: BaseBusiness;
    setData: (bookingSetup: BaseBusiness) => void;
    value: string;
    label: string;
}

const SpecialOccasionOption = ({ data, bookingSetup, value, label, setData }: ComponentProps) => {
    const selected = data.indexOf(value) > -1;

    const onClick = () => {
        const newBookingSetup = {
            ...bookingSetup,
            bookingConfigurationSettings: {
                ...bookingSetup.bookingConfigurationSettings,
                specialOccasions: (selected ? data.filter(x => x != value) : [...data, value]).join('|')
            }
        }
        setData(newBookingSetup)
    }

    return (
        <DayBox dynamicWidth onClick={onClick} checked={selected}>
            <Icon name={selected ? 'check' : 'times'} /> {label}
        </DayBox>
    );
};

export default SpecialOccasionOption;