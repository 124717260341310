import React from 'react';
import { ApiPage, PageRequest } from '../../../../../../api/api-definitions';
import { ApiService } from '../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../services/NotificationService';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { cloneDeep } from 'lodash';
import { StyledDropdown, StyledTextInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

interface ComponentProps {
    closeModal: (refresh?: boolean) => void;
    layouts: ApiPage[];
    selectedPage: ApiPage;
}

const UrlLabel = styled.label`
    font-weight: bold;
    color: grey;
    font-size: 0.8rem;
`

const AddPageModal = ({ closeModal, layouts, selectedPage }: ComponentProps) => {
    const params: any = useParams();
    const [page, setPage] = React.useState<PageRequest>({});
    const [loading, setLoading] = React.useState(false);

    const addPage = () => {
        setLoading(true)
        page.parent = selectedPage.rowKey;
        page.useMaster = true;
        page.partitionKey = params.parentId;
        page.url = selectedPage.url === '/' ? selectedPage.url + page.url : selectedPage.url + '/' + page.url;
        ApiService.page.New__POST(page).then(response => {
            NotificationService.Confirm('Page added successfully');
            closeModal(true)
        }).catch(() => {
            setLoading(false)
            NotificationService.Error('Sorry, there was an error adding your page')
        })
    }

    const updateProperty = (key: string, value: any) => {
        const newPage = cloneDeep(page);
        newPage[key] = value;
        setPage(newPage)
    }

    return (
        <CoreModal title='Add new page' small onClose={closeModal} actionBar={<CoreButton text='Add page' onClick={addPage} disabled={loading || isNullOrWhitespace(page.name) || isNullOrWhitespace(page.url)} />}>
            <Row>
                <Column size={6}>
                    <StyledTextInput unlink onChange={(e) => updateProperty('name', e.target.value)} label='Page Name' value={page.name} disabled={loading} />
                </Column>
                <Column size={6}>
                    <StyledTextInput unlink onChange={(e) => updateProperty('url', e.target.value)} label='Page URL' value={page.url} disabled={loading} />
                    <UrlLabel>{selectedPage.url === '/' ? selectedPage.url + (page.url || '') : selectedPage.url + '/' + (page.url || '')}</UrlLabel>
                </Column>
                {/* <Column size={3}>
                    <StyledDropdown items={layouts.map(x => ({ label: x.name, value: x.rowKey }))}  onChange={(e) => updateProperty('url', e.target.value)} value={page.}/>
                </Column> */}
            </Row>
        </CoreModal>
    );
};

export default AddPageModal;