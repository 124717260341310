import styled from "styled-components";
import BREAKPOINTS from "../../../config/breakpoints";

const TopBarPaddingVerticle = '1rem';
const TopBarPaddingHorizontal = '1.5rem';
const TopBarBorderSize = '1px';

export const TabBar = styled.div<{reverse?: boolean; noMarginBottom?: boolean}>`
    display: flex;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    ${props => props.noMarginBottom ? '' : 'margin-bottom: 1rem;'}
    font-weight: bold;
    border-bottom: ${TopBarBorderSize} solid ${props => props.theme.borderColor};
`;

export const TabTitle = styled.div`
    flex: 1 1 auto;
    height: 3rem;
    padding: ${TopBarPaddingVerticle} ${TopBarPaddingHorizontal} ${TopBarPaddingVerticle} 0rem;
`;

interface TopBarButtonProps {
    active?: boolean;
    disabled?: boolean;
}

export const TabButton = styled.div<TopBarButtonProps>`
    flex: 0 1 auto;
    padding: ${TopBarPaddingVerticle} ${TopBarPaddingHorizontal};

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        padding: ${TopBarPaddingVerticle} 0.75rem;
        flex: 1;
        text-align: center;
    }

    ${(props) => props.active ? `
        color: ${props.theme.primary};
        border-bottom: 2px solid ${props.theme.primary};
    ` : `
        border-bottom: none;
    `}

    ${(props) => props.disabled ? `
        color: ${props.theme.borderColor};
    ` : `
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    `}
`;