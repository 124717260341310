import BusinessDetails from "./modules/LocationModules/BusinessDetails/BusinessDetails";
import Stats from "./modules/LocationModules/BusinessDetails/Stats";
import Users from "./modules/LocationModules/BusinessDetails/Users";
import BookingSetup from "./modules/LocationModules/Bookings/BookingSetup";
import BookingTimeline from "./modules/LocationModules/Bookings";
import BookingUsers from "./modules/ParentBusinessModules/Guestbook";
import BookingTableLayout from "./modules/LocationModules/Bookings/TableLayout";
import Menus from "./modules/ParentBusinessModules/MenuManagement/ViewMenu";
import Locations from "./modules/ParentBusinessModules/Locations";
import OpeningTimes from "./modules/LocationModules/BusinessDetails/OpeningTimes";
import DocumentBuilder from "./modules/ParentBusinessModules/DocumentBuilder";
import VerifyBusiness from "./modules/LocationModules/BusinessDetails/VerifyBusiness";
import Servers from "./modules/LocationModules/Servers";
import DepositRules from "./modules/LocationModules/Bookings/BookingRules/DepositRules";
import Integrations from "./modules/LocationModules/Integrations";
import PaymentMethods from "./modules/ParentBusinessModules/Payments/PaymentMethods";
import PaymentCenter from "./modules/ParentBusinessModules/Payments";
import LocationReports from "./modules/LocationModules/Reporting";
import ParentBusinessDetails from "./modules/ParentBusinessModules/BusinessDetails/ParentBusinessDetails";
import Experiences from "./modules/LocationModules/Bookings/Experiences";
import TerminalSetup from "./modules/LocationModules/TerminalSetup";

interface PageItem {
    component: (match: any) => JSX.Element;
    name: string;
    icon: string;
    link: string;
    offline?: boolean;
    hideFromNav?: boolean;
    category?: string;
}

export const adminSidebar: PageItem[] = [
    {
        name: 'All businesses',
        icon: 'book-atlas',
        component: null,
        link: 'all-businesses',
    },
    {
        name: 'Business requests',
        icon: 'bell',
        component: null,
        link: 'business-requests',
    },
    {
        name: 'Support desk',
        icon: 'messages-question',
        component: null,
        link: 'support-desk',
    },
    {
        name: 'Overlapping bookings',
        icon: 'diagram-venn',
        component: null,
        link: 'overlapping-bookings',
    },
    {
        name: 'Bookings admin',
        icon: 'user-pen',
        component: null,
        link: 'bookings-admin',
    },
]

export const BusinessSidebar: PageItem[] = [
    {
        name: 'Locations',
        icon: 'store',
        component: Locations,
        link: 'locations',
        offline: true
    },
    {
        name: 'Business details',
        icon: 'edit',
        component: ParentBusinessDetails,
        link: 'details'
    },
    // {
    //     name: 'Menu setup',
    //     icon: 'book-open',
    //     component: Menus,
    //     link: 'menus'
    // },
    {
        name: 'Payment center',
        icon: 'file-invoice',
        component: PaymentCenter,
        link: 'payment-center'
    },
    // {
    //     name: 'User setup',
    //     icon: 'user',
    //     component: Users,
    //     link: 'users'
    // },
    // {
    //     name: 'Group statistics',
    //     icon: 'chart-line',
    //     component: Stats,
    //     link: 'stats'
    // },
    // {
    //     name: 'Document builder',
    //     icon: 'file-pdf',
    //     component: DocumentBuilder,
    //     link: 'document-builder',
    //     offline: true
    // },
]

export const LocationSidebar: PageItem[] = [
    {
        name: 'Location details',
        icon: 'edit',
        component: BusinessDetails,
        link: 'edit-details'
    },
    {
        name: 'Guestbook',
        icon: 'address-book',
        component: BookingUsers,
        link: 'guestbook',
    },
    {
        name: 'Verification status',
        icon: 'badge-check',
        component: VerifyBusiness,
        link: 'verify-business'
    },
    {
        name: 'Opening times',
        icon: 'clock',
        component: OpeningTimes,
        link: 'opening-times'
    },
    {
        name: 'View menus',
        icon: 'book-open',
        component: Menus,
        link: 'menus'
    },
    // {
    //     name: 'Location users',
    //     icon: 'user',
    //     component: Users,
    //     link: 'users'
    // },
    // {
    //     name: 'Location statistics',
    //     icon: 'chart-line',
    //     component: Stats,
    //     link: 'stats'
    // },
    {
        name: 'Integrations',
        icon: 'grid-2-plus',
        component: Integrations,
        link: 'integrations'
    },
    {
        name: 'Bookings',
        icon: 'tasks',
        component: BookingTimeline,
        link: 'bookings',
        category: 'bookings',
        offline: true
    },
    {
        name: 'Floor plans',
        icon: 'dining-table',
        component: BookingTableLayout,
        link: 'table-setup',
        category: 'bookings'
    },
    {
        name: 'Booking configuration',
        icon: 'cog',
        component: BookingSetup,
        link: 'booking-configuration',
        category: 'bookings'
    },
    {
        name: 'Experiences / events',
        icon: 'sparkles',
        component: Experiences,
        link: 'experiences',
        category: 'bookings',
        offline: true
    },
    // {
    //     name: 'Card readers',
    //     icon: 'credit-card',
    //     component: TerminalSetup,
    //     link: 'card-readers',
    //     offline: false
    // },
    {
        name: 'Payment rules',
        icon: 'credit-card',
        component: DepositRules,
        link: 'deposit-rules',
        category: 'bookings'
    },
    // {
    //     name: 'Servers',
    //     icon: 'clipboard-user',
    //     component: Servers,
    //     link: 'servers',
    //     category: 'bookings'
    // },
    {
        name: 'Reports',
        icon: 'chart-line',
        component: LocationReports,
        link: 'reports'
    },
]