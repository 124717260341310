import { useEffect, useRef, useState } from "react";
import { Rect, Circle, Transformer, Text } from "react-konva";
import { LAYOUTWIDTH, LAYOUTHEIGHT, getCenter } from "./tableLayoutUtils";
import { CanvasImage } from "./MainStage";
import { BookingStatus } from "../../../../../../api/api-definitions";
import { svgToURL } from "../../../../../../utils/data-helpers";
import { bookingTables } from "../../../../../../svgs/bookingTables";

export const widths = {
    '1-square': 65,
    '2-square': 65,
    '3-square': 65,
    '4-square': 65,
    '2-rectangle': 65,
    '4-rectangle': 65 * 2,
    '6-rectangle': 65 * 3,
    '8-rectangle': 65 * 4,
    '10-rectangle': 65 * 5,
    '12-rectangle': 65 * 6,
    '1-circle': 70,
    '2-circle': 55,
    '4-circle': 70,
    '6-circle': 95,
    '8-circle': 135,
}

export const heights = {
    '1-square': 65,
    '2-square': 70,
    '3-square': 70,
    '4-square': 70,
    '2-rectangle': 70,
    '4-rectangle': 70,
    '6-rectangle': 70,
    '8-rectangle': 70,
    '10-rectangle': 70,
    '12-rectangle': 70,
    '1-circle': 70,
    '2-circle': 65,
    '4-circle': 70,
    '6-circle': 95,
    '8-circle': 135,
}

export const tableCenter = {
    '1-square': { x: 32, y: 32 },
    '2-square': { x: 32, y: 35 },
    '3-square': { x: 32, y: 35 },
    '4-square': { x: 32, y: 35 },
    '2-rectangle': { x: 32, y: 35 },
    '4-rectangle': { x: 32 * 2, y: 35 },
    '6-rectangle': { x: 32 * 3, y: 35 },
    '8-rectangle': { x: 32 * 4, y: 35 },
    '10-rectangle': { x: 32 * 5, y: 35 },
    '12-rectangle': { x: 32 * 6, y: 35 },
    '1-circle': { x: 32, y: 35 },
    '2-circle': { x: 32, y: 32 },
    '4-circle': { x: 35, y: 35 },
    '6-circle': { x: 47, y: 47 },
    '8-circle': { x: 72, y: 72 },
}

const getFolder = (status: BookingStatus) => {
    if (status == BookingStatus.Pending) return 'upcoming';
    if (status == BookingStatus.Seated) return 'seated';
    return 'free';
}

const Table = ({ shapeProps, isSelected, onSelect, mergeMode, onChange, shape, onDragStart, onDragMove, onDragEnd, readonly }: any) => {
    const [dragging, setDragging] = useState(false);
    const [selected, setSelected] = useState(false);
    const shapeRef = useRef();
    const trRef = useRef<any>();

    useEffect(() => {
        setSelected(isSelected)
    }, [isSelected]);
    
    useEffect(() => {
        if (selected && trRef?.current) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selected])

    useEffect(() => {
        if (isSelected) {
            setSelected(false)
            setTimeout(() => {
                setSelected(true)
            }, 10);
        }
    }, [shape])

    const tableSvgString = bookingTables[`${shapeProps.seats}-${shape}`](shapeProps.fill, shapeProps.seatFill, shapeProps.chairColours);
    const tableUrl = svgToURL(tableSvgString);

    return (
        <>
            <CanvasImage
                url={tableUrl}
                onClick={onSelect}
                onTap={onSelect}
                forwardRef={shapeRef}
                width={widths[`${shapeProps.seats}-${shape}`]}
                height={heights[`${shapeProps.seats}-${shape}`]}
                x={shapeProps.x}
                y={shapeProps.y}
                attrs={{table: shapeProps}}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "pointer";
                  }}
                  onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "default";
                  }}
                border={1}
                name="moveable"
                rotation={shapeProps.rotation}
                draggable={!readonly}
                onDragStart={(e) => {
                    if (!readonly) {
                        setDragging(true);
                        onDragStart(e);
                    }
                }}
                onTouchStart={(e) => {
                    if (!readonly) {
                        setDragging(true);
                        onDragStart(e);
                    }
                }}
                onDragMove={onDragMove}
                // dragBoundFunc={_pos => {
                //     let w = shapeProps.width, h = shapeProps.height, gW = LAYOUTWIDTH, gH = LAYOUTHEIGHT;
                //     return {
                //         x: _pos.x < 0 ? 0 : (_pos.x + w > gW ? gW - w : _pos.x),
                //         y: _pos.y < 0 ? 0 : (_pos.y + h > gH ? gH - h : _pos.y)
                //     };
                // }}
                onDragEnd={(e) => {
                    setDragging(false)
                    onDragEnd(e)
                    onChange({
                        ...shapeProps,
                        x: Math.round(e.target.x()),
                        y: Math.round(e.target.y()),
                    });
                }}
                onTransformEnd={(e) => {
                    const node: any = shapeRef.current;
                    if (node) {
                        onChange({
                            ...shapeProps,
                            x: Math.round(node.x()),
                            y: Math.round(node.y()),
                            rotation: parseInt(node.attrs.rotation)
                        });
                    }
                }}
            />
            {/* <Rect width={5} height={5} fill="yellow" x={center.x} y={center.y} /> */}
            {/* <Rect width={5} height={5} fill="blue" x={shapeProps.x} y={shapeProps.y} /> */}
            {!mergeMode && selected && (
                <Transformer
                    resizeEnabled={false}
                    rotateEnabled={!readonly}
                    rotationSnaps={[0, 90, 180, 270]}
                    zIndex={100}
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};

export default Table;