import styled from 'styled-components';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { isNullOrWhitespace } from '../../utils/text-helpers';

interface ComponentProps {
    children: ReactNode;
    to?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const StyledLink = styled(Link)`
    font-weight: bold;
    display: contents;

    &:hover {
        text-decoration: underline;
    }
`

const StyledA = styled.a`
    font-weight: bold;
    display: contents;

    &:hover {
        text-decoration: underline;
    }
`

const InlineLink = ({ children, to, onClick }: ComponentProps) => {
  if (isNullOrWhitespace(to)) return <StyledA onClick={onClick}>{children}</StyledA>;
  return <StyledLink to={to} onClick={onClick}>{children}</StyledLink>;
};

export default InlineLink;