import React, { useState } from 'react';
import { ApiComponent } from '../../../../../../api/api-definitions';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { StyledTextInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';

interface ComponentProps {
    item: ApiComponent;
    onClose: (item?: ApiComponent) => void;
}

const ComponentModal = ({ item, onClose }: ComponentProps) => {
    const [component, setComponent] = useState<ApiComponent>(item);

    return (
        <CoreModal isOpen onClose={() => onClose()} title='Component settings' actionBar={<CoreButton onClick={() => onClose(component)}>Save</CoreButton>}>
            <StyledTextInput unlink label='Name' value={component.name} onChange={(e) => setComponent({ ...component, name: e.target.value })} /><br />
            <StyledTextInput unlink label='Category' value={component.category} onChange={(e) => setComponent({ ...component, category: e.target.value })} /><br />
            <StyledTextInput unlink label='File path' value={component.filePath} onChange={(e) => setComponent({ ...component, filePath: e.target.value })} /><br />
        </CoreModal>
    );
};

export default ComponentModal;