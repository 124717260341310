import React from 'react';
import { ApiBusinessBookingDetails, OrderMenu, OrderMenuResponse } from '../../../../api/api-definitions';
import { IBasket } from './basket';
import FoodItemHorizontal from './foodItemHorizontal';
import styled from 'styled-components';
import { Row } from '../../../../components/Layout/Grid';

interface ComponentProps {
    data: OrderMenuResponse,
    menu: OrderMenu,
    setActive: (id: number) => void,
    business: ApiBusinessBookingDetails;
}

const MenuItemList = ({ data, menu, setActive, business }: ComponentProps) => {
    return (
        <>
            <br />
            {menu.sections.filter(x => x.dishes.length > 0).map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                    <SectionTitleContainer>
                        <SectionTitle id={`section-${sectionIndex}`} data-section-item={sectionIndex}>{section.title}</SectionTitle>
                    </SectionTitleContainer>
                    <Row>
                        {section.dishes.map((dish, dishIndex) => (
                            <FoodItemHorizontal
                                business={business}
                                dish={data.dishes[dish]}
                                currencyCode='GBP'
                                key={dish}
                                onClick={() => setActive(dish)}
                                size={4}
                            />
                        ))}
                    </Row>
                </React.Fragment>
            ))}
        </>
    );
};

const SectionTitleContainer = styled.div<{first?: boolean}>`
  padding: 1rem 0;
  margin-bottom: 1rem;
  text-align: center;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  ${({first, theme}) => !first && `border-top: 1px solid ${theme.borderColor};`}
  background-color: rgba(0, 0, 0, 0.05);
`

const SectionTitle = styled.a`
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1px;
`

export default MenuItemList;