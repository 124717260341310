import { createGlobalStyle } from "styled-components";

export const PageEditorGlobalStyle = createGlobalStyle`
.component {
    border: 1px solid ${props => props.theme.borderColor};
    margin-bottom: 20px;
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    flex: 1 0 auto;
  
    &-fit {
      max-width: calc(100% - 77px);
    }
  
    &-flex, &_actions {
      display: flex;
    }
  
    &_select {
      width: 100%;
      display: block;
    }
  
    &_optionSelect {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid ${props => props.theme.borderColor};
  
      &-noBorder {
        border: none;
      }
    }
  
    &_pageTree {
      position: relative;
  
      // &::before {
      //   content: '\f078';
      //   font-family: 'FontAwesome', sans-serif;
      //   position: absolute;
      //   height: 20px;
      //   border-right: 1px solid ${props => props.theme.borderColor};
      //   left: -2rem;
      //   top: 1.7rem;
      //   z-index: -1;
      // }
    }
  
    &_button {
      flex: 0 0 auto;
      border: 1px solid ${props => props.theme.borderColor};
      margin: 0rem 0rem 20px 1rem;
      text-align: center;
      width: 57px;
      line-height: 57px;
      max-height: 59px;
      background-color: #fff;
      font-size: 1.7rem;
      transition: all 0.3s ease;
      cursor: pointer;
  
      &:hover {
        background-color: #337ab7;
        color: #fff;
      }
  
      &-positive:hover {
        background-color: green;
        color: #fff;
      }
  
      &-negative:hover {
        background-color: red;
        color: #fff;
      }
    }
  
    &-draggable {
      margin-bottom: 0px;
    }
  
    &_dragBox {
      border: 1px dashed #000;
      text-align: center;
      height: 10px;
      margin: 0.2em 0;
      opacity: 0;
  
      &-active {
        height: auto;
        opacity: 0.7;
  
        &:hover {
          opacity: 0.9;
        }
      }
    }
  
    &_action {
      flex: 1 0 auto;
      text-align: center;
      padding: 0.5rem;
      border-bottom: 1px solid ${props => props.theme.borderColor};
      border-right: 1px solid ${props => props.theme.borderColor};
      cursor: pointer;
  
      &.active {
        background-color: #337ab7;
        color: #fff;
      }
  
      &:not(.active):hover {
        background-color: #e2e2e2;
      }
  
      &:last-child {
        border-right: none;
      }
    }
  
    &.new {
      width: 300px;
    }
  
    &.dropped {
      .fa {
        color: #000;
      }
    }
  
    &-toggle {
      float: right;
      color: ${props => props.theme.borderColor};
      margin-left: 15px;
    }
  
    &-plus {
      margin: 0 auto 2rem auto;
      background-color: #03A9F4;
      display: block;
      width: 3rem;
      line-height: 3rem;
      color: white;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
  
      &:hover {
        background-color: darken(#03A9F4, 4%)
      }
    }
  
    &-title {
      padding: 15px 20px;
      font-size: 18px;
      background-color: #fff;
      color: #000;
      transition: all ease 150ms;
      text-transform: capitalize;
  
      &:hover {
        padding-left: 30px;
        cursor: pointer;
      }
  
      &.thin {
        padding: 7px 20px;
  
        &:hover {
          padding-left: 20px;
        }
      }
  
      &--nonClickable:hover {
        padding-left: 20px;
        cursor: default;
      }
  
      .subtext {
        font-size: 14px;
        opacity: 0.7;
        line-height: 18px;
      }
  
      &.with-date {
        float: left;
        width: calc(100% - 180px);
      }
  
      &.active {
        background-color: #337ab7;
        color: #fff;
        
        .fa {
          color: #fff;
        }
      }
    }
  
    &-date {
      padding: 17px 20px;
      font-size: 15px;
      border-right: 1px solid ${props => props.theme.borderColor};
      text-align: center;
    }
  
    &-indicator {
      background-color: green;
      height: 56px;
      width: 30px;
  
      &.red {
        background-color: red;
      }
  
      &.green {
        background-color: green;
      }
  
      &.amber {
        background-color: #ff930d;
      }
    }
  
    .sub-title {
      padding: 10px;
      margin-bottom: 10px;
      margin-top: 20px;
      text-align: center;
      font-size: 12px;
      border-bottom: 1px solid ${props => props.theme.borderColor};
      border-top: 1px solid ${props => props.theme.borderColor};
      background-color: #eaeaea;
      &:first-child {
        margin-top: 0px;
      }
    }
  
    &-section-title {
      font-size: 1.8rem;
      width: 98%;
    }
  
    &-placeholder-title {
      font-size: 1.5rem;
      background-color: #337ab7;
      color: #fff;
      margin-bottom: 1rem;
      padding: 5px 10px;
    }
  
    &-field-list {
      padding: 20px;
      clear: both;
  
      &.with-indicator {
        padding-left: 30px;
      }
  
      &.border {
        border-top: 1px solid ${props => props.theme.borderColor};
      }
  
      &.default-open {
        display: block;
      }
  
      &.thin {
        padding: 0 10px;
      }
  
      .field-label {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 2px;
        color: #337ab7;
        position: relative;
  
        &.type {
          float: right;
          color: #164e64;
        }
      }
    }
  
    .component-field-list-settings {
      padding: 10px;
      padding-top: 20px;
  
      .field-label {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 2px;
        color: #337ab7;
  
        &.type {
          float: right;
          color: #164e64;
        }
      }
    }
  
    .component-order {
      border: 1px solid #fff;
      color: #fff;
      float: right;
      transition: all ease 0.4s;
      font-size: 0.6em;
      line-height: 27px;
      text-align: center;
      height: 30px;
      margin-left: 15px;
      padding: 0px 10px;
  
      .fa {
        margin-right: 7px;
      }
  
      &:hover {
        color: #466baf;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }
  
  .component-control {
    background-color: #fff;
    border: 1px solid ${props => props.theme.borderColor};
    padding: 15px;
    margin-bottom: 10px;
  
    &:hover {
      border: 1px solid #164e64;
      cursor: pointer;
  
      &.disabled {
        border: 1px solid ${props => props.theme.borderColor};
        cursor: initial;
      }
    }
  
    &.disabled {
      opacity: 0.5;
    }
  }
  
  .category-folder {
    text-align: center;
    background-color: #fff;
    padding: 40px 0;
    font-size: 1.5rem;
    border: 1px solid ${props => props.theme.borderColor};
    margin: 10px;
    display: inline-block;
    width: calc(33% - 20px);
  
    &.quarter {
      width: calc(25% - 20px);
    }
  
    .fa {
      margin-bottom: 15px;
      display: block;
      font-size: 3rem;
      color: #164e64;
    }
  
    &:hover {
      border: 1px solid #002c3d;
      cursor: pointer;
    }
  }
  
  .field-label {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #337ab7;
  
    &.type {
      float: right;
      color: #164e64;
    }
  }
  
  .menu-builder {
    width: calc(100% - 110px);
    border: none;
    float: left;
    margin-top: 0px;
    margin-bottom: 0px;
  
    .component-title {
      position: relative;
      font-size: 1.7rem;
      line-height: 26px;
      padding: 10px;
  
      &:hover {
        padding-left: 10px;
        cursor: default;
      }
    }
  
    &-clear {
      clear: both;
      overflow: auto;
    }
  
    &-options {
      position: absolute;
      background-color: #fff;
      border: 1px solid #000;
      padding: 10px;
      right: 5px;
      bottom: 100%;
  
      .link {
        width: auto;
        padding: 5px;
        margin-bottom: 5px;
        cursor: pointer;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  
    &-main {
      &.active .component-title {
        background-color: #466baf;
        color: #fff;
      }
  
      &:hover .component-title {
        background-color: lighten(#466baf, 10%);
        color: #fff;
        cursor: pointer !important;
      }
    }
  
    &-toggle {
      line-height: 45px;
      text-align: center;
      width: 45px;
      border: 1px solid ${props => props.theme.borderColor};
      margin: 0 5px;
      background-color: #fff;
      float: left;
  
      &:hover {
        cursor: pointer;
      }
  
      &-blank {
        border-color: transparent;
        background-color: transparent;
      }
  
      &.active {
        background-color: #466baf;
        color: #fff;
      }
  
      &.delete:hover {
        background-color: red;
        color: #fff;
      }
  
      &.add:hover {
        background-color: green;
        color: #fff;
      }
    }
  }
  
  .menu-builder-list {
    margin-left: -32px;
  
    &:before {
      content: '';
      background-color: #efeff1;
      width: 12px;
      position: absolute;
      height: 100%;
      left: 20px;
      top: -1px;
      z-index: 1;
    }
  }
  
  .menu-builder-list, .menu-builder-list ul, .menu-builder-list li {
       position: relative;
  }
  
  .menu-builder-list li {
       padding-top: 10px;
  }
  
  .menu-builder-list ul {
      list-style: none;
      padding-left: 32px;
  }
  
  .menu-builder-list li::before, .menu-builder-list li::after {
      content: "";
      position: absolute;
      left: -12px;
  }
  
  .menu-builder-list li::before {
      border-top: 1px solid #000;
      top: 33px;
      width: 12px;
      height: 0;
  }
  
  .menu-builder-list li::after {
      border-left: 1px solid #000;
      height: 100%;
      width: 0px;
      top: -1px;
  }
  
  .menu-builder-list ul > li:last-child::after {
      height: 35px;
  }
`