import React from 'react';
import styled from 'styled-components';
import Icon from '../Media/Icon';

interface ComponentProps {
    url: string;
}

const DocsIcon = styled(Icon)`
 color: ${props => props.theme.primary};
 font-size: 1.5rem;
`

const Button = styled.a`
  float: right;
`

const DocsButton = ({ url }: ComponentProps) => {
  return (
    <Button href={`https://docs.dishforager.com/docs/${url}`} target='_blank'><DocsIcon name='book' /></Button>
  );
};

export default DocsButton;