import { Moment } from 'moment';
import React, { useState } from 'react';
import { businessNowTime, getUtcNow, secondsToTime } from '../../../../../../utils/date-helpers';
import moment from 'moment';
import { useForceUpdate } from '../../../../../../hooks/useForceUpdate';
import Icon from '../../../../../../components/Media/Icon';

interface ComponentProps {
    time: Moment;
    tableNames: string[];
}

const BookingTimer = ({ time, tableNames }: ComponentProps) => {
    const [secondsRemaining, setSecondsRemaining] = useState(time.diff(businessNowTime(), 'seconds'))
    const timeLeft = secondsToTime(secondsRemaining);

    if (secondsRemaining > 0) setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
    }, 1000);

    return (
        <div>
            <Icon name='exclamation-triangle' /> <strong>Table{tableNames.length > 1 ? 's' : ''} "{tableNames.join(', ')}" held for: {timeLeft.m || 0} minutes, {timeLeft.s || 0} seconds</strong>
        </div>
    );
};

export default BookingTimer;