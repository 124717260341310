import React, { useEffect, useState } from 'react';
import Board from '@asseinfo/react-kanban'
import { useApi } from '../../../../../hooks/useApi';
import { ApiService } from '../../../../../api/api-connectors';
import DataTable, { DataTableItem } from '../../../../../components/Layout/Datatable';
import { DISPLAY_DATE_TIME_FORMAT, formatDate } from '../../../../../utils/date-helpers';
import Loader from '../../../../../components/Layout/Loader';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import CoreButton from '../../../../../components/Forms/Button';
import Icon from '../../../../../components/Media/Icon';
import { TabBar, TabButton } from '../../../../../components/Layout/Tabs';
import { BaseSupportTicket, SupportTicketStatus } from '../../../../../api/api-definitions';
import ViewSupportTicket from './viewSupportTicket';
import SupportTicketStatusLabel from './supportTicketStatusLabel';
import SupportTicketPriorityLabel from './supportTicketPriorityLabel';
import { useRecoilValue } from 'recoil';
import { LoginState } from '../../../../../components/Authentication/AuthenticationWrapper';
import { KanbanWrapper } from './kanban';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Badge } from '@chakra-ui/react';
import { getQueryParams } from '../../../../../utils/data-helpers';

interface ComponentProps {
}

interface IBoard {
    columns: { id: number, title: string, cards: BaseSupportTicket[] }[]
}

const SupportTickets = ({ }: ComponentProps) => {
    moment.tz.setDefault(moment.tz.guess());
    const [loaded, data] = useApi(ApiService.supportTicket.List__GET);
    const [tab, setTab] = useState(0);
    const [adminTab, setAdminTab] = useState(0);
    const [viewTicket, setViewTicket] = useState<BaseSupportTicket>();
    const loginValue = useRecoilValue(LoginState);
    const [board, setBoard] = useState<IBoard>();
    const isSuperAdmin = loginValue.data.isSuperAdmin;

    useEffect(() => {
        if (data) {
            getBoard();
            const queryString = getQueryParams();
            if (queryString.ref) {
                const foundTicket = data.find(x => x.id == queryString.ref)
                if (foundTicket) setViewTicket(foundTicket);
            }
        }
    }, [loaded])

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        data.forEach((item) => {
            const closed = item.status === (SupportTicketStatus.Resolved || SupportTicketStatus.WontDo);
            if ((tab === 0 && !closed) || (tab === 1 && closed)) {
                const dataItem: DataTableItem = {
                    data: {
                        'Ref': {
                            value: <Badge colorScheme='cyan'>#{item.ticketReference}</Badge>,
                            sortable: true,
                            sortValue: item.ticketReference
                        },
                        'Date raised': {
                            value: formatDate(item.dateRaisedUtc, DISPLAY_DATE_TIME_FORMAT, true),
                            sortable: true,
                        },
                        'Summary': {
                            value: item.summary,
                        },
                        'Status': {
                            value: <SupportTicketStatusLabel status={item.status} />,
                        },
                        'Priority': {
                            value: <SupportTicketPriorityLabel status={item.priority} />,
                        },
                        'View': {
                            value: <CoreButton onClick={() => setViewTicket(item)}>View issue <Icon name='arrow-right' /></CoreButton>
                        }
                    },
                }
                items.push(dataItem);
            }
        });
        return items;
    }

    const getBoard = () => {
        const newBoard: IBoard = {
            columns: [
                {
                    id: 0,
                    title: 'With us',
                    cards: []
                },
                {
                    id: 1,
                    title: 'With customer',
                    cards: []
                },
                {
                    id: 2,
                    title: 'Resolved',
                    cards: []
                }
            ]
        }

        data.forEach((item) => {
            if (item.status === SupportTicketStatus.WithUs) newBoard.columns[0].cards.push(item);
            if (item.status === SupportTicketStatus.WithCustomer) newBoard.columns[1].cards.push(item);
            if (item.status === SupportTicketStatus.Resolved) newBoard.columns[2].cards.push(item);
            if (item.status === SupportTicketStatus.WontDo) newBoard.columns[3].cards.push(item);
        });

        setBoard(newBoard)
    }

    const moveItem = (item: BaseSupportTicket, positionOld: any, positionNew: any) => {
        const fromColumn = positionOld.fromColumnId;
        const newColumn = positionNew.toColumnId;
        const toIndex = positionNew.toPosition;
        if (newColumn === 0) item.status = SupportTicketStatus.WithUs;
        if (newColumn === 1) item.status = SupportTicketStatus.WithCustomer;
        if (newColumn === 2) item.status = SupportTicketStatus.Resolved;
        if (newColumn === 3) item.status = SupportTicketStatus.WontDo;
        const newBoard = cloneDeep(board);
        newBoard.columns[fromColumn].cards = newBoard.columns[fromColumn].cards.filter((x: BaseSupportTicket) => x.id !== item.id);
        newBoard.columns[newColumn].cards.splice(toIndex, 0, cloneDeep(item));
        setBoard(newBoard)
        ApiService.supportTicket.Save__POST(item);
    }

    return (
        <div>
            <DashboardHeader icon='messages-question' title='Support issues' />
            {viewTicket && <ViewSupportTicket ticket={viewTicket} onClose={() => setViewTicket(undefined)} />}
            {!loaded && <Loader />}
            {loaded && isSuperAdmin &&
                <TabBar>
                    <TabButton active={adminTab === 0} onClick={() => setAdminTab(0)}>Kanban</TabButton>
                    <TabButton active={adminTab === 1} onClick={() => setAdminTab(1)}>List</TabButton>
                </TabBar>
            }
            {loaded && (!isSuperAdmin || adminTab === 1) &&
                <>
                    <TabBar>
                        <TabButton active={tab === 0} onClick={() => setTab(0)}>Open issues</TabButton>
                        <TabButton active={tab === 1} onClick={() => setTab(1)}>Closed issues</TabButton>
                    </TabBar>
                    <DataTable data={gentable()} />
                </>
            }
            {loaded && board && isSuperAdmin && adminTab === 0 &&
                <KanbanWrapper>
                    <Board
                        disableColumnDrag
                        onCardDragEnd={(item: BaseSupportTicket, positionOld, positionNew) => moveItem(item, positionOld, positionNew)}
                        renderCard={(item: BaseSupportTicket) => (
                            <div className="react-kanban-card" onClick={() => setViewTicket(item)}>
                                <span>
                                    <CardTitle className="react-kanban-card__title">
                                        {item.summary}
                                    </CardTitle>
                                    <Badge colorScheme='cyan'>Ref: #{item.ticketReference}</Badge> <Badge colorScheme='teal'>{formatDate(item.dateRaisedUtc, DISPLAY_DATE_TIME_FORMAT, true)}</Badge><br />
                                    <SupportTicketPriorityLabel status={item.priority} /><br />
                                    <span><strong>Raised by:</strong> {item.businessName ? item.businessName : (item.parentBusinessName ? item.parentBusinessName : item.username)}</span><br />
                                </span>
                                <div style={{maxHeight: '3rem', textOverflow: 'ellipsis', overflow: 'hidden'}} className="react-kanban-card__description" dangerouslySetInnerHTML={{__html: item.description}}>
                                </div>
                            </div>
                        )}
                    >
                        {board}
                    </Board>
                </KanbanWrapper>
            }
        </div>
    );
};

const CardTitle = styled.div`
    display: block !important;
`

const CardReference = styled.span`
    font-size: 0.9rem;
    font-style: italics;
`

export default SupportTickets;