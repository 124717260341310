import React from 'react';
import { ApiPage, ApiPageComponent } from '../../../../../../api/api-definitions';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { WarningMessage } from '../../../../../../components/Forms/Messaging';
import CoreButton from '../../../../../../components/Forms/Button';
import { ApiService } from '../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../services/NotificationService';
import { cloneDeep } from 'lodash';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';

interface ComponentProps {
    component: ApiPageComponent;
    componentName: string;
    componentList: ApiPageComponent[];
    closeModal: (refresh?: boolean) => void;
    updateData: (component: ApiPageComponent[]) => void;
}

function deleteItem(array: ApiPageComponent[], id: string, parentId: string) {
    array.forEach((item, index) => {
      if (item.rowKey == id) {
        array.splice(index, 1);
      }
      if (!isNullOrWhitespace(parentId)) {
        const parentComponent = array.find(x => x.rowKey == parentId);
        parentComponent.values.forEach(value => {
          const values = value.value.split('|');
          value.value = values.filter(x => x !== id).join('|');
        });
      }
    });
  }

const DeleteComponentModal = ({ component, componentName, componentList, closeModal, updateData }: ComponentProps) => {
    
  const confirmDelete = () => {
        const newComponents = cloneDeep(componentList);
        deleteItem(newComponents, component.rowKey, component.parent);
        closeModal(true)
        updateData(newComponents)
    }

    return (
        <CoreModal title='Delete component' small onClose={closeModal} actionBar={<CoreButton text='Delete component' type='danger' onClick={confirmDelete} />}>
            Are you sure you want to delete <strong>{componentName}</strong>?
            <br />
            <br />
            <WarningMessage>This action will delete any components that are under it.</WarningMessage>
        </CoreModal>
    );
};

export default DeleteComponentModal;