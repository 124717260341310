import React, { useEffect } from 'react';
import styled from 'styled-components';
import Icon from '../../Media/Icon';
import { ApiService } from '../../../api/api-connectors';
import { ApiImage } from '../../../api/api-definitions';
import { Constants } from '../../../constants';
import useParentBusiness from '../../../hooks/useParentBusiness';
import { BoxShadowStyleWithHover, BoxShadowStyle } from '../../../theme';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { Row, Column } from '../../Layout/Grid';
import Loader from '../../Layout/Loader';
import CoreButton from '../Button';
import FileUpload from '../FileUpload';
import { InfoMessage, SuccessMessage } from '../Messaging';
import CoreModal from '../../Layout/CoreModal';
import { H4style } from '../../Typography/Headings';

interface ComponentProps {
  value: string;
  isMulti?: boolean;
  onClose: () => void;
  onConfirm: (image: string) => void;
}

const PrimaryIcon = styled(Icon)`
  color: ${props => props.theme.secondary};
`

const Folder = styled.div`
  ${BoxShadowStyleWithHover}
  text-align: center;
  padding: 1rem;
  border: 1px solid ${props => props.theme.borderColor};
`

const ImageContainer = styled.div`
  ${BoxShadowStyle}
  text-align: center;
  padding: 1rem;
  border: 1px solid ${props => props.theme.borderColor};
`

const ImagePreview = styled.div<{ image: string}>`
  background-image: url('${props => props.image}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
`

const ImageModal = ({ value, isMulti, onClose, onConfirm }: ComponentProps) => {
  const [image, setImage] = React.useState(value);
  const [images, setImages] = React.useState<ApiImage[]>()
  const [selectedFolder, setSelectedFolder] = React.useState<string>()
  const [folders, setFolders] = React.useState<string[]>();
  const [businessLoaded, data, setData, reload] = useParentBusiness();

  useEffect(() => {
    if (data) ApiService.image.GetForSite__GET(data.id.toString()).then(response => {
      const foldersToAdd: string[] = []
      response.forEach(item => {
        const name = isNullOrWhitespace(item.folder) ? 'Uncategorised' : item.folder;
        if (foldersToAdd.indexOf(name) < 0) foldersToAdd.push(name);
      });
      setFolders(foldersToAdd)
      setImages(response);
    })
  }, [businessLoaded])

  const onImageUpload = (imageName: string, imageDeatils: ApiImage) => {
    setImage(imageName);
    setImages([...images, imageDeatils])
    setSelectedFolder(imageDeatils.folder)
    onConfirm(imageName)
  }

  return (
    <CoreModal title='Image gallery' onClose={onClose} actionBar={<CoreButton disabled={isNullOrWhitespace(image)} onClick={() => onConfirm(image)}>Confirm</CoreButton>}>
      <FileUpload onUpload={onImageUpload} site={data?.id?.toString()} folders={folders} allowedFileTypes={['jpg', 'jpeg', 'png']} />
      <br />
      <H4style>Or select from existing</H4style>
      {images && images.length == 0 && <InfoMessage>There are no images in your gallery. Please upload a new image.</InfoMessage>}
      {!images && <Loader />}
      {selectedFolder !== undefined &&
        <>
          <CoreButton outline type='secondary' onClick={() => setSelectedFolder(undefined)}><Icon name='chevron-left' /> Back</CoreButton>
          <br />
          <br />
        </>
      }
      <Row>
        {selectedFolder !== undefined && images && images.filter(x => x.folder === selectedFolder).map(x => (
          <Column size={3} key={x.rowKey}><ImageContainer>
            <ImagePreview image={Constants.baseImageUrl + x.blobId + '?w=300'} />
            <strong>{x.title}</strong>
            <br />
            {x.blobId === image ? <SuccessMessage>Selected</SuccessMessage> : <CoreButton full type='secondary' onClick={() => onConfirm(x.blobId)}>Select image</CoreButton>}
            {/* <StyledButton full type='secondary' outline>Edit details</StyledButton> */}
          </ImageContainer></Column>
        ))}
        {selectedFolder === undefined && folders && folders.map(item => (
          <Column size={4} key={item}>
            <Folder onClick={() => setSelectedFolder(item === 'Uncategorised' ? '' : item)}><PrimaryIcon name='folder' /> {item}</Folder>
          </Column>
        ))}
      </Row>
    </CoreModal>
  );
};

const StyledButton = styled(CoreButton)`
  margin-top: 0.5rem;
`

export default ImageModal;