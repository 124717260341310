import React from "react";
import Renderer, { Text, View, Image, Document, Page, Link } from "@react-pdf/renderer"
import DocumentService from "../../DocumentService";

interface Style {
    [key: string]: string | number
}

interface CoreProps {
    style?: Style | Style[];
    children?: React.ReactNode;
    break?: boolean;
    debug?: boolean;
    wrap?: boolean;
}

interface CoreLinkProps extends CoreProps {
    src: string;
}

interface CoreImageProps {
    src: string;
    cache?: boolean;
    style?: Style | Style[];
    break?: boolean;
    debug?: boolean;
    fixed?: boolean;
}

interface CoreDocumentProps {
    title?: string;
    author?: string;
    creator?: string;
    producer?: string;
    children: React.ReactNode;
    fonts: any[];
    basePath: string;
}

interface CorePageProps extends Renderer.PageProps {
    style?: Style | Style[];
    children: React.ReactNode;
}

function getStyles(style: Style | Style[]) {
    let newStyles = {};

    if (Array.isArray(style)) {
        style.forEach(style => {
            newStyles = {
                ...newStyles,
                ...style
            }
        });
    } else {
        newStyles = style;
    }

    return newStyles;
}

export const CoreText = (props: CoreProps) => {
    const { style, children, debug } = props;
    if (DocumentService.ViewMode === 0) return <Text style={style} debug={debug} break={props.break} wrap={props.wrap}>{children}</Text>;
    return <span style={getStyles(style)}>{children}</span>
}

export const CoreLink = (props: CoreLinkProps) => {
    const { style, children, debug, src } = props;
    if (DocumentService.ViewMode === 0) return <Link src={src} style={style} debug={debug} break={props.break} wrap={props.wrap}>{children}</Link>;
    return <a style={getStyles(style)} href={src} target="_blank">{children}</a>
}

export const CoreView = (props: CoreProps) => {
    const { style, children, debug } = props;
    if (DocumentService.ViewMode === 0) return <View style={style} debug={debug} break={props.break} wrap={props.wrap}>{children}</View>;
    return <div style={getStyles(style)}>{children}</div>
}

export const CoreImage = (props: CoreImageProps) => {
    const { style, debug, src, cache, fixed } = props;

    if (DocumentService.ViewMode === 0) return <Image src={src} style={style} debug={debug} break={props.break} cache={cache} fixed={fixed} />;
    return <img src={src} style={getStyles(style)} />
}

export const CoreDocument = ({ title, author, creator, producer, children, fonts, basePath }: CoreDocumentProps) => {
    if (DocumentService.ViewMode === 0) return <Document title={title} author={author} creator={creator} producer={producer}>{children}</Document>;
    return <html>
        <head>
            <title>{title}</title>
            <style>
                {fonts.map(font => `
                    @font-face { font-family: ${font.name}; src: url('${basePath + font.src}'); }
                `)}
            </style>
        </head>
        <body>
            {children}
        </body>
    </html>
}

export const CorePage = ({ size, style, children }: CorePageProps) => {
    if (DocumentService.ViewMode === 0) return <Page style={style} size={size}>{children}</Page>;
    return <div style={{ ...getStyles(style), maxWidth: '70rem', margin: '0 auto' }}>{children}</div>
}