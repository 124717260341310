import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import DashboardHeader from '../../../../components/Dashboard/Header';
import { Column, Row } from '../../../../components/Layout/Grid';
import Loader from '../../../../components/Layout/Loader';
import Icon from '../../../../components/Media/Icon';
import { H3 } from '../../../../components/Typography/Headings';
import { useBusiness } from '../../../../hooks/useBusiness';
import { BoxShadowStyleWithHover } from '../../../../theme';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { LocationSidebar } from '../../sideBars';
import SevenDayOverview from '../../../../components/Reporting/SevenDayOverview';

const PanelLink = styled(Link)`
  ${BoxShadowStyleWithHover};
  background-color: ${props => props.theme.background};
  display: block;
  padding: 1rem;
  text-align: center;
`

const LocationDashboard = () => {
  const [loaded, data] = useBusiness();
  const params: any = useParams();
  if (!data) return <Loader />
  return (
    <div>
      <DashboardHeader title={`${data.locationAddress.formattedName}`}>Manage your business, restaurant bookings, statistics and more!</DashboardHeader>
      <br />
      <H3>7 day booking overview</H3>
      <SevenDayOverview />
      <H3>Location management</H3>
      <Row>
        {LocationSidebar.filter(x => isNullOrWhitespace(x.category)).map((link, index) => (
          <Column size={4} tablet={6} mobile={12} key={`LocationManagementLink-${index}`}>
            <PanelLink to={`/dashboard/${params.parentId}/locations/${params.id}/${link.link}`}><Icon name={link.icon} /> {link.name}</PanelLink>
          </Column>
        ))}
      </Row>
      <H3>Booking management</H3>
      <Row>
        {LocationSidebar.filter(x => x.category === 'bookings').map((link, index) => (
          <Column size={4} tablet={6} mobile={12} key={`BookingManagementLink-${index}`}>
            <PanelLink to={`/dashboard/${params.parentId}/locations/${params.id}/${link.link}`}><Icon name={link.icon} /> {link.name}</PanelLink>
          </Column>
        ))}
      </Row>
    </div>
  );
};

export default LocationDashboard;