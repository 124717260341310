import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { NotificationService } from "../services/NotificationService";
import { createUUID } from "../utils/data-helpers";
import { useMergeState } from "./useMergeState";

interface InternalState {
    data?: any;
    rawData?: any;
    loaded: boolean;
}

export const useApi = function <T>(apiCall: (params?: any) => Promise<T>, params?: any, errorMessage: string = '', callBack?: (data: T) => void): [boolean, T, (setData: T) => void, (setItem: any) => void, () => Promise<void | (() => void)>, boolean] {
    const [state, setState] = useMergeState<InternalState>({loaded: false});
    useEffect(() => {
        try {
            if (apiCall) loadApi();
        }
        catch (e) {
            console.error(e)
        }
    }, [apiCall]);
    const loadApi = (): Promise<void | (() => void)> => {
        setState({loaded: false})
        return apiCall(params).then(data => {
            if (Array.isArray(data)) {
                data.forEach(item => {
                    item.DATA_API_ID = createUUID();
                });
            }
            setState({data, loaded: true, rawData: data})
            if (callBack) callBack(data)
        }).catch(() => {
            NotificationService.Error(errorMessage)
        })
    }
    const updateItem = (item: any) => {
        if (Array.isArray(state.data)) {
            const newData = cloneDeep(state.data);
            const foundItem = newData.find(x => x.DATA_API_ID == item.DATA_API_ID);
            Object.assign(foundItem, item);
            setState({data: newData})
        }
    }
    const setData = (data: T) => {
        setState({data})
    }

    return [state.loaded, state.data, setData, updateItem, loadApi, JSON.stringify(state.data) != JSON.stringify(state.rawData)];
};