import React, { useState } from 'react';
import { BaseMenu } from '../../../../../../api/api-definitions';
import DashboardPanel from '../../../../../../components/Dashboard/Panel';
import CoreButton from '../../../../../../components/Forms/Button';
import TextInput from '../../../../../../components/Forms/TextInput';
import { Row, Column } from '../../../../../../components/Layout/Grid';
import MenuImportService from '../../../../../../services/MenuImportService';
import { StyledTextInput } from '../../../../../../theme/input.styles';

interface ComponentProps {
    setLoading: (value: boolean) => void;
    loadMenu: () => void;
    menu: BaseMenu;

}

const ImportMenu = ({ setLoading, loadMenu, menu }: ComponentProps) => {
    const [importUrl, setImportUrl] = useState<string>('');

    const finishImport = () => {
        setLoading(false)
        loadMenu()
    }

    const startImport = () => {
        setLoading(true);
        MenuImportService.ImportWix(importUrl, menu, finishImport)
    }

    return (
        <DashboardPanel title='Import menu'>
            <Row>
                <Column size={6} noMarginBottom>
                    <StyledTextInput onChange={(e) => setImportUrl(e.target.value)} value={importUrl} label='Import from Deliveroo/Uber Eats' placeholder='Please enter URL' />
                </Column>
                <Column size={2} noMarginBottom>
                    <br />
                    <CoreButton full text='Import' onClick={startImport} />
                </Column>
            </Row>
        </DashboardPanel>
    );
};

export default ImportMenu;