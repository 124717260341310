import React from 'react';
import { useInitialiseDocument, FontItem } from './useInitialiseDocument';
import { Constants } from '../../constants';
import { DocumentContentNode } from '.';
import { CoreDocument, CorePage, CoreText, CoreView } from '../shared/CoreModules/coreComponents';

interface ComponentProps {
  children: React.ReactNode;
  title: string;
  fonts: FontItem[];
  basePath: string;
  data: DocumentContentNode[];
}

const BaseDocument = ({ children, title, fonts, basePath, data }: ComponentProps) => {
  const intialised: boolean = useInitialiseDocument(fonts, basePath)

  if (!intialised) return null;
  try {
    if (!data) {
      return (
        <CoreDocument title={title} author={Constants.businessName} creator={Constants.businessName} producer={Constants.businessName} fonts={fonts} basePath={basePath}>
          <CorePage size="A4">
            <CoreView>
              <CoreText>Document could not be loaded</CoreText>
            </CoreView>
          </CorePage>
        </CoreDocument>
      );
    }
  } catch(e) {
    console.error('An error occurred when loading the document:');
    console.error(e)
    return null;
  }
  
  return <CoreDocument title={title} author={Constants.businessName} creator={Constants.businessName} producer={Constants.businessName} fonts={fonts} basePath={basePath}>
    {children}
  </CoreDocument>;
};

export default BaseDocument;