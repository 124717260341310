import { isNullOrWhitespace } from "../utils/text-helpers";

function getChairColourString(chairColours: { [key: number]: string }) {
  if (Object.keys(chairColours).length == 0) return '';
  let returnValue = ``;
  Object.keys(chairColours).forEach(key => {
    returnValue += `
      .chair-${key} {
        fill: ${chairColours[key]} !important;
        stroke: none !important;
      }`
  })
  return returnValue;
}

const circle1 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 359 393">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1 chair-1" d="m99,0h161c19.32,0,35,15.68,35,35v74H64V35C64,15.68,79.68,0,99,0Z"/>
    <rect class="cls-2" x="0" y="34" width="359" height="359" rx="179.5" ry="179.5"/>
  </g>
</svg>
`

const circle2 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 359 435">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1 chair-1" d="m99,326h161c19.32,0,35,15.68,35,35v74H64v-74c0-19.32,15.68-35,35-35Z" transform="translate(359 761) rotate(180)"/>
    <path class="cls-1 chair-2" d="m99,0h161c19.32,0,35,15.68,35,35v74H64V35C64,15.68,79.68,0,99,0Z"/>
    <rect class="cls-2" x="0" y="34" width="359" height="359" rx="179.5" ry="179.5"/>
  </g>
</svg>
`

const circle4 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595 588">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1 chair-1" d="m217,479h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(595 1067) rotate(180)"/>
    <path class="cls-1 chair-2" d="m217,0h161c19.32,0,35,15.68,35,35v74h-231V35c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-3" d="m-26,239h161c19.32,0,35,15.68,35,35v74H-61v-74c0-19.32,15.68-35,35-35Z" transform="translate(-239 348) rotate(-90)"/>
    <path class="cls-1 chair-4" d="m460,239h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(834 -247) rotate(90)"/>
    <rect class="cls-2" x="54" y="54" width="486" height="479" rx="239.5" ry="239.5"/>
  </g>
</svg>
`

const circle6 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 845 810.89">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1 chair-1" d="m148,658.95h161c19.32,0,35,15.68,35,35v74H113v-74c0-19.32,15.68-35,35-35Z" transform="translate(2.55 1427.71) rotate(-144.69)"/>
    <path class="cls-1 chair-2" d="m548,42.95h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(171.97 -345.37) rotate(35.31)"/>
    <path class="cls-1 chair-3" d="m-26,348.95h161c19.32,0,35,15.68,35,35v74H-61v-74c0-19.32,15.68-35,35-35Z" transform="translate(-348.95 457.95) rotate(-90)"/>
    <path class="cls-1 chair-4" d="m710,351.95h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(1196.95 -384.05) rotate(90)"/>
    <path class="cls-1 chair-5" d="m547.79,656.87h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(1553.58 922.61) rotate(144.31)"/>
    <path class="cls-1 chair-6" d="m140.39,46.96h161c19.32,0,35,15.68,35,35v74H105.39v-74c0-19.32,15.68-35,35-35Z" transform="translate(-17.71 147.92) rotate(-35.69)"/>
    <rect class="cls-2" x="76" y="55.78" width="696.16" height="696.16" rx="348.08" ry="348.08"/>
  </g>
</svg>
`

const circle8 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 962 950">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1 chair-1" d="m401,841h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(963 1791) rotate(180)"/>
    <path class="cls-1 chair-2" d="m401,0h161c19.32,0,35,15.68,35,35v74h-231V35c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-3" d="m-26,431h161c19.32,0,35,15.68,35,35v74H-61v-74c0-19.32,15.68-35,35-35Z" transform="translate(-431 540) rotate(-90)"/>
    <path class="cls-1 chair-4" d="m827,421h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(1383 -432) rotate(90)"/>
    <path class="cls-1 chair-5" d="m711.79,713.92h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(1895.88 751.54) rotate(135)"/>
    <path class="cls-1 chair-6" d="m90.79,121.92h161c19.32,0,35,15.68,35,35v74H55.79v-74c0-19.32,15.68-35,35-35Z" transform="translate(-74.58 172.79) rotate(-45)"/>
    <path class="cls-1 chair-7" d="m718.79,114.92h161c19.32,0,35,15.68,35,35v74h-231v-74c0-19.32,15.68-35,35-35Z" transform="translate(353.91 -515.56) rotate(45)"/>
    <path class="cls-1 chair-8" d="m99.79,733.92h161c19.32,0,35,15.68,35,35v74H64.79v-74c0-19.32,15.68-35,35-35Z" transform="translate(-249.72 1473.4) rotate(-135)"/>
    <rect class="cls-2" x="54.89" y="55" width="852.28" height="840" rx="420" ry="420"/>
  </g>
</svg>
`

const square1 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 359 393">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1 chair-1" d="m99,0h161c19.32,0,35,15.68,35,35H64C64,15.68,79.68,0,99,0Z"/>
    <rect class="cls-2" y="34" width="359" height="359"/>
  </g>
</svg>
`

const square2 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 359 427">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1 chair-1" d="m99,0h161c19.32,0,35,15.68,35,35H64C64,15.68,79.68,0,99,0Z"/>
    <path class="cls-1 chair-2" d="m99,392h161c19.32,0,35,15.68,35,35H64c0-19.32,15.68-35,35-35Z" transform="translate(359 819) rotate(180)"/>
    <rect class="cls-2" y="34" width="359" height="359"/>
  </g>
</svg>
`

const square4 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 429 427">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1 chair-1" d="m134,0h161c19.32,0,35,15.68,35,35H99C99,15.68,114.68,0,134,0Z"/>
    <path class="cls-1 chair-2" d="m134,392h161c19.32,0,35,15.68,35,35H99c0-19.32,15.68-35,35-35Z" transform="translate(429 819) rotate(180)"/>
    <path class="cls-1 chair-3" d="m331,196h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(625 -198) rotate(90)"/>
    <path class="cls-1 chair-4" d="m-63,196H98c19.32,0,35,15.68,35,35H-98c0-19.32,15.68-35,35-35Z" transform="translate(-196 231) rotate(-90)"/>
    <rect class="cls-2" x="35" y="34" width="359" height="359"/>
  </g>
</svg>
`

const rectangle2 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.5 401">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <rect class="cls-2" y="35.5" width="341.5" height="330.5"/>
    <path class="cls-1 chair-1" d="m86.5,0h161c19.32,0,35,15.68,35,35H51.5C51.5,15.68,67.18,0,86.5,0Z"/>
    <path class="cls-1 chair-2" d="m90.5,366h161c19.32,0,35,15.68,35,35H55.5c0-19.32,15.68-35,35-35Z" transform="translate(342 767) rotate(180)"/>
  </g>
</svg>
`

const rectangle4 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 646.5 401">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <rect class="cls-2" y="35.5" width="646.5" height="330.5"/>
    <path class="cls-1 chair-1" d="m86.5,0h161c19.32,0,35,15.68,35,35H51.5C51.5,15.68,67.18,0,86.5,0Z"/>
    <path class="cls-1 chair-2" d="m90.5,366h161c19.32,0,35,15.68,35,35H55.5c0-19.32,15.68-35,35-35Z" transform="translate(342 767) rotate(180)"/>
    <path class="cls-1 chair-3" d="m379.5,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-4" d="m383.5,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(928 767) rotate(180)"/>
  </g>
</svg>
`

const rectangle6 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 922.5 401">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <rect class="cls-2" y="35.5" width="922.5" height="330.5"/>
    <path class="cls-1 chair-1" d="m86.5,0h161c19.32,0,35,15.68,35,35H51.5C51.5,15.68,67.18,0,86.5,0Z"/>
    <path class="cls-1 chair-2" d="m90.5,366h161c19.32,0,35,15.68,35,35H55.5c0-19.32,15.68-35,35-35Z" transform="translate(342 767) rotate(180)"/>
    <path class="cls-1 chair-3" d="m379.5,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-4" d="m383.5,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(928 767) rotate(180)"/>
    <path class="cls-1 chair-5" d="m663.5,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-6" d="m667.5,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(1496 767) rotate(180)"/>
  </g>
</svg>
`

const rectangle8 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1204.5 401">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <rect class="cls-2" y="35.5" width="1204.5" height="330.5"/>
    <path class="cls-1 chair-1" d="m86.5,0h161c19.32,0,35,15.68,35,35H51.5C51.5,15.68,67.18,0,86.5,0Z"/>
    <path class="cls-1 chair-2" d="m90.5,366h161c19.32,0,35,15.68,35,35H55.5c0-19.32,15.68-35,35-35Z" transform="translate(342 767) rotate(180)"/>
    <path class="cls-1 chair-3" d="m379.5,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-4" d="m383.5,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(928 767) rotate(180)"/>
    <path class="cls-1 chair-5" d="m663.5,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-6" d="m667.5,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(1496 767) rotate(180)"/>
    <path class="cls-1 chair-7" d="m955.5,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-8" d="m959.5,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(2080 767) rotate(180)"/>
  </g>
</svg>
`

const rectangle10 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1519 401">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      .cls-2 {
        fill: ${tableColor};
      }
      ${getChairColourString(chairColours)}
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <rect class="cls-2" y="35.5" width="1519" height="330.5"/>
    <path class="cls-1 chair-1" d="m98,0h161c19.32,0,35,15.68,35,35H63C63,15.68,78.68,0,98,0Z"/>
    <path class="cls-1 chair-2" d="m102,366h161c19.32,0,35,15.68,35,35H67c0-19.32,15.68-35,35-35Z" transform="translate(365 767) rotate(180)"/>
    <path class="cls-1 chair-3" d="m391,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-4" d="m395,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(951 767) rotate(180)"/>
    <path class="cls-1 chair-5" d="m675,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-6" d="m679,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(1519 767) rotate(180)"/>
    <path class="cls-1 chair-7" d="m967,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-8" d="m971,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(2103 767) rotate(180)"/>
    <path class="cls-1 chair-9" d="m1251,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-10" d="m1255,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(2671 767) rotate(180)"/>
  </g>
</svg>
`

const rectangle12 = (tableColor: string = '#FFFF', seatColor: string = '#4d4d4d', chairColours: { [key: number]: string } = '') => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1819 401">
  <defs>
    <style>
      .cls-1 {
        fill: ${seatColor};
      }

      
      ${getChairColourString(chairColours)}

      .tableBox {
        fill: ${tableColor};
      }
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <rect class="tableBox" y="35.5" width="1819" height="330.5"/>
    <path class="cls-1 chair-1" d="m398,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-2" d="m402,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(965 767) rotate(180)"/>
    <path class="cls-1 chair-3" d="m691,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-4" d="m695,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(1551 767) rotate(180)"/>
    <path class="cls-1 chair-5" d="m975,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-6" d="m979,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(2119 767) rotate(180)"/>
    <path class="cls-1 chair-7" d="m1267,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-8" d="m1271,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(2703 767) rotate(180)"/>
    <path class="cls-1 chair-9" d="m1551,0h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z"/>
    <path class="cls-1 chair-10" d="m1555,366h161c19.32,0,35,15.68,35,35h-231c0-19.32,15.68-35,35-35Z" transform="translate(3271 767) rotate(180)"/>
    <path class="cls-1 chair-11" d="m98,0h161c19.32,0,35,15.68,35,35H63C63,15.68,78.68,0,98,0Z"/>
    <path class="cls-1 chair-12" d="m102,366h161c19.32,0,35,15.68,35,35H67c0-19.32,15.68-35,35-35Z" transform="translate(365 767) rotate(180)"/>
  </g>
</svg>
`

export const bookingTables: { [key: string]: (tableColor?: string, seatColor?: string, chairColours?: { [key: number]: string }) => string } = {
  '1-circle': circle1,
  '2-circle': circle2,
  '4-circle': circle4,
  '6-circle': circle6,
  '8-circle': circle8,
  '1-square': square1,
  '2-square': square2,
  '4-square': square4,
  '2-rectangle': rectangle2,
  '4-rectangle': rectangle4,
  '6-rectangle': rectangle6,
  '8-rectangle': rectangle8,
  '10-rectangle': rectangle10,
  '12-rectangle': rectangle12,
}